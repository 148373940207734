/***user form fill ********/


button.edit-form-user {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  background: var(--dark-black-color);
  color: white;
  padding: 12px 12px;
  min-width: 208px;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  text-align: center;
  margin: 0 10px 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.form-fill-user {
  color: rgb(0 0 0 / 100%);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  word-break: break-word;
}


.form-fill-user-bold {
  color: rgba(43, 43, 43, 1);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 700;
  word-break: break-word;
}

.f-admin-fill label {
  color: var(--dark-black-color);
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  margin-bottom: 15px;
}

.f-admin-fill-light label {
  color: rgba(43, 43, 43, 0.7);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  margin-bottom: 15px;
}

.form-fill-user-active {
  color: #079463;
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
}

.form-fill-user-in-active {
  color: #F32131;
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
}
