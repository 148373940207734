/********share list model ********/

.share-list-feild {
  padding: 10px;
  border: 0px solid var(--boder-color);
  width: 100%;
  position: relative;
  border-radius: 5px;
  background: rgba(221, 222, 238, 0.25);
}

.share-list-f label {
  color: #000000;
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

.admin-status-dropdown-frm {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  overflow: auto;
  z-index: 1;
  margin: 15px 0 0 auto;
  left: 0;
  display: none;
  max-height: 185px;
  height: auto;
}

.share-list-feild.open .admin-status-dropdown-frm {
  display: block;
}

.share-list-feild .admin-status-dropdown-frm {
  display: none;
}

/* width */

.admin-status-dropdown-frm::-webkit-scrollbar {
  width: 8px;
}

/* Track */

.admin-status-dropdown-frm::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.admin-status-dropdown-frm::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
}

.error-input .share-list-feild {
  border: 1px solid var(--error-msg-color) !important;
}

.form-font-list-share {
  color: #000000;
  font-family: var(--arial-main);
  font-size: 13px;
  font-weight: 400;
  border-radius: 4px;
  background-color: rgb(221 222 238 / 0%) !important;
  width: 100%;
  padding: 0px 12px 0 0;
  border: 0 !important;
}

ul.list-share-drop {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.list-share-drop li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 0px 10px;
  cursor: pointer;
  /* color: rgb(0 0 0 / 50%); */
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
}

ul.list-share-drop li:hover {
  border-radius: 6px;
  background: #f2f7ff;
  color: var(--text-color);
  font-weight: var(--font-weight-medium);
}

.tags-share ul.tags-share-list {
  list-style: none;
  display: flex;
  gap: 8px;
  margin: 8px 0 0px 0;
  padding: 0;
  flex-wrap: wrap;
  width: 100%;
}

.tags-share ul.tags-share-list li img {
  height: 15px;
  width: 15px;
}

.tags-share ul.tags-share-list li {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 0.5px solid #f2f7ff;
  background: #e2edff;
  padding: 4px 8px;
  color: var(--primary-color);
  font-size: 13px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}