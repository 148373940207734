/****ats resume template****/

.name-res-ats {
    font-family: var(--Time-new-roman);
    font-weight: var(--font-weight-bold);
    color: var(--dark-black-color);
    font-size: 32px;
    text-align: center;
}

.location-res-ats {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    padding: 5px 0px 10px;
    border-bottom: 2px solid var(--dark-black-color);
}

.location-res-ats p {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--dark-black-color);
    font-size: 16px;
    line-height: 22px;
}

.resume-info-ats {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.res-ats-phone,
.res-mail-ats {
    display: flex;
    align-items: flex-start;
    gap: 5px;
}

.res-ats-phone span,
.res-mail-ats span {
    font-family: var(--Time-new-roman);
    font-weight: var(--font-weight-bold);
    color: var(--dark-black-color);
    font-size: 16px;
    word-break: break-all;
    line-height: 18px;
}

.res-ats-phone a {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--dark-black-color);
    font-size: 16px;
    text-decoration: none;
    word-break: break-all;
    line-height: 18px;
}

.res-mail-ats a {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--dark-black-color);
    font-size: 16px;
    text-decoration: none;
    word-break: break-all;
    line-height: 18px;
}

.res-mail-ats ul {
    margin-bottom: 0;
    padding-left: 20px;
}

.first-heading-res-ats {
    font-family: var(--Time-new-roman);
    font-weight: var(--font-weight-bold);
    color: var(--dark-black-color);
    font-size: 24px;
    text-align: center;
}

.res-sumry-ats {
    font-family: var(--Time-new-roman);
    font-weight: 500;
    color: var(--text-color);
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    padding: 10px 0 0px;
}

.resume-summar-ats {
    padding: 30px 0;
    border-bottom: 2px solid var(--dark-black-color);
}

.keys-list-ats {
    display: flex;
    justify-content: flex-start;
    gap: 5px 0;
    flex-wrap: wrap;
    padding-top: 15px;
}

.keys-list-content-ats {
    flex-basis: 33%;
    font-family: var(--Time-new-roman);
    font-weight: 500;
    color: var(--text-color);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.resume-technical-skill-ats {
    padding: 30px 0;
}

.add-content-ats {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--text-color);
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 20px 0;
    background-color: #ECECEC;
    margin-top: 12px;
}

.sub-heading-res-ats,
.sub-heading-res-right-ats {
    font-family: var(--Time-new-roman);
    font-weight: var(--font-weight-bold);
    color: var(--text-color);
    font-size: 18px;
    line-height: 24px;
}

.time-comp-res-ats {
    display: flex;
    justify-content: space-between;
    padding: 15px 0 5px 0;
}

.res-postion-ats,
.res-postion-ats span {
    font-family: var(--Time-new-roman);
    font-weight: var(--font-weight-bold);
    color: var(--text-color);
    font-size: 18px;
    line-height: 18px;
}

.resume-job-list-ats {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--text-color);
    font-size: 16px;
    line-height: 25px;
    text-align: justify;
    padding: 15px 0 0 0px;
}

a.project-link-res-ats {
    font-family: var(--Time-new-roman);
    font-weight: var(--font-weight-medium);
    color: var(--text-color);
    font-size: 16px;
}

.project-comp-res-ats {
    display: flex;
    justify-content: space-between;
    padding: 0px 0 10px 0;
}

.uni-heading-res-ats {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--text-color);
    font-size: 18px;
    line-height: 24px;
}

.edu-resume-ats {
    font-family: var(--Time-new-roman);
    font-weight: var(--font-weight-bold);
    color: var(--text-color);
    font-size: 16px;
    line-height: 28px;
}

.certification-res-ats {
    display: flex;
    justify-content: space-between;
    padding: 0px 0 0px 0;
}

.resume-certificate-ats {
    font-family: var(--Time-new-roman);
    font-weight: 400;
    color: var(--text-color);
    font-size: 16px;
    line-height: 28px;
    padding: 0px 0 20px 0px;
}

