.chat-wrapper {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 65px;
}

.chat-wrapper .chat-grid {
  display: flex;
  align-items: center;
  gap: 5px;
}

.chat-wrapper .chat-header {
  padding: 12px 16px;
  background: #F7F7FC;
  gap: 28px;
}

.chat-wrapper .chat-grid .listing-wrap {
  max-width: 370px;
  flex: 0 0 370px;
  height: 100%;
  overflow: auto;
}

.chat-wrapper .chat-grid .listing-wrap::-webkit-scrollbar {
  width: 8px;
  background-color: #EEEEEE;
}

.chat-wrapper .chat-grid .listing-wrap::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(43, 43, 43, .24);
}

.chat-wrapper .chat-grid .listing-wrap::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #EEEEEE;
}

.chat-wrapper .chat-grid .msg-wrap {
  flex: auto;
}

.msg-wrap.btns-group {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.msg-wrap.btns-group .btn-outline-black {
  border: 0.5px solid #2B2B2B4D;
  font-weight: 400;
}

.msg-wrap.btns-group .btn-outline-black.active {
  background: #2B2B2B;
  color: #fff;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.list-info {
  border-radius: 12px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 10px 20px;
  position: relative;
}

.list-info .user-avatar {
  display: flex;
  align-items: center;
  flex: 0 0 50px;
}

.list-info .user-avatar span {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.list-info .user-avatar span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.list-info .user-desc h4 {
  font-size: 18px;
  font-weight: 500;
  color: #2B2B2B;
  display: inline;
}

.list-info .user-avatar span .user-sub-avatar{
  margin-inline-end: -25px;
}

.user-desc {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  justify-content: center;
}

.user-content {
  width: 100%;
  display: flex;
}

.list-info .company-inbox {
  opacity: 100%;
  color: #2b2b2b;
  font-size: 12px;
  margin: 0;
}

.list-info p {
  opacity: 50%;
  color: #2b2b2b;
  font-size: 12px;
  margin: 0;
}

.list-info .user-option {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  gap: 10px;
}

.chat-wrapper .chat-body {
  height: 590px;
  align-items: unset;
}

.list-info.selected {
  box-shadow: 4px 0 0 var(--dark-black-color) inset;
  background: rgba(0, 159, 181, 0.06);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.list-info.unread {
  background: rgba(0, 159, 181, 0.16);
}

.list-info.unread h4 {
   font-weight: var(--font-weight-bold);
}

.list-info.unread p {
  opacity: 1;
  font-weight: var(--font-weight-medium);
}

.inbox-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 24px;
  overflow: hidden;
  border: 1px solid rgb(43 43 43 / 20%);
}

.inbox-wrapper .inbox-body {
  flex: auto;
  height: 405px;
  overflow: auto;
}

.inbox-wrapper .inbox-body::-webkit-scrollbar {
  width: 8px;
  background-color: #EEEEEE;
}

.inbox-wrapper .inbox-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(43, 43, 43, .24);
}

.inbox-wrapper .inbox-body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #EEEEEE;
}

.inbox-wrapper .inbox-footer {
  padding: 16px 12px;
  background: #F0F2F5;
  position: relative;
}

.inbox-wrapper .inbox-header {
  padding: 16px 24px;
  background: #F7F7FC;
  cursor: pointer;
}

.clint-avatar-right {
  margin-inline-end: -25px;
} 

.clint-info-right {
  margin-left: 30px;
}

.search-field-inbox{
  border: 0.5px solid #2B2B2B4D;
  width: 93%;
}
/* Employer Index Right Card */

.message-container.message-right {
  width: 100% !important;
  max-width: 350px !important;
  align-items: flex-end !important;
  margin: 15px 0 15px auto;
  padding: 0 30px 0 0;
}

.message-content-right{
  background-color: rgba(0, 159, 181, 0.16) !important
}

.right-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: -30px;
  border: 2px solid #00aced;
  position: absolute;
  left: revert;
  top: 10px;
}


.message-container .time-stamp {
  margin: 0 0 0 auto !important;
  display: flex;
  justify-content: end;
}

.message-container.message-right .time-stamp {
  margin: 0 auto 0 0 !important;
  display: flex;
  justify-content: start;
}

/* Employer Index */
.msg-wrap.btns-group .btn-outline-black.active {
  background: #2b2b2b;
  color: #fff;
}

.msg-wrap.btns-group .btn-outline-black {
  border: .5px solid #2b2b2b4d;
  font-weight: 400;
}

.right-btn-chat {
  display: inline-flex;
  width: 100%;
  gap: 20px;
  align-items: center;
}

.invite-pople-list {
  padding: 7px 12px;
  border: .5px solid #2b2b2b4d;
  width: 100%;
  position: relative;
  border-radius: 5px;
  background: #fff;
}

.invite-pople-list.open .admin-status-dropdown-frm {
  display: block;
  z-index: 2;
}

.invite-pople-list .admin-status-dropdown-frm {
  display: none;
}

.add-pople-icon {
  border: .5px solid #2b2b2b4d;
  padding: 6px 9px;
  border-radius: 5px;
  background: #ffff;
}

.field-f-inbox {
  max-width: 35% !important;
}

.invite-pople-list-img {
  width: 10px;
}
/* ellipse dropdown*/

.ep-inbox {
  width: 20px;
}

.dd-listitem-inbox .btn {
  line-height: 1;
}

.dd-listitem-inbox .cv-cions {
  box-shadow: 0 1px 12px 4px #0000000f;
  display: none;
  position: absolute;
  z-index: 9;
  right: 0;
}

.dd-listitem-inbox .cv-cions ul {
  list-style: none;
  padding: 12px;
  border-radius: 8px;
  background-color: #fff;
  min-width: 200px;
  width: 200px;
  margin: 0;
}

.dd-listitem-inbox .cv-cions ul li {
  padding: 8px;
  transition: 0.25s all ease-in;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.dd-listitem-inbox .cv-cions ul li:hover {
  background: #ECECEC;
  font-weight: 700;
}

.dd-listitem-inbox .cv-cions.active {
  display: block;
}

.dd-pinned {
  width: 20px;
}

/******************/


/***chat sticky note***/

.chat-box-note {
  width: 310px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  padding: 15px 10px 15px 20px;
  overflow: visible;
  cursor: pointer;
  opacity: 1;
  z-index: 2;
  position: absolute;
}

.invite-pople-heading .chat-box-note {
  cursor: pointer;
}

.chat-header-note {
  text-align: left;
  font-size: 20px;
  padding: 0px 10px 10px 20px;
  line-height: 23px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: 700;
  width: calc(100% + 31px);
  margin-left: -20px;
  border-bottom: 1px solid #F0F2F3;
}

.chat-header-note::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 16px;
  border-width: 10px 15px 10px 0;
  border-style: solid;
  border-color: #ffffff00 white #ffffff00 #fbfbfb00;
  z-index: 1;
}

.chat-list-note {
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  overflow: auto;
  background-color: white;
  max-height: 150px;
  height: auto;
}


/* width */

.chat-list-note::-webkit-scrollbar {
  width: 8px;
}


/* Track */

.chat-list-note::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


/* Handle */

.chat-list-note::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--dark-black-color);
  background-color: var(--dark-black-color);
}

.chat-list-note li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 0px solid #f1f1f1;
}

.chat-list-note li:last-child {
  border-bottom: none;
}

.chat-list-note img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.user-status-list {
  width: 8px;
  height: 8px;
  background-color: var(--dark-black-color);
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}

p.name-chat-own {
  font-size: 16px;
  line-height: 25px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: 400;
}


/*************/


@media(max-width: 767px) {
  .chat-wrapper .chat-header {
    flex-direction: column;
    gap: 5px;
    padding: 0;
  }

  .chat-wrapper .chat-header .listing-wrap {
    width: 100%;
  }

  .chat-wrapper .chat-grid .listing-wrap {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .chat-wrapper .chat-header .listing-wrap {
    text-align: center;
  }

  .chat-wrapper .chat-grid .msg-wrap {
    display: none;
  }

  .msg-wrap.btns-group {
    padding: 16px 0;
    width: 100%;
    border-top: 1px solid #2B2B2B;
    border-bottom: 1px solid #2B2B2B;
    margin-top: 15px;
    display: flex !important;
  }

  .inbox-wrapper .inbox-header {
    padding: 16px;
  }

}