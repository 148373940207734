/**********choose resume model*********/

.choose-job-model-popup-bg {
  width: 688px;
  margin: 0 auto;
  overflow: hidden;
}

.choose-upload-heading {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
  font-size: 24px;
  text-align: center;
  padding: 20px 0 10px 0;
}

.sub-text-choose-res {
  font-family: var(--arial-main);
  color: rgba(0, 0, 0, 0.60);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 0px 0 12px 0;
}

.popup-resume-content-choose {
  width: 100%;
  height: 560px;
  margin: 50px auto 0;
  border-radius: 16px;
  background: var(--color-white);
  padding: 20px 40px 20px 40px;
  position: relative;
}

.scroll-resume-mdl-job {
  overflow: auto;
  max-height: 380px;
  overflow-y: auto;
}

.scroll-resume-mdl-job::-webkit-scrollbar {
  width: 8px;
}

.scroll-resume-mdl-job::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.scroll-resume-mdl-job::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--dark-black-color);
}

.choose-res-row {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: var(--color-white);
  margin-bottom: 8px;
  margin-right: 8px;
}

.choose-remove-row {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: #E15C52;
  margin-bottom: 8px;
  justify-content: center;
  margin-right: 8px;
}

.remove-f-mdl {
  text-align: center;
  padding: 20px 0;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 600;
}

.choose-res-all-content {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 9px 12px 9px 8px;
  border-left: 1px solid rgba(0, 0, 0, 0.16);
  gap: 10px;
}

.pdf-res-icon {
  padding: 11px 17px;
}

p.name-file-choose {
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: 600;
}

a.job-link-modl {
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  word-break: break-word;
}

span.link-mdl-j {
  color: var(--dark-black-color);
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}

p.date-res-choose {
  color: var(--dark-black-color-500);
  font-size: 12px;
  font-weight: 400;
}

img.dowld-res {
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid var(--dark-black-color-600);
}

img.pdf-icon-res {
  padding-right: 12px;
}

.two-icon-choose-res {
  max-width: 18%;
  text-align: end;
  width: 100%;
}

.two-icon-choose-res img {
  max-width: 100%;
}

.show-less {
  text-align: end;
  padding: 10px 0;
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
}

.show-less::before,
.show-less::after {
  content: "";
  position: absolute;
  height: 100%;
}

.show-less::before {
  left: 0;
  width: calc(50% - 71px);
  border-top: 1px solid rgb(0 0 0 / 20%);
  top: 20px;
}

.show-less::after {
  right: 0;
  width: calc(50% - 71px);
  border-top: 1px solid rgb(0 0 0 / 20%);
  top: 20px;
}