:root {
  /*** COLORS ***/
  --primary-color: #143975;
  --secondary-color: #F2F7FF;
  --accent-color: #FFA033;
  --text-color: #000000;
  --color-white: #fff;
  --color-grey: #FBFBFB;
  --btn-color: #E2EDFF;
  --boder-color: #BDBDBD;
  --pag-fill-light: #898989;
  --form-text-account: #64A7B6;
  --bg-color-sec: #FCFCFC;
  --error-msg-color: #E15C52;
  --login-text: #54A8C4;
  --or-text-color: #4F4F4F;
  --fomr-telcode-bg: #F9F9F9;
  --card-home-bg: #F3F6FB;

  /*** COLORS polycare ***/
  --pacific-blue-color: #009FB5;
  --pacific-blue-color-300: rgba(0, 159, 181, 0.03);
  --pacific-blue-color-700: rgba(0, 159, 181, 0.07);

  --pacific-light-blue-color: #F3F8F9;
  --light-aqua-color: #D9F1F4;
  --dark-black-color: #2B2B2B;
  --dark-black-color-500: rgba(43, 43, 43, .5);
  --dark-black-color-200: rgba(43, 43, 43, .2);
  --dark-black-color-600: rgba(43, 43, 43, .16);
  --dark-black-color-060: rgba(43, 43, 43, 0.60);
  --dark-black-color-800: rgba(43, 43, 43, .8);

  --white-smoke-color: #F6F6F6;
  --white-smoke-light-color: #F3F3F3;
  --pattens-blue-color: #D9F1F4;
  --Whisper-color: #ECECEC;
  --Whisper-light-color: #EEEEEE;
  --tag-bg-color: #ECECEC;
  --neutral-color: #170F49;
  --solitude-color-7: #D8E6F7;
  --solitude-color-6: #DFEAF6;
  --snow-drift-color: #D9E8E9;
  --gainsboro-color: #E0D9D7;
  --grey27-color: #454545;
  --grey48-color: #7A7A7A;
  --suva-grey-color: #8E8E8E;
  --blue-gery-color: #375b97;
  --blue-alice-color: #FAFDFF;

  /*** FONT SIZES ***/
  --contact-heading: 55px;
  --main-heading: 45px;
  --cookies-heding: 40px;
  --form-heading: 36px;
  --sub-heading: 32px;
  --fig-caption: 30px;
  --text-size: 24px;
  --body-text: 20px;
  --button-text: 16px;
  --skill-text-size: 14px;
  --promoted-btn: 18px;
  /*** FONT FAMILY ***/
  --museo-sans: 'Museo Sans';
  --fira-sans: 'Fira Sans';

  /*** FONT FAMILY polycare***/
  --arial-main: 'Arial';
  --source-pro: 'Source Serif Pro';
  --outfit: 'Outfit';
  --Time-new-roman: 'Times New Roman';

  /*** FONT WEIGHT ***/
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-light: 300;
}

/*** FONTS *****/

@font-face {
  font-family: "Fira Sans";
  src: url(/public/fonts/FiraSans-Bold.woff2) format("woff2"),
    url(/public/fonts/FiraSans-Bold.woff) format("woff"),
    url(/public/fonts/FiraSans-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Museo Sans";
  src: url(/public/fonts/museosans_300_macroman.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Museo Sans";
  src: url(/public/fonts/MuseoSans_500-webfont.woff) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Museo Sans";
  src: url(/public/fonts/MuseoSans_700-webfont.woff) format("woff");
  font-weight: 700;
}

/*** FONTS polycare *****/

@font-face {
  font-family: 'Arial';
  src: url(/public/fonts/ArialMT.woff) format('woff');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial GEO';
  src: url(/public/fonts/ArialGEOBold.woff) format('woff');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif Pro';
  src: url(/public/fonts/SourceSerifPro-Regular.woff) format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif Pro';
  src: url(/public/fonts/SourceSerifPro-Semibold.woff) format('woff');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Source Serif Pro';
  src: url(/public/fonts/SourceSerifPro-Bold.woff) format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url(/public/fonts/Outfit-Medium.woff2) format('woff2');
  font-weight: 500;
}


@font-face {
  font-family: 'Times New Roman';
  src: url(/public/fonts/times_newroman.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Times New Roman';
  src: url(/public/fonts/times_newroman_bold.ttf);
  font-weight: 700;
}

/*** HEADING TEXT SIZE****/

.text-primary {
  color: var(--pacific-blue-color) !important
}

.main-block {
  min-height: calc(100vh - 70px);
}

body {
  font-family: var(--arial-main);
  background-color: var(--color-grey);
}

h1 {
  font-size: var(--main-heading);
  font-family: var(--arial-main);
  color: var(--dark-black-color);
}

h2 {
  font-family: var(--arial-main);
  font-size: var(--main-heading);
  color: var(--dark-black-color);
}

h3 {
  font-family: var(--arial-main);
  font-size: var(--text-size);
  color: var(--dark-black-color);
}

h4 {
  font-family: var(--arial-main);
  font-size: var(--contact-heading);
  color: var(--dark-black-color);
}

h5 {
  font-family: var(--arial-main);
  font-size: var(--sub-heading);
  color: var(--dark-black-color);
}

h6 {
  font-family: var(--arial-main);
  font-size: var(--cookies-heding);
  color: var(--dark-black-color);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p {
  margin-bottom: 0rem !important;
}

html {
  font-size: 14px;
}

.container-site {
  max-width: 1170px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.container-site-profile {
  max-width: 1140px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}

.container-site-job-match {
  max-width: 1920px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.container-medium {
  max-width: 1440px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.container-small {
  max-width: 1340px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}


/************/

.all-page-bg-clr {
  background: #FBFBFB;
}

.col-two-button .find-job-btn {
  margin-right: 80px;
}

button.find-j-nav {
  background: var(--primary-color);
  color: var(--color-white);
  padding: 8px 53px;
  text-align: center;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  font-family: var(--arial-main);
  font-size: 18px;
  font-weight: 500;
}

button.login-header {
  padding: 8px 32px;
  font-size: 18px;
  background: transparent;
  color: var(--primary-color);
  border-radius: 8px;
  border: 1px none var(--primary-color);
  cursor: pointer;
  font-family: var(--arial-main);
  font-weight: 600;
}

button.signuo-nav {
  background: var(--primary-color);
  color: var(--color-white);
  padding: 8px 32px;
  font-size: 18px;
  text-align: center;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  font-family: var(--arial-main);
  font-weight: 500;
}

.mr-1 {
  margin-right: 0.25rem;
}