.test-percent {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 28%;
    /* transform: translateY(-50%); */
    text-align: center;
}

.test-percent p{
    font-size: 30px;
    font-weight: 700;
    margin: 0;
}