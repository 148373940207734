/*************job detail of company show *********/

section.job-description-sec {
  padding: 40px 0px 0px 0;
}

.job-company-details {
  max-width: 72%;
  width: 100%;
  padding-right: 40px;
}

.company-job-announcements {
  max-width: 28%;
  width: 100%;
  border-radius: 16px;
  border: 0.5px solid var(--dark-black-color-200);
  padding: 32px 24px;
}

.Job-Opportunities-row-company {
  display: flex;
  padding: 30px 0px 30px 0px;
  justify-content: space-between;
  width: 100%;
}

.job-heading-detail-sec h5 {
  font-weight: var(--font-weight-bold);
  margin-bottom: 0px;
  padding: 0px 20px;
  border-radius: 8px;
  font-size: 20px;
}

.recruite-note-box h5 {
  font-weight: var(--font-weight-bold);
  text-align: center;
  font-size: 20px;
  color: var(--text-color);
}

.recruite-note-box {
  margin-top: 40px;
  margin-bottom: 0px;
  border-radius: 12px;
  background-image: url(/public/image/rec-bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid rgba(20, 57, 117, 0.2);
  background-size: 100% 100%;
  padding: 24px 20px;
}

.recruite-note-box p {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 300;
  font-family: var(--arial-main);
  padding: 5px 0 5px 0px;
  line-height: 32px;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

.job-details-first-title h5 {
  font-weight: var(--font-weight-bold);
}

/* .job-heading-detail-sec p {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 300;
  font-family: var(--arial-main);
  padding: 32px 0 0px 20px;
  line-height: 32px;
  text-align: justify;
} */

.job-heading-detail-sec ul,
.job-heading-detail-sec p {
  /* padding: 32px 0 0px 40px; */
  /* padding: 20px; */
  margin-bottom: 0;
}

.view-job-detail-space-1,
.view-job-detail-space-2{
  padding: 20px 20px;
}

/* .view-job-detail-space-1 p {
  padding: 0px 20px;
} */

.job-heading-detail-sec ul {
  padding-left: 40px;
}

.job-heading-detail-sec ul li,
.job-heading-detail-sec p {
  color: rgb(0 0 0 / 100%);
  font-size: 16px;
  line-height: 25px;
}

.job-heading-detail-sec {
  padding-bottom: 0px;
}

.job-location-title {
  /* padding: 0 0 40px 0; */
}

ul.job-location-list {
  /* padding: 10px 0 0 30px; */
  margin: 20px 0px;
}

ul.job-location-list li {
  /* padding: 10px 0 0 0; */
  color: var(--text-color);
  font-size: 16px;
  font-weight: 300;
}

.job-location-title p {
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--text-size);
}

.company-right-col {
  border-top: 0.5px solid var(--dark-black-color-200);
}

.j-com-dev-info {
  padding: 16px 0;
}

p.company-j-info-l {
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--dark-black-color);
  font-size: 20px;
}

p.j-l-text {
  color: rgb(0 0 0 / 100%);
  font-weight: 300;
  font-size: 16px;
  padding-top: 16px;
}

.skill-list-j ul,
.skill-list-j p {
  color: rgb(0 0 0 / 100%);
  font-weight: 300;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 0;
  line-height: 32px;
  padding-top: 15px;
}

.skill-list-j ul li {
  color: rgb(0 0 0 / 100%);
  font-size: 16px;
  line-height: 25px;
  text-align: start;
}

.qr-code-img {
  text-align: center;
}

.qr-code-img img {
  padding-top: 20px;
  width: 150px;
  height: 180px;
}

.job-skills-detail-col {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
}

.job-skills-detail-col button {
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-size: var(--skill-text-size);
  border-radius: 25px;
  border: 0;
  padding: 5px 8px;
  font-weight: 400;

  /* padding: 7px 12px;
  color: var(--primary-color);
  font-size: var(--skill-text-size);
  border-radius: 4px;
  border: 1px solid #d4e5ff;
  background: #f2f7ff; */
}

.job-search-box-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

#jon-search-box {
  border: 0;
  width: 85%;
  margin-left: 5px;
  background: transparent !important;
}

.smilar-job-heading {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}

.smilar-job-heading h2 {
  padding-bottom: 0;
}

.smilar-job-heading h2 {
  text-align: center;
}

.loader-div {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width:767px) {
  .open-pos-lg-head {
    display: none !important
  }
  .job-heading-detail-summary{
     padding-top: 20px !important;  
  }
}