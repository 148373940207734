.card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.btn-transparent {
  border: 0;
  box-shadow: none;
  outline: 0;
}