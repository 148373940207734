/************** world conect section **********/

.world-conect-sec {
  background: var(--color-white);
  padding: 65px;
}


/************world ********/

.world-conect-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.world-conect-row img {
  max-width: 100%;
}

.world-conect-row p {
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

/************** world conect section - poly **********/

section.all-oversite-sec {
  padding: 50px 0;
  background: var(--color-white);
}

.site-text-poly-sec {
  text-align: center;
  font-family: var(--arial-main);
  font-weight: 700;
  font-size: 32px;
  color: var(--dark-black-color);
}

@media only screen and (max-width:767px) {
  .earth-mbl {
    width: 45px;
    height: 45px;
  }

  .sm-font {
    font-size: 16px !important;
  }

  /************** world conect section - poly **********/

  section.all-oversite-sec {
    padding: 25px 0;
  }

  .site-text-poly-sec {
    font-size: 20px;
  }

}