.notes-container {
  border-radius: 16px;
}

.header {
  padding: 21px 30px;
  background-color: rgba(247, 247, 247, 1);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: 1px solid rgba(43, 43, 43, 0.2);
}

.footer-with-action {
  padding: 21px 30px;
  background-color: rgba(247, 247, 247, 1);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: 1px solid rgba(43, 43, 43, 0.2);
}

.border {
  border-color: rgba(43, 43, 43, 0.16) !important;
}

.notes-header-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
}

.notes-users-count {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

.notes-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 27.6px;
}

.notes-area {
  height: 512px;
}

.border-muted {
  border-color: #dee2e6 !important;
}

.date-border {
  height: 1px;
  background-color: rgba(43, 43, 43, 0.16);
}

.date {
  width: 30rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.1px;
}

.notesbox {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px 14px;
}

.notesbox .chat-slot {
  padding: 30px 24px 20px;
  border: 2px solid rgba(43, 43, 43, 1);
  border-radius: 32px;
  flex: 0 0 calc(33.33% - 16px);
  box-shadow: 0px 6px 12px 0px rgba(18, 28, 45, 0.1);
  position: relative;
}

.notesbox .chat-slot .single-note-item {
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.no-notes {
  height: 720px;
  text-align: center;
  padding-top: 50px;
  border-top: 1px solid rgba(43, 43, 43, 1)
}

.pin-chat-icon {
  position: absolute;
  right: 24px;
  top: 7px;
  cursor: pointer;
}

.no-notes p {
  margin-bottom: 20px !important;
  font-size: 20px;
}

.new-notes-box textarea {
  background: rgba(221, 222, 238, 0.25);
  padding-bottom: 25px;
  height: 210px;
}

.new-notes-box .label-field {
  position: relative;
}

.new-notes-box .label-field p {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 16px;
  color: rgba(43, 43, 43, 1);
}


.notesbox .chat-slot .note-item {
  padding: 0;
  border: 0;
  box-shadow: none;
}

.notesbox .chat-slot .note-item .note-item-text {
  font-size: 16px;
  line-height: 25px;
  width: 100%;
}

.user-avatar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-avatar .avatar-img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;
}

.user-avatar .avatar-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-avatar .avatar-desc {
  font-weight: 700;
  font-size: 16px;
}

.notes-scr .cand-inque {
  background: #f7f7f7;
}

.note-item {
  padding: 16px;
  border-radius: 16px;
}

.note-item-text {
  width: 80%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.note-item-time {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.note-item h4 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 6px;
}

.text-edited {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.avatars img:not(:first-child) {
  margin-top: -0.25rem;
  margin-left: -15px;
}

.border-white {
  border-color: white !important;
}

.edit-btn-profile {
  height: 44px;
}

.status-badge {
  width: 12px;
  height: 12px;
  margin-top: -2px;
}

@media only screen and (max-width: 576px) {


  .notes-area {
    height: 356px;
  }

  .note-item-text {
    width: 100% !important
  }

  .recruiter-avatar {
    width: 32px;
    height: 32px;
  }

  .notes-header-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.4px;
  }

  .status-badge {
    width: 10px;
    height: 10px;
  }

  .text-edited {
    font-weight: 400;
    font-size: 12px;
    line-height: 13.8px;
  }

  .header {
    padding: 12px;
    border-bottom-color: white;
  }

  .avatars img {
    border: 2px solid white;
    width: 32px;
    height: 32px;
  }


  .note-item {
    padding: 12px;
    border-radius: 12px;
  }

  .date {
    width: 50rem;
  }

  .footer-with-action {
    padding: 12px;
    background-color: #F0F2F5;

    input {
      background-color: #F8F8F8;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
    }

    button {
      background-color: #F8F8F8 !important;
    }
  }

  .edit-btn-profile {
    height: 32px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  .note-edit-input {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    background-color: #F3F4F8;
  }

  .container-with-border {
    border: 1px solid rgba(43, 43, 43, 0.16) !important;
    border-radius: 16px;
  }

}

.invite-members-footer {
  padding: 16px 46px !important;
}

/*********journey page start here***********/

.single-note-item {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.note-item {
  box-shadow: 0px 4px 32px 6px rgba(0, 0, 0, 0.05);
  border: 0.5px solid rgba(43, 43, 43, 0.20);
  border-radius: 12px;
}

.single-note-item .note-edit-input {
  border-radius: 6px;
  border: 1px solid rgba(43, 43, 43, 0.20);
  background: #F3F4F8;
  font-size: 14px;
  min-height: 140px;
}

.single-note-item .btn {
  font-weight: 400;
}

@media(max-width: 767px) {
  .notesbox {
    gap: 12px;
  }
  .notesbox .chat-slot {
    flex: 0 0 100%;
  }
}