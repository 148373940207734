/*******admin cards******/

.admin-cards {
  padding: 40px 80px 50px 20px;
}

.all-admin-cards-rows {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
}

.admin-card-one {
  max-width: 29%;
  width: 100%;
  padding: 20px 24px 24px;
  border-radius: 12px;
  box-shadow: 0px 1px 25px 7px rgba(193, 193, 193, 0.22);
}

p.admin-main-title {
  font-family: var(--arial-main);
  font-size: 20px;
  line-height: 38px;
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
  padding-top: 0px;
}

ul.crad-admin-list {
  padding: 0px 0 30px 0;
  margin: 0;
  list-style-type: none;
}

ul.crad-admin-list li {
  font-family: var(--arial-main);
  font-size: 14px;
  line-height: 21px;
  font-weight: var(--font-weight-medium);
  color: var(--primary-color);
  padding-top: 12px;
  background: url(/public/image/list-icon-admin.png) no-repeat 0px 13px;
  padding-left: 25px;
}

button.admin-continue {
  text-align: end;
  display: block;
  margin: 0 0 auto auto;
  border-radius: 6px;
  background: var(--color-white);
  box-shadow: 0px 2px 18px 4px rgba(0, 0, 0, 0.10);
  border: 0;
  padding: 12px 24px;
  font-size: 14px;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
}

.card-bg-0 {
  background: #B8DCE7;
}

.card-bg-1 {
  background: #D3E6EF;
}

.card-bg-2 {
  background: #B3DAFB;
}

.card-bg-3 {
  background: #D8ECFF;
}

.card-bg-4 {
  background: #C4E9FC;
}

.card-bg-5 {
  background: #FFC685;
}

.card-bg-6 {
  background: #FFC685;
}