/*****************pagenation of top match page ***********/

section.pageniation-sec {
  padding-bottom: 50px;
}

.pagination-job {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.fill-box {
  background: var(--dark-black-color);
  color: var(--color-white) !important;
  border: 1px solid var(--dark-black-color) !important;
}

.fill-box.light-bg {
  background: var(--pag-fill-light);
  color: var(--color-white) !important;
  border: 1px solid var(--pag-fill-light) !important;
}

.pagination-job a {
  color: var(--boder-color);
  padding: 8px 10px 5px;
  text-decoration: none;
  border: 1px solid var(--boder-color);
  border-radius: 4px;
  margin: 0 5px;
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  line-height: 1;
}

.pagination-job a.active {
  color: var(--dark-black-color);
  border: 2px solid var(--dark-black-color);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}