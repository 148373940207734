.adJob-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  border: 1px solid #eee;
  overflow: hidden;
  padding: 0 10px;
  max-width: 250px;
  flex: 0 0 250px;
  display: flex;
  flex-direction: column;
}

.btColorActive{
  border-top: 10px solid #5AAC99 !important;
}

.btColorInactive{
  border-top: 10px solid #C7C8CC !important;
}

.adJob-card-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 12px 0 47px;
  gap: 8px;
}

.adJob-card-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: bold;
}

.adJob-card p.with-icon {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2B2B2B;
  margin-top: 8px;
}

.adJob-card-body {
  padding: 0px 0 10px;
  flex: 1;
}


.adJob-card-footer {
  padding: 8px 0px;
  font-size: 0.8rem;
  color: #2B2B2B;
  border-top: 1px solid #eee;
  text-align: right;
}

.ellipses-dd {
  position: relative;
}

.ellipses-dd .cv-cions {
  position: absolute;
  right: 0;
  top: 0%;
  opacity: 0;
  flex-direction: column;
  padding: 12px 8px;
  width: 180px;
  box-shadow: 0px 1px 12px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  gap: 0;
  transition: 0.25s all ease-in;
  z-index: -9;
  background-color: var(--color-white);
}

.ellipses-dd .cv-cions.active {
  opacity: 1;
  top: 100%;
  z-index: 1;
}

.ellipses-dd .dd-val {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 0;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
}

.ellipses-dd .dd-val:hover {
  background: #ECECEC;
}

.ellipses-dd .dd-val img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.ellipses-dd .dd-content {
  font-size: 14px;
}

.user-status {
  display: inline-block;
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #eee;
}

.user-status.active{
  background: #07944f;
}
.user-status.inactive{
  background: #e15c52;
}