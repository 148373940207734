/************bulk resume admin********/

.th-zip-files-name{
  width: 130px;
}

.th-total-files{
  width: 100px;
}

.th-status-res {
  width: 130px;
}

.th-resume-analyzed {
  width: 155px;
}

.th-date-submitted {
  width: 150px;
}

.four-resume-img {
  position: relative;
  display: flex;
  justify-content: center;
}

.four-resume-img img {
  margin-right: 9px;
}

.tooltip-jd-resume {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: -33px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: var(--color-white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

.tooltip-request-analysis-res {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: -34px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

.tooltip-request-detail-res {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: -65px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

img.show-tooltip2:hover ~ .tooltip-request-analysis-res {
  opacity: 1;
}

img.show-tooltip3:hover ~ .tooltip-request-detail-res {
  opacity: 1;
}

img.show-tooltip:hover ~ .tooltip-jd-resume {
  opacity: 1;
}

.select-filter-resume {
  display: flex;
  align-items: center;
  justify-content: end;
  max-width: 15%;
  width: 100%;
  gap: 10px;
  margin: 0 10px 10px auto;
  position: relative;
}

.select-filter-resume label {
  color: var(--dark-black-color);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.text-filter-admin-bulk {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.text-filter-admin-bulk p {
  color: #000000;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.page-sub-heading {
  margin: 7px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.resume-batch-sub-heading {
  margin: 15px 0px;
}

.resume-batch-sub-heading-1 {
  font-family: var(--arial-main);
  font-size: 24px;
  line-height: 22px;
  text-align: left;
}

.resume-batch-sub-heading-2 {
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin: 15px 0px;
}

.resume-batch-sub-heading p {
  font-weight: 500;
}

.resume-batch-sub-heading span {
  font-weight: 700;
}

.resume-batch-t-p-time {
  text-align: center !important;
}

.resume-batch-progress-container {
  display: inline-block;
  position: relative;
  width: 55px;
  height: 55px;
}