.feild-f-name-invite {
  width: 100%;
  max-width: 100% !important;
}

/****invite pepole model css*******/

section.invite-model {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 80%);
  top: 0%;
  z-index: 9;
}

.popup-bg-invite-pople {
  width: 750px;
  margin: 0 auto;
  border-radius: 18px;
  padding: 14px 24px;
  background: #fff;
  box-shadow: 0px 1px 25px 8px rgba(0, 0, 0, 0.12);
  margin: 50px auto 0;
}

.inivite-logo img {
  max-width: 100%;
  width: 145px;
  margin: 0 auto;
}

.popup-invite-list-content {
  width: 100%;
  margin: 0px auto 0;
  position: relative;
}

.close-img-invite {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.invite-pople-heading {
  text-align: left;
  font-size: 24px;
  line-height: 28px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  padding: 20px 0 15px 0;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
}

p.chat-text-box {
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: 400;
  opacity: 0.5;
  padding: 10px 0 0px 0;
}

.invite-from-step {
  display: flex;
  align-items: flex-start;
  gap: 25px 18px;
  flex-wrap: wrap;
}

.list-of-pepole {
  display: flex;
  align-items: center;
  border: 1px solid #2b2b2b33;
  gap: 15px;
  padding: 6px 10px;
  border-radius: 8px;
  margin-top: 10px;
  flex-wrap: wrap;
  position: relative;
  word-break: break-word;
}

.img-invite-phone {
  width: 150px;
  max-width: 150px;
}

a.img-invite-phone {
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: none;
  font-size: 13px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: 400;
}

.img-invite {
  width: 180px;
  max-width: 180px;
}

a.img-invite {
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: none;
  font-size: 13px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: 400;
}

.image-name-log-invite {
  display: flex;
  gap: 15px;
  width: 220px;
  max-width: 220px;
  align-items: center;
}

.image-name-log-invite img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

p.invite-name {
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: 400;
}

p.degnation-invite {
  color: #2b2b2b99;
  font-size: 10px;
  line-height: 18px;
  font-family: var(--arial-main);
  font-weight: 400;
}

.searchbox-invite {
  padding: 12px 12px 0;
  position: relative;
}

.searchbox-invite:before {
  background: url(/public/image/job-search.png) no-repeat;
  background-size: contain;
  content: "";
  height: 20px;
  left: 28px;
  position: absolute;
  top: calc(50% + 5px);
  transform: translateY(-50%);
  width: 20px;
}

.inivte-pepole-list-sec {
  margin: 10px 0 0 0;
  background: #fff;
  overflow: auto;
  max-height: 220px;
  overflow-y: auto;
  border-top: 1px solid rgb(0 0 0 / 20%);
  border-bottom: 1px solid rgb(0 0 0 / 20%);
}

.searchbox-invite input {
  border: 1px solid #2b2b2b66 !important;
  border-radius: 12px;
  padding: 10px 16px 10px 44px;
  width: 100% !important;
}

input.location-search-field {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inivte-pepole-list-sec::-webkit-scrollbar {
  width: 12px;
}

.inivte-pepole-list-sec::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.inivte-pepole-list-sec::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: 0px 4px 4px 0px #00000000 inset; */
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
  background-color: #2b2b2b3d;
}

.list-of-pepole:last-of-type {
  margin-bottom: 15px;
}

.text-filter-icon div img,
.text-filter-icon img,
.text-filter-icon-table div img {
  height: 10px;
}

/************bulk resume admin********/

.th-status-res {
  width: 130px;
}

.four-resume-img {
  position: relative;
  display: flex;
  justify-content: center;
}

.four-resume-img img {
  margin-right: 9px;
}

.tooltip-jd-resume {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: -33px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: var(--color-white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

.tooltip-request-analysis-res {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: -34px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

.tooltip-request-detail-res {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: -65px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

img.show-tooltip2:hover ~ .tooltip-request-analysis-res {
  opacity: 1;
}

img.show-tooltip3:hover ~ .tooltip-request-detail-res {
  opacity: 1;
}

img.show-tooltip:hover ~ .tooltip-jd-resume {
  opacity: 1;
}

.select-filter-resume {
  display: flex;
  align-items: center;
  justify-content: end;
  max-width: 15%;
  width: 100%;
  gap: 10px;
  margin: 0 10px 10px auto;
  position: relative;
}

.select-filter-resume label {
  color: var(--dark-black-color);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.text-filter-admin-bulk {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.text-filter-admin-bulk p {
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

p.user-name-inbox {
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: 20px;
}

button.next-chat {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  min-width: 140px;
  padding: 12px 10px;
  background: var(--dark-black-color);
  color: var(--color-white);
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
}

.search-container-inbox {
  padding-top: 30px;
}

.divider.divide-mbl-2 {
display: none;
}

/*******two boxes ****/

.two-radio-box {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 20px 0;
  width: 430px;
  margin: 0 auto;
}

.box-radio-btn {
  text-align: center;
  background: #fff;
  border: 1px solid #2b2b2b29;
  padding: 0 20px;
  height: 80px;
  display: flex;
  width: 196px;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  gap: 6px;
  border-radius: 16px;
  /* opacity: 0.5; */
}

.img-invite-pople img {
  height: 34px;
  width: 34px;
  object-fit: contain;
}

.img-invite-pople {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  border: 1px solid #2b2b2b33;
  justify-content: center;
  border-radius: 8px;
}

p.box-scd-para {
  color: #2b2b2bb2;
  font-size: 11px;
  font-family: var(--arial-main);
  font-weight: 400;
}

p.box-first-pra {
  color: var(--dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 700;
}

.box-radio-active {
  border: 2px solid #009fb5;
}

.box-radio-active .img-invite-pople {
  border: 1px solid #009fb5;
  background: #009fb514;
}

input.chek-btn-box {
  accent-color: var(--dark-black-color);
}

.img-invite-tooltip {
  background: #0000;
  border: 0;
  font-size: 18px;
  left: 0;
  opacity: 1;
  padding: 0 0 0 8px;
  position: relative;
  top: 0;
  cursor: pointer;
}

.tooltip-invite {
  position: absolute;
  top: -25px;
  display: inline-block;
  right: -19px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: var(--color-white);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  font-weight: 500;
  white-space: nowrap;
}

img.invite-res:hover ~ .tooltip-invite {
  opacity: 1;
}

/***********/

.invite-form-box {
  background: #fff;
  margin-top: 10px;
  position: relative;
}

@media (max-width: 767px) {
  .inivte-pepole-list-sec {
    max-height: 180px;
  }

  .img-invite-phone,
  .img-invite {
    width: 100%;
    max-width: 100%;
  }

  .image-name-log-invite {
    display: flex;
    gap: 15px;
    width: auto;
    max-width: auto;
  }

  .notes-box p.user-name-inbox {
    font-size: 16px;
  }
  .invite-from-step {
    gap: 10px 0;
  }
  button.next-chat {
    width: 100%;
  }
  .divider.divide-mbl-2 {
    display: block;
    }
  /****two boxes***/
  .invite-pople-heading {
    text-align: center;
    }
    p.chat-text-box {
        display: none;
    }
  .two-radio-box {
    width: auto;
    margin: 0 auto;
    padding: 10px 0;
  }
  .box-radio-btn {
    padding: 0 15px;
    width: auto;
  }
  .tooltip-invite {
    right: 6px;
  }
  .img-invite-tooltip {
    position: unset;
  }
  .invite-form-box-container {
    height: 260px;
    overflow: auto;
    overflow-y: scroll;
  }
  .popup-bg-invite-pople {
    padding: 14px 12px;
  }
  .inivte-pepole-list-sec {
    overflow: unset;
    min-height: 250px;
    overflow-y: unset;
    max-height: unset;
  }
  .admin-form-btn {
    padding-top: 10px;
  }
  button.next-admin {
    min-width: 100%;
  }
}
