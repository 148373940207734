.hide-model-comment {
  display: none;
}

.model-comment-popup {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 55%);
  top: 0%;
  overflow-y: auto;
  z-index: 99;
}

.comment-model-title {
  position: relative;
}

.comment-model-title hr {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-color: var(--dark-black-color);
  margin: 0;
}

/* 
.border-text fieldset {
  border-top: 1px solid var(--boder-color);
}

.border-text fieldset legend {
  text-align: center;
  float: none;
  width: auto;
  padding: 0 20px;
  color: #666;
  font-family: var(--arial-main);
  font-size: var(--body-text);
  font-weight: var(--font-weight-bold);
} */

.comment-chat-model {
  position: relative;
  padding: 0 0px;
  margin-bottom: 50px;
}

.comment-chat-model-right {
  position: relative;
  padding: 0 20px;
  margin: 13px 0;
  text-align: end;
}

.hide-model-chat {
  display: none;
}

.chat-comment-box {
  height: auto;
  width: 570px;
  position: relative;
  left: 45px;
  border-radius: 12px 12px 12px 0px;
  border: 1px solid #d7d7d7;
  background: #f4f4f4;
  top: 0;
  padding: 20px 15px;
  z-index: 1;
  display: block;
  margin: 0 auto 0 0;
}

.chat-comment-box-right {
  min-height: 98px;
  width: 570px;
  position: relative;
  right: 0px;
  border-radius: 12px 12px 0px 12px;
  box-shadow: 6px 1px 15px 0px rgba(43, 43, 43, 0.09);
  background: #D9FDD2;
  top: 0;
  padding: 12px 15px;
  z-index: 1;
  display: flex;
  margin: 0 0 0 auto;
}

.chat-comment-box-right:after {
  content: '';
  position: absolute;
  z-index: -1;
  right: -8px;
  top: calc(100% - 36px);
  background: url('../../../../public/image/sender.png') no-repeat;
  background-size: contain;
  width: 33px;
  height: 46px;
}

.chat-comment-box-left-bg-chng {
  height: auto;
  width: 93%;
  position: relative;
  left: 0px;
  background: #f4f8ff;
  padding: 10px 35px 10px 10px;
  z-index: 1;
  margin: 0 auto 10px 0;
  border-left: 8px solid var(--dark-black-color);
}

.chat-comment-box-right-bg-chng {
  height: auto;
  width: 570px;
  position: relative;
  left: 0px;
  border-radius: 4px;
  border: 0.5px solid #bdbdbd;
  background: #f3f3f3;
  padding: 10px 35px 10px 10px;
  z-index: 1;
  text-align: start;
  display: block;
  margin: 0 0 0 auto;
}

.comment-chat-model-end-user {
  /* position: fixed; */
  padding: 0 0px;
  margin-bottom: 0px;
  /* z-index: 1;
  bottom: 78px;
  width: 86%; */
}

.comment-chat-model-user-reply {
  position: relative;
  padding: 0 0px;
  margin-bottom: 30px;
}

.chat-comment-box-left-udser-reply {
  height: auto;
  width: 570px;
  position: relative;
  border-radius: 4px;
  border: 0.5px solid #bdbdbd;
  background: var(--color-white);
  padding: 10px 35px 10px 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto 0px 0;
}

.chat-comment-box-right-udser-reply {
  height: auto;
  width: 570px;
  position: relative;
  border-radius: 4px;
  border: 0.5px solid #bdbdbd;
  background: #f4f8ff;
  padding: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0 0px auto;
  right: 50px;
}

.chat-info-box {
  border-radius: 6px;
  margin: 5px 0;
}

.chat-info-box p {
  color: #000;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  padding: 27px 10px;
}

p.left-chat-c {
  color: #000;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}

.hide-para-chat {
  display: none;
}

.info-border .chat-info-box p {
  color: #000;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  padding: 15px 10px 15px;
}

.chat-reply {
  display: flex;
  justify-content: end;
  padding-right: 10px;
  gap: 10px;
}

.chat-reply p {
  color: #4f4f4f;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  line-height: 24px;
}

.chat-model-comment-box {
  overflow: auto;
  padding: 10px 0 0;
  height: calc(100% - 156px);
  display: flex;
  flex-direction: column;
  position: relative;

}

.chat-modela-comment-box:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url('../../../../public/image/chat-bg.png')no-repeat;
  background-size: cover;
  opacity: 0.6;
}

.chat-model-footer {
  padding: 20px 12px;
}

.all-chat-comment-m {
  flex: 1;
}

.popup-chat-model-user {
  width: 100%;
  height: 85vh;
  margin: 20px auto;
  padding: 0px;
  position: relative;
  border: 2px solid #bdbdbd;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: url('../../../../public/image/chat-bg.png')no-repeat var(--color-white);
  background-size: cover;
  overflow: hidden;
}

/* width */

.chat-model-comment-box::-webkit-scrollbar {
  width: 15px;
}

/* Track */

.chat-model-comment-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.chat-model-comment-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--dark-black-color);
}

.chat-comment-f {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  /* position: absolute;
  bottom: 20px;
  width: 95%;
  margin: 0 auto; */
  /* position: absolute;
  bottom: 20px;
  width: 92.5%;
  margin: 0 auto;
  z-index: 1; */
}

.chat-form {
  border-radius: 8px;
  background: #F8F8F8;
  padding: 7px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex: auto;
}

.chat-form img {
  cursor: pointer;
}

input#comment-f {
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
}

input#comment-f:focus-visible {
  outline: 0;
}

input#comment-f:focus {
  outline: 0;
  box-shadow: none;
}

.send-comment button {
  border-radius: 8px;
  background: #F8F8F8;
  padding: 10px;
  border: 0;
}

.send-comment button:disabled img {
  filter: grayscale(1);
  opacity: 0.6;
}

.send-comment button img {
  max-width: 28px;
  transition: 0.25s all ease-in;
}

input#comment-f::-webkit-input-placeholder {
  color: var(--boder-color);
  font-size: var(--button-text);
  font-family: var(--arial-main);
  line-height: 24px;
}

.clinet-profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.clinet-profile img {
  max-width: 48px;
  max-height: 48px;
  border-radius: 100%;
}

.header-bar-comment {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid rgba(180, 171, 171, 0.66);
  background: var(--color-white);
}

.close-img-comment {
  text-align: end;
}

.header-bar-comment>.size-w-d {
  flex-basis: 33%;
}

p.name-clinet {
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  font-size: 20px;
}

p.clinet-co {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
  font-family: var(--arial-main);

}

p.clinet-rank {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
  font-family: var(--arial-main);
  opacity: 0.5;

}

.close-img-comment img {
  cursor: pointer;
}

.comment-img-b {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  font-size: 22px;
  justify-content: center;
}

.chat-date-b {
  text-align: center;
}

.chat-date-b h1 {
  color: var(--dark-black-color-500);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  font-size: 14px;
  text-align: center;
  padding: 7px;
  display: inline-block;
  text-align: center;
  margin: 0;
  position: relative;
  z-index: 1;
  background: #efebe6;
}

.chat-f-icon {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  width: 100%;
  flex: 1;
}

.comment-chat-model img.model-avtar {
  position: absolute;
  bottom: 0px;
  left: 5px;
  border-radius: 100%;
  z-index: 2;
  max-width: 50px;
}

.comment-chat-model-right img.model-avtar {
  display: none;
}

p.time-chat-left {
  text-align: end;
  color: #707070;
  font-size: 14px;
  font-weight: 300;
  font-family: var(--arial-main);
  height: auto;
  width: 570px;
  position: relative;
  left: 38px;
  top: 0;
  padding: 10px 0px 0 0;
  z-index: 1;
  display: block;
  margin: 0 auto 0 0;
}

.comment-chat-model-right p.time-chat-left {
  text-align: left;
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: 300;
  font-family: var(--arial-main);
  height: auto;
  max-width: 570px;
  width: 100%;
  position: relative;
  right: 0;
  top: 0;
  left: 0px;
  padding: 10px 0px 0 0;
  z-index: 1;
  display: block;
  margin: 0 0 0 auto;
}

.edit-icon-model {
  display: flex;
  gap: 10px;
  justify-content: end;
  cursor: pointer;
  align-items: center;
}

.edit-icon-model img {
  max-width: 18px;
  max-height: 18px;
}

.edit-icon-model p {
  display: flex;
  align-items: center;
}

.chat-info-box-f {
  width: 100%;
}

textarea.chat-user-in-f {
  box-sizing: border-box;
  border: 0;
  width: 98%;
  background: transparent;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
  font-family: var(--arial-main);
  padding-right: 20px;
  resize: none;
  height: 60px;
  /* transition: height 0.3s; */
}

input.chat-user-in-f {
  border: 0;
  width: 100%;
  background: transparent;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
  font-family: var(--arial-main);
  padding-right: 20px;
  border-bottom: 1px solid var(--dark-black-color);
}

.chat-info-box-f {
  width: 100%;
  border: 0;
  text-align: left;
  padding-right: 15px;
  word-break: break-word;
  flex: 1;
}

.chat-info-box-f:focus-visible {
  width: 100%;
  border: 0;
  outline: 0;
  text-align: left;
}

/***********commment data info *******/

.comment-add-box {
  display: flex;
  justify-content: space-between;
}

.comment-add-box-left {
  display: flex;
  justify-content: end;
}

.comment-add-box-left img {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.comment-add-box-right {
  display: flex;
  justify-content: end;
}

.name-comment-owner-left {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: var(--promoted-btn);
}

.chat-info-box-left {
  border-radius: 4px;
  border: 0.5px solid #bdbdbd;
  background: #fdfdfd;
  padding: 20px 10px;
}

.chat-info-box-right {
  border-radius: 4px;
  border: 0.5px solid #bdbdbd;
  background: #fdfdfd;
  padding: 20px 10px;
  border-left: 8px solid var(--dark-black-color);
}

.chat-info-box-left-bg-clr-chg {
  border-radius: 4px;
  border: 0.5px solid #f3f3f3;
  background: #f3f3f3;
  padding: 20px 10px;
  border-left: 8px solid var(--dark-black-color);
  margin: 10px 0;
}

.chat-info-box-bg-clr {
  border-radius: 4px;
  background: #f2f7ff;
  padding: 10px;
}

.two-icon-chat {
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
}

.name-info-add-box {
  display: flex;
  justify-content: space-between;
}

.comment-owner-info {
  display: flex;
  gap: 10px;
  align-items: center;
}

.name-owner-info {
  display: flex;
  gap: 10px;
  align-items: center;
}

.comment-owner-details {
  display: flex;
  gap: 10px;
  align-items: center;
}

p.name-comment-owner {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 20px;
}

p.comment-add-date {
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  color: #adadad;
  padding-right: 15px;
}

p.comment-remove {
  color: #f32131;
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

.comment-info-box p {
  color: #4f4f4f;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  line-height: 24px;
  padding: 15px 55px;
  text-align: left;
  word-wrap: break-word;
}

.comment-reply {
  display: flex;
  justify-content: end;
  padding-right: 50px;
  gap: 10px;
}

.comment-reply p {
  color: #4f4f4f;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  line-height: 24px;
}

.more-comment p {
  color: #989898;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  text-align: right;
  padding-top: 10px;
}

.more-comment a {
  text-decoration: underline;
  color: #989898;
}

.name-info-box p {
  color: #4f4f4f;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  line-height: 24px;
  text-align: left;
  padding: 0px 50px;
}

/*Emoji Style*/

.emoji-container {
  display: flex;
  align-content: center;
  justify-content: right;
  position: absolute;
  right: 80px;
  bottom: 70px;
  z-index: 99;
}