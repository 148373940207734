.profile-icon {
    width: 24px;
    height: 24px;
}

.location-icon {
    width: 23px;
    height: 23px;
}

.location-name-local {
    margin-left: 5px;
}

.email-icon {
    width: 22px;
    height: 22px;
}

.location-icon-drop {
    width: 25px;
    height: 22px
}

.profile-name-f {
    display: flex;
    justify-content: space-between;
}

.profile-fname-f {
    width: 47% !important;
}

.addProfile-icon {
    position: absolute;
    right: -13px;
    bottom: -10px;
}

.addProfile-icon img {
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.new-profile-image-st {
    border: 0.5px solid #dad9d9;
    border-radius: 8px;
    background: white !important;
    position: relative;
}

.profile-opac {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.about-me-head {
    font-size: 24px;
    color: var(--primary-color);
    font-weight: 600;
    margin-top: -25px;
}

.profile-img-mbl {
    display: none;
}

.candidate-info-mbl {
    display: none;
}

.candidate-header-col {
    display: flex;
}

.candidate-indo-sub-mbl {
    display: none;
}

.addProfile-icon-view-mbl {
    position: absolute;
    bottom: -20px;
    right: -20px;
}

.addProfile-icon-view-mbl img {
    width: 40px !important;
    height: 40px !important;
}

.edit-mode-head-mbl {
    display: none;
}

.prof-divider {
    display: none;
}

.company-logo-mbl {
    width: 150px !important;
    height: 150px !important;
    object-fit: contain;
}

.prog-bar-container {
    width: 50px;
    height: 50px; 
    position: relative; 
    display: inline-block; 
}

@media only screen and (max-width:767px) {
    .edit-mode-head-mbl {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: -50px;

    }

    .candidate-header-col {
        display: block;
    }

    .edit-mode-head-mbl h2 {
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    .candidate-info-desktop {
        display: none;
    }

    .d-none {
        display: none;
    }

    .company-logo-mbl {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
    }

    .candidate-indo-sub-mbl {
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding: 15px;
        width: 100%;
        border: 0.5px solid #14397536;
        border-radius: 12px;
        background: white;
        box-shadow: 0px 0px 32px 6px #0000000D;


    }

    .candiate-info-heading {
        width: 200px !important;
    }

    .candidate-info-mbl {
        display: flex;
        flex-direction: column;
        width: 300px;
        margin-left: 20px;
    }


    .profile-pic-desktop {
        display: none;
    }

    .new-profile-image-st {
        width: 100px !important;
        max-width: 100% !important;
        border-radius: 4px !important;
        height: 100px !important;
    }

    .profile-img-mbl {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .profile-name-f {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .profile-fname-f {
        width: 100% !important;
    }

    .profile-fname-b {
        margin-top: 20px;
    }

    .profile-icon {
        width: 24px !important;
        height: 24px !important;
    }



    .companyName-mbl {
        font-size: 20px !important;
        font-weight: 600;
        color: #143975;
        margin-bottom: -10px;
    }

    .userName-mbl {
        font-size: 14px !important;
        margin-bottom: -10px;
    }

    .candiate-info-heading-mbl {
        margin-bottom: -10px;
    }

    .company-desc-mbl {
        /* margin-top: -150px !important; */
    }

    .candiate-info-heading-mbl-sub {
        margin-top: -15px;
    }

    .addProfile-icon-view-mbl {
        right: -15px;
        bottom: -15px;
    }

    .addProfile-icon-view-mbl img {
        width: 30px !important;
        height: 30px !important;
    }

    .prof-divider-mbl {
        width: 100% !important;
        height: 1px !important;
        background: #143975 !important;
        margin-top: 5px;
    }

    .comapny-desc-mbl-p {
        background: linear-gradient(0deg, rgb(189 189 189 / 40%), rgb(221 222 238 / 25%));
        padding: 10px;
        border-radius: 6px;
        font-weight: 500 !important;
    }
}