  
  .title {
    text-align: center;
    margin: 20px 0;
    font-size: 2.5rem;
    color: black;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  .video-card:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  .thumbnail {
    width: 100%;
    display: block;
  }
  
  .video-info {
    padding: 10px;
    text-align: start;
  }
  
  .video-title {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    color: rgba(0, 159, 181, 1);
  }

  .video-title-sub{
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    color:rgba(43, 43, 43, 1);
    opacity: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  /* Overlay Styles */
  .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlay-content {
    position: relative;
    width: 80%;
    max-width: 900px;
    height: 60%;
    background: #000;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  }
  
  .video-player {
    border: none;
    width: 100%;
    height: 100%;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 159, 181, 1);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .close-btn:hover {
    background-color: rgba(0, 159, 181, 1);
  }
  