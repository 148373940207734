/*********job question page ****/

.space-top-question {
    padding-top: 30px;
}

.question-sec {
    padding: 20px 0 80px 0;
}

.single-question {
    padding: 16px 0;
    opacity: 80%;
}

.single-question:not(:last-child) {
    border-bottom: 1px solid var(--dark-black-color-200);
}

.single-question .answer-key {
    padding-top: 12px;
}

.single-question p.question-key {
    font-size: 18px;
    font-weight: var(--font-weight-semibold);
}

.single-question p {
    font-size: 16px;
    color: var(--dark-black-color);
}

.accordion-item {
    border-bottom: 1px solid var(--dark-black-color-200) !important;
    border-radius: 0 !important;
}

.ques-title {
    text-align: center;
    border-bottom: 1px solid var(--dark-black-color-200);
    padding: 10px 0;
    margin-bottom: 20px;
}

.ques-title h2 {
    font-size: 24px;
    font-weight: var(--font-weight-bold);
    color: var(--dark-black-color);
}

button.q-tabs {
    padding: 20px 0 !important;
    margin: 0;
    text-align: left !important;
    display: flex;
    font-size: 24px !important;
    border-radius: 0 !important;
    background: transparent !important;
    color: var(--dark-black-color) !important;
    /* box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05) !important; */
}

button.sub-heading {
    border-radius: 8px !important;
    background: var(--color-white) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.08) !important;
    padding: 20px 10px !important;
    margin: 0 !important;
    display: flex !important;
    align-items: flex-start !important;
}

button.queation-answer-tab {
    border-radius: 8px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.08) !important;
    padding: 20px 30px 20px 15px !important;
    margin: 0 !important;
    border: 1px solid rgba(0, 0, 0, 0.20) !important;
    background: rgba(221, 222, 238, 0.25) !important;
    display: flex;
    align-items: flex-start !important;
}

button.queation-answer-tab::after {
    display: none;
}

span.q-text-s {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 300;
    font-family: var(--arial-main);
    padding-left: 10px;
    width: 80%;
    padding-top: 2px;
}

span.q-text-ans {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 300;
    font-family: var(--arial-main);
    padding-left: 10px;
    line-height: 22px;
    text-transform: capitalize;
}

button.accordion-button.sub-heading.collapsed::after {
    display: none;
}

.accordion-body.side-space {
    padding: 0rem 0 !important;
}

.accordion-body {
    padding: .0rem 1.25rem !important;
}

.sub-header {
    border: 0px solid #375B96 !important;
    border-radius: 8px !important;
}

.border-0 {
    border: 0 !important;
}

button.bg-clr-q {
    border-radius: 8px !important;
    background: var(--color-white) !important;
    color: var(--dark-black-color) !important;
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.08) !important;
    padding: 20px 10px !important;
    margin: 0 !important;
    text-align: center !important;
    display: block;
    font-size: 20px !important;
    padding: 22px !important;
}

button.bg-clr-q:after {
    content: none
}

/* button.bg-clr-q::after {
    display: none !important;
} */

.tab-space-tb {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

@media only screen and (max-width:767px) {
    .accordian-body-mbl {
        padding: .0rem .0rem !important;
    }

    button.sub-heading {
        display: flex !important;
        /* flex-direction: column !important; */
        align-items: flex-start !important;
    }

    button.question-head {
        display: flex;

    }

    span.q-text-s {
        color: var(--text-color);
        font-size: 14px;
        font-weight: 300 !important;
        font-family: var(--arial-main);
        padding-left: 0px;
        margin-top: 0px;
        width: 70%;
        font-weight: 600;
        margin-left: 7px;
    }
}

@media only screen and (max-width:767px) {
    button.answer-bg {
        background: white !important;
        border: 1px solid #143975 !important
    }
}