.page-nf .btn {
  margin-top: 40px;
  font-size: 20px;
  font-weight: 400;
  padding: 9px 15px;
  max-width: 280px;
}

.page-nf h1 {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.page-nf h3 {
  margin: 0;
  font-size: 40px;
}

.page-nf p {
  font-size: 18px;
  margin: 0;
  margin-top: 20px;
  opacity: 70%;
}

@media(max-width: 767px) {
  .page-nf {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .page-nf h1 {
    font-size: 40px;
  }

  .page-nf h3 {
    font-size: 30px;
  }

  .page-nf p {
    font-size: 14px;
  }

  .page-nf .btn {
    margin-top: 20px;
  }
}