/***********steps section polycare****/

section.three-steps-poly {
  padding: 50px 0;
  background: #fbfbfb;
}

.three-rows-poly-sec {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}

.row-onestep-poly {
  background-color: #F3F3F3;
  padding: 40px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px 75px;
  margin-bottom: 25px;
}

p.step-heading-poly {
  color: var(--dark-black-color);
  font-size: 28px;
  font-family: var(--arial-main);
  font-weight: 700;
}

p.step-content-poly {
  color: var(--dark-black-color);
  font-size: 20px;
  font-family: var(--arial-main);
  font-weight: 500;
}

.row-twostep-poly {
  background-color: #F3F3F3;
  padding: 40px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px 75px;
  margin-bottom: 25px;
}

.two-btn-post-steps {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding-top: 20px;
}

button.post-btn-poly {
  border: 0;
  padding: 13px 60px;
  background: var(--dark-black-color);
  color: var(--color-white);
  font-size: 20px;
  font-weight: 500;
  font-family: var(--arial-main);
  border-radius: 8px;
  min-width: 220px;
}

button.contact-btn-poly {
  border: 3px solid var(--dark-black-color);
  padding: 13px 60px;
  background: transparent;
  color: var(--dark-black-color);
  font-size: 20px;
  font-weight: 500;
  font-family: var(--arial-main);
  border-radius: 8px;
  min-width: 220px;
}

/*****************************/
