/*******error table not found **********/

.table-error {
  text-align: center;
  background: #eee;
  padding: 26px 0;
  font-size: 18px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  margin-top: 15px;
}