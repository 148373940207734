.most-job-search-tag {
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 9px;
  align-items: center;
}

.most-job-search-tag p {
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-black-color);
  white-space: nowrap;
  margin-top: 0;
}

ul.find-job-tag {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 8px;
  justify-content: flex-start;
  padding: 0;
}

ul.find-job-tag li {
  background: var(--Whisper-color);
  padding: 8.25px 10px;
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  border-radius: 25px;
  border: 1px solid #e2edff;
  min-width: 60px;
  text-align: center;
  border: 0;
}