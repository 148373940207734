/**resume journy page *******/

.text-resume-buttn-j {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  padding-bottom: 25px;
}

p.content-heading-jour-res {
  color: var(--dark-black-color);
  font-size: 24px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  padding-bottom: 0px;
  margin-bottom: 0px !important;
}

.resume-dwl-pr-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}

button.resum-btn-p {
  min-width: 130px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  color: var(--dark-black-color);
  background: transparent;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.all-content-resume-jour {
  padding: 50px 60px;
}

.name-res-jou {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 32px;
  text-align: center;
}

.location-res-j {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
}

.location-res-j p {
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 22px;
}

.resume-can-info {
  display: flex;
  justify-content: center;
  padding: 5px 0px 5px;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  gap: 30px;
  align-items: center;
}

.box-one-res,
.box-two-res,
.box-three-res {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.box-one-res span,
.box-two-res span {
  font-weight: 600;
  color: var(--text-color);
  font-size: 16px;
  text-decoration: none;
}

.box-one-res a {
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  text-decoration: none;
}

.box-two-res a {
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  text-decoration: none;
}

.box-three-res a {
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 16px;
}


/*****resueme for mbl********/

.mbl-resume-all {
  display: none;
}

.resume-top-cantainer {
  margin-top: 20px;
}

.resume-name-mbl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px !important;
  border: 1px solid #eee !important;
  background: rgba(212, 239, 255, 0.07) !important;
  padding: 14px;
}

.resume-name-mbl a {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 18px;
  text-decoration: none;
}

.resume-name-mbl a span {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 14px;
}

/*************/

.resume-start-jour {
  border-radius: 10px;
  z-index: 1;
  height: auto;
}

/* width */

.resume-start-jour::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.resume-start-jour::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 15px;
}

/* Handle */

.resume-start-jour::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--dark-black-color);
}

.resume-content-start {
  padding: 20px 0 50px 0;
}

.first-heading-res-j {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 24px;
}

p.res-sumry-jour {
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  padding: 20px 0;
}

.time-comp-res-jour {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 10px 0;
}

.project-comp-resr {
  display: flex;
  justify-content: space-between;
  padding: 0px 0 10px 0;
}

.sub-heading-res-j {
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 18px;
}

.sub-heading-res-j-right {
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 18px;
}

a.project-link-res-j {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
}

p.res-postion-j {
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 18px;
}

p.res-postion-j span {
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 18px;
}

ul.resume-job-list {
  padding: 15px 0 0 20px;
}

ul.resume-job-list li {
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
}

.cus-resume-job-list {
  padding: 15px 0 0 0px;
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
}

p.edu-resume {
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 28px;
}

.projects-resu {
  padding: 15px 0;
}

ul.certificate-res-list {
  padding: 0px 0 0 20px;
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 28px;
}

.certification-res-jour {
  display: flex;
  justify-content: space-between;
  padding: 0px 0 0px 0;
}

.lbl-ai-mdl-btn {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px 0;
}

a.ai-rec-btn-from {
  text-decoration: none;
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #143975;
  background: #143975;
  display: inline-flex;
  padding: 7px 8px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  min-width: 153px;
}

a.ai-rec-btn-from {
  white-space: nowrap;
}

.ai-rec-btn-from img {
  max-width: 100%;
}

/*********resume verivication*************/

.back-arrrow-job-title-resume {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0px;
  align-items: center;
}

.arrow-text-res-form {
  display: flex;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

.resum-verivication-job {
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
  text-align: center;
  font-size: 28px;
  flex-grow: 1;
}

.arrow-back-t-res {
  width: 215px;
  text-align: start;
}

/* .jres-verification-row {
  padding-top: 15px;
} */

.toogle-btn-res {
  text-align: end;
  border-radius: 100px;
  background: lch(99.65 0 0 / 0.6);
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.2) inset;
  display: block;
  width: fit-content;
  margin: 0 0 0px auto !important;
  padding: 5px;
}

button.template-btn {
  color: #74869d;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  border: 0px;
  padding: 12px 19px;
  background: transparent;
  min-width: 105px;
}

button.active-toggle-btn-ver {
  background: var(--color-white);
  border-radius: 100px;
  border: 2px solid var(--dark-black-color);
  padding: 12px 19px;
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  line-height: 18px;
  opacity: 1;
  min-width: 105px;
}