/***********feed back****/

.feedback-model-popup-bg {
  width: 600px;
  margin: 0 auto;
}

.feed-back-content {
  background: var(--color-white);
  border: 1px solid #ebeffa;
  border-radius: 10px;
  box-shadow: 0 17px 34px 0 #0000001a;
  height: 50%;
  margin: 50px auto 0;
  padding: 16px;
  position: relative;
  width: 100%;
}

.feed-back-heading {
  font-size: 28px;
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  line-height: 32px;
  text-transform: capitalize;
  padding: 0 0 10px0;
  text-align: center;
}

p.feed-back-text {
  font-family: var(--arial-main);
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
  padding: 12px 0;
  border-bottom: 1px solid #000000;
}

.rating-row {
  padding: 13px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rating-row p {
  font-family: var(--arial-main);
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  width: 65%;
  justify-content: center;
}

textarea.feed-back-txt-are {
  width: 100%;
  background: #f6f6f6;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  padding: 1rem;
}

button.btn-feed-back {
  text-align: center;
  width: 145px;
  display: block;
  margin: 10px 0 0 auto;
  border: 1px solid #2b2b2b;
  border-radius: 6px;
  color: var(--color-white);
  background-color: var(--dark-black-color);
  padding: 15px 0;
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

p.feedback-tabs-msg {
  color: rgba(43, 43, 43, 1);
  font-size: 14px;
  font-family: var(--arial-mains);
  font-weight: var(--font-weight-medium);
  text-align: end;
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {
  /******feed-back***/
  .feedback-model-popup-bg {
    width: auto;
    margin: 0 auto;
  }

  .rating-row {
    flex-direction: column;
  }

  .rating-row p {
    text-align: center;
    padding: 5px 0px;
  }
}
