.jd-container {
  max-width: 1030px;
  margin-top: 5px;
  border-radius: 16px;
  border: 1px solid #003866;
  padding: 24px;
}

.jd-container h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 17px;
}

.jd-container {
  margin: 10px 0 16px !important;
}