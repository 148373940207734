/**********resume left side page***/

.resume-verivication-form {
  margin-top: 30px;
  /* overflow: auto;
  z-index: 1;
  max-height: 1500px;
  height: auto; */
}

/* width */

.resume-verivication-form::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.resume-verivication-form::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 15px;
}

/* Handle */

.resume-verivication-form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  color: var(--dark-black-color);
}

.resume-verivication-res {
  margin-top: 30px;
  border-radius: 12px;
  background: var(--color-white);
  padding: 40px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.resume-start-verivication {
  max-width: 880px;
  width: 100%;
  margin: auto;
  padding: 20px;
  /* min-height: 1800px; */
}

.all-content-resume-verification {
  padding: 0px;
}

.location-res-left {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
}

.location-res-left p {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 22px;
}

.resume-can-info-left {
  display: flex;
  justify-content: center;
  padding: 5px 0px 5px;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  gap: 30px;
  align-items: center;
}

.box-one-res-l,
.box-two-res-l,
.box-three-res-l {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.box-one-res-l span,
.box-two-res-l span {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 16px;
  word-break: break-word;
  line-height: 20px;
}

.box-one-res-l a {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  text-decoration: none;
  word-break: break-word;
  line-height: 20px;
}

.box-two-res-l a {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  text-decoration: none;
  word-break: break-word;
  line-height: 20px;
}

.box-three-res-l a {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  word-break: break-word;
  line-height: 20px;
}

.first-heading-res-left {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 24px;
}

p.res-sumry-left {
  font-family: var(--Time-new-roman);
  font-weight: 500;
  color: var(--text-color);
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  padding: 10px 0;
}

.time-comp-res-left {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 5px 0;
}

.project-comp-res-left {
  display: flex;
  justify-content: space-between;
  padding: 0px 0 10px 0;
}

.sub-heading-res-left {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 18px;
  line-height: 24px;
}

.sub-heading-res-right {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 18px;
  line-height: 24px;
}

a.project-link-res {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
}

p.res-postion-left {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 18px;
  line-height: 22px;
}

p.res-postion-left span {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  font-size: 18px;
  line-height: 22px;
}

.certificat-resume {
  padding-top: 15px;
}

.certificat-resume-heading {
  padding-bottom: 15px;
}

ul.certificate-res-list-left {
  padding: 0px 0 0 20px;
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 28px;
}

ul.resume-job-list-left {
  padding: 15px 0 0 20px;
}

ul.resume-job-list-left li {
  font-family: var(--Time-new-roman);
  font-weight: 400;
  color: var(--text-color);
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
}

.cus-resume-job-list-left {
  padding: 15px 0 0 20px;
  font-family: var(--Time-new-roman);
  font-weight: 400;
  color: var(--text-color);
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
}


ul.resume-job-list-skill li {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 28px;
  text-align: justify;
}

.project-resu {
  padding-top: 20px;
}

p.edu-resume-left {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  font-size: 16px;
  line-height: 28px;
}

.resume-content-verification {
  padding: 20px 0 0 0;
}

.name-res-left {
  font-family: var(--Time-new-roman);
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 32px;
  text-align: center;
}