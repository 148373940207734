.message-container {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 0 0 0 30px;
  flex-direction: column;
  margin: 0 0 15px;
  max-width: 350px !important;
  width: 100% !important;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
  border: 2px solid #00aced;
  position: absolute;
  left: 0;
  top: 10px;
  /* Add border to the avatar */
}

.message-content-container {
  width: 100%;
}

.message-content {
  flex: 1;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 16px 20px;
  max-width: 510px;
  padding-right: 12px;
  color: #2b2b2b;
  width: 100% !important;
  max-width: 350px !important;
  word-break: break-word;
}

.message-container a {
  display: flex;
  text-decoration: none;
  color: inherit;
  padding: 2px 0px;
}

.sender-name {
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 8px;
}

.message-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-line;
  margin: 0;
  font-size: 14px;
  padding: 5px 2px;
}

.message-text-right {
  margin-left: 5px;
}

.inbox-img {
  width: 220px;
}

.inbox-file-img {
  width: 25px;
}

.ep-msg-inbox {
  width: 16px;
}

.time-stamp {
  font-size: 13px;
  font-weight: 500;
  color: #2b2b2b;
  margin-left: auto;
  padding: 5px 5px 0;
}

.dd-listitem-inbox-msg .btn {
  line-height: 1;
}

.dd-listitem-inbox-msg .cv-cions {
  display: none;
  position: absolute;
  right: 0;
  box-shadow: 0px 1px 12px 4px rgba(0, 0, 0, 0.06);
  z-index: 9;
}

.other-options .dd-listitem-inbox-msg .cv-cions {
  left: unset;
  right: 0;
}

.dd-listitem-inbox-msg .cv-cions ul {
  list-style: none;
  padding: 12px;
  border-radius: 8px;
  background-color: #fff;
  min-width: 125px;
  margin: 0;
}

.dd-listitem-inbox-msg .cv-cions ul li {
  padding: 8px;
  transition: 0.25s all ease-in;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.dd-listitem-inbox-msg .cv-cions ul li:hover {
  background: #ececec;
  font-weight: 700;
}

.dd-listitem-inbox-msg .cv-cions.active {
  display: block;
}

.pin-chat.pin-chat-right {
  margin-left: 5px;
}
.message-content-top-w {
  width: 95%;
}

.message-content-top-right {
  display: flex;
  justify-content: space-between;
}
