.page-title-sub-heading {
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  padding: 0px 30px 0px 20px;
  color: rgba(0, 0, 0, 1);
  margin-top: -25px;
}

.coin-container {
  gap: 0px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 20px 30px 0px 20px;
  padding: 40px 30px 0px 20px;
}

.flex-auto {
  flex: auto;
}

.page-space {
  padding: 40px 30px 36px 20px;
}

.pc-img {
  max-width: 50px;
  max-height: 50px;
}

.coin-dd {
  display: flex;
  gap: 15px;
}

.coin-dd h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 10 0 0;
}

.coin-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.coin-panel .single-panel {
  background-color: #f0f0f0;
  padding: 14px 25px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coin-panel .single-panel .btn {
  min-width: 88px;
  font-weight: 500;
}

.coin-dd-buttom {
  margin-top: 30px;
  display: flex;
}

.coin-dd-buttom-p {
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  padding-left: 65px;
  padding-bottom: 30px;
}
