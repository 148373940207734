.common-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 80%);
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.common-modal>div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: calc(100vw - 30px);
  margin: 0 auto;
}

.common-modal .modal-content-info {
  max-width: 400px;
  width: 100%;
  min-height: 340px;
  background: var(--color-white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.common-modal[data-size='lg'] .modal-content-info {
  max-width: 720px;
}

.modal-content-info .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.label-field label {
  font-weight: 700;
  font-size: 16px;
  color: var(--dark-black-color);
  margin: 0 0 8px;
}

.modal-content-info .modal-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content-info .modal-footer {
  padding: 16px;
  gap: 12px;
}

.modal-content-info .modal-header .modal-logo {
  max-width: 140px;
}

.modal-content-info .modal-header .btn-close {
  background-size: 10px;
  padding: 3px;
  border: 1px solid var(--text-color);
  border-radius: 100%;
  opacity: 0.7;
  transition: 0.25s all ease-in;
}

.modal-content-info .modal-header .btn-close:hover {
  opacity: 1;
}

.modal-content-info .modal-body h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 20px 0 10px;
  color: var(--dark-black-color);
  display: flex;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
}

.modal-content-info .modal-body p {
  font-size: 16px;
  color: var(--dark-black-color-060);
}

.modal-content-info .modal-footer .btn {
  width: calc(50% - 6px);
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
}

@media(max-width: 767px) {
  .modal-content-info .modal-header .btn-close {
    width: 0.875rem;
    height: 0.875rem;
    background-size: 9px;
  }

  .modal-content-info .modal-header .modal-logo {
    max-width: 110px;
  }

  .modal-content-info .modal-header {
    padding-top: 25px;
  }

  .modal-content-info .modal-body {
    padding-top: 0;
  }

  .modal-content-info .modal-body h3 {
    font-size: 20px;
  }
}