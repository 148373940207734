/***************job model popoup **********/

.model-comment-popup {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 55%);
  top: 0%;
  overflow-y: auto;
  z-index: 2;
}

.popup-bg-job-cont {
  width: 70%;
  max-width: 710px;
  margin: 0 auto;
}

.popup-job-sec {
  width: 100%;
  /* height: 70vh; */
  margin: 100px auto 0;
  position: relative;
  background: var(--color-white);
  border: 0px solid #bdbdbd;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
}

.model-left-right-job {
  display: flex;
}

.col-left-model-cont {
  max-width: 20%;
  width: 100%;
  border-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: var(--pacific-blue-color);
  opacity: 0.08;
}

.col-right-model-cont {
  max-width: 80%;
  width: 100%;
  padding: 56px 0px;
}

.model-logo-job-sec {
  text-align: center;
}

.content-f-cont {
  padding: 10px 40px;
  display: flex;
  justify-content: center;
}

.content-f-cont h5 {
  text-align: center;
  font-size: 28px !important;
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
}

.first-full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.contact-message::placeholder {
  color: rgb(167, 167, 167);
  font-weight: 300;
}

.contact-btn {
  border-radius: 8px !important;
  padding: 20px;
}

.message-field {
  margin-top: -10px;
}

p.login-f-model {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: var(--button-text);
  font-weight: var(--font-weight-medium);
}

.model-signin-cont {
  background: var(--color-white);
  backdrop-filter: blur(13px);
  border-radius: 8px;
  max-width: 85% !important;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

input#email-j-model {
  max-width: 100%;
  width: 100%;
  display: block;
  padding: 0 10px 0 10px !important;
  border: 0;
}

input#password-field-job {
  max-width: 90%;
  width: 100%;
  display: block;
  padding: 0 10px 0 10px !important;
  border: 0;
}

.error-input>.mail-log-waring-icon {
  background: url(/public/image/warning.png);
  background-repeat: no-repeat;
  background-position: 98% center;
  background-size: 20px;
  padding-right: 40px !important;
}

.error-input>.mail-log-waring-icon {
  border: 1px solid var(--error-msg-color) !important;
}

.error-input>.password-icon-job {
  border: 1px solid var(--error-msg-color) !important;
}

.password-icon-job {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px;
  border-radius: 8px;
  border: 1px solid var(--dark-black-color-600);
  background: var(--color-white);
}

.mail-log-waring-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 8px;
  border-radius: 8px;
  border: 1px solid var(--dark-black-color-600);
  background: var(--color-white);
}

button.form-submit-job-model {
  width: 100%;
  background: transparent;
  border: 0.5px solid var(--accent-color);
  border-radius: 12px;
  padding: 18px 0;
  color: var(--accent-color);
  font-family: var(--arial-main);
  font-size: var(--body-text);
  margin-top: 15px;
  line-height: 24px;
}

.or-divider-job {
  display: flex;
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.divider-one-job {
  max-width: 40%;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.signup-here-job {
  padding: 15px 0px;
}

.signup-here-job p {
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.signup-here-job a {
  text-decoration: none;
}

.signup-here-job p span {
  color: var(--accent-color);
}

.text-or-job {
  text-align: center;
  color: var(--text-color);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.model-login-google {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 15px 0;
  margin-top: 10px;
}

.model-login-google a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-decoration: none;
}

.model-login-google a p {
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  opacity: 0.3;
}

.model-login-google img {
  max-width: 100%;
  height: 25px;
  width: 25px;
}

.footer-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: -20px;
}

.email-sec,
.address-sec {
  display: flex;
  align-items: center;
}

.email-sec p,
.address-sec p {
  margin-left: 10px;
  font-size: 14px;
  font-family: var(--arial-main);
  font-size: 13px;
}

.bluish-clr {
  color: var(--dark-black-color);
}

.bluish-clr a {
  color: var(--dark-black-color);
}

.addr-head {
  font-weight: 600;
  font-size: 14px;
}

.email-sec p {
  font-weight: 600;
}

.email-add {
  font-size: 13px;
  font-weight: 400;
}

.thankyou-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 540px;
}

.thank-head {
  font-size: 40px;
  color: #000;
  font-family: var(--arial-main);
  font-weight: 600;
}

.sent-img {
  width: 160px;
  height: 160px;
  margin-top: 20px;
}

.sent-msg {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--arial-main);
  margin-top: 30px;
}

.divider-mble {
  display: none;
}

.contact-send-btn {
  width: 120px;
  height: 46px;
  border-radius: 8px;
  margin-top: 20px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}

@media only screen and (max-width:767px) {
  .popup-bg-job-cont {
    width: 90%;
    margin: 0 auto;
  }

  .popup-job-sec {
    width: 100%;
    /* height: 70vh; */
    margin: 100px auto 0;
    position: relative;
    background: #ffffff;
    border: 0px solid #bdbdbd;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
  }

  .model-left-right-job {
    display: flex;
  }

  .col-left-model-cont {
    max-width: 20%;
    width: 100%;
    /* background: linear-gradient(136deg, #143975 0%, rgba(20, 57, 117, 0.00) 100%); */
    border-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: var(--pacific-blue-color);
    opacity: 0.08;
  }

  .col-right-model-cont {
    max-width: 80%;
    width: 100%;
    padding: 56px 0px;
  }

  .model-logo-job-sec {
    text-align: center;
  }

  .content-f-cont {
    padding: 10px 40px;
    display: flex;
    justify-content: start;
    /* margin-top: -7px; */
  }

  .content-f-cont h5 {
    text-align: center;
    font-size: 28px !important;
    font-weight: var(--font-weight-bold);
    color: var(--dark-black-color);
  }

  .first-full-width {
    width: 100% !important;
    max-width: 100% !important;
  }

  .contact-message::placeholder {
    color: rgb(167, 167, 167);
    font-weight: 300;
  }

  .message-field {
    margin-top: -10px;
  }

  .model-signin-cont {
    background: var(--color-white);
    backdrop-filter: blur(13px);
    border-radius: 8px;
    max-width: 85% !important;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  input#email-j-model {
    max-width: 100%;
    width: 100%;
    display: block;
    padding: 0 10px 0 10px !important;
    border: 0;
  }

  input#password-field-job {
    max-width: 90%;
    width: 100%;
    display: block;
    padding: 0 10px 0 10px !important;
    border: 0;
  }

  .error-input>.mail-log-waring-icon {
    background: url(/public/image/warning.png);
    background-repeat: no-repeat;
    background-position: 98% center;
    background-size: 20px;
    padding-right: 40px !important;
  }

  .error-input>.mail-log-waring-icon {
    border: 1px solid var(--error-msg-color) !important;
  }

  .error-input>.password-icon-job {
    border: 1px solid var(--error-msg-color) !important;
  }

  .password-icon-job {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 14px 8px;
    background: #fff;
  }

  .mail-log-waring-icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 14px 8px;
    background: #fff;
  }

  button.form-submit-job-model {
    width: 100%;
    background: transparent;
    border: 0.5px solid var(--accent-color);
    border-radius: 12px;
    padding: 18px 0;
    color: var(--accent-color);
    font-family: var(--arial-main);
    font-size: var(--body-text);
    margin-top: 15px;
    line-height: 24px;
  }

  .or-divider-job {
    display: flex;
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
  }

  .divider-one-job {
    max-width: 40%;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .signup-here-job {
    padding: 15px 0px;
  }

  .signup-here-job p {
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    font-size: var(--promoted-btn);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
  }

  .signup-here-job a {
    text-decoration: none;
  }

  .signup-here-job p span {
    color: var(--accent-color);
  }

  .text-or-job {
    text-align: center;
    color: var(--text-color);
    font-size: var(--promoted-btn);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
  }

  .model-login-google {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 15px 0;
    margin-top: 10px;
  }

  .model-login-google a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-decoration: none;
  }

  .model-login-google a p {
    font-size: var(--promoted-btn);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    color: var(--text-color);
    opacity: 0.3;
  }

  .model-login-google img {
    max-width: 100%;
    height: 25px;
    width: 25px;
  }

  .col-left-model-cont {
    max-width: 0%;
  }

  .col-right-model-cont {
    max-width: 100%;
  }

  .content-f-cont {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    margin-bottom: -15px;
  }

  .content-f-cont h5 {
    text-align: center;
    font-size: 23px !important;
  }

  .divider-mble {
    display: flex;
  }

  .footer-div {
    display: none;
  }

  .thankyou-sec {
    min-height: 435px;
    padding: 0 15px;
    text-align: center;
  }

  .thank-head {
    font-size: 24px;
  }

  .sent-img {
    width: 80px;
    height: 80px;
  }

  .sent-msg {
    font-size: 14px;
  }
}