
/******first row journey************/

.back-arrrow-job-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0px;
    align-items: center;
}

.job-title-joourny {
    color: var(--dark-black-color);
    font-family: var(--source-pro);
    font-weight: 600;
    text-align: center;
    font-size: 28px;
    padding: 25px 0;
    background: var(--pacific-blue-color-300);
}

.arrow-back-t {
    font-size: 25px;
    color: var(--primary-color);
}

