.faq-section {
    display: flex;
}

.faq-section .topic-sec {
    flex: 0 0 262px;
    max-width: 262px;
    padding: 30px 0;
    border-right: 1px solid rgba(0,0,0,0.2);
    cursor: pointer;
    padding-right: 10px;
}

.faq-section .faq-question {
    flex: auto;
    padding: 30px 40px;
}

.faq-section .faq-question .faq-title {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(43, 43, 43, 1);
    margin: 0 0 15px;
}

.faq-section .faq-question .faq-title h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    text-transform: capitalize;
}

.faq-section .topic-sec .topic-item {
    padding: 10px 24px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(43, 43, 43, 1);
    border-radius: 8px;
    overflow: hidden;
}

.faq-section .faq-question .question-item {
    padding: 16px 0;
    color: rgba(43, 43, 43, 1);
}

.faq-section .faq-question .question-item h2 {
    font-size: 20px;
    font-weight: 800;
    margin: 0 0 15px;
}

.faq-section .faq-question .question-item h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 8px;
}

.faq-section .faq-question .question-item p {
    font-size: 14px;
}

.faq-section .topic-sec .topic-item.active {
    background: rgba(0, 159, 181, 0.08);
    font-weight: 700;
}

@media(max-width: 767px) {
    .faq-section {
        flex-wrap: wrap;
        margin: 20px 0;
    }
    
    .faq-section .topic-sec {
        flex: 0 0 100%;
        max-width: 100%;
        border: 0;
        border-top: 1px solid rgba(0,0,0,0.2);
        border-bottom: 1px solid rgba(0,0,0,0.2);
        padding: 10px 0;
        max-height: 235px;
        overflow: hidden;
        transition: 0.25s all ease-in;
    }

    .faq-section .topic-sec.active {
        max-height: 999px;
    }

    .faq-section .topic-sec .topic-item {
        color: rgba(20, 57, 117, 1);
        text-decoration: underline;
    }
    .faq-section .faq-question {
        padding: 0;
        margin-top: 20px;
    }

    .faq-section .faq-question .faq-title h2 {
        font-size: 20px;
    }

    .faq-section .faq-question .question-item h3 {
        font-size: 16px;
    }
}