/**************departemt & role page css ********/

.page-title-heading {
  display: flex;
}

.page-title-heading h2 {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 24px;
}

.page-space {
  padding: 40px 30px 100px 20px;
}

.bg-clr-admin {
  background: #f9f9f9;
}

.bg-clr-admin a {
  text-decoration: none;
}

.create-department {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  background: var(--dark-black-color);
  color: var(--color-white);
  padding: 15px 12px;
  min-width: 208px;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  text-align: center;
  display: block;
  margin: 0 0px 0 auto;
}

.department-table-imge {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-left-table-depart {
  max-width: 54%;
  width: 100%;
  margin-top: -10px;
}

.col-right-image-depart {
  max-width: 45%;
  width: 100%;
}

.col-right-image-depart img {
  border-radius: 16px;
  max-width: 100%;
  width: 100%;
}

table.depart-table-left {
  background-color: #f0f0f000;
  border-collapse: separate;
  border-spacing: 0 15px;
}

thead.tb-heading-deprt {
  border: 0;
  background: var(--color-white);
}

tbody.tb-admin {
  border-radius: 16px;
  border: 0px solid #bdbdbd;
  background: var(--color-white);
}

tbody.tb-admin tr,
thead.tb-heading-deprt tr {
  border: 0;
}

thead.tb-heading-deprt th {
  padding: 18px 0;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  color: rgb(0 0 0 / 100%);
  line-height: 18px;
}

thead.tb-heading-deprt th .th-admin-heading {
  padding: 0px 10px;  
  border-left: 1px solid #bdbdbd;
}

thead.tb-heading-deprt th:first-child .th-admin-heading {
  display: block;
  text-align: left;
  border: 0;
  padding-left: 25px;
}

.th-admin-heading .arrow-icon {
  width: 20px;
  height: 20px;
}

tbody.tb-admin td {
  padding: 15px 0px 15px 0px;
  font-size: 14px;
  color: var(--primary-color);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  text-align: center;
}

td.deprt-left-td {
  text-align: left !important;
  padding-left: 20px !important;
}

tbody.tb-admin tr:nth-child(even) {
  background-color: white;
}

tbody.tb-admin tr:nth-child(odd) {
  background-color: var(--Whisper-light-color);
}

.tb-admin tr {
  margin-bottom: 10px !important;
}

span.later-circle {
  background: rgba(20, 57, 117, 0.05);
  padding: 10px;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 8px;
  color: var(--dark-black-color);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  display: inline-flex;
  height: 38px;
  width: 38px;
  justify-content: center;
}

.four-role-img img {
  margin-right: 9px;
  border-radius: 3px;
  padding: 3px;
  width: 28px;
}

.active-tb-data {
  display: inline-flex;
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  color: var(--dark-black-color);
  background: rgba(20, 57, 117, 0.05);
}

.inactive-tb-data {
  display: inline-flex;
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background: rgba(243, 33, 49, 0.08);
  color: #f32131;
}

.four-role-img {
  position: relative;
  display: flex;
  justify-content: center;
}