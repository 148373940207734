/*************favourites job page ***********/

.employee-list-btn button {
  background: var(--dark-black-color);
  color: var(--color-white);
  border: 0;
}

.list-btn-create-opcity button {
  color: rgb(43 43 43 / 50%) !important;
  border: 1px solid rgb(43 43 43 / 50%) !important;
}

.employee-list-btn-create button {
  background: transparent;
  color: var(--dark-black-color);
  border: 1px solid var(--dark-black-color);
}

.fix-width-btn {
  min-width: 200px;
}

.fix-width-btn button {
  border-radius: 8px;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
  font-family: var(--arial-main);
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.employee-list-btn {
  margin-right: 0px;
}

.two-btn-list {
  display: flex;
  justify-content: start;
  gap: 20px;
}

.filter-search-box {
  padding: 8px;
  background: #fff;
  border: 1px solid #b3b2b2;
  border-radius: 8px;
  width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
}

.filter-search-box img {
  max-width: 100%;
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.employee-list-fllter-container {
  display: flex;
  gap: 20px;
}

.employee-searchbox-btn {
  display: flex;
  justify-content: space-between;
  padding: 30px 10px;
  gap: 20px;
}

#candidate-list-search {
  border: 0;
  margin-left: 5px;
  width: 75%;
}

.search-list-box {
  position: relative;
}

.data-not-found {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px 0 0 0;
  position: absolute;
  right: 0;
  left: 0;
}

.data-not-found p {
  color: var(--error-msg-color);
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
}

.hide-notfound {
  display: none;
}

.list-search-dropdown {
  position: absolute;
  border-radius: 4px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 85%;
  overflow: auto;
  z-index: 1;
  margin: 0 0 0 auto;
  right: 15px;
  top: 100%;
  height: 150px;
}

ul.list-job-title {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

ul.list-job-title li {
  border-radius: 4px 4px 0px 0px;
  background: rgb(103 170 185 / 7%);
  padding: 12px 17px;
  border-bottom: 0.5px solid rgb(0 0 0 / 20%);
}

/* width */

.list-search-dropdown::-webkit-scrollbar {
  width: 8px;
}

/* Track */

.list-search-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.list-search-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
}

@media only screen and (max-width: 767px) {
  .filter-search-box {
    width: 100%;
  }

  .two-btn-list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .employee-list-fllter-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}