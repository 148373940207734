/*********company detail page start here ************/

.open-postion {
  padding: 50px 0px 0px 0px;
}

.company-content-logo {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  gap: 20px;
}

.company-logo-left img {
  max-width: 100%;
  width: 320px;
  height: 160px;
  object-fit: contain;

}

.company-logo-left {
  max-width: 15%;
  width: 100%;

}

.company-content-right {
  max-width: 85%;
  width: 100%;
}

.company-logo-left h6 {
  display: none;
}

.company-content-right h6 {
  font-weight: var(--font-weight-bold);
  font-size: 24px;
}

.company-content-right p {
  font-weight: 300;
  font-size: 16px;
  color: black;
  line-height: 23.38px;
  /* opacity: 0.8; */
  /* text-align: justify; */
  margin-top: 24px;
}

.search-box {
  max-width: 30%;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  border: 1px solid var(--dark-black-color-200);
  border-radius: 8px;
  background: var(--color-white);
}

.search-box img {
  height: 18px;
  width: 18px;
  position: relative;
  left: 5px;
  top: -2px;
  margin: 0px 10px;
}

.j-fillter-only {
  margin: 30px auto 30px 0;
  display: flex;
  justify-content: start;
  align-items: center;
  max-width: 100%;
  width: 100%;
  gap: 20px 24px !important;
  flex-wrap: wrap;
  padding-right: 0px;
}

.j-company-fillter {
  padding: 12px;
  border: 0.5px solid var(--boder-color);
  border-radius: 6px;
  width: 200px;
  background: var(--Whisper-color);
}

section.company-job-sec {
  padding-bottom: 80px;
}

.company-detail-heading h2 {
  font-size: 28px;
  text-align: center;
  font-weight: var(--font-weight-semibold);
  padding-bottom: 20px;
}

.space-inner-row {
  padding: 0 0px;
}

.space-inner-nav {
  padding: 0 40px;
}

.company-detail-sec {
  padding: 50px 0px 30px;
}

.no-data-msg {
  font-size: 18px !important;
  margin-top: 50px;
  font-weight: 600 !important;
}

.logo-border {
  border: 0.5px solid #0000001F !important;
  border-radius: 7px;
  padding: 5px;
}

@media only screen and (max-width:767px) {
  .company-details-heading {
    margin-top: -25px;
    margin-bottom: -10px;
  }

  .logo-border {
    width: 50px;
    height: 50px;
  }

  .company-logo-mbl {
    /* margin-top: -25px;
    margin-bottom: -10px; */
  }

  .company-logo-mbl h6 {
    margin-left: 20px !important;
    margin-bottom: 0px !important;
  }

  .company-logo-mbl img {
    width: 80px;
    height: 80px;
  }

  .company-content-logo {
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
    gap: 20px;
  }
}