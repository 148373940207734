/********job post model final******/

section.model-popup-post-job {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 80%);
  top: 0%;
  z-index: 1;
}

.popup-post-job {
  width: 740px;
  margin: 0 auto;
}

.popup-content-post-job {
  width: 100%;
  height: 50%;
  margin: 50px auto 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 80%, rgba(20, 57, 117, 1) 80%);
  padding: 20px 100px 100px 100px;
  position: relative;
  border-radius: 30px;
}

.three-row {
  display: flex;
  align-items: center;
  padding: 35px 0 70px 0px;
  gap: 24px;
}

.col-job-cardone {
  border-radius: 20px;
  border: 3px solid #143975;
  background: #9CC7E3;
  padding: 20px 20px 40px 20px;
  max-width: 45%;
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.line-one {
  border: 10px solid var(--accent-color);
  margin-bottom: 5px;
}

.line-two {
  border: 5px solid var(--accent-color);
  margin-bottom: 10px;
}

.line-white {
  border: 4px solid var(--color-white);
  margin: 6px 0;
}

p.copy-j-description-content {
  color: #252525;
  text-align: center;
  font-family: var(--arial-main);
  font-size: 18px;
  font-weight: 300;
  line-height: 23.1px;
  padding-top: 20px;
}

p.j-card-text-job {
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  text-align: center;
  font-family: var(--arial-main);
  line-height: 23px;
}

.col-job-cardtwo p {
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-size: 24px;
  text-align: center;
  font-family: var(--arial-main);
}

.col-job-cardtwo {
  max-width: 10%;
  width: 100%;
}

.col-job-cardthree {
  border-radius: 20px;
  border: 3px solid #143975;
  background: #FFBD72;
  padding: 10px 20px 20px 20px;
  max-width: 45%;
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.col-job-cardthree img {
  max-width: 100%;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center;
  display: block;
  object-fit: contain;
}

p.text-zoeai {
  color: var(--text-color);
  font-weight: var(--font-weight-medium);
  font-size: 18px;
  text-align: center;
  font-family: var(--arial-main);
  line-height: 23px;
}

p.text-zoeai span {
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  text-align: center;
  font-family: var(--arial-main);
  line-height: 23px;
}


img.close-m-gen {
  position: absolute;
  right: 20px;
  cursor: pointer;
}