.img-btn {
  margin: 9px 10px 9px 0px;

}

.dp {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

}

.page-title-heading {
  display: flex;
  justify-content: space-between;
}


.four-role-img img {
  margin-right: 0px;
  border-radius: 3px;
  padding: 3px;
  width: 28px;
}

.four-role-img {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 5px;
}