/********create list model********/

.form-font-list {
  color: #000000;
  font-family: var(--arial-main);
  font-size: 13px;
  font-weight: 400;
  border-radius: 4px;
  background-color: rgba(221, 222, 238, 0.25) !important;
  width: 100%;
  padding: 12px 12px;
  border: 0 !important;
}

.heading-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.creadt-list-form label {
  color: #000000;
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

.creadt-list-form {
  margin-bottom: 16px;
}

textarea.form-font-list {
  resize: none;
}