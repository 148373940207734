/****************** job details page css start here **********/

.j-fillter-only .j-company-fillter input::placeholder {
  color: var(--text-color);
  font-family: var(--arial-main);
}

.j-fillter-only .j-company-fillter input {
  font-weight: 500;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--arial-main);
  background: transparent;
}

.j-fillter-only .j-company-fillter input+img {
  position: absolute;
  right: 5px;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

section.f-dream.job {
  margin-top: 85px;
}

.f-job-heaading h2 {
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-size: 36px;
  text-transform: capitalize;
}

section.job-search-sec {
  padding: 0;
}

.job-search-tag-heading h2 {
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-size: 28px;
  text-transform: capitalize;
}


/************joib filter css **********/

.three-filter-inline,
.job-post-date {
  background: white;
}

.filter-serach-btn {
  max-width: 100%;
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-fillter-f {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  width: 100%;
}

.job-fillter-f input::placeholder {
  color: rgb(0 0 0 / 80%)
}

.three-filter-inline {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  align-items: center;
  border: 0.5px solid var(--boder-color);
  border-radius: 6px;
  padding: 11px 8px;
  position: relative;
}

.three-filter-inline:focus {
  outline: none;
}

/* .three-filter-inline:focus-within {
  border: 1px solid var(--primary-color);
} */

.job-filter-btn button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/****************dropdown css *************/

.job-search-dropdown {
  position: absolute;
  top: 50px;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 270px;
  z-index: 1;
  padding-bottom: 10px;
  left: 0;
}

.job-search-dropdown-job-type {
  position: absolute;
  top: 40px;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  z-index: 1;
  left: 0;
}

.job-location-dropdown {
  position: absolute;
  top: 100%;
  max-height: 300px;
  overflow: auto;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  /* width: 100%; */
  z-index: 1;
  left: 0;
}

.filter-serach-btn .job-location-dropdown {
  top: 40px;
}


/*******filterdropdown hide ***********/

.job-search-dropdown-job-type,
.job-date-dropdown,
.job-location-dropdown {
  display: none;
}

.job-location-dropdown.open-dd {
  display: block;
}

.location-input-f {
  display: flex;
}

.job-type-f.open .job-search-dropdown-job-type {
  display: block;
}

.location-input-f.open .job-location-dropdown {
  display: block;
}

.job-post-date.open .job-date-dropdown {
  display: block;
}

.job-dropdown-hide {
  display: none;
}


/************date drop down *******/

.job-date-dropdown {
  position: absolute;
  top: 50px;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  z-index: 1;
  right: 0;
}

ul.dorpdown-list-date li {
  color: rgba(0, 0, 0, 0.80);
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: 300;
  line-height: 40px;
  display: flex;
  align-items: flex-start;
  padding-left: 12px;
  word-break: break-word !important;
}

ul.dorpdown-list-date li:hover {
  border-radius: 6px;
  background: #F2F7FF;
}

ul.dorpdown-list-date {
  list-style: none;
  margin: 0;
  list-style: none;
  padding: 15px 10px;
}

ul.dorpdown-list-date li img {
  padding-right: 10px;
  height: 14px;
  position: relative;
  top: 11px;
}


/******************/

h3.job-suggestion {
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-bold);
  padding: 15px 20px 10px 22px;
  color: #A8A8A8;
  margin: 0;
}

ul.dorpdown-list-search {
  margin: 0;
  list-style: none;
  padding: 0 20px;
}

ul.dorpdown-list-search li img {
  height: 18px;
  position: relative;
  top: 10px;
}

ul.dorpdown-list-search li {
  color: rgba(0, 0, 0, 0.80);
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: 300;
  line-height: 40px;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
}

ul.dorpdown-list-search li:hover {
  border-radius: 6px;
  background: #F2F7FF;
}


/************** job type****/

ul.dorpdown-list-search-j-type {
  margin: 0;
  list-style: none;
  padding: 15px 10px;
}

ul.dorpdown-list-search-j-type li img {
  height: 18px;
  position: relative;
  top: 10px;
}

ul.dorpdown-list-search-j-type li {
  color: rgba(0, 0, 0, 0.80);
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: 300;
  line-height: 40px;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
}

ul.dorpdown-list-search-j-type li:hover {
  border-radius: 6px;
  background: #F2F7FF;
}


/*************/

ul.dorpdown-list-location {
  list-style: none;
  margin: 0;
  list-style: none;
  padding: 15px 10px;
}

ul.dorpdown-list-location li {
  word-break: break-word !important;
  cursor: pointer;
  border-radius: 8px;
  background: var(--color-white);
  padding: 5px 10px;
  color: rgb(0 0 0 / 50%);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 1.35;
  display: flex;
  align-items: flex-start;
  padding: 5px 10px;
}

ul.dorpdown-list-location li:hover {
  border-radius: 6px;
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}

ul.dorpdown-list-location li img {
  padding-right: 10px;
  height: 18px;
  position: relative;
  /* top: 10px; */
}

ul.dorpdown-list-location li:hover {
  border-radius: 6px;
  background: var(--Whisper-color);
}

.job-post-date {
  max-width: 20%;
  width: 100%;
}

#job-search-field,
#job-select-field,
#job-location-field,
#job-post-date-feild {
  border: 0;
  outline: 0;
}

.job-location-f img {
  padding: 0 12px 0 0;
}

.job-keywords img,
.job-type-f img {
  padding: 0px 10px;
}

.job-keywords {
  padding-right: 0px;
  border-right: 1px solid var(--boder-color);
  max-width: 38%;
  width: 100%;
}

input#job-search-field-4,
input#job-search-field-5 {
  width: calc(100% - 0px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0;
    outline: 0;
}

.job-type-f {
  padding: 0 20px 0 10px;
  border-right: 1px solid var(--boder-color);
  max-width: 30%;
  width: 100%;
}

.job-location-f {
  padding: 0 10px 0 10px;
  max-width: 30%;
  width: 100%;
  position: relative;
}

.job-post-date {
  padding: 12px;
  margin-left: 15px;
  border: 0.5px solid var(--boder-color);
  border-radius: 6px;
}

.job-filter-btn {
  position: relative;
}

p.clear-filter {
  color: var(--dark-black-color);
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  text-align: center;
  text-decoration: underline;
  position: absolute;
  right: 50%;
  top: calc(100% + 10px);
  transform: translateX(50%);
}

.findLoader {
  width: 96px;
}

.job-filter-icon {
  display: none;
}

@media only screen and (max-width:1000px) {
  .jobType-dropdown-mbl {
    margin-left: auto !important;
    right: 0%;
  }
}

@media only screen and (max-width:767px) {
  .job-filter-icon {
    display: inline;
    margin-right: 8px;
    padding: 0 !important;
    width: 20px !important;
    height: 20px !important;
    object-fit: contain;
  }

  input#job-search-field-4,
  input#job-search-field-5 {
    width: calc(100% - 30px);
  }

  .job-fillter-f .company-form-dropdownBox {
    gap: 0 !important;
  }

  .job-keywords img {
    margin-right: 8px;
  }

  .find-job-page {
    display: flex;
    justify-content: center;
  }

  .find-job-page button {
    display: flex !important;
  }

  .findLoader {
    width: 75px;
  }


}