/***********cookies scetion start here **********/

.cookies-sec {
  padding: 20px;
  background: var(--color-white);
  border: 1px solid #BDBDBD;
  box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1001;
  animation: fadeInUp 1s linear;
}

.cookies-heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cookies-heading p {
  color: var(--dark-black-color);
  font-size: var(--sub-heading);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.cookies-text-btn {
  width: 93%;
  margin: 0 auto;
}

p.cookies-paragrapgh {
  color: rgba(43 43 43 / 70%);;
  font-size: var(--body-text);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-light);
  line-height: 28px;
  text-align: justify;
}

.cookies-paragrapgh a {
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}

.cookies-btns {
  gap: 20px;
  display: flex;
  justify-content: end;
  padding-top: 20px;
}

button.decline-btn {
  padding: 14px 48px;
  background: transparent;
  border: 1px solid var(--dark-black-color);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: var(--button-text);
  line-height: 20px;
  font-weight: var(--font-weight-medium);
  border-radius: 8px;
}

button.allow-btn {
  padding: 14px 58px;
  background: var(--dark-black-color);
  border: 1px solid var(--dark-black-color);
  color: var(--color-white);
  font-family: var(--arial-main);
  font-size: var(--button-text);
  line-height: 20px;
  font-weight: var(--font-weight-medium);
  border-radius: 8px;
}
