.coin-container {
    max-width: 1119px;
    gap: 0px;
    border-radius: 20px;
    margin: 0;
    background-color: #FFFFFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .flex-auto {
    flex: auto;
  }
  
  .page-space {
    padding: 40px 30px 36px 20px;
  }
  
  .pc-img {
    max-width: 50px;
    max-height: 50px;
  }
  
  .coin-dd {
    display: flex;
    gap: 15px;
  }
  
  .coin-dd h3 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 10px;
  }
  
  .coin-panel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
  }
  
  .coin-panel .single-panel {
    background-color: #F0F0F0;
    padding: 14px 25px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .coin-panel .single-panel .btn {
    min-width: 88px;
    font-weight: 500;
  }

  .buy-more{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .slt {
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .qty{
    text-align: right;
    padding: 5px 0;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  