/*******delet model css***********/

section.model-delet-list {
    position: fixed;
    height: 100vh;
    width: 100vw;
    /* background: rgb(0 0 0 / 80%); */
    top: 0%;
}

.popup-bg-delet-list {
    width: 370px;
    margin: 0 auto;
    border-radius: 32px;
    background: var(--color-white);
    box-shadow: 0px 1px 25px 8px rgba(0, 0, 0, 0.12);
    margin: 50px auto 0;
}

.popup-bg-delet-admin-pormotion {
    width: 370px;
    border-radius: 32px;
    background: var(--color-white);
    box-shadow: 0px 1px 25px 8px rgba(0, 0, 0, 0.12);
    margin: 50px auto 0;
    position: relative;
    right: 100px;
}

.popup-delet-list-content {
    width: 100%;
    height: 450px;
    margin: 15px auto 0;
    position: relative;
}

.close-img-delet {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.uper-part-delet {
    background: var(--color-white);
    padding: 30px 20px 20px;
    border-radius: 30px 30px 0px 0px;
}

.warning-logo-model {
    padding: 20px;
    text-align: center;
}

p.text-delet-list {
    text-align: center;
    font-size: 32px;
    color: var(--error-msg-color);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
}

.warning-logo-delet {
    padding: 10px;
    text-align: center;
}

.lower-part-delet {
    padding: 40px 20px;
    border-radius: 0px 0px 30px 30px;
    background: #FBE8E7;
}

p.text-delet-tow {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    color: var(--error-msg-color);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
}

.btn-yes-no-delet {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 40px;
}

button.no-btn-delet {
    border-radius: 4px;
    border: 1px solid #E15C52;
    background: white;
    padding: 10px 0px;
    color: #E15C52;
    font-family: var(--arial-main);
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    min-width: 120px;
    line-height: normal;
}

button.yes-btn-delet {
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    padding: 10px 0px;
    color: var(--color-white);
    font-family: var(--arial-main);
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    min-width: 120px;
    line-height: normal;
}


/**************delet list drop down********/

.delet-list-menu {
    position: absolute;
    border-radius: 12px;
    background: var(--color-white);
    width: 240px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
    left: -90px;
    z-index: 1;
    padding: 12px;
}

ul.list-item-delet {
    padding: 0 0px;
    list-style: none;
    margin: 0;
}

ul.list-item-delet li {
    display: flex;
    justify-content: space-between;
    padding: 9px 7px;
    color: var(--dark-black-color);
    font-family: var(--arial-main);
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}

ul.list-item-delet li:hover {
    border-radius: 4px;
    background: var(--Whisper-color);
    font-weight: var(--font-weight-bold);
}


/*******delet model css***********/

section.model-delet-list {
    position: fixed;
    height: 100vh;
    width: 100vw;
    /* background: rgb(0 0 0 / 80%); */
    top: 0%;
}

.popup-bg-delet-list {
    width: 370px;
    margin: 0 auto;
    border-radius: 32px;
    background: var(--color-white);
    box-shadow: 0px 1px 25px 8px rgba(0, 0, 0, 0.12);
    margin: 50px auto 0;
}

.popup-delet-list-content {
    width: 100%;
    margin: 15px auto 0;
    position: relative;
}

.close-img-delet {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.uper-part-delet {
    background: var(--color-white);
    padding: 30px 20px 20px;
    border-radius: 30px 30px 0px 0px;
}

.warning-logo-model {
    padding: 20px;
    text-align: center;
}

p.text-promotion-list {
    text-align: center;
    font-size: 32px;
    color: var(--accent-color);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
}

.warning-logo-delet {
    padding: 10px;
    text-align: center;
}

.lower-part-promtion {
    padding: 40px 20px;
    border-radius: 0px 0px 30px 30px;
    background: var(--accent-color);
}

p.text-prom-below {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-white);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
}