/****************post job form*****************/

.signup-jobs-option #choose-jobs {
    width: 100%;
    padding: 18px 8px;
    border: 0.5px solid var(--dark-black-color-600);
    border-radius: 6px;
    font-size: var(--promoted-btn);
    font-family: var(--arial-main);
    font-weight: 300;
    color: var(--text-color);
    opacity: 0.8;
  }
  
  .industry-feild-frm-country.open {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  
  .icon-cursor-f {
    cursor: pointer;
  }
  
  .icon-cursor-f img {
    width: 33px;
    position: relative;
    top: -3px;
    padding-right: 10px;
  }
  
  .industry-feild-frm-country {
    width: 100%;
    padding: 13px 10px;
    border: 1px solid var(--dark-black-color-600);
    border-radius: 8px;
    font-size: 18px;
    font-family: var(--arial-main);
    font-weight: 300;
    color: var(--text-color);
    position: relative;
    display: flex;
  }
  
  .industry-feild-frm-country .list-search-dropdown-company {
    display: none;
  }
  
  .industry-feild-frm-country.open .list-search-dropdown-company {
    display: block;
  }
  
  .company-form-dropdownBox-f {
    width: 100%;
    cursor: pointer;
    color: var(--dark-black-color);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 400;
    justify-content: space-between;
  }
  
  .company-form-dropdownBox-f-placeholder {
    color: var(--dark-black-color-800);
  }
  
  .company-form-dropdownBox-kit {
    width: 100%;
    cursor: pointer;
    color: var(--dark-black-color);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 400;
  }
  
  .company-form-dropdownBox-kit::-webkit-input-placeholder {
    color: rgb(0 0 0 / 40%);
    font-size: 16px;
    font-family: var(--arial-main);
    font-weight: 300;
  }
  
  .company-form-dropdownBox-profile {
    width: 100%;
    cursor: pointer;
    color: rgb(0 0 0 / 100%);
    font-size: 16px;
    font-family: var(--arial-main);
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: var(--font-weight-medium);
    padding-left: 10px;
  }
  
  .company-drop-list-t {
    margin-top: 0px;
    width: 100%;
    border-radius: 4px;
    background: var(--color-white);
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0px;
  }
  
  .company-drop-list-t ul li {
    font-size: var(--skill-text-size);
    font-weight: var(--font-weight-medium);
    color: rgb(0 0 0 / 70%);
    line-height: 38px;
    cursor: pointer;
    list-style: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  p.error-msg-place {
    position: absolute !important;
  }
  
  .signup-jobs-option #choose-jobs-type {
    width: 100%;
    padding: 18px 8px;
    border: 0.5px solid var(--dark-black-color-600);
    border-radius: 6px;
    font-size: var(--promoted-btn);
    font-family: var(--arial-main);
    font-weight: 300;
    color: var(--text-color);
    opacity: 0.8;
  }
  
  button.job-reset-btn-f {
    width: 100%;
    max-width: 64px;
    padding: 15px 10px;
    border: 0.5px solid var(--dark-black-color-600);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(221 222 238 / 25%);
  }
  
  .reset-img-btn {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }
  
  .signup-jobs-option.f-feild-post-first {
    width: 95%;
  }
  
  .job-postion-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  label.job-postion-btn {
    font-size: var(--promoted-btn);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    color: #666666;
    margin-bottom: 0 !important;
  }
  
  .signup-salary #salary {
    width: 8%;
    padding: 18px 8px;
    border: 1px solid var(--dark-black-color-600);
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    appearance: none;
    background: var(--fomr-telcode-bg);
  }
  
  .signup-salary input[type="text"] {
    width: 92%;
    padding: 16px 8px;
    border: 1px solid var(--dark-black-color-600);
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  p.cities-job-heading {
    text-align: center;
    color: var(--dark-black-color);
    font-size: 28px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
  }
  
  .img-radion-btn {
    display: flex;
    align-items: center;
    margin: 50px 0 0 0;
  }
  
  .city-img {
    max-width: 25%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
  .city-img img {
    height: 75px;
    width: 75px;
    object-fit: cover;
  }
  
  p.country-img-text {
    color: var(--dark-black-color);
    font-size: 16px;
    padding-top: 5px;
    font-weight: var(--font-weight-medium);
    font-family: var(--arial-main);
  }
  
  .cities-btn {
    max-width: 75%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 0;
    padding-left: 30px;
  }
  
  .city-radio-btn {
    max-width: 25%;
    width: 100%;
  }
  
  .candidate-from-signup-post-job {
    max-width: 50%;
    width: 100%;
    margin: 0 auto;
  }
  
  .hide-show-post-btn {
    display: none;
  }
  
  /********* job posting boxes two ***********/
  
  .job-two-boxes {
    padding: 30px 0px;
  }
  
  p.box-heading-f {
    color: #143975;
    text-align: center;
    font-family: var(--arial-main);
    font-size: 26px;
    font-weight: var(--font-weight-bold);
  }
  
  .tow-boxes-data {
    display: flex;
    padding-top: 30px;
    gap: 20px;
    justify-content: center;
  }
  
  .left-form-data,
  .right-form-data {
    max-width: 340px;
    width: 100%;
    height: 400px;
  }
  
  .uper-part-clr {
    border-radius: 12px 12px 0px 0px;
    background: #375b96;
    box-shadow: 0px 0px 49.8px 0px rgba(0, 0, 0, 0.02);
  }
  
  .uper-part-clr img {
    text-align: center;
    display: block;
    margin: 0px auto -30px;
    padding-top: 15px;
  }
  
  .lower-part-data {
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #d9d9d9;
    background: var(--color-white);
    box-shadow: 0px 0px 49.8px 0px rgba(0, 0, 0, 0.1);
    padding: 50px 41px 25px;
  }
  
  p.l-box-one {
    color: #143975;
    text-align: center;
    font-family: var(--arial-main);
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    line-height: 23px;
    text-transform: uppercase;
  }
  
  p.l-boc-two span {
    color: #143975;
    text-align: center;
    font-family: var(--arial-main);
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    line-height: 31px;
  }
  
  p.l-boc-two {
    color: #000;
    text-align: center;
    font-family: var(--arial-main);
    font-size: 18px;
    font-weight: 300;
    line-height: 31px;
    padding: 24px 0 24px;
  }
  
  button.copy-paste-l {
    border-radius: 31px;
    border: 1px solid #375b96;
    min-width: 200px;
    padding: 7px 10px;
    background: transparent;
    color: #143975;
    text-align: center;
    font-family: var(--arial-main);
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    margin: 0 auto;
    display: block;
  }
  
  .uper-part-clr-right {
    border-radius: 12px 12px 0px 0px;
    background: #64a7b6;
    box-shadow: 0px 0px 49.8px 0px rgba(0, 0, 0, 0.02);
  }
  
  .uper-part-clr-right img {
    text-align: center;
    display: block;
    margin: 0px auto -30px;
    padding-top: 15px;
  }
  
  /******Generate Job Description btn*********/
  
  button.job-des-btn {
    display: flex;
    align-items: center;
    min-width: 208px;
    justify-content: center;
    padding: 12px 10px;
    border: 0;
    color: var(--color-white);
    border-radius: 8px;
    background: var(--dark-black-color);
    gap: 10px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    font-size: 16px;
  }
  
  button.job-reset-btn {
    display: flex;
    align-items: center;
    min-width: 208px;
    justify-content: center;
    padding: 12px 10px;
    border: 1px solid #143975;
    color: var(--primary-color);
    border-radius: 4px;
    background: transparent;
    gap: 10px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    font-size: 13px;
  }
  
  .job-des-d {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 15px;
  }
  
  /**************post the final job ************/
  
  .f-feild-post {
    margin-bottom: 34px;
  }
  
  .f-feild-post label {
    margin-bottom: 12px;
    color: var(--dark-black-color);
    font-family: var(--arial-main);
    font-size: 16px;
    font-weight: var(--font-weight-bold);
  }
  
  .recommended-feild label {
    margin: 12px 0;
    color: var(--primary-color);
    font-family: var(--arial-main);
    font-size: 16px;
    font-weight: var(--font-weight-bold);
  }
  
  label.job-postion-btn {
    font-size: 16px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    color: #666666;
    margin-bottom: 0 !important;
  }
  
  .list-box-post {
    border-radius: 6px;
    background: #eff0f4;
    padding: 20px 40px 20px 16px;
    margin: 12px 0;
  }
  
  .list-box-post-form {
    border-radius: 6px;
    background: #eff0f4;
    padding: 30px 16px 20px 16px;
    margin: 12px 0;
  }
  
  .input-box-user {
    border-radius: 6px;
    background: #eff0f4;
    padding: 20px 16px 20px 16px;
    margin: 12px 0;
  }
  
  .list-radio-post {
    display: flex;
    gap: 10px;
    align-items: baseline;
  }
  
  ul.post-list-content {
    margin-top: -4px;
  }
  
  .list-radio-post span {
    color: var(--text-color);
    font-size: 13px;
    font-weight: var(--font-weight-medium);
    font-family: var(--arial-main);
    line-height: normal;
  }
  
  ul.post-list-content li {
    color: var(--text-color);
    font-size: 13px;
    font-weight: var(--font-weight-medium);
    font-family: var(--arial-main);
    line-height: 25px;
    text-align: justify;
  }
  
  .post-type-content-btn {
    display: flex;
    justify-content: end;
    gap: 16px;
    padding-top: 20px;
  }
  
  .post-j-content-btn {
    display: flex;
    justify-content: end;
    gap: 16px;
    padding-top: 20px;
    border-top: 1px solid rgb(0 0 0 / 20%);
  }
  
  .type-user-f {
    display: flex;
    background: var(--color-white);
    padding: 6px;
    border-radius: 4px;
  }
  
  input#chat-cv-job {
    width: 100%;
    border: 0;
  }
  
  button.send-chat-j {
    border: 0;
    border-radius: 0;
    background: transparent;
  }
  
  .re-gen-btn {
    padding-bottom: 20px;
    display: flex;
    justify-content: end;
  }
  
  button.clr-reg-btn {
    border-radius: 4px;
    background: rgba(20, 57, 117, 0.08);
    color: var(--primary-color);
    font-size: 12px;
    border: 0;
    min-width: 127px;
    padding: 8.5px 8px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  
  button.clr-j-btn {
    border-radius: 4px;
    background: rgba(20, 57, 117, 0.08);
    color: var(--primary-color);
    font-size: 12px;
    border: 0;
    min-width: 127px;
    padding: 8.5px 0;
  }
  
  button.update-j-btn {
    border-radius: 4px;
    background: #143975;
    font-size: 12px;
    border: 0;
    min-width: 127px;
    padding: 8.5px 0;
    color: var(--color-white);
  }
  
  .job-textarea-box textarea {
    width: 100%;
    padding: 10px;
    border: 0.5px solid var(--dark-black-color-600);
    border-radius: 6px;
  }
  
  .job-textarea-box {
    border-bottom: 1px solid rgb(0 0 0 / 30%);
    padding-bottom: 30px;
  }
  .job-textarea-box-top-m {
    border-bottom: 1px solid rgb(0 0 0 / 30%);
    margin-bottom: 30px;
  }

  .company-form-dropdownBox-post {
    width: 100%;
    cursor: pointer;
    color: rgb(0 0 0 / 100%);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 300;
  }
  
  .company-form-dropdownBox-post-kit {
    width: 100%;
    cursor: pointer;
    color: rgb(0 0 0 / 100%);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 300;
  }
  
  .level-of-developer {
    display: flex;
    align-items: center;
    gap: 20px 50px;
  }
  
  .employe-final-job-post {
    max-width: 63%;
    width: 100%;
    margin: 30px auto;
  }
  
  .final-post-employe {
    margin: 0px;
    max-width: 100%;
    width: 100%;
  }
  
  .salary-range-start-end {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  .merge-feild-1 {
    display: flex;
    max-width: 45%;
    width: 100%;
  }
  
  .merge-feild-2 {
    max-width: 5%;
    width: 100%;
  }
  
  .merge-feild-3 {
    display: flex;
    max-width: 35%;
    width: 100%;
  }
  
  p.salryto {
    text-align: center;
    font-size: var(--promoted-btn);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    color: var(--text-color);
  }
  
  .merge-feild-4 {
    max-width: 13%;
    width: 100%;
  }
  
  input#job-post-field {
    width: 100%;
    border: 0 !important;
    outline: 0;
    background: none !important;
    color: rgb(0 0 0 / 100%);
    font-size: 16px;
    font-family: var(--arial-main);
    font-weight: 500;
  }
  
  input#salary-end {
    padding: 15px 8px;
    border: 0.5px solid var(--dark-black-color-600);
    border-radius: 6px;
    width: 100%;
  }
  
  #fsalary-country {
    padding: 18px 5px;
    border: 0px solid var(--dark-black-color-600);
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #e9e9e9;
    -webkit-appearance: none;
    /* background-image: url(/public/image/salary-year.png); */
    background-repeat: no-repeat;
    background-position: right 2px bottom 20px;
    background-size: 20px;
    width: 100%;
    font-size: 14px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    color: var(--text-color);
    text-align: center;
    justify-content: center;
  }
  
  .salary-f-job {
    max-width: 70px !important;
    width: 100%;
    pointer-events: none;
  }
  
  .salary-start {
    max-width: 100% !important;
    width: 100%;
    border: 1px solid var(--dark-black-color-600);
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  div#fsalary-year {
    padding: 15px 8px;
    border: 0px solid var(--dark-black-color-600);
    border-radius: 8px;
    background: rgba(221, 222, 238, 0.25);
    width: 100%;
    text-align: center;
    -webkit-appearance: none;
    background-image: url(/public/image/salary-year.png);
    background-repeat: no-repeat;
    background-position: right 5px bottom 15px;
    background-size: 20px;
    font-size: 14px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    color: var(--text-color);
  }
  
  .Year {
    border: 0.5px solid var(--dark-black-color-600);
    border-radius: 6px;
    position: relative;
  }
  
  .industry-feild-frm {
    width: 100%;
    padding: 13px 10px;
    border: 0.5px solid var(--dark-black-color-600);
    border-radius: 6px;
    font-size: 18px;
    font-family: var(--arial-main);
    font-weight: 300;
    color: var(--text-color);
    position: relative;
    display: flex;
  }
  
  .industry-feild-frm:focus,
  .industry-feild-frm:focus-visible {
    outline: 1px solid var(--dark-black-color) !important;
    box-shadow: 0px 0px 49px -3px rgba(20, 57, 117, 0.15);
  }
  
  .industry-feild-frm.open {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  
  .dropdown-form-f {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: var(--color-white);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    max-height: 185px;
    height: auto;
    overflow: auto;
  }
  
  .dropdown-form-f {
    top: 100%;
    left: 0;
    margin-top: 5px !important;
  }
  
  .dropdown-form-f ul {
    margin-bottom: 0;
    padding: 0;
  }
  
  .dropdown-form-f ul li {
    padding: 4px 14px;
    margin: 0 11px;
  }
  
  .dropdown-form-f ul li:hover {
    border-radius: 4px;
    background: var(--Whisper-color);
    color: var(--dark-black-color);
    font-weight: var(--font-weight-bold);
  }
  
  /* width */
  
  .dropdown-form-f::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  
  .dropdown-form-f::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--dark-black-color);
    border-radius: 10px;
  }
  
  /* Handle */
  
  .dropdown-form-f::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px var(--dark-black-color);
    background-color: var(--dark-black-color);
  }
  
  .industry-feild-frm.open .dropdown-form-f {
    display: block;
  }
  
  .industry-feild-frm.open .list-search-dropdown-company {
    display: block;
  }
  
  .dropdown-icon-postjob {
    -webkit-appearance: none;
    background-image: url(/public/image/post-form-dropdown.png) !important;
    background-repeat: no-repeat !important;
    background-position: right 8px bottom 22px !important;
    background-size: 20px !important;
  }
  
  .post-job-title-employe h5 {
    font-weight: var(--font-weight-bold);
    padding-bottom: 10px;
    font-size: 28px;
  }
  
  .cities-form-feild {
    max-width: 50%;
    margin: 20px auto 0 0;
  }
  
  .cities-center-in-form {
    max-width: 50%;
    margin: 20px auto 0;
  }
  
  .delete-post-container {
    display: none;
  }
  
  .edit-post-container {
    display: none;
  }
  
  .delete-post-container button {
    background: var(--color-grey);
    border: 2px solid #E15C52;
    border-radius: 8px;
    color: #E15C52;
    padding: 8px 18px;
    font-size: 16px;
    font-weight: var(--font-weight-medium);
    width: 100%;
  }
  
  .edit-post-container button {
    background: transparent;
    border: 2px solid var(--accent-color);
    border-radius: 6px;
    color: var(--accent-color);
    padding: 8px 20px;
    font-size: 16px;
    font-weight: var(--font-weight-medium);
    width: 100%;
    margin-top: 15px;
  }
  
  
  /********radio button styling for post a job form ***********/
  
  .post-line-content {
    width: 100%;
    max-width: 100%;
  }
  
  .post-line-content ul {
    padding-left: 16px;
  }
  
  .post-line-content ul li,
  .post-line-content p {
    color: #000;
    font-size: 14px;
  }
  
  .radio-button-container {
    width: 12px;
    height: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  .count-span {
    display: flex;
    max-width: 40px;
    width: 100%;
  }
  
  input#clr-btn {
    appearance: none;
    width: 25px;
    height: 13px;
    border: 1px solid var(--dark-black-color);
    ;
    border-radius: 50%;
    background-clip: content-box;
    padding: 2px;
  }
  
  input#clr-btn:checked {
    background-color: var(--dark-black-color) !important;
  }
  
  input#job-postion:checked {
    background-color: var(--dark-black-color) !important;
  }
  
  input#job-postion {
    appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid #2b2b2b !important;
    background-clip: content-box;
    padding: 1px;
    border-radius: 50px !important;
  }
  
  input#job-postion-check {
    accent-color: var(--dark-black-color);
  }
  
  /**********post card-sec******/
  
  .post-card-sec-bg {
    background-color: var(--snow-drift-color);
    padding-top: 50px;
  }
  
  .post-card-third-bg {
    padding: 35px 0;
    background: var(--pacific-blue-color-300);
  }
  
  .post-job-row-1 {
    margin: 100px 0;
  }
  
  .post-job-row-2 {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 20px 0 20px 0;
    justify-content: start;
  }
  
  .post-job-title-heading {
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
    align-items: center;
  }
  
  .main-row-job-card {
    display: flex;
    align-items: center;
  }
  
  .left-job-content-postcard {
    padding-right: 70px;
  }
  
  p.ai-content-post {
    color: var(--pacific-blue-color);
    font-size: 20px;
    font-weight: var(--font-weight-bold);
    font-family: var(--arial-main);
  }
  
  p.create-post-heading-post {
    color: var(--dark-black-color);
    font-size: 40px;
    font-family: var(--source-pro);
    font-weight: 600;
  }
  
  p.post-card-content-ai {
    color: var(--dark-black-color);
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    text-align: justify;
    font-family: var(--arial-main);
  }
  
  .two-ai-post-btn {
    padding-top: 30px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  
  button.copy-paste-btn {
    min-width: 274px;
    border: 1px solid var(--dark-black-color);
    padding: 17px 10px;
    border-radius: 8px;
    background: var(--color-white);
    color: var(--dark-black-color);
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    font-family: var(--arial-main);
  }
  
  button.generate-job-btn {
    min-width: 274px;
    border: 1px solid var(--dark-black-color);
    padding: 17px 10px;
    border-radius: 8px;
    background: var(--dark-black-color);
    color: var(--color-white);
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    font-family: var(--arial-main);
  }
  
  /*************post a job error feild**********/

.error-input>.salary-start {
    border: 1px solid var(--error-msg-color) !important;
    outline: 0;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .salary-start :focus-visible {
    border: 1px solid var(--error-msg-color) !important;
    outline: 0 !important;
  }
  
  .error-input>.salary-start :focus-visible {
    border: 0px solid var(--error-msg-color) !important;
    outline: 0 !important;
  }
  
  .error-input>.Year {
    border: 1px solid var(--error-msg-color) !important;
    outline: 0;
    border-radius: 6px;
  }
  
  #year :focus-visible {
    border: 1px solid var(--error-msg-color) !important;
    outline: 0 !important;
  }
  
  #fsalary-year :focus-visible {
    border: 0px solid var(--error-msg-color) !important;
    outline: 0 !important;
  }
  
  .error-input>#fsalary-year :focus-visible {
    outline: 0 !important;
    border: 0px !important;
  }
  
  .btn-outline-google-signup {
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #000;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    gap: 6px;
    font-weight: 500;
    padding: 10px;
  
  }
  
  .btn-outline-google-signup:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.1);
    opacity: 0.3;
  }
  
  .btn-outline-google-signup img {
    flex: 0 0 32px;
    max-width: 32px;
  }
  
  .or-passage {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0 32px;
  }
  
  .or-passage p {
    margin: 0;
    padding: 0 12px;
    background: #fbfbfb;
    font-size: 18px;
    position: relative;
    z-index: 1;
  }
  
  .or-passage:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 1px;
    z-index: 0;
  }
  
  .back-to-home {
    position: absolute;
    left: 0;
    top: 4px;
    z-index: 9;
    text-decoration: none;
  }
  
  .with-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
  }
  
  .back-to-home img {
    width: auto;
    height: auto;
    max-width: 20px;
  }
  
  /* React Quill*/
  
  .ql-editor {
    background: rgba(221, 222, 238, 0.25) !important;
    height: 270px !important;
    border: 1px solid #ccc;
  }
  
  .ql-container.ql-snow {
    border: none !important;
  }
  
  .ql-editor.ql-blank::before {
    color: var(--dark-black-color-500);
    font-family: var(--arial-main);
    font-size: 14px;
    line-height: 23px;
    text-align: justify;
    font-weight: 300;
    font-style: normal !important;
  }
  
  .ql-editor ol,
  .ql-editor ul {
    padding-left: 0em;
  }
  
  .ql-editor p,
  .ql-editor ul li {
    color: rgb(0 0 0 / 100%);
    font-family: var(--arial-main);
    font-size: 16px;
    line-height: 23px;
    text-align: justify;
  }
  
  .job-des-copy-paste .ql-editor{
    height: 700px !important;
  }
  /* React Circular Progress Bar*/
  
  .CircularProgressbar .CircularProgressbar-text {
    font-family: var(--arial-main);
    font-weight: 700;
  }

  
  .int-modal .loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .int-modal {
    color: #2b2b2b;
    text-align: center;
    position: relative;
  }
  .int-modal img {
    max-height: 80px;
  }
  .int-modal h2 {
    font-size: 22px;
    margin: 12px 0;
    color: inherit;
    font-weight: 700;
  }
  .int-modal .btn {
    font-weight: 400;
  }
  .int-modal p {
    font-size: 16px;
    color: inherit;
    margin: 0;
    opacity: 50%;
  }
  
  .step-wizard {
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 6px;
    margin-top: 30px;
    /* margin: 30px -20px 0; */
}

.step-wizard .step-wizard-item {
    width: 110px;
    position: relative;
    /* padding: 0 20px; */
}

.step-wizard .step-wizard-item .step-wizard-content {
    margin-top: 16px;
    font-size: 12px;
    font-weight: 700;
    color: #2B2B2B;
}

.step-wizard .step-wizard-item:not(:last-child):before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 3px;
    width: calc(100% - 25px);
    background: #06B217;
    border-radius: 100px;
}

.step-wizard .step-wizard-item .checkCircle {
  position: absolute;
  left: 0;
  top: -10px;
  width: 20px;
  height: 20px;
}

  @media(max-width: 767px) {
    .large-tooltip.tooltip .tooltip-inner {
      min-width: 250px;
    }
  
    .tooltip .tooltip-inner {
      font-size: 12px;
      padding: 10px;
    }
  
    .edit-icon-emply {
      display: none !important;
      padding: 10px 0px;
    }
  
    .delete-post-container {
      display: flex;
    }
  
    .delete-post-container a {
      display: flex;
      width: 100%;
      margin: 0 auto;
      display: block;
      width: 100%;
    }
  
    .delete-post-container-icon {
      position: relative;
      width: 20px;
      top: -2px;
    }
  
    .edit-post-container {
      display: flex;
      padding-top: 3px;
    }
  
    .edit-post-container a {
      display: flex;
      width: 100%;
      margin: 0 auto;
      display: block;
      width: 100%;
    }
  
    .edit-post-container-icon {
      position: relative;
      width: 20px;
      top: -3px;
    }
  
  }

