/********zoe check job post model final******/

section.model-popup-post-job {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 80%);
  top: 0%;
  z-index: 1;
}

.popup-post-job {
  width: 740px;
  margin: 0 auto;
}

.popup-content-zoe-chek {
  width: 100%;
  margin: 50px auto 0;
  background-image: url(/public/image/zoai-bg.png);
  padding: 20px 40px 100px 40px;
  position: relative;
  border-radius: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  height: 500px;
}

.col-job-zoecard {
  border-radius: 16px;
  border: 1px solid rgba(20, 57, 117, 0.20);
  padding: 20px;
  max-width: 60%;
  width: 100%;
  margin-top: 70px;
  position: relative;
}

img.eye-img-m {
  position: absolute;
  right: -10px;
  top: -12px;
}

p.zeo-chek-text {
  color: var(--primary-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  font-family: var(--arial-main);
}

p.zeo-chek-text strong {
  font-weight: var(--font-weight-bold);
}

p.zeo-chek-text span {
  color: var(--accent-color);
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

button.zeo-nobtn {
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  min-width: 85px;
  padding: 8px 0;
  color: #143975;
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
  text-align: center;
}

button.zeo-yesbtn {
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  min-width: 85px;
  padding: 8px 0;
  color: var(--color-white);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  background-color: #143975;
  ;
  text-align: center;
}