/******New Job Opportunities*******/

.new-job {
  padding: 50px 0px;
}

.see-more-job {
  margin-top: 40px;
}

.see-more-job button {
  display: block;
  text-align: center;
  margin: 0 auto;
  background: var(--dark-black-color);
  padding: 8px 0px;
  min-width: 229px;
  margin-top: 0;
  border: 0px;
  border-radius: 8px;
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  font-size: 20px;
}

/* .toggleSeeMoreText {
  display: block;
} */

.toggleFindMoreJobsHere {
  display: none;
}

.see-more-job a {
  text-decoration: none;
}

.job-search-div {
  align-items: center;
  background: #fff;
  border: 1px solid #b3b2b2;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: 40px;
  margin-left: 12%;
  margin-top: -5px;
  position: absolute;
  width: 302px;
  top: -5px;
  right: 0;
}

.job-heading-local {
  display: flex;
  flex-direction: row;
  justify-content: end;
  position: relative;
}

.new-job-search {
  padding: 30px 0px;
  background-color: var(--pacific-light-blue-color);
}

.job-heading-local h2 {
  flex: auto
}

.job-searchIcon {
  margin-left: 20px;
  width: 18px;
  height: 18px;
}

.job-search-input {
  width: 70%;
  margin-left: 15px;
  color: black;
  font-size: 18px;
  font-family: var(--arial-main);
  outline: none;
  border: none;
  background: transparent;
}

.job-search-input::placeholder {
  color: rgb(179, 178, 178);
  /* Replace with the desired color value */
}

.job-search-div-mbl-pos,
.job-search-div-mbl {
  display: none;
}

.job-search-div-mbl-pos,
.job-search-div-mbl {
  position: relative;
}

.job-searchClose {
  display: none;
}

.jobs-section-sm {
  display: none;
}

.mbl-jobs {
  display: none;
  margin-top: 20px;
}

@media only screen and (max-width:1023px) {
  .job-search-div {
    width: 222px;
  }

}

@media only screen and (max-width:900px) {
  .job-search-div {
    width: 170px;
    margin-top: 0px;
  }

  .job-search-input {
    width: 70%;
    margin-left: 5px;
    font-size: 14px;
  }
}

.divide-mbl {
  display: none;
}

@media only screen and (max-width:767px) {
  .desktop-jobs {
    display: none;
  }

  .mbl-jobs {
    display: block;
  }

  /* .toggleSeeMoreText {
    display: none;
  } */

  .toggleFindMoreJobsHere {
    display: block;
  }

  .divide-desk {
    display: none;
  }

  .new-job {
    margin-top: 20px;
  }

  .jobs-section-sm {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .job-searchBtn-open {
    display: none;
  }

  .job-search-open {
    display: none;
  }

  .job-search-div-mbl-pos,
  .job-search-div-mbl {
    width: 40px;
    height: 40px;
    margin-left: 22%;
    display: flex;
    border-radius: 100px;
  }

  .job-search-div-mbl-pos {
    margin-left: 25%;
  }

  .job-search-input-mbl {
    display: none;
  }

  .job-searchIcon-mbl {
    margin-left: 10px;
    width: 18px;
    height: 18px;
  }

  .job-search-div-abs {
    position: absolute;
    width: 90%;
    background: white;
    right: 5%;
  }

  .job-heading-local-mbl {
    justify-content: center;
  }

  .job-searchClose {
    display: flex;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 10px;
  }

  .job-search-input {
    margin-left: 15px;
    font-size: 18px;
  }

  .job-search-div-mbl {
    margin-top: -5px;
    border-radius: 25px;
  }
}

@media only screen and (max-width: 639px) {
  .job-search-div-mbl {
    margin-left: 15%;
  }

  .job-search-div-mbl-pos {
    margin-left: 25%;
  }
}

@media only screen and (max-width: 428px) {
  .job-search-div-mbl {
    margin-left: 5%;
  }

  .job-search-div-mbl-pos {
    margin-left: 20%;
  }
}

@media only screen and (max-width: 380px) {
  .job-search-div-mbl-pos {
    margin-left: 15%;
  }
}