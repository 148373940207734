.hide-desktop-vw {
  display: none;
}

@media only screen and (max-width:767px) {
  .company-mbl-bg {
    border-radius: 8px !important;
    background: var(--pacific-blue-color-300);
    width: 428px;
    height: 72px;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }

  .hide-page-title {
    display: none !important;
  }
}