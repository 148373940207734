/******term and policy pages **********/

section.term-condition {
    margin-top: 30px;
    padding: 24px 0;
    background: var(--pacific-blue-color-300);
}

.main-heading-term {
    color: var(--dark);
    font-family: var(--source-pro);
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}

.first-para-term {
    color: #000;
    text-align: justify;
    font-family: var(--arial-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 26.72px;
    padding: 40px 0;
}

.sub-heading-term {
    color: var(--dark-black-color);
    font-family: var(--arial-main);
    font-size: 32px;
    font-weight: 600;
    text-align: left
}

.first-sub-para-term {
    color: #000;
    text-align: justify;
    font-family: var(--arial-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 26.72px;
    padding: 20px 0 40px;
}

.bold-text-policy {
    color: #000;
    text-align: justify;
    font-family: var(--arial-main);
    font-size: 18px;
    font-weight: 600;
}

.lis-privcay-policy {
    padding-top: 20px;
}

.lis-privcay-policy ul {
    padding: 0px;
    list-style: none;
}

.lis-privcay-policy ul li {
    color: #000;
    text-align: justify;
    font-family: var(--arial-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 26.72px;
    padding-bottom: 20px;
}

.lis-privcay-policy ul li span {
    font-size: 18px;
    font-weight: 600;
}

.lis-privcay-policy-icon {
    padding-top: 20px;
}

.lis-privcay-policy-icon ul {
    padding-left: 20px;
}

.lis-privcay-policy-icon ul li {
    color: #000;
    text-align: justify;
    font-family: var(--arial-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 26.72px;
}

.lis-privcay-policy-space-icon {
    padding-top: 20px;
}

.lis-privcay-policy-space-icon ul {
    padding-left: 20px;
}

.lis-privcay-policy-space-icon ul li {
    color: #000;
    text-align: justify;
    font-family: var(--arial-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 26.72px;
    padding-bottom: 0px;
}

.lis-privcay-policy-space-icon ul li span {
    font-size: 18px;
    font-weight: 600;
}
