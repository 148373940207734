/************** common error validation from csss *****************/

p.error-msg {
  text-align: start;
  color: var(--error-msg-color) !important;
  font-size: 14px !important;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

p.error-msg-date-res {
  text-align: start;
  color: var(--error-msg-color);
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

p.error-msg-verify-email {
  text-align: center;
  color: var(--error-msg-color);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  margin: 20px 0px;
}

ul.error-msg li {
  color: var(--error-msg-color);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

li.colr-green {
  color: #07944f !important;
}

p.hide-error {
  display: none !important;
}

p.error-email {
  text-align: center !important;
  margin-top: 30px;
  display: none;
}

/* .error-input>label {
  color: var(--error-msg-color) !important;
} */

.error-input>input {
  border: 1px solid var(--error-msg-color) !important;
}

.error-input>input:focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input>textarea {
  border: 1px solid var(--error-msg-color) !important;
}

.error-input>textarea:focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input.candidate-res-file {
  border: 1px dashed var(--error-msg-color) !important;
  outline: 0;
}

.error-input.bulk-res-file-upload {
  border: 1px dashed var(--error-msg-color) !important;
  outline: 0;
}

input:focus-visible,
textarea:focus-visible {
  outline: 0;
}

#choose-jobs-type:focus,
#choose-jobs:focus,
#country:focus {
  outline: 0;
  box-shadow: none;
}

.error-input>input {
  background: url(/public/image/warning.png);
  background-repeat: no-repeat;
  display: block;
  display: block;
  background-position: 98% center;
  background-size: 20px;
  padding-right: 40px !important;
}

.error-input>select {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input>select :focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0 !important;
}

.error-input>select {
  background: url(/public/image/warning.png);
  background-repeat: no-repeat;
  display: block;
  display: block;
  background-position: 98% center;
  background-size: 20px;
  padding-right: 40px !important;
}

.error-input .industry-feild-frm {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input .industry-feild-frm :focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0 !important;
}

.error-input .industry-feild-frm-country {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input .industry-feild-frm-country :focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0 !important;
}

.error-input>textarea {
  background: url(/public/image/warning.png);
  background-repeat: no-repeat;
  display: block;
  display: block;
  background-position: right 10px bottom 20px;
  background-size: 20px;
  padding-right: 40px !important;
}

.error-input>#countryCode {
  display: block;
  background-position: 98% center;
  background-size: 20px;
  padding-right: 0px !important;
}

.error-input>#countryCode {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input>#countryCode>p {
  color: var(--error-msg-color);
}

.error-input>#countryCode :focus-visible {
  border: 0px solid var(--error-msg-color) !important;
  outline: 0 !important;
}

.error-input>#salary {
  display: block;
  background-position: 98% center;
  background-size: 20px;
  padding-right: 0px !important;
}

.error-input>#salary {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input>#salary>p {
  color: var(--error-msg-color);
}

.error-input>.month {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input>.Year-res-v {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input>#salary :focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0 !important;
}

.upload-password-icon>input {
  background: url(/public/image/password.png);
  background-repeat: no-repeat;
  display: block;
  background-position: 98% center;
  background-size: 20px;
  padding-right: 40px !important;
}

.upload-hide-password-icon>input {
  background: url(/public/image/hide-password.png);
  background-repeat: no-repeat;
  display: block;
  background-position: 98% center;
  background-size: 20px;
  padding-right: 40px !important;
}

.error-input>.password-icon {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

/*************passwors icon test **************/

.password-icon {
  display: flex;
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
}

input#password-field {
  max-width: 95%;
  width: 100%;
  padding: 0 15px 0 0;
  border: 0;
  border-radius: 0;
}

.icon-hide {
  max-width: 5%;
  width: 100%;
}

.icon-hide img {
  max-width: 100%;
}

img.password-icon-togle {
  display: none;
}

/*************image cursour ***********/

.icon-cursor {
  cursor: pointer;
}

/**********error page ***********/

.job-heading-wishlist-error h2 {
  font-size: 28px;
  font-weight: var(--font-weight-bold);
  padding-bottom: 35px;
  text-align: center;
}

p.error-wishlist {
  margin-top: 30px;
  margin-bottom: 30px !important;
  text-align: center;
  color: black;
  font-family: var(--arial-main);
  font-size: 20px;
}

.error-input .quill {
  border: 1px solid var(--error-msg-color) !important;
}

/*************filter common css**************/

.opacity-70 {
  opacity: 70%;
}

.company-form-dropdownBox {
  box-sizing: border-box;
  width: 100%;
  max-width: 484px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(0 0 0 / 80%);
  gap: 5px;
}

.company-form-dropdownBox p {
  max-width: calc(100% - 50px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.company-form-dropdownBox1 {
  box-sizing: border-box;
  width: 100%;
  max-width: 484px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--text-color);
}

.company-drop-list {
  margin-top: 0px;
  width: 100%;
  border: 0.5px solid var(--boder-color);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0px;
}

.company-drop-list ul li:last-of-type {
  border: 0;
}

.company-drop-list ul {
  padding: 0;
}

.dropdown-menu {
  top: 100%;
  left: 0;
  margin-top: 5px !important;
}

.dropdown-menu ul {
  margin-bottom: 0;
}

.dropdown-menu ul li {
  padding: 4px 14px;
}

.dropdown-menu ul li:hover {
  background: #e2edff;
}

.company-drop-list ul li {
  font-size: var(--promoted-btn);
  font-weight: 300;
  color: rgb(0 0 0 / 80%);
  line-height: 38px;
  cursor: pointer;
  list-style: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);
}

.job-type-f.open .dropdown-menu {
  display: block;
}

.job-post-date.open .dropdown-menu {
  display: block;
}

.j-company-fillter.open .dropdown-menu {
  display: block;
}

.job-type-f.open {
  display: flex;
  align-items: center;
  position: relative;
}

.job-post-date.open {
  display: flex;
  align-items: center;
  position: relative;
}

.j-company-fillter.open {
  display: flex;
  align-items: center;
  position: relative;
}

.j-company-fillter,
.job-type-f,
.job-post-date {
  position: relative;
}

.company-form-dropdownBox:after {
  content: " ";
  background-image: url(/public/image/DropDown.png);
  width: 38px;
  height: 10px;
  background-repeat: no-repeat;
  margin-left: 10px;
  position: absolute;
  background-size: contain;
  right: 0;
}

.text-filter-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.text-filter-icon img,
.text-filter-icon div img,
.text-filter-icon-table div img {
  height: 10px;
}

.text-filter-icon p {
  color: #000000cc;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

p.file-error-msg {
  text-align: center;
  color: var(--error-msg-color);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

.j-company-fillter .list-search-dropdown-company {
  display: none;
}

.j-company-fillter.open .list-search-dropdown-company {
  display: block;
}

.j-company-fillter.open .list-search-dropdown-status {
  display: block;
}

.list-job-title-company-local-comp {
  width: 270px;
}

/**********admin from common css******/

.error-input-admin>input {
  border: 1px solid var(--error-msg-color) !important;
}

.error-input-admin>input:focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input-admin>input {
  background: url(/public/image/warning.png) !important;
  background-repeat: no-repeat !important;
  display: block;
  background-position: 98% center !important;
  background-size: 20px;
  padding-right: 40px !important;
}

.error-input-admin .admin-status-filter-form {
  border: 1px solid var(--error-msg-color) !important;
}

.filter-bg-clr-f {
  background-color: rgba(221, 222, 238, 0.25) !important;
}

.hide-error {
  display: none;
}

.email-error--admin p {
  text-align: center;
  padding-top: 20px;
  font-size: 18px;
  color: var(--error-msg-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}