/********************* employer sign up form start here ***********/

.job-title-joourny-post {
  color: var(--dark-black-color);
  font-family: var(--source-pro);
  font-weight: 600;
  text-align: center;
  font-size: 28px;
}


ul.progressbar-steps {
  padding-left: 0;
  z-index: 1;
  position: relative;
}

.step-progressbar-container {
  margin: 50px 0;
}

.progressbar-steps {
  counter-reset: step;
}

.progressbar-steps li {
  list-style: none;
  display: inline-block;
  width: 30.33%;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}

.progressbar-steps li:before {
  content: counter(step);
  counter-increment: step;
  width: 50px;
  height: 50px;
  border: 3px solid rgb(0 0 0 / 20%);
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: var(--color-grey);
  font-size: 25px;
  line-height: 38px;
}

.progressbar-steps li.active-circle:before {
  border: 3px solid var(--pacific-blue-color);
}

.progressbar-steps li.active-circle:after {
  background-color: var(--pacific-blue-color);
}

ul.progressbar-steps li {
  color: #bdbdbd;
}

.progressbar-steps li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #ddd;
  top: 22px;
  left: -50%;
  z-index: -1;
}

.progressbar-steps li:first-child:after {
  content: none;
}

.progressbar-steps li.active {
  color: var(--pacific-blue-color);
}

.progressbar-steps li.active:before {
  border-color: var(--pacific-blue-color);
}

.signup-website-url input[type="text"] {
  width: 100%;
  padding: 13px 8px;
  border: 0.5px solid var(--dark-black-color-600);
  border-radius: 6px;
}

.signup-company-name input[type="text"] {
  width: 100%;
  padding: 13px 8px;
  border: 0.5px solid var(--dark-black-color-600);
  border-radius: 6px;
}

.tooltip {
  box-shadow: 0px 8px 16px -2px rgba(27, 33, 44, 0.12);
}

.tooltip .tooltip-arrow {
  display: none;
}

.large-tooltip.tooltip .tooltip-inner {
  min-width: 350px;
}

.tooltip .tooltip-inner {
  background: var(--color-white);
  color: #000;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  border: 1px solid var(--pacific-blue-color);
  border-radius: 8px;
}

.label-text p {
  color: var(--dark-black-color);
  font-family: (--arial-main);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

p.msg-show-blowe-textarea {
  text-align: end;
  color: var(--dark-black-color);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
}

.signup-file-company-logo label {
  display: flex;
  align-items: center;
  gap: 9px;
  flex-wrap: wrap;
}

.signup-file-company-logo input {
  display: none;
}

.signup-file-company-logo label strong {
  cursor: pointer;
  width: 60px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-file-company-logo [type="file"]::-webkit-file-upload-button {
  display: none;
}

p.company-logo-file {
  color: rgb(0 0 0 / 80%);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  font-size: 16px;
  max-width: calc(100% - 70px);
  word-break: break-word;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.signup-file-company-logo span {
  color: rgb(0 0 0 / 80%);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

p.file-size {
  color: var(--dark-black-color);
  font-size: var(--button-text);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.btn-next-pre {
  display: flex;
  gap: 25px;
}

button.btn-next-pre-toggle:hover {
  background: var(--dark-black-color);
  color: var(--color-white);
  border: 1px solid var(--dark-black-color);
}

button.btn-next-previous {
  width: 100%;
  background: transparent;
  border: 0;
  border-radius: 8px;
  padding: 14px 0;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 20px;
  margin-top: 15px;
  border: 1px solid var(--dark-black-color);
}

button.btn-job-post {
  width: 100%;
  background: var(--dark-black-color);
  border: 0;
  border-radius: 8px;
  padding: 6px 0;
  color: var(--color-white);
  font-family: var(--arial-main);
  font-size: 20px;
  margin-top: 15px;
  border: 1px solid var(--dark-black-color);
}

/****** City Dropdown """""****/

ul.tags-titles-list {
  display: flex;
  gap: 20px;
  list-style: none;
  margin: 15px 0 30px;
  padding: 0;
  flex-wrap: wrap;
}

ul.tags-titles-list li span img {
  vertical-align: baseline;
  width: 12px;
  height: 12px;
}

ul.tags-titles-list li button {
  border: 0;
  background: transparent;
  font-size: 15px;
}

ul.tags-titles-list li {
  display: inline-flex;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  justify-content: space-between;
  padding: 4px 8px;
  gap: 14px;
  align-items: flex-start;
  background-color: rgba(236 236 236 / 70%);
  color: var(--dark-black-color);
  font-size: var(--skill-text-size);
  border-radius: 16px;
  border: 0.5px solid var(--Whisper-color);
}

