/***************wishlist page start here ******/

p.conter-text-wiishlist {
  color: var(--color-white);
  height: 22px;
  width: 22px;
  position: absolute;
  font-size: 16px;
  top: -10px;
  display: inline-block;
  z-index: 2;
  background: var(--dark-black-color);
  border-radius: 50%;
  bottom: 0;
  left: 25px;
  text-align: center;
  font-weight: 700;
  padding: 0px 0 0px 0px;
  vertical-align: middle;
  line-height: 20px;
}

section.wishlist-sec {
  padding-top: 45px;
}

.job-heading-wishlist h2 {
  text-align: center;
  font-weight: var(--font-weight-bold);
  padding-bottom: 20px;
  font-size: 28px;
}

.job-wishlist-btn button {
  background: transparent;
  border: 2px solid var(--error-msg-color);
  border-radius: 8px;
  color: var(--error-msg-color);
  padding: 10px 20px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  width: 100%;
  display: block;
  margin-top: 12px;
}

.job-wishlist-btn a {
  text-decoration: none;
}

.job-announcements-col-candidate button {
  background: transparent;
  border: 2px solid var(--dark-black-color);
  border-radius: 8px;
  color: var(--dark-black-color);
  padding: 10px 20px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  width: 100%;
  display: block;
}

.job-announcements-col-candidate a {
  text-decoration: none;
}

section.wishlist-btn-sec {
  padding: 40px 0 80px 0;
}

.whishlist-three-btn-stack {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.whishlist-btn button {
  max-width: 100%;
  min-width: 245px;
  margin-bottom: 20px;
}

.wishlist-apply-btn button {
  padding: 10px 0;
  color: var(--dark-black-color);
  background-color: transparent;
  border: 2px solid var(--dark-black-color);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  border-radius: 8px;
  text-align: center;
}

.wishlist-clear-btn button {
  padding: 10px 0;
  color: var(--error-msg-color);
  background-color: transparent;
  border: 2px solid var(--error-msg-color);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  border-radius: 8px;
  text-align: center;
}

.wishlist-findjob-btn button {
  padding: 10px 0;
  color: var(--dark-black-color);
  background-color: transparent;
  border: 2px solid var(--dark-black-color);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  border-radius: 8px;
  text-align: center;
}