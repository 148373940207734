/****candidate resume menu ****/

.resume-choose-template-sec {
    width: 100%;
    border-bottom: 2px solid #ECECEC;
    padding-top: 50px;
    margin-bottom: 30px;
}

.resume-template-type {
    display: flex;
    gap: 0 30px;
}

.res-type-name a {
    text-decoration: none;
    color: var(--dark-black-color);
}

.res-type-name {
    font-size: 20px;
    font-family: var(--arial-main);
    font-weight: 400;
    padding-bottom: 5px;
    color: var(--dark-black-color);
}

.active-resume-template {
    font-weight: 700;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 12px;
}

.template-res-type-content {
    color: var(--dark-black-color);
    font-size: 16px;
    line-height: 24px;
    font-family: var(--arial-main);
    font-weight: 400;
    text-align: justify;
}

.template-resume-sample {
    padding: 30px 0;
    display: flex;
    gap: 0 60px;
}

.first-sample img {
    border-radius: 13px;
    height: 170px;
    object-fit: cover;
    cursor: pointer;
    border: 0.5px solid #2B2B2B33;
    box-shadow: 0px 1px 8.6px 0px #00000029;
}

.active-sample-res img {
    border: 1.5px solid #009FB5;
}

p.type-sample-res {
    text-align: center;
    font-size: 14px;
    font-family: var(--arial-main);
    font-weight: 700;
    line-height: 16px;
    padding: 10px 0;
}

p.price-sample-res {
    text-align: center;
    font-size: 14px;
    font-family: var(--arial-main);
    font-weight: 700;
    line-height: 16px;
}

