.ex1-content {
  border-radius: 16px;
  background: var(--color-white);
  border: 1px solid var(--color-white);
}

.overall-col {
  border-radius: 12px;
  border: 1px solid rgba(78, 122, 181, 0.25);
  background: rgba(78, 122, 181, 0.05);
  padding: 20px 30px;
  margin-bottom: 16px;
}

.ative-all-five-col {
  box-shadow: 0px 2px 12.4px 4px rgba(20, 57, 117, 0.16);
}

.ative-all-exper-col {
  box-shadow: 0px 2px 12.4px 4px rgba(67, 91, 156, 0.16);
}

.ative-all-skill-col {
  box-shadow: 0px 2px 12.4px 4px rgba(0, 142, 155, 0.16);
}

.ative-all-education-col {
  box-shadow: 0px 2px 12.4px 4px rgba(4, 191, 218, 0.16);
}

.ative-all-job-summary-col {
  box-shadow: 0px 2px 12.4px 4px rgba(100, 167, 182, 0.16);
}

.col-all-link-tb {
  text-decoration: none;
}

.experience-col {
  border-radius: 12px;
  border: 1px solid rgba(67, 91, 156, 0.25);
  background: rgba(67, 91, 156, 0.05);
  padding: 20px 30px;
  margin-bottom: 16px;
}

.skills-col {
  border-radius: 12px;
  border: 1px solid rgba(0, 142, 155, 0.25);
  background: rgba(0, 142, 155, 0.05);
  padding: 20px 30px;
  margin-bottom: 16px;
}

.education-col {
  border-radius: 12px;
  border: 1px solid rgba(4, 191, 218, 0.25);
  background: rgba(4, 191, 218, 0.05);
  padding: 20px 30px;
  margin-bottom: 16px;
}

.summary-col {
  border-radius: 12px;
  border: 1px solid rgba(100, 167, 182, 0.25);
  background: rgba(100, 167, 182, 0.05);
  padding: 20px 30px;
}

.experience-content-tabs {
  display: flex;
  align-items: center;
  gap: 15px;
}

.overall-col-c {
  height: 80px;
  width: 80px;
  border: 6px solid #143975;
  border-radius: 50%;
  font-size: 20px;
  color: #143975;
  line-height: 70px;
  text-align: center;
  z-index: 1;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.orange-col {
  height: 80px;
  width: 80px;
  border: 6px solid #ffa033;
  border-radius: 50%;
  font-size: 20px;
  color: #ffa033;
  line-height: 70px;
  text-align: center;
  z-index: 1;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.orange-col-skill {
  height: 80px;
  width: 80px;
  border: 6px solid #375b96;
  border-radius: 50%;
  font-size: 20px;
  color: #375b96;
  line-height: 70px;
  text-align: center;
  z-index: 1;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.orange-col-edu {
  height: 80px;
  width: 80px;
  border: 6px solid #04bfda;
  border-radius: 50%;
  font-size: 20px;
  color: #04bfda;
  line-height: 70px;
  text-align: center;
  z-index: 1;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.orange-col-summary {
  height: 80px;
  width: 80px;
  border: 6px solid #64a7b6;
  border-radius: 50%;
  font-size: 20px;
  color: #64a7b6;
  line-height: 70px;
  text-align: center;
  z-index: 1;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.can-eval-circular-bar {
  height: 80px;
  width: 80px;
  font-size: 20px;
  line-height: 70px;
  text-align: center;
  z-index: 1;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

p.heading-main-jour {
  font-size: 20px;
  color: #435b9c;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

p.over-all-clr {
  color: var(--primary-color);
}

p.skill-clr {
  color: #008e9b;
}

p.edu-clr {
  color: #04bfda;
}

p.summar-clr {
  color: #64a7b6;
}

p.content-main-jour {
  color: #848a9c;
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

p.right-col-heading {
  color: var(--primary-color);
  font-size: 24px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

p.right-col-percentage {
  width: 220px;
  height: 220px;
  border: 15px solid var(--primary-color);
  border-radius: 50%;
  font-size: 48px;
  text-align: center;
  margin: 15px auto !important;
  color: var(--primary-color);
  padding: 58px 50px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

p.right-col-sub-heading {
  font-size: 20px;
  padding: 10px 0;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

p.right-col-jour-sub-content {
  color: var(--text-color);
  font-size: 16px;
  text-align: justify;
  font-family: var(--arial-main);
  font-weight: 300;
  line-height: 20px;
  word-break: break-word;
}

.tab-size-jour {
  font-size: 20px !important;
  font-family: var(--arial-main) !important;
  font-weight: 500 !important;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.3) !important;
}

a.active.tab-size-jour {
  font-weight: var(--font-weight-bold) !important;
  color: var(--primary-color) !important;
}

a.nav-link.active.tab-size-jour {
  background: transparent !important;
}

/****expericnece**/

p.experience-heading-color {
  color: #435b9c;
}

p.percentage-experince-clr {
  border: 15px solid #435b9c;
  color: #435b9c;
}

/**skills tabs****/

p.percentage-skill-clr {
  border: 15px solid #008e9b;
  color: #008e9b;
}

p.skill-heading-color {
  color: #008e9b;
}

/****education**/

p.education-heading-color {
  color: #04bfda;
}

p.percentage-education-clr {
  border: 15px solid #04bfda;
  color: #04bfda;
}

/****Job Summary Similarity**/

p.summary-similarity-heading-color {
  color: #64a7b6;
}

p.percentage-summary-similarity-clr {
  border: 15px solid #64a7b6;
  color: #64a7b6;
}

.feedback-resume {
  margin-top: 1.5rem;
}

.feedback-resume h3 {
  font-weight: 600;
  text-decoration: underline;
  font-size: 1rem;
  margin: 0;
}

.feedback-options {
  margin-top: 1rem;
}

.radio-container-eval {
  display: inline-block;
  margin-right: 10px;
}

.hidden-radio {
  display: none;
}

.radio-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 2px solid rgba(0, 0, 0, 0.16);
  border-radius: 100%;
  padding: 5px;
  transition: 0.25s all ease-in;
}

.radio-label img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.radio-label .img-dislike {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: rotate(180deg);
}

.radio-label:hover {
  background: #d9e7ff;
}

.hidden-radio:checked + .radio-label {
  border-color: #143975;
  background: #d9e7ff;
  /* Change border color when selected */
}

.can-eval-progress-bar {
  position: relative;
  display: inline-block,
}