/************** forget password ****************/

input#forgotpassowrd-mail {
  width: 100%;
  padding: 10px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
  color: var(--text-color);
  font-size: var(--promoted-btn);
  font-weight: var(--font-weight-medium);
}

.progress-bar-password {
  width: 300px;
  height: 6px;
  border: 0px solid #ccc;
  margin: 10px 0;
  position: relative;
}

.progress-password {
  height: 100%;
  width: 100%;
  display: flex;
}

.progress-part-password {
  flex-grow: 1;
  background-color: #ccc;
  margin-right: 5px;
}

.prograssbar-active {
  background-color: #07944F;
}

.candidate-signin-password-ft {
  background: var(--color-white);
  border: 1px solid var(--dark-black-color-200);
  box-shadow: 0px 1px 23px 1px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 40px 60px 40px 60px;
}

.candidate-signin-password-ft h2 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: var(--font-weight-bold);
  font-size: 28px;
}

.change-password-f h2 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: var(--font-weight-bold);
  font-size: 24px;
}

/******change password feild ***********/

.password-change-f {
  margin-bottom: 20px;
}
