/******header main banner journey***********/

.jour-cantainer {
  border-radius: var(--16, 16px);
  border: 1px solid rgba(43, 43, 43, 0.20);
  background: #FFF;
  padding: 24px;
}

.jour-left-right {
  display: flex;
  align-items: flex-start;
}

.left-col-jour {
  max-width: 10%;
  width: 100%;
}

.left-col-jour-secd {
  max-width: 10%;
  width: 100%;
}

.left-col-jour img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.right-col-jour {
  max-width: 88%;
  width: 100%;
  margin-left: 30px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.name-cand-jour-porgres {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-bottom: 15px;
}

.progress-icon-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.cv-icons-list img {
  height: 26px;
  object-fit: contain;
}

p.name-can-jou {
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
  font-size: 20px;
}

.text-list-tile-mbl {
  display: none;
  color: var(---dark-black-color);
  font-weight: var(--font-weight-bold);
  font-size: 20px;
  line-height: 24px;
}

p.progress-percent-jour {
  height: 50px;
  width: 50px;
  border: 4px solid #0ab84d;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  color: #0ab84d;
  line-height: 46px;
  text-align: center;
  z-index: 1;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.inner-right-jour {
  display: flex;
  gap: 20px;
  max-width: 50%;
  width: 100%;
  justify-content: left;
}

.icon-row-profile-jour {
  width: 324px;
}

.inner-left-jour {
  max-width: 50%;
  width: 100%;
}

.four-icon-link-jour {
  display: flex;
  align-items: center;
  gap: 10px;
}

.four-icon-link-jour img {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.link-profile-btn-jour {
  display: flex;
  flex-direction: column;
}

.jour-info-list {
  display: flex;
}

.info-below-mbl {
  display: none;
}

ul.info-list-jour {
  padding-left: 0;
  list-style: none;
  line-height: 2.5rem;
  margin-bottom: 0;
}

ul.info-list-jour li {
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  display: flex;
}

ul.info-list-jour li span {
  color: var(---dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  min-width: 180px !important;
}

.jour-info-list ul {
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  margin-bottom: 4px !important;
  list-style: none;
  padding-left: 10px;
}

.jour-info-list p {
  color: var(---dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  margin-bottom: 4px !important;
}

button.link-profile-jour {
  border-radius: 8px;
  background: #0288d1;
  padding: 12px 24px;
  color: var(--color-white);
  font-size: 16px;
  font-family: var(--arial-main);
  display: flex;
  gap: 10px;
  border: 0;
  justify-content: center;
  margin-bottom: 20px;
  min-width: 240px;
}

.link-profile-btn-jour a {
  text-decoration: none;
}

button.link-profile-jour img {
  height: 24px;
  width: 24px;
}

button.link-mail-jour {
  background-color: transparent;
  padding: 10px 24px;
  color: var(---dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  min-width: 240px;
}

button.link-mail-jour img {
  height: 24px;
  width: 24px;
}

p.work-jour-heading {
  color: var(---dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  margin-bottom: 12px !important;
}

.tags-jour {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 25px;
}

button.tag-jour-btn {
  border-radius: 16px;
  background: var(--Whisper-color);
  color: var(---dark-black-color);
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: 500;
  padding: 7px;
  line-height: 16.8px;
  border: 1px solid var(--Whisper-color);
}

.journey-row {
  padding-top: 50px;
}

.summary-col-jour {
  margin-left: 30px;
}

p.summary-jour {
  color: var(---dark-black-color);
  font-size: 18px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

p.summary-jour-content {
  color: var(--text-color);
  font-size: 16px;
  text-align: justify;
  font-family: var(--arial-main);
  font-weight: 300;
  line-height: 26.6px;
}

/******tootltips  journey***********/

.img-table-tooltip {
  position: relative;
  cursor: pointer;
}

.good-cv-box-j {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #0ab84d;
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  padding: 8px 12px;
  top: -60px;
  width: 150px;
  justify-content: space-between;
}

.good-cv-box-j p {
  color: #0ab84d;
  font-family: var(--arial-main);
  font-size: 12px;
  font-weight: var(--font-weight-medium);
}

.good-cv-box-j img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.badreview-cv-box-j {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--error-msg-color);
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  padding: 8px 12px;
  top: -60px;
  width: 150px;
  justify-content: space-between;
}

.badreview-cv-box-j p {
  color: var(--error-msg-color);
  font-family: var(--arial-main);
  font-size: 12px;
  font-weight: var(--font-weight-medium);
}

.badreview-cv-box-j img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.thinkneed-cv-box-j {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--accent-color);
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  padding: 8px 12px;
  top: -60px;
  width: 150px;
  justify-content: space-between;
}

.thinkneed-cv-box-j p {
  color: var(--accent-color);
  font-family: var(--arial-main);
  font-size: 12px;
  font-weight: var(--font-weight-medium);
}

.thinkneed-cv-box-j img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.tooltip-table-jour {
  position: absolute;
  top: -57px;
  display: inline-block;
  left: -4px;
  padding: 5px;
  opacity: 0;
  font-weight: 500;
  white-space: nowrap;
}

img.show-tooltip:hover~.tooltip-table-jour {
  opacity: 1;
}

.attention-box-resume {
  border-radius: 6px;
  border: 1px solid var(--accent-color);
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  padding: 8px 15px;
  position: absolute;
  bottom: 35px;
  left: -12px;
  width: 225px;
}

.can-eval-progress-bar-container {
  width: 55px;
  height: 55px;
  position: relative;
  display: inline-block,
}