/*****prmotion request table************/

.four-pormotion-img img {
    margin-right: 9px;
    border-radius: 3px;
    padding: 3px;
    width: 26px;
    height: 26px;
}

.four-pormotion-img {
    position: relative;
    display: flex;
}

.dash-td-por {
    width: 13px;
    border-bottom: 4px solid var(--dark-black-color);
    margin: 0 auto;
}
