/*************responsivness start here ***********/

@media only screen and (max-width: 1350px) {

  /***********promotion table*/
  .th-size-two {
    width: 78px;
  }

  .th-size-five {
    width: 87px;
  }

  .th-size-three {
    width: 70px;
  }

  .th-size-one {
    width: 100px;
  }
}

@media only screen and (max-width: 1225px) {

  /***********promotion cards*/

  .job-type-heading {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .left-boxes-cards {
    gap: 16px;
    max-width: 104%;
    width: 100%;
    flex: initial;
    align-self: flex-start;
  }

  .right-box-lists {
    max-width: 50%;
    width: 100%;
    margin-top: 20px;
  }

  .card-slot-bg-clr,
  .card-slot-bg-dark-card {
    max-width: 48%;
    width: 100%;
  }

  button.can-res-btn-last,
  button.save-res-btn-last {
    min-width: 250px;
  }
}

@media only screen and (max-width: 1120px) {
  .job-col-right {
    height: auto;
  }

  .job-title {
    max-width: 46%;
  }

  .job-seclude {
    max-width: 50%;
  }

  .lower-part-data {
    padding: 50px 25px 25px;
  }

  p.box-heading-f {
    font-size: 24px;
  }

  /***header****/
  ul.menu-list li a {
    padding: 16px 0px;
  }
}

/***** for down 1000 *****/

@media only screen and (max-width: 1050px) {
  .col-2-banner h1 {
    font-size: 36px;
  }

  .button-stack button {
    width: 80%;
  }

  .job-title h3 {
    max-width: 100%;
    width: 100%;
  }

  .long-text-job-company {
    max-width: 100%;
    width: 100%;
  }

  .job-title a.title-job-link {
    text-decoration: none;
    max-width: 100%;
  }

  .job-title a {
    max-width: 50%;
    width: 100%;
  }

  h4.team-remote {
    font-size: 36px;
  }

  h4.contect-readt-start {
    font-size: 36px;
  }

  /*********** signup form candidate**********/
  .first-name {
    max-width: 100%;
    width: 100%;
  }

  .last-name {
    max-width: 100%;
    width: 100%;
  }

  .candidate-signup-form-feild {
    max-width: 80%;
  }

  /*********** filters company details *********/
  .j-fillter-only {
    max-width: 100%;
    width: 100%;
    padding-right: 0;
  }

  .job-type-f,
  .job-keywords {
    padding-right: 15px;
  }

  .job-location-f img {
    padding-right: 8px;
    padding-left: 7px;
  }

  /************candidate profile ******/
  button.accordion-button.collapsed {
    font-size: 20px;
  }

  .candidate-profile-btn {
    max-width: 26%;
  }

  .three-stack-btn button {
    padding: 10px 0;
  }

  /************company page *********/
  .search-box {
    display: none;
  }

  /******iframe model css ********/
  .popup-bg-iframe {
    width: 90%;
    margin: 0 auto;
  }

  a.anchor-cards-job {
    max-width: 46%;
    width: 100%;
  }

  /******job search page *********/
  .job-col-right .job-title {
    max-width: 100%;
  }

  .job-col-right .job-seclude {
    max-width: 70%;
    justify-content: flex-start;
  }

  .job-seclude {
    max-width: 70%;
    justify-content: flex-start;
  }

  /*******form image boxes****/
  p.l-boc-two {
    height: 175px;
  }

  /****journey page***/
  .tabs-content-jour {
    width: auto;
  }

  button.can-res-btn-last,
  button.save-res-btn-last {
    min-width: 230px;
  }

  /***********job resume candiate***/
  .candidate-job-resume {
    flex-direction: column;
  }

  .left-res-txt,
  .job-form-info {
    max-width: 100%;
    width: 100%;
  }

  .img-stok-job {
    position: relative;
    left: 0px;
    text-align: center;
  }

  /**header******/
  .menu-items {
    gap: 10px;
  }

  button.signup-poly {
    padding: 9px;
  }

  button.login-header-poly {
    padding: 12px 9px;
  }
}

@media only screen and (max-width: 1000px) {
  .job-skills {
    max-width: 80%;
  }

  /*********** job detal asge css start here **********/
  .filter-serach-btn {
    max-width: 100%;
    width: 100%;
    display: block;
  }

  .three-filter-inline {
    border: 0px solid var(--boder-color);
    border-radius: 0px;
    padding: 0px;
    max-width: 100%;
    display: block;
    width: 100%;
  }

  .job-keywords,
  .job-type-f,
  .job-location-f {
    padding: 12px;
    margin-left: 0px;
    border: 0.5px solid var(--boder-color);
    border-radius: 6px;
    margin-bottom: 15px;
  }

  .job-post-date {
    padding: 12px;
    margin-left: 0px;
    border: 0.5px solid var(--boder-color);
    border-radius: 6px;
  }

  p.clear-filter {
    right: 0;
    bottom: -34px;
    left: 0;
  }

  #job-select-field,
  #job-post-date-feild {
    width: 100%;
  }

  .job-filter-btn {
    position: relative;
    text-align: center;
    margin-top: 30px;
    flex-direction: column;
  }

  .job-filter-btn button {
    padding: 12px 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  /***************job search ******/
  .job-fillter-f {
    flex-direction: column;
  }

  .job-keywords,
  .job-type-f,
  .job-location-f {
    max-width: 100%;
  }

  .job-post-date {
    max-width: 100%;
    width: 100%;
  }

  /**************job search match page *************/
  .col-right-j-space {
    max-width: 65%;
    flex-direction: column;
  }

  .Job-Opportunities-row-j-search {
    flex-wrap: wrap;
  }

  .job-col-right .job-requriments {
    width: 100%;
    margin-bottom: 30px;
  }

  .job-col-right {
    height: auto;
  }

  .job-col-right .job-skills {
    max-width: 100%;
  }

  .job-col-right .job-location {
    max-width: 100%;
    justify-content: flex-start;
  }

  .job-location {
    max-width: 100%;
    justify-content: flex-start;
  }

  .col-left-j-space {
    max-width: 35%;
  }

  /******cnadidaate profile 1000***/
  .candidtae-profile-image {
    max-width: 23%;
  }

  .candidate-profile-btn {
    max-width: 23%;
  }

  .logo-col-profile-candidate {
    max-width: 60%;
  }

  .logo-col-profile-left {
    max-width: 65%;
  }

  .profile-right-col,
  .dropdown-dashbord {
    max-width: 40%;
  }

  .hide-wishlist-style {
    max-width: 35%;
  }

  button.accordion-button.collapsed {
    font-size: 20px;
  }

  /**candidate model ******/
  .resume-heading h1 {
    font-size: 36px;
  }

  /*********empolyeee dahsbord *******/
  .employee-job-dasboard {
    grid-template-columns: [col1-start] 1fr [col2-start] 1fr [col3-start] 1fr [col3-end];
  }

  /*image box f***/
  .lower-part-data {
    padding: 50px 15px 25px;
  }

  p.l-box-one {
    font-size: 16px;
  }

  p.box-heading-f {
    font-size: 20px;
  }

  /*******jb file upload***********/
  .two-col-jbmdl {
    flex-direction: column-reverse;
    gap: 0px;
  }

  .col-left-jbmdl,
  .col-right-jbmdl {
    max-width: 100%;
    width: 100%;
    margin: 0 0 15px 0;
  }

  .upload-resume-model-jbmdl {
    width: auto;
  }

  /***final  signup form**********/
  .city-img {
    max-width: 33%;
  }

  .cities-btn {
    max-width: 66%;
    padding-left: 18px;
  }

  .city-radio-btn {
    max-width: 33%;
  }

  /***header**/
  .col-two-button {
    width: 23%;
  }

  .col-two-button button {
    padding: 7px 8px;
  }

  ul.menu-list li a,
  .text-menu-icon p {
    padding: 7px 0px;
    font-size: 13px;
  }

  button.login-header-poly {
    font-size: 18px;
  }

  button.signup-poly {
    font-size: 17px;
  }

  /*****banner poly***/
  .banner-heading-poly h2 {
    font-size: 60px;
  }

  /**********post card-sec******/
  .left-job-content-postcard {
    padding-right: 40px;
  }

  /**** skills tabsss*****/
  .left-right-content-jour {
    flex-direction: column;
    justify-content: center;
  }

  .left-col-vertical,
  .right-content-col-jour {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  th.th-descrip {
    width: 325px;
  }
}

@media only screen and (max-width: 860px) {
  .col-2-banner p.banner-text {
    font-size: 16px;
    padding: 0px 0px;
  }

  .button-stack button {
    width: 90%;
  }

  .ensure-heading {
    width: 80%;
    margin: 0 auto;
  }

  .col-two-button .find-job-btn {
    margin-right: 70px;
  }

  /************* login form *********/
  .candidate-login-row,
  .candidate-login-row-password {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .candidate-profile {
    max-width: 100%;
    width: 100%;
  }

  .candiate-login-form {
    max-width: 100%;
    width: 100%;
  }

  button.employer-btn,
  button.candidate-btn {
    padding: 15px 60px;
  }

  /*****candidate-resume-decession*****/
  .right-crd-img {
    display: none;
  }

  .card-left-res,
  .card-right-res {
    width: 350px;
    padding: 20px;
  }

  /***images box f ******/
  .left-form-data,
  .right-form-data {
    max-width: 100%;
    width: 100%;
    height: 329px;
  }

  p.l-box-one {
    font-size: 14px;
  }

  p.l-boc-two {
    font-size: 14px;
    padding: 10px 0 10px;
  }

  p.l-boc-two span {
    font-size: 14px;
  }

  p.l-boc-two {
    height: 110px;
  }
}

/******tablet*********/

@media only screen and (max-width: 859px) and (min-width: 768px) {

  .col-left-j-space {
    max-width: 35%;
  }

  /******header******/
  .row-log {
    align-items: center;
  }

  .find-job-btn button {
    padding: 8px 20px;
  }

  .text-menu-icon {
    gap: 8px;
  }

  button.login-header {
    padding: 8px 20px;
  }

  .col-two-button button {
    padding: 8px 8px;
  }

  .col-1-banner,
  .col-3-banner {
    max-width: 25%;
    width: 100%;
  }

  .col-2-banner {
    max-width: 50%;
    width: 100%;
    padding: 0 20px;
  }

  .col-2-banner h1 {
    font-size: 24px;
    line-height: 30px;
  }

  /***** home page feature sec ********/
  .button-stack button {
    width: 100%;
  }

  .featured-col {
    width: 100%;
    padding: 0 15px !important;
    max-width: 50%;
  }

  .sucess-featured-img {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  .sucess-featured-info {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    padding: 30px 0px 0px !important;
  }

  .sucess-featured {
    display: block;
  }

  .first-col-content p {
    padding: 20px 0;
  }

  .Job-Opportunities-row,
  .Job-Opportunities-row-company,
  .j-compny-btn {
    display: block;
  }

  .job-company-details {
    max-width: 100%;
    padding-right: 0;
  }

  .j-company-logo {
    max-width: 40%;
    margin: 0 auto;
  }

  .j-company-btn-apply {
    max-width: 50%;
    margin: 0 auto;
  }

  button.apply-j-btn,
  button.fav-j-btn {
    margin: 0 auto 25px auto;
  }

  .icon-text-job-requre {
    justify-content: center;
  }

  p.job-discription-com-j-recruite {
    width: auto;
  }

  .j-company-dev {
    max-width: 100%;
    margin: 30px 0;
    text-align: center;
  }

  .company-job-announcements {
    max-width: 100%;
    margin-top: 50px;
  }

  .job-requriments {
    width: calc(100% - 0px);
  }

  .job-announcements {
    width: 100%;
    margin-top: 30px;
  }

  .world-conect-row p {
    font-size: 30px;
  }

  /*********** candidate signup form **********/
  .signup-phone #countryCode {
    width: 18%;
  }

  .candidate-signup-form,
  .candidate-signup-form-feild,
  .candidate-from-signup-create-final-job,
  .goole-login-border,
  .candidate-from-signup-post-job {
    max-width: 90%;
  }

  .signup-salary #salary {
    width: 18%;
  }

  .signup-salary input[type="text"] {
    width: 82%;
  }

  p.clear-filter {
    right: 0px;
    bottom: -36px;
    left: 0;
  }

  /********** smilar job section *********/
  .job-search-box-row {
    display: block;
  }

  .smilar-job-heading h2 {
    position: relative;
    text-align: center;
  }

  .search-box {
    max-width: 50%;
    width: 100%;
    margin: 0 auto;
  }

  /**********filter feild job detail page *************/
  .job-keywords img {
    width: 34px;
    max-width: 100%;
    height: auto;
  }

  .job-location-f img {
    max-width: 100%;
    height: auto;
    width: 34px;
  }

  .job-fillter-f {
    display: block;
    max-width: 85%;
    width: 100%;
  }

  .job-post-date {
    max-width: 100%;
    width: 100%;
  }

  .job-keywords,
  .job-type-f {
    max-width: 100%;
  }

  /*********company details ************/
  .open-postion {
    padding-top: 0px;
  }

  /******cnadidaate profile tablet***/
  section.profile-edit {
    padding: 75px 0px 50px 0px;
  }

  .logo-col-profile-candidate,
  .logo-col-profile-left {
    max-width: 50%;
  }

  .profile-right-col,
  .dropdown-dashbord {
    max-width: 50%;
  }

  .my-profile-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .my-profile-row-date-fill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .candidtae-profile-image {
    max-width: 80%;
    height: 300px;
  }

  .candidate-profile-btn {
    max-width: 80%;
  }

  button.accordion-button.collapsed {
    font-size: 20px;
  }

  input#preferences-prfile {
    width: 100%;
  }

  .tabs-candidate-col-space {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .space-col-center {
    max-width: 80%;
    width: 100%;
  }

  /************candidate-model css *********/
  p.candiadte-resume-heading {
    font-size: 30px;
  }

  .resume-heading h1 {
    left: 35%;
  }

  /*********profile candidate *********/
  /*******candidate job dashboard ***/
  .job-announcements-col-candidate button,
  .job-wishlist-btn button {
    width: 35%;
    display: block;
  }

  .find-job-btn-candidate-dashbord {
    display: none;
  }

  .job-recommend-applied,
  .job-inbox-journey 
  {
    flex-direction: column;
  }

  .link-box {
    max-width: 100%;
  }

  .employee-job-dasboard {
    flex-direction: inherit;
  }

  a.card-link-box {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  /************comment moedel *******/
  .chat-model-comment-box {
    padding: 0px;
  }

  /*************profile canddate ********/
  .candidate-call {
    max-width: 15%;
  }

  /******* image model css****/
  p.popup-text-model-img {
    font-size: 18px;
  }

  /*******post job form new****/
  .employe-final-job-post {
    max-width: 90%;
    width: 100%;
    margin: 30px auto;
  }

  /********resume verification*/
  .resume-verivication-form {
    flex-direction: column;
  }

  .resume-start-verivication {
    max-width: 94%;
  }

  .resume-form-info {
    max-width: 100%;
  }

  .first-name-res,
  .last-name-res {
    max-width: 100%;
  }

  .resume-personal-form-chek {
    gap: 3px;
  }
}

/**********mobile***********/

.menu-items-poly .close-menu {
  display: none;
}

.menu-items-poly .profile-section {
  display: none;
}

ul.menu-list li a img,
.text-menu-icon p img {
  display: none;
}

@media only screen and (max-width: 767px) {

  .cand-activities {
    padding: 0 10px;
  }

  .profile-card {
    padding: 15px 10px;
  }

  .cand-meetup h3 img {
    display: none;
  }

  .notes-box .comment-img-b {
    display: none;
  }

  .comments-sec {
    padding: 0;
  }

  .notes-box .clinet-profile img {
    max-width: 32px;
  }

  .notes-box p.clinet-rank {
    font-size: 12px;
  }

  .notes-box p.name-clinet {
    font-size: 16px;
  }

  .cand-inque {
    padding: 12px 14px;
  }

  .cand-inque h3 {
    font-size: 16px;
  }

  .cand-inque ul li {
    height: 32px;
    width: 32px;
    margin-inline-end: -14px;
  }

  .chat-dd {
    padding: 0 16px;
  }

  .pin-toogle {
    left: 10px;
    top: 50px;
    width: calc(100% - 30px);
    right: 0;
    max-width: 100%;
  }

  .notes-box .btn-outline-black img {
    max-height: 20px;
    padding: 1px;
  }

  .notes-box .header-bar-comment .btn-outline-black {
    background: var(--color-white);
    font-size: 14px;
    padding: 2.5px 10px;
  }

  .notes-box .btn-outline-black {
    padding: 1px 10px;
  }

  .single-chat .chit-chat .avatar {
    width: 38px;
    height: 38px;
  }

  .notes-box .chat-model-comment-box {
    height: calc(100% - 164px);
    overflow: unset;
    padding: 0;
    padding-top: 60px;
  }

  .notes-box .chat-model-comment-box.pinned {
    height: calc(100% - 165px);
    overflow: unset;
    padding: 0;
    padding-top: 130px;
  }

  .single-chat .chit-chat .chit-box {
    padding-left: 22px;
  }

  .chat-slot {
    padding: 10px;
  }

  .single-chat .chit-chat {
    padding-left: 20px;
  }

  .single-chat.replied .chit-chat .chit-box {
    padding-right: 22px;
  }

  .single-chat.replied .chit-chat {
    padding-right: 20px;
  }

  .single-chat .chit-chat .pin-chat {
    max-height: 16px;
  }

  .whishlist-btn button {
    width: 100%;
    margin-bottom: 8px;
  }

  .col-left-j-space .short-content-left .view-job-btn {
    width: 100%;
  }

  /*banner polycare***/
  .banner-row-poly {
    padding: 30px 0px 30px;
  }

  .banner-heading-poly h2 {
    font-size: 28px;
  }

  .two-btn-poly {
    text-align: center;
    padding: 25px 0 25px 0;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }

  button.find-job-poly,
  button.post-job-poly {
    padding: 16px 80px;
  }

  .banner-img {
    display: none;
  }

  p.poly-row-test-job {
    font-size: 14px;
  }

  /*************************/

  .candidate-list-header {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .candidate-list-header>div {
    margin-left: auto;
  }

  .emp-profile-mob.candidtae-profile-image-avatar {
    max-width: 100%;
    flex-direction: row;
    border: 0;
    align-items: flex-start;
    height: auto;
  }

  .employee-searchbox-btn {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  .resp-mob {
    position: relative;
    left: 0 !important;
    top: 0 !important;
    margin-bottom: 15px;
  }

  .max-mob-100 {
    max-width: 100% !important;
  }

  ul.find-job-tag {
    gap: 4px;
  }

  .divider-mobile .signup-job-titles+.signup-job-titles {
    border-top: 1px solid #0d0d0d20;

  }

  .employee-job-dasboard>a {
    height: 120px;
  }

  .employee-job-dasboard .btn-black {
    width: 100%;
  }

  .modal-content-info .modal-body.job-purchase p {
    font-size: 16px;
  }

  .j-fillter-only-dashbord {
    margin: 30px 0 16px;
  }

  .profile-heading h2 {
    font-size: 20px;
  }

  .profile-field-f {
    margin-bottom: 16px;
  }

  .profile-field-f:last-child {
    margin-bottom: 10px;
  }

  .employee-profile-compnay {
    margin-bottom: 20px;
  }

  .candidate-name-field-profile {
    gap: 16px;
  }

  .employee-job-dasboard {
    padding: 25px 0 25px 0;
  }

  .employee-job-dasboard>a .job-applied-col {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .employee-job-dasboard>a .job-applied-col .card-employee-info {
    flex: auto;
  }

  .Job-Opportunities-row {
    box-shadow: 0px 1px 12px 4px #0000000F;
    padding: 22px 12px;
    margin: 0 0 8px;
    border-radius: 12px;
  }

  .Job-Opportunities-row .job-skill-location {
    display: none;
  }

  .job-details {
    gap: 0;
    flex-direction: column;
    display: block;
  }

  .job-services-anchor p {
    font-size: 14px;
    font-weight: 600;
  }

  .Job-Opportunities-row+.divider {
    display: none;
  }

  .candidate-profile-form {
    border-left: 0;
  }

  .candidate-information h5 {
    font-size: 20px;
    padding-right: 10px !important;
  }

  .accordion-candidate-prfile .accordion-item+.accordion-item {
    margin-top: 20px;
  }

  .profile-edit-body h3,
  .profile-card .s-amenity h3,
  .profile-card .f-feild label {
    font-size: 16px;
  }

  .profile-edit-body h3 {
    padding-right: 55px;
  }

  .price-card {
    max-width: 100%;
    width: 100%;
  }

  .price-card .price-card-body .card-para {
    height: auto;
  }

  .profile-card .s-amenity {
    margin-bottom: 20px;
  }

  .profile-edit-body p {
    font-size: 14px;
  }

  .profile-edit-body .edit-footer .action-edit {
    width: 100%;
    margin-top: 10px;
    justify-content: flex-end;
  }

  .profile-edit-body .edit-footer .btn-ai {
    padding: 6px 15px;
  }

  .form-block-width {
    padding: 16px 0;
  }

  .job-type-heading {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .find-job-tag li:first-child {
    background: transparent;
    border: 0;
    padding: 0;
  }

  .most-job-search-tag p {
    margin-top: 4px;
  }

  .no-companyCard {
    display: none;
  }

  .menu-items {
    display: none !important;
  }

  .col-two-button .find-job-btn {
    margin-right: 30px;
  }

  .list-men-dropdown-head {
    position: static !important;
    width: 100% !important;
    box-shadow: none !important;
    margin: 0 !important;
  }

  ul.menu-item-drop-poly {
    padding: 10px 10px 0 56px !important;
  }

  ul.menu-item-drop-poly li {
    font-size: 16px;
    line-height: 30px;
  }

  .menu-items-poly {
    position: fixed;
    left: calc(0px - 100%);
    right: 0;
    width: 100% !important;
    top: 0;
    bottom: 0;
    background: #fff;
    height: 100%;
    z-index: 999;
    padding: 0 !important;
    transition: 0.25s ease-in;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start !important;
  }

  .menu-items-poly.openNavbar {
    left: 0;
  }

  .menu-items-poly .close-menu {
    position: absolute;
    right: 0;
    top: 5px;
    display: block;
  }

  .close-menu img {
    max-width: 20px;
  }

  .text-menu-icon>img {
    margin-right: 5px;
  }

  .option-name {
    margin-left: 16px;
  }

  .dashboard-icon {
    width: 100%;
    height: 100%;
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: #fff;
    border: 1px solid #fefefe;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .profile-avatar .loggedout-prof {
    width: auto;
    height: auto;
    max-width: 100px;
    max-height: 100px;
  }

  ul.menu-list li a,
  .text-menu-icon p {
    color: var(--dark-black-color) !important;
  }

  .menu-dropdown-head.open .text-menu-icon p {
    font-weight: 900;
    color: var(--dark-black-color);
  }

  .menu-items-poly .profile-section {
    width: 100%;
    background: rgba(43, 43, 43, .05);
    display: flex;
  }

  ul.menu-list {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start !important;
    width: 100%;
    padding: 20px 16px !important;
    gap: 2px !important;
  }

  .menu-dropdown-head.open .text-menu-icon {
    background: #ECECEC;
    border-radius: 8px;
  }

  .candidate-name-img {
    flex-grow: unset !important;
  }

  ul.menu-list li {
    padding: 0 !important;
    width: 100%;
    font-size: 16px !important;
    line-height: 30px !important;
  }

  ul.menu-list li a,
  .text-menu-icon p {
    font-size: 16px !important;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 10px 16px !important;
  }

  ul.menu-list li a img,
  .text-menu-icon p img {
    display: inline-block;
    max-width: 24px;
    max-height: 24px;
  }

  .toggleBtn {
    display: block !important;
  }

  .logo-col {
    max-width: 40%;
    width: 100%;
  }

  .row-log {
    gap: 25px;
    align-items: center;
  }

  .row-login-candidate {
    gap: 25px;
    align-items: center;
    justify-content: space-between;
  }

  .logo-col img {
    max-width: 100%;
  }

  .col-two-button {
    max-width: 60%;
    width: 100%;
    justify-content: end;
  }

  .col-2-banner h1 {
    font-size: 24px;
    line-height: 30px;
  }

  .col-1-banner,
  .col-3-banner {
    display: none;
  }

  .col-2-banner {
    max-width: 100%;
    width: 100%;
  }

  .find-job-btn button {
    padding: 6px 12px;
    font-size: 16px;
    display: none;
  }

  .find-job-btn-candidate-dashbord button {
    padding: 6px 12px;
    font-size: 16px;
  }

  .create-listing-btns {
    width: 100%;
  }

  .fix-width-btn {
    width: 100%;
    min-width: unset;
  }

  .banner-row {
    padding: 0;
    margin: 60px 0 20px 0;
  }

  .col-2-banner {
    padding: 0 30px 0 30px;
  }

  .col-2-banner h2 {
    font-size: 30px;
    font-weight: 700;
  }

  img.vector {
    display: none;
  }

  .logs-ros {
    width: 100%;
    margin: 0 auto;
    gap: 20px;
  }

  .featured-col {
    width: 100%;
    padding: 0 0px;
    max-width: 100%;
  }

  .sucess-featured-img {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .sucess-featured-info {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 30px 0px 0px !important;
  }

  .sucess-featured {
    display: block;
  }

  /***** home page feature sec 1440********/
  /*******/
  .Job-Opportunities-row,
  .Job-Opportunities-row-company {
    display: block;
  }

  .job-requriments {
    width: calc(100% - 0px);
  }

  .job-announcements {
    width: 100%;
    margin-top: 12px;
  }

  .button-stack button {
    width: 80%;
    font-size: 16px;
  }

  .featured-sec {
    padding: 30px 0px;
  }

  .ensure-heading {
    width: 90%;
    margin: 0 auto;
  }

  .ensure-heading h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .sucess-featured-sec,
  .sucess-featured-sec-alt {
    background: transparent;
  }

  .sucess-featured-info {
    padding: 40px 20px !important;
    border-radius: 8px;
    box-shadow: 2px 4px 7px 3px rgb(0 0 0 / 10%);
  }

  .first-col-content p {
    font-size: 16px;
    line-height: 28px;
    padding: 0px 0px 10px;
  }

  .sucess-featured-sec-alt,
  .sucess-featured-sec {
    margin: 0px;
    padding: 50px 0;
  }

  .sucess-featured-sec {
    margin: 0px;
  }

  .sucess-featured-info button {
    margin: 0 0 0 auto;
    display: block;
  }

  .image-heading h3,
  .sucess-featured-info h3 {
    font-size: 20px;
  }

  .sucess-featured-info a {
    text-decoration: none;
  }

  .new-job {
    padding: 0px 0px 50px;
  }

  .job-skills {
    max-width: 100%;
  }

  .job-title {
    max-width: 100%;
  }

  .job-title,
  .job-seclude {
    max-width: 100%;
  }

  .job-seclude {
    justify-content: flex-start;
  }

  .job-location {
    max-width: 100%;
    justify-content: center;
  }

  .job-location-switch {
    max-width: 70%;
  }

  .job-title h3 {
    padding-bottom: 0px;
  }

  .job-title h3,
  .job-title a {
    max-width: 100%;
    width: 100%;
    font-size: 20px !important;
  }

  .job-skills {
    flex-wrap: wrap;
  }

  .job-time-wishlist {
    padding-left: 0px;
    text-align: center;
  }

  .job-apply,
  .job-favorite,
  .job-opstion-status,
  .job-candidate-list-icon,
  .job-applied-text,
  .job-applied-text-candidate {
    justify-content: center;
  }

  .job-announcements-col button {
    margin: 0 auto;
    display: block;
    width: 100%;
  }

  .tow-btn-job-dash {
    width: 100%;
  }

  .main-dropdown-heading {
    width: 100% !important;
  }

  .first-menu-dropdown li .sub-menu-lists {
    justify-content: space-between;
    gap: 20px;
  }

  .job-skill-location {
    justify-content: center;
  }

  .see-more-job button {
    font-weight: var(--font-weight-bold);
    font-size: 16px;
    min-width: 200px;
    padding: 12px 0;
  }

  .countries-work-heading h2 {
    text-align: center;
    font-size: 24px;
  }

  .countries-work-location {
    justify-content: center;
    padding-top: 30px;
  }

  .countries-work-location figure {
    max-width: 44%;
    width: 100%;
  }

  .countries-work-location figcaption {
    font-size: 18px;
    line-height: 28px;
  }

  .ready-start-image {
    display: none;
  }

  .ready-start-info {
    max-width: 100%;
    width: 100%;
  }

  h4.team-remote,
  h4.contect-readt-start {
    font-size: 24px;
    text-align: center;
  }

  h4.team-remote {
    padding-bottom: 20px;
  }

  /***********steps section polycare****/

  .contact-sec {
    padding: 50px 0px 50px 0px;
  }

  .contact-btn button {
    margin: 0 auto;
  }

  .row-twostep-poly {
    flex-direction: column-reverse;
    padding: 20px;
    margin-bottom: 15px;
    gap: 20px 75px;
  }

  .row-onestep-poly {
    flex-direction: column;
    padding: 20px;
    margin-bottom: 15px;
    gap: 20px 75px;
  }

  p.step-heading-poly {
    font-size: 28px;
  }

  .step-content-poly {
    font-size: 18px;
  }

  section.three-steps-poly {
    padding: 30px 0;
    background: #fbfbfb;
  }

  .two-btn-post-steps {
    gap: 20px;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }

  section.all-oversite-sec {
    padding: 25px 0;
  }

  /************** world conect section - poly **********/
  .site-text-poly-sec {
    font-size: 20px;
  }

  button.post-btn-poly {
    padding: 12px 60px;
  }

  button.contact-btn-poly {
    padding: 9px 60px;
  }

  /************************/

  .footer-address,
  .footer-services,
  .footer-countries,
  .footer-privacy {
    width: 100%;
    max-width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  p.office-address {
    width: 100%;
  }

  .footer-list ul {
    padding-left: 0px;
    margin: 0;
  }

  .footer-row {
    padding-bottom: 30px;
  }

  .footer-countries.footer-list {
    order: 1;
    padding: 10px 0;
  }

  .footer-services.footer-list {
    order: 1;
  }

  .footer-privacy.footer-list {
    order: 1;
    padding-top: 20px;
  }

  .footer-address {
    order: -1;
  }

  .footer-rights {
    display: block;
  }

  ul.site-list {
    justify-content: center;
    padding-left: 0px;
  }

  .footer-site-development {
    text-align: center;
    padding-bottom: 15px;
  }

  /********1440px ****/
  .space-inner-row {
    padding: 0 0px;
  }

  /**********cookies section css mobile**********/
  .cookies-sec {
    padding: 20px;
  }

  .cookies-heading p {
    font-size: 20px;
    line-height: 30px;
  }

  button.decline-btn {
    padding: 8px 25px;
    font-size: 18px;
  }

  button.allow-btn {
    padding: 8px 31px;
    font-size: 18px;
  }

  p.cookies-paragrapgh {
    font-size: 16px;
    line-height: 1.5em;
    width: 100%;
    margin: 15px auto 0;
  }

  .cookies-btns {
    justify-content: center;
  }

  /******** candidiatelogin page mobile**********/
  .world-conect-sec {
    padding: 20px;
  }

  .candidate-profile {
    margin-top: 30px;
    display: none;
  }

  .world-conect-row p {
    font-size: 20px;
  }

  .candidate-signin,
  .candidate-signin-password-ft {
    max-width: 100%;
    width: 100%;
    padding: 20px 15px 40px 15px;
  }

  .candidate-login-row {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .candidate-login-row-password {
    padding-top: 40px;
    padding-bottom: 0;
    flex-direction: row;
  }

  .list-search-dropdown-status {
    max-height: 300px;
    overflow: auto;
  }

  .j-company-fillter-table-data {
    padding: 12px;
  }

  .company-form-dropdownBox1-tabeldate {
    font-size: 16px;
  }

  ul.list-table-title-cand li {
    font-size: 18px;
  }

  .candidate-signin h2,
  .candidate-signin-password-ft h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .candidate-from-submit input[type="submit"],
  button.password-btn {
    font-size: 16px;
  }

  .candidate-from-signup input[type="submit"] {
    font-size: 18px;
  }

  /************candidate signup form mobile************/
  .candidate-signup-form,
  .candidate-signup-form-feild,
  .candidate-from-signup-create-final-job,
  .goole-login-border,
  .candidate-from-signup-post-job {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .candiate-signup-row {
    padding: 30px 0;
  }

  .candidate-signup-google {
    max-width: 100%;
    width: 100%;
  }

  .candidate-employe-btn {
    gap: 10px;
    margin-bottom: 16px;
  }

  button.employee-btn {
    border: 0px solid rgba(0, 0, 0, 0.2);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 10px 40px;
  }

  .signup-phone #countryCode {
    width: 20%;
  }

  .tags-tittles ul.tags-titles-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .signup-check.f-feild {
    width: 100%;
    margin: 0 auto;
  }

  .candidate-signup h2 {
    font-size: 24px;
    line-height: 30px;
  }

  button.form-submit {
    font-size: 16px;
    line-height: 19.5px;
  }

  .signup-email input[type="email"],
  .signup-passowrd input[type="password"],
  .signup-country #country,
  .signup-phone #countryCode,
  .signup-phone input[type="tel"],
  .signup-job-titles input[type="text"],
  .signup-skill-tags input[type="text"],
  .signup-resume input[type="file"],
  .signup-resume input[type="text"],
  .signup-linkedIn-link input[type="text"],
  .candidate-username-signup-form input[type="text"],
  .signup-salary #salary,
  .signup-salary input[type="text"],
  .signup-jobs-option #choose-jobs,
  .password-icon,
  .candidate-username input[type="text"],
  input#preferences-prfile,
  input#candidate-name-field,
  input#candidate-lname-field,
  select#candidate-location-feild,
  input#profile-email-candidate,
  input#profile-call,
  input#social-link,
  .candidate-call,
  .location-f-icon {
    padding: 12px 8px;
  }

  /************* popup model mobile*************/
  .popup-content,
  .popup-content-modelimage {
    width: 90%;
    /* padding: 20px 15px 20px; */
  }

  .close-img {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .close-img-resume {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 1;
  }

  p.popup-text {
    font-size: 20px;
  }

  .send-correct {
    padding-top: 20px;
  }

  .send-correct img {
    width: 60%;
  }

  .send-correct {
    padding: 20px 0;
  }

  p.popup-text-model-img {
    font-size: 18px;
  }

  /****************progress bar css  for mobile*************/
  ul.progressbar-steps {
    padding-left: 0;
  }

  .progressbar-steps li {
    width: 32.33%;
    font-size: 18px;
  }

  .progressbar-steps li:before {
    width: 40px;
    height: 40px;
    line-height: 36px;
    font-size: 18px;
  }

  .progressbar-steps li:after {
    top: 18px;
  }

  /*************create company profile mobile***********/
  .signup-file-company-logo label {
    flex-wrap: wrap;
  }

  /************job post css ***********/
  .signup-salary div#salary {
    width: 14%;
  }

  .signup-salary input[type="text"] {
    width: 86%;
  }

  p.cities-job-heading {
    font-size: 24px;
    line-height: 30px;
  }

  .img-radion-btn {
    display: block;
    margin: 30px 0 0 0;
  }

  .city-img {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .cities-btn {
    max-width: 100%;
    margin-top: 20px;
    justify-content: center;
    padding-left: 0px;
  }

  .city-radio-btn {
    max-width: 30%;
    width: 100%;
  }

  .mbl-jobs {
    margin-top: 0;
  }

  .mbl-jobs .job-name {
    max-width: calc(100% - 50px);
  }

  .promoted-job-btn {
    border: 0;
    padding: 0;
  }

  .job-card {
    margin-top: 4px;
    margin-bottom: 4px
  }

  /*********** job details page css start here mobile**********/
  .filter-serach-btn {
    max-width: 100%;
    width: 100%;
    display: block;
    padding-top: 20px;
  }

  .job-fillter-f {
    display: block;
    max-width: 100%;
    width: 100%;
  }

  .job-fillter-company {
    display: block;
    max-width: 100%;
    width: 100%;
  }

  .j-company-fillter-table,
  .job-fillter-company-table {
    width: 100%;
  }

  .three-filter-inline {
    border: 0px solid var(--boder-color);
    border-radius: 0px;
    padding: 0px;
    max-width: 100%;
    display: block;
    width: 100%;
  }

  .job-keywords,
  .job-type-f,
  .job-location-f {
    padding: 12px;
    margin-left: 0px;
    border: 0.5px solid var(--boder-color);
    border-radius: 6px;
    margin-bottom: 15px;
  }

  .job-keywords,
  .job-type-f {
    max-width: 100%;
  }

  .job-post-date {
    padding: 12px;
    margin-left: 0px;
    border: 0.5px solid var(--boder-color);
    border-radius: 6px;
  }

  #job-select-field,
  #job-post-date-feild {
    width: 100%;
  }

  .job-filter-btn {
    position: relative;
    text-align: center;
    margin-top: 30px;
  }

  .job-filter-btn button {
    padding: 12px 50px;
  }

  ul.job-details-list {
    padding: 32px 0 0px 20px;
    margin-bottom: 0;
  }

  ul.job-details-list li {
    font-size: 16px;
    line-height: 28px;
  }

  .recruite-note-box {
    /* background: none; */
    margin: 0px 20px;
  }

  /***********banner heading *******************/
  .f-job-heaading h2,
  .job-search-tag-heading h2,
  .j-react-title h2,
  .job-heading h2,
  .company-detail-heading h2 {
    font-size: 24px;
  }

  .job-heading h1 {
    font-size: 22px;
  }

  .level-of-developer {
    gap: 20px;
  }

  .f-feild-post {
    margin-bottom: 20px;
  }

  .f-feild {
    margin-bottom: 24px;
  }

  .job-location-dropdown {
    width: 100%;
  }

  ul.dorpdown-list-location li {
    white-space: normal;
  }

  .header-bar-comment>.clinet-profile {
    flex: 0 0 80%;
  }

  .job-heading h1+p {
    font-size: 16px;
  }

  .emoji-container {
    right: 0;
    left: 0;
    width: 100%;
  }

  /******************/
  p.clear-filter {
    right: 0;
    bottom: -34px;
    left: unset;
    transform: translateX(0);
  }

  .job-heading {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }

  .job-heading h2 {
    margin: 0px;
    padding: 0px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .job-heading p {
    font-size: 16px !important;
  }

  .job-heading p br {
    display: none;
  }

  .smilar-job-heading h2 {
    position: relative;
    text-align: center;
    flex: auto;
  }

  .ques-title h2 {
    font-size: 20px;
  }

  .single-question p.question-key {
    font-size: 16px;
  }

  button.q-tabs {
    font-size: 18px !important;
    padding: 12px 0 !important;
  }

  .job-details-first-title h5 {
    font-size: 20px;
  }

  .job-discription,
  .job-discription-com-j {
    font-size: 16px;
  }

  .job-search-box-row {
    display: block;
  }

  .job-search-box-row {
    padding-bottom: 0;
  }

  .d-ban-content .btn {
    width: 100%;
  }

  .d-ban-content {
    font-size: 16px;
    padding-right: 0;
  }

  .d-ban-content h2 {
    font-size: 22px;
  }

  .smilar-job-heading {
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  .search-box {
    display: none !important;
    max-width: 80%;
    width: 60px;
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 4px;
    background: var(--color-white);
    z-index: 9;
    transition: 0.25s all ease-in;
  }

  .search-box.active {
    width: 100%;
  }

  .search-box #jon-search-box {
    width: 0;
  }

  .search-box.active #jon-search-box {
    width: 85%;
  }

  /*************company details page css start here **********/
  .company-detail-sec {
    padding: 25px 0px;
  }

  .j-fillter-only {
    display: block;
    margin: 15px auto;
  }

  .j-company-fillter {
    width: auto !important;
    margin-bottom: 10px;
  }

  .company-logo-left h6 {
    display: block;
    font-size: 20px;
    font-weight: var(--font-weight-bold);
  }

  .company-content-right h6 {
    display: none;
  }

  .company-content-logo {
    display: block;
  }

  .company-logo-left {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 15px;
  }

  .container-site .company-logo-left {
    max-width: calc(100% + 30px);
    width: calc(100% + 30px);
    margin: 0 -15px;
  }

  .company-content-right {
    max-width: 100%;
    width: 100%;
  }

  .search-box {
    display: none;
  }

  /**************** job details page filer***********/
  section.f-dream.job {
    margin-top: 0;
  }

  .job-posstion-job-content {
    padding: 18px 0 50px;
  }

  .job-post-date {
    max-width: 100%;
    width: 100%;
  }

  .job-keywords img {
    width: 34px;
    max-width: 100%;
    height: auto;
  }

  .job-location-f img {
    max-width: 100%;
    height: auto;
    width: 34px;
  }

  /*********company details ************/
  .j-compny-btn {
    display: block;
  }

  .job-company-details {
    max-width: 100%;
    padding-right: 0;
  }

  .j-company-logo {
    /* max-width: 40%;
    margin: 0 auto; */
    width: 80px;
    height: 80px;
  }

  .j-company-btn-apply {
    margin: 18px auto 0;
    max-width: 100%;
  }

  .back-to-home {
    top: 1px;
  }

  .company-name {
    font-size: 16px;
  }

  .job-name-title {
    font-size: 20px;
  }

  .open-postion {
    margin-top: 20px;
    padding-top: 0px;
  }

  section.company-job-sec {
    padding-bottom: 50px;
  }

  p.job-discription-com-j-recruite {
    width: auto;
  }

  .j-company-dev {
    max-width: 100%;
    /* margin: 30px 0; */
    text-align: center;
  }

  .icon-text-job-requre {
    justify-content: center;
  }

  /* button.apply-j-btn, */
  button.fav-j-btn {
    margin: 0 auto 25px auto;
  }

  .job-description-sec {
    padding: 20px 0px !important;
  }

  .job-description-sec .container {
    padding: 0px;
  }

  .job-heading-detail-sec .view-job-detail-space-1 {
    color: #000;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.4px;
    letter-spacing: 0.16px;
    padding: 20px;
  }
  .job-heading-detail-sec .view-job-detail-space-2,
  .job-heading-detail-sec ul li {
    color: #000;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.4px;
    letter-spacing: 0.16px;
  }

  p.j-c-salary,
  .job-find-list ul li {
    font-size: 16px;
  }

  .icon-text-job-requre {
    padding: 2px 0;
  }

  .company-job-announcements {
    max-width: 100%;
    margin-top: 0;
  }

  .social-media-share {
    width: 50%;
    margin: 15px auto 0;
  }

  .company-form-dropdownBox1,
  .company-form-dropdownBox1-table,
  .company-form-dropdownBox1-tabeldate {
    max-width: 100%;
  }

  /***************top match job page css for moobile**********/
  .left-right-content {
    display: flex;
    flex-direction: column;
  }

  .col-left-j-space {
    max-width: 100%;
    width: 90vw;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
    scroll-snap-type: x mandatory;
    /* margin-top: 20px; */
  }

  .col-left-j-space div {
    width: 80vw;
    scroll-snap-align: start;
    /* min-height: 200px; */
  }

  .col-left-j-space .short-content-left {
    min-height: 170px;
    padding: 20px 20px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: var(--color-white);
    width: 85%;
  }

  .col-left-j-space .job-discription-left {
    display: none;
  }

  .col-right-j-space {
    max-width: 100%;
    margin: 0;
  }

  .col-left-j-space button {
    margin: 0 auto;
  }

  .Job-Opportunities-row-j-search {
    justify-content: center;
    padding-left: 0;
  }

  .job-col-right .job-seclude {
    max-width: 80%;
  }

  /************pagenation on job page match****/
  .pagination-job {
    display: flex;
    gap: 20px;
    margin-right: 0px;
  }

  .pagination-job a {
    margin: 0 3px;
  }

  /********candidate-profile-mobile *********/
  .logo-col-profile-candidate,
  .logo-col-profile-left,
  .hide-wishlist-style {
    max-width: 50%;
    width: 100%;
  }

  .logo-col-profile-candidate img {
    width: 160px;
  }

  .logo-col-profile-left img {
    width: 160px;
  }

  .profile-right-col,
  .dropdown-dashbord {
    max-width: 100%;
    width: 100%;
  }

  .profile-right-col {
    gap: 16px;
  }

  .candidate-name-img {
    padding-left: 15px;
    flex-grow: 0;
  }

  p.candidate-name {
    padding: 0 20px 0 0px;
  }

  .candidate-name-img img {
    width: 25px;
  }

  p.candidate-name {
    font-size: 18px;
    display: none;
  }

  .hide-wishlist-style {
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
  }

  .profile-heading h2 {
    font-size: 24px;
  }

  section.profile-edit {
    padding: 50px 0px 20px 0px;
  }

  .my-profile-row {
    gap: 20px;
    flex-direction: column;
  }

  .my-profile-row-date-fill {
    gap: 20px;
    flex-direction: column;
  }

  .candidtae-profile-image {
    max-width: 100%;
    width: 100%;
    border-radius: 4px;
    height: auto;
  }

  .profile-row {
    gap: 10px;
  }

  .f-feild label {
    font-size: 14px;
  }

  .step-wizard {
    flex-direction: column;
  }

  .step-wizard .step-wizard-item {
    padding: 0 0 35px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .step-wizard .step-wizard-item .checkCircle {
    position: relative;
    top: 0;
  }
  .step-wizard .step-wizard-item:not(:last-child):before {
    right: unset;
    left: 9.5px;
    top: 18px;
    bottom: 0;
    height: calc(100% - 16px);
    width: 2px;
  }

  .fd-options p.btn {
    font-size: 14px;
  }

  .step-wizard .step-wizard-item .step-wizard-content {
    padding-left: 5px;
    margin-top: 0;
  }

  .candidate-information {
    max-width: calc(100% - 80px);
    padding-right: 25px
  }

  .candiate-info-heading a {
    gap: 7px;
  }

  .candiate-info-heading a img {
    width: 18px !important;
    height: 18px !important;
  }

  .candiate-info-heading p.candidate-info-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: normal;
  }

  .candidate-information.with-accordion {
    max-width: 100%;
    width: 100%;
  }

  .candidtae-profile-image-avatar {
    width: 80px;
    flex: 0 0 80px;
    height: 80px;
  }

  .candidate-profile-btn {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
  }

  .space-col-center {
    max-width: 100%;
    width: 100%;
  }

  .tabs-candidate-col-space {
    margin: 30px 0px;
  }

  .accordian-profile-res {
    height: 48px;
  }

  button.accordion-button.collapsed,
  .accordion-button:not(.collapsed) {
    font-size: 16px;
  }

  .candidate-profile-datefill {
    font-size: 20px;
  }

  .back-save-btns {
    gap: 15px;
    max-width: 90%;
    margin: 0 auto;
  }

  input#preferences-prfile {
    width: 100%;
  }

  .save-cancel-btn {
    justify-content: space-around;
  }

  button.cancel-btn-profile,
  button.save-btn-profile {
    padding: 9px 45px;
  }

  /****************profile detail fill bobile******/
  .intro-profile-vedio-chek {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    gap: 20px;
  }

  .upload-candidate-intro-vedio {
    max-width: 100%;
  }

  .check-box-vedio {
    max-width: 100%;
  }

  p.candidate-profile-data {
    line-height: 26px;
  }

  /***************candidate model css mobile *******/
  .candidate-name-btn {
    display: block;
    padding: 30px 0px;
  }

  .model-logo {
    text-align: center;
    position: relative;
    top: 2px;
    padding-bottom: 10px;
  }

  .resume-data-profile,
  .chat-model-comment-box {
    overflow-y: scroll;
    padding: 0 20px;
  }

  .resume-heading {
    max-width: 100%;
    padding: 10px 0px;
  }

  .resume-heading h1 {
    font-size: 30px;
    position: relative;
    text-align: center;
    justify-content: center;
  }

  .download-print-btn {
    max-width: 100%;
    position: relative;
    text-align: center;
    justify-content: center;
  }

  ul.candidate-social-list {
    justify-content: flex-start;
    padding-left: 0px;
  }

  ul.candidate-social-list li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p.candiadte-resume-heading {
    font-size: 30px;
  }

  .resume-profile-accadmic {
    padding: 30px 0;
  }

  .university-year {
    gap: 10px;
    font-size: 18px;
  }

  .candidate-name-field-profile {
    flex-direction: column;
  }

  input#candidate-name-field,
  input#candidate-lname-field {
    max-width: 100%;
  }

  /*********** iframe model mobile ******/
  .popup-bg-iframe {
    width: 90%;
    margin: 0 auto;
  }

  /**********profile form for mobile ******/
  .candidate-call {
    max-width: 24%;
  }

  select#candidate-location-feild,
  input#profile-email-candidate,
  .candidate-call,
  input#social-link {
    background-position: left 10px bottom 15px;
  }

  .first-name-profile {
    max-width: 100%;
    width: 100%;
  }

  /*******candidate job dashboard ***/
  .job-announcements-col-candidate button,
  .job-wishlist-btn button {
    width: 100%;
    display: block;
    margin: 10px auto;
  }

  .find-job-btn-candidate-dashbord {
    display: none;
  }

  .job-recommend-applied {
    padding: 35px 0 35px 0;
  }

  .job-inbox-journey {
    padding: 0px 0 35px 0
  }

  .job-recommend-applied,
  .job-inbox-journey 
   {
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    gap: 12px;
  }

  .job-time-wishlist.job-app {
    margin-top: 0;
  }

  .link-box {
    max-width: 100%;
  }

  .employee-job-dasboard {
    flex-direction: inherit;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  p.job-title-candidate-dashboard {
    font-size: 18px;
  }

  p.job-desc-candidate-dashboard {
    font-size: 16px;
  }


  .job-listing {
    padding-top: 30px;
  }

  .j-fillter-only-dashbord .j-company-fillter {
    width: 100% !important;
  }

  .job-discription {
    padding-bottom: 0;
  }

  .j-fillter-only-table {
    padding-right: 0;
  }

  a.anchor-cards-job {
    max-width: 100%;
    width: 100%;
  }

  a.card-link-box {
    padding: 0 1px 15px;
    max-width: 100%;
    width: 100%;
  }

  p.job-applied-candidate-dashboard {
    font-size: 44px;
    line-height: 1;
    margin-top: 10px;
  }

  /*****************employee dashbord job table **************/
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 0.5px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 0.5px solid #ddd;
    display: block;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }

  /***********model responsivee*******/
  .chat-comment-box,
  .chat-comment-box-right,
  .chat-comment-box-left-bg-chng,
  .chat-comment-box-right,
  .chat-comment-box-left-udser-reply,
  .chat-comment-box-right-udser-reply {
    width: auto;
    padding: 10px 10px;
    left: 0;
    right: 0;
  }

  .comment-chat-model-right,
  .comment-chat-model-user-reply,
  .comment-chat-model {
    position: relative;
    padding: 0 0px;
    margin-bottom: 25px;
  }

  p.name-comment-owner {
    font-size: 14px;
  }

  .comment-owner-details {
    justify-content: flex-start;
    text-align: start;
  }

  /***wishlist popup*******/
  .popup-bg-wishlist {
    width: auto;
    margin: 0 auto;
    padding: 0 15px;
  }

  /*******post job form new****/
  .post-job-row-1 {
    margin: 30px 0;
  }

  .employe-final-job-post {
    max-width: 100%;
    width: 100%;
    margin: 30px auto;
  }

  /***images box f ******/
  .left-form-data,
  .right-form-data {
    max-width: 100%;
    width: 190px;
    height: 210px;
    border-radius: 12px;
    border: 0.5px solid #375B96;
    background: var(--color-white);
  }

  p.l-box-one {
    font-size: 12px;
    line-height: 20px;
  }

  p.l-boc-two {
    font-size: 14px;
    padding: 0px 0 0px;
    line-height: 20px;
  }

  p.l-boc-two span {
    font-size: 14px;
  }

  p.l-boc-two {
    height: auto;
  }

  button.copy-paste-l {
    display: none;
  }

  .lower-part-data {
    padding: 30px 10px 0px;
  }

  .uper-part-clr img,
  .uper-part-clr-right img {
    text-align: center;
    display: block;
    margin: 0px auto -20px;
    padding-top: 10px;
    width: 48px;
  }

  .uper-part-clr,
  .lower-part-data,
  .uper-part-clr-right {
    border-radius: 12px 10px 0px 0px;
    box-shadow: none;
    border: 0;
  }

  .lower-part-data {
    border-radius: 0;
  }

  /*********React Quill post a job form mobile **********/
  .ql-editor p,
  .ql-editor ul li {
    padding-left: 0 !important;
  }

  .ql-editor ol,
  .ql-editor ul {
    padding-left: 0em;
  }

  .cities-center-in-form {
    margin: 20px auto 0;
    max-width: 100%;
  }

  .ql-editor p,
  .ql-editor ul li,
  .ql-editor.ql-blank:before {
    text-align: left;
  }

  input#salary-start-range {
    padding: 10px 8px;
  }

  .salary-f-job {
    max-width: 40% !important;
  }

  #fsalary-country,
  input#salary-end {
    padding: 12px 5px;
    background-position: right 0px bottom 14px;
  }

  div#fsalary-year {
    padding: 15px 8px;
  }

  .merge-feild-3 {
    max-width: 25%;
  }

  .merge-feild-4 {
    max-width: 22%;
  }

  div#fsalary-year {
    background-position: right 2px bottom 14px;
  }

  .list-search-dropdown-year-job {
    right: 0px;
    width: 100%;
    max-width: 100%;
  }

  ul.list-job-title-year-job {
    padding: 15px 5px;
  }

  button.job-des-btn,
  button.job-reset-btn {
    width: 100%;
  }

  .popup-content-zoe-chek {
    object-fit: cover;
    height: auto;
    width: 100%;
    margin: 50px auto 0;
    background-image: url(/public/image/zoe-mbl-bg.png);
    padding: 20px 20px 115px 20px;
    position: relative;
    border-radius: 30px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .col-job-zoecard {
    padding: 10px;
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
  }

  p.zeo-chek-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }

  .zeo-btn {
    max-width: 100%;
    width: 100%;
    gap: 20px;
  }

  .popup-content-zoe-job-gen {
    width: 100%;
    margin: 50px auto 0;
    background-image: url(/public/image/zoe-mbl-bg.png);
    padding: 20px 40px 100px 40px;
    position: relative;
    border-radius: 30px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .popup-post-job {
    width: auto;
    margin: 0 auto;
  }

  ul.list-job-title-country {
    padding: 7px 10px;
  }

  .list-search-dropdown-country-job {
    width: 37%;
  }

  .list-search-dropdown-country-job-post {
    width: 38%;
  }

  /*******journey page**/
  .left-right-content-jour {
    flex-direction: column;
  }

  .left-col-vertical {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .right-content-col-jour {
    max-width: 100%;
    width: 100%;
  }

  .left-right-content-jour {
    padding: 30px 0px;
  }

  .experience-col,
  .skills-col,
  .education-col,
  .summary-col,
  .overall-col {
    max-width: 100%;
    width: 100%;
    padding: 12px 18px;
    margin-bottom: 0;
  }

  p.heading-main-jour {
    font-size: 16px;
    text-align: left;
  }

  p.content-main-jour {
    font-size: 14px;
    text-align: left;
  }

  .tabs-content-jour {
    padding: 20px 0;
  }

  p.right-col-percentage {
    width: 140px;
    height: 140px;
    font-size: 36px;
    padding: 28px 25px;
  }

  p.right-col-jour-sub-content {
    font-size: 16px;
    font-weight: 400;
  }

  p.right-col-sub-heading,
  p.right-col-heading {
    font-size: 18px;
  }

  p.progress-percent-jour {
    display: none;
  }

  .jour-left-right {
    background: var(--color-white);
    border-radius: 12px;
    box-shadow: 0px 1px 12px 4px rgba(0, 0, 0, 0.06);
    padding: 16px 12px;
    word-break: break-word;
  }

  .link-profile-btn-jour {
    padding-top: 15px;
    width: 100%;
  }

  .inner-right-jour {
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
  }

  .left-col-jour {
    max-width: 20%;
    width: 100%;
    display: none;
  }

  .right-col-jour {
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
  }

  .inner-left-jour {
    max-width: 100%;
    width: 100%;
  }

  .name-cand-jour-porgres {
    display: block;
    background: white;
    border-radius: 16px;
    box-shadow: 0px 1px 12px 4px #0000000F;
    padding: 16px 12px;
  }

  .info-below-mbl {
    display: block;
  }

  .jour-left-right {
    background: transparent;
    border-radius: 12px;
    box-shadow: none;
    padding: 0;
    word-break: break-word;
  }

  p.progress-percent-jour {
    display: block;
    position: absolute;
    top: 14px;
  }

  .cus-progress-percent-jour {
    display: block;
    position: absolute;
    top: 14px;
  }

  .info-below-jour {
    margin-top: 20px;
  }

  .progress-icon-box {
    justify-content: end;
  }

  ul.info-list-jour-mbl {
    line-height: normal;
    margin-bottom: 0;
    padding: 12px 0;
  }

  ul.info-list-jour-mbl li a,
  ul.info-list-jour-mbl li {
    color: rgb(0 0 0 / 50%);
    font-size: 16px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    display: flex;
    line-height: 25.8px;
    text-decoration: none;
    word-break: break-word;
  }

  ul.info-list-jour {
    /* max-height: 95px; */
    background: var(--color-white);
    box-shadow: 0px 1px 12px 4px #0000000F;
    padding: 15px 15px;
    border-radius: 12px;
    transition: 0.25s all ease-in;
    overflow: hidden;
  }

  ul.info-list-jour.showDetail {
    max-height: 999px;
  }

  ul.info-list-jour li,
  ul.info-list-jour li a {
    color: rgb(0 0 0 / 100%);
    font-size: 16px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    display: flex;
    line-height: 25.8px;
    text-decoration: none;
    margin-bottom: 12px;
    word-break: break-word;
  }

  .candidate-detail-btn p {
    display: inline-block;
    font-weight: 700;
    text-decoration: underline;
    margin: 6px 0 0;
  }

  .tags-wrok {
    display: flex;
    justify-content: space-between;
    background: var(--color-white);
    box-shadow: 0px 1px 12px 4px #0000000F;
    padding: 15px 15px;
    border-radius: 12px;
    margin-bottom: 12px;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  p.work-jour-heading {
    margin-bottom: 0px !important;
    white-space: nowrap;
    margin-right: 30px;
  }

  .tags-jour {
    padding-bottom: 0px;
  }

  li.hide-li-mbl {
    display: none !important;
  }

  .icon-row-profile-jour,
  button.link-profile-jour,
  button.link-mail-jour {
    display: none;
  }

  p.name-can-jou {
    font-size: 18px;
  }

  .text-list-tile-mbl {
    display: block;
    margin-top: 17px;
    padding-left: 6px;
  }

  .jour-info-list ul,
  .jour-info-list p {
    font-size: 14px;
  }

  .progressbar-journey li:before {
    width: 28px;
    height: 28px;
    font-size: 14px;
    line-height: 18px;
  }

  .progressbar-journey li {
    font-size: 12px;
  }

  ul.progressbar-journey {
    display: flex;
  }

  .progressbar-journey li:after {
    width: 60%;
    top: 13px;
    left: -29%;
  }

  .left-col-jour-secd {
    display: none;
  }

  .summary-col-jour {
    margin-left: 1px;
  }

  .tab-size-jour {
    font-size: 14px !important;
  }

  .tab-size-jour {
    font-size: 16px !important;
  }

  a.active.tab-size-jour {
    font-size: 16px !important;
  }

  ul.nav.nav-tabs.mb-3.tabs-summary {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
    padding-bottom: 20px;
  }

  a.nav-link.tab-size-jour {
    white-space: nowrap !important;
  }

  .job-title-joourny {
    font-size: 20px;
  }

  .price-card .price-card-header {
    height: auto;
  }

  table.skill-body-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: var(--font-weight-bold);
    text-transform: initial;
    margin-right: 20px;
    min-width: 125px;
  }

  table.skill-body-table tr {
    border-bottom: 1px solid #2B2B2B29 !important;
    border-top: 0px solid #2B2B2B29 !important;
    padding: 0;
  }

  table.skill-body-table tr td:last-of-type {
    border-bottom: 0 !important;
  }

  td.td-third-row,
  td.td-first-row {
    display: flex;
  }

  table.skill-body-table tr td {
    border: 0;
    border-bottom: 0px solid #2B2B2B29 !important;
  }

  td.td-seacond-row {
    padding-top: 15px;
    padding-left: 0px;
    text-align: center;
  }

  .progress.prgress-width {
    width: 110px;
    margin: 0 auto 0 0;
  }

  .feedback-section-lower {
    padding-top: 30px;
  }

  .arrow-back-t {
    font-size: 18px;
  }

  .tooltip-table-jour {
    left: -75px;
  }

  .attention-box-resume {
    left: -92px;
  }

  p.content-heading-jour,
  .text-resume-buttn-j {
    display: none;
  }

  .overall-col-c,
  .orange-col,
  .orange-col-skill,
  .orange-col-edu,
  .orange-col-summary,
  .can-eval-circular-bar {
    height: 50px;
    width: 50px;
    font-size: 16px;
    line-height: 40px;
  }

  .col-all-link-tb {
    width: 100%;
  }

  .table-user-permission {
    padding-top: 30px;
  }

  /********resume verification*/
  .resume-verivication-form {
    flex-direction: column;
    overflow: hidden;
    max-height: none;
    height: auto;
  }

  .resume-start-verivication {
    display: none;
  }

  .resume-verivication-res {
    margin-top: 30px;
    border-radius: 12px;
    background: var(--color-white);
    padding: 0;
    border: 0;
  }

  .back-arrrow-job-title-resume {
    justify-content: end;
    flex-direction: column;
    align-items: end;
  }

  .arrow-back-t-res {
    width: 14px;
    text-align: start;
  }

  .resum-verivication-job {
    font-size: 22px;
  }

  .arrow-text-res-form {
    display: flex;
    align-items: center;
    flex-grow: 0;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
  }

  .main-heading-form-right {
    font-size: 20px;
  }

  .resume-form-info {
    max-width: 100%;
    padding: 0px 0px 20px 0px;
  }

  .resume-personal-form {
    padding-top: 5px;
  }

  .personal-info-right,
  .experience-info-right {
    font-size: 20px;
  }

  .first-name-res,
  .last-name-res {
    max-width: 100%;
    padding-top: 15px;
  }

  .resume-personal-form-chek {
    gap: 3px;
  }

  .progressbar-steps-res li {
    width: 45.33%;
  }

  button.can-res-btn-last,
  button.save-res-btn-last {
    min-width: 156px;
    padding: 17px 10.5px 16px;
  }

  .job-title-resume-form {
    /* flex-direction: column;
        align-items: flex-start; */
    align-items: end;
  }

  .compay-name-job-resume-right {
    max-width: 55%;
    width: 100%;
  }

  .date-job-res {
    max-width: 46%;
    justify-content: end;
    width: 100%;
    align-items: end;
    margin-right: 0;
  }

  .date-job-res p {
    /* margin-top: -25px; */
  }

  .experince-resume-right .date-job-res {
    flex-wrap: wrap;
  }

  .experince-resume-right .date-job-res p {
    order: 1;
    width: 100%;
    text-align: right;
  }

  img.del-res {
    top: 11px;
  }

  /* img.edit-res {
    position: absolute;
    top: 12px;
    right: 50px;
  } */

  button.bg-clr-q {
    font-size: 18px !important;
    padding: 16px !important;
  }

  p.right-resume-cmp {
    padding-bottom: 18px;
  }

  button.data-tab-res-right {
    padding: 10px 10px !important;
  }

  p.job-pro-url-res-right {
    word-wrap: break-word;
  }

  p.right-resume-certificate {
    padding-bottom: 18px;
  }

  /*****resume hide***********/
  /* .resume-start-jour,
  .resume-orignal-template {
    display: none;
  } */

  /* .mbl-resume-all {
    display: block;
  } */

  .all-content-resume-jour {
    padding: 10px 0px;
  }

  .resume-can-info {
    flex-wrap: wrap;
    gap: 0;
    column-gap: 30px;
  }

  .name-res-jou {
    font-size: 28px;
  }

  .box-one-res a,
  .box-two-res a {
    font-size: 14px;
  }

  /***********job resume candiate***/
  .candidate-job-resume-sec,
  .candidate-job-resume {
    margin: 0 auto;
    width: auto;
  }

  .resume-top-cantainer {
    margin-top: 20px;
  }

  .resume-sec-upload {
    margin: 30px 0;
    padding: 20px 0;
    background: var(--pacific-blue-color-300);
  }

  .main-heading-l {
    font-size: 24px;
    line-height: 32px;
    padding-right: 30px;
  }

  .img-stok-job {
    display: none;
  }

  p.job-ai-text {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 0;
  }

  .job-form-info {
    padding: 20px 0;
  }

  .job-res-info-right {
    font-size: 20px;
    text-align: left;
  }

  .text-mainheadingupload {
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 1.24px;
  }

  .resume-sec-upload {
    margin: 30px 0 16px;
    padding: 20px 0;
    background: var(--pacific-blue-color-300);
  }

  p.text-sumtext-upload {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 0 10px;
  }

  .cv-toggler {
    position: static;
    transform: translateY(0);
    margin-top: 10px;
  }

  button.active-toggle-btn-ver {
    min-width: 67px;
    padding: 10px 19px;
    font-size: 14px;
  }

  button.template-btn {
    font-size: 14px;
    padding: 10px 9px;
    min-width: 67px;
  }

  label#candidate-res img {
    width: 40px;
    height: 35px;
  }

  p.candidate-file-res-drop {
    padding: 0px 0 10px 0;
  }

  .candidate-res-file {
    height: 120px;
  }

  /*****candidate-resume-decession*****/
  .candidate-card-heading-main {
    font-size: 22px;
  }

  .card-left-res,
  .card-right-res {
    width: 189px;
    padding: 16px 12px;
  }

  .res-main-heading-crd {
    font-size: 16px;
    line-height: 22px;
  }

  .res-custmize-btn {
    padding-bottom: 0px;
  }

  a.res-crd-btn {
    padding: 9.5px 10px;
    font-size: 13px;
    min-width: 150px;
    text-align: center;
    margin-bottom: 0 !important;
  }

  .two-card-res {
    gap: 10px;
  }

  p.crd-res-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  /*******jb file upload***********/
  .all-text-jbmdl {
    padding: 20px;
    background: var(--color-white);
    border-radius: 0px 0px 16px 16px;
    overflow: auto;
    max-height: 500px;
    overflow-y: auto;
  }

  .all-text-jbmdl::-webkit-scrollbar {
    width: 8px;
  }

  .all-text-jbmdl::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  .all-text-jbmdl::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(--primary-color);
  }

  .col-right-jbmdl {
    max-width: 100%;
    width: 100%;
    margin: 0 0 15px 0;
  }

  .two-btn-jbmdl {
    justify-content: space-between;
    gap: 12px;
  }

  p.org-text-right,
  p.org-text-right-r {
    font-size: 14px;
  }

  .img-text-jbmdl,
  .img-text-jbmdl-right {
    gap: 10px;
    margin-bottom: 0px;
    padding: 10px 15px;
    min-height: auto;
  }

  .img-text-jbmdl-right.height-box-r {
    min-height: 80px;
    margin-bottom: 0px;
  }

  button.replace-res {
    min-width: 150px;
    margin-right: 0;
    padding: 10px 10px;
    font-size: 14px;
  }

  button.regenerate-res {
    min-width: 150px;
    margin-right: 0;
    padding: 10px 10px;
    font-size: 14px;
  }

  .res-jbmdl-heading {
    padding: 0;
  }

  button.can-res-btn-res,
  button.save-res-btn-res {
    font-size: 14px;
    padding: 16px 10px;
  }

  button.res-img-btn {
    padding: 8px 7px;
  }

  .res-img-btn img {
    width: 28px;
    height: 28px;
  }

  textarea.user-inp-jbmdl {
    height: 3em;
  }

  .textedditor-jbmdl {
    margin-top: 8px;
  }

  .textedditor-jbmdl-exp {
    margin-top: 8px;
  }

  .img-text-jbmdl-right img,
  .img-text-jbmdl img {
    height: 36px;
    width: 36px;
    object-fit: contain;
  }

  .col-left-jbmdl {
    margin: 0 0 0px 0;
  }

  .two-btn-jbmdl {
    padding: 15px 0;
  }

  .toottip-job-mdl {
    display: block;
  }

  .tooltip-outer-box {
    position: relative;
    opacity: 0;
  }

  p.tootl-tip-text-jbmdl {
    width: 313px;
    border: 1px solid #FFA033;
    box-shadow: 0px 2px 7.300000190734863px 0px #00000040;
    padding: 5px 6px;
    color: #6D6E71;
    font-size: 12px;
    font-weight: 400;
    font-family: var(--arial-main);
    border-radius: 8px;
    position: absolute;
    right: 0px;
    top: 6px;
    background: var(--color-white);
    z-index: 1;
  }

  .tooltip-outer-box::before {
    content: '';
    display: block;
    width: 16px;
    height: 1px;
    position: absolute;
    top: 1px;
    background: #FFA033;
    transform: rotate(-54deg);
    right: 30px;
    z-index: 1;
    bottom: 0;
  }

  .tooltip-outer-box::after {
    content: '';
    display: block;
    width: 13px;
    height: 1px;
    position: absolute;
    top: 1px;
    background: #FFA033;
    transform: rotate(54deg);
    right: 23px;
    z-index: 1;
    bottom: 0;
  }

  p.tootl-tip-text-jbmdl::after {
    content: '';
    display: block;
    width: 15px;
    height: 2px;
    background: var(--color-white);
    position: absolute;
    top: -1px;
    right: 25px;
    z-index: 99;
  }

  .toottip-job-mdl:hover .tooltip-outer-box {
    opacity: 1;
  }

  /****resume uploadmodel************/
  .upload-resume-model-popup-bg,
  .choose-job-model-popup-bg,
  .popup-bg-employee {
    width: auto;
  }

  .popup-resume-content {
    padding: 20px;
  }

  /**choose resume *******/
  .choose-resume-model-popup-bg {
    width: auto;
  }

  p.name-file-choose {
    font-size: 14px;
  }

  p.date-res-choose {
    font-size: 12px;
  }

  .popup-resume-content-choose {
    padding: 20px 10px;
  }

  .res-name-choose {
    padding-right: 10px;
  }

  img.dowld-res {
    padding-right: 8px;
    margin-right: 8px;
  }

  .two-icon-choose-res {
    display: flex;
    max-width: 15%;
    width: 100%;
    align-items: start;
    justify-content: end;
  }

  /*****term and condition ploicy pages********/
  section.term-condition {
    padding: 20px 0 20px 0;
  }

  .main-heading-term {
    font-size: 24px;
    padding-bottom: 16px;
  }

  .first-para-term,
  .first-sub-para-term {
    padding: 24px 0;
  }

  .sub-heading-term {
    font-size: 20px;
    padding: 12px 0 12px 12px;
  }

  /**********post card-sec******/

  .job-title-joourny-post {
    font-size: 20px;
  }

  .right-col-Aimage {
    display: none;
  }

  .left-job-content-postcard {
    padding-right: 0px;
  }

  .post-card-sec-bg {
    padding: 50px 0;
  }

  p.ai-content-post {
    font-size: 16px;
  }

  p.create-post-heading-post {
    font-size: 22px;
    line-height: 28px;
    padding: 10px 0;
  }

  p.post-card-content-ai {
    font-size: 16px;
    line-height: 22px;
  }

  .two-ai-post-btn {
    padding-top: 60px;
  }

  button.copy-paste-btn,
  button.generate-job-btn {
    width: 100%;
    padding: 14px 10px;
  }

  /******/
  .arrow-back-t {
    font-size: 18px;
  }

  .tooltip-table-jour {
    left: -75px;
  }

  .attention-box-resume {
    left: -92px;
  }

  p.content-heading-jour,
  .text-resume-buttn-j {
    display: none;
  }

  .overall-col-c,
  .orange-col,
  .orange-col-skill,
  .orange-col-edu,
  .orange-col-summary {
    height: 50px;
    width: 50px;
    font-size: 16px;
    line-height: 40px;
  }

  .col-all-link-tb {
    width: 100%;
  }

  .table-user-permission {
    padding-top: 30px;
  }

  /****index*******/
  .invite-pople-list {
    display: none;
  }

  /****invite pepole model css mbl*******/
  .popup-bg-invite-pople {
    width: auto;
  }

  .feild-f-admin {
    max-width: 100%;
  }

  .inivte-pepole-list-sec {
    padding: 0 10px;
  }

  .list-of-pepole {
    justify-content: unset;
    gap: 5px 15px;
  }

  img.invite-res {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .yt-banner .yt-content {
    margin: 110px 0;
  }
  .yt-content .demo-page-home-title {
    font-size: 18px;
    line-height: normal;
  }
  .yt-banner .yt-content .vc-img {
    max-width: 190px;
  }

  .yt-banner .yt-content .vc-img.img-bottom {
    right: -3vw;
  }
}

.tags-tittles ul.tags-titles-list {
  display: flex;
  gap: 20px;
  list-style: none;
  margin: 15px 0 30px;
  padding: 0;
  flex-wrap: wrap;
}

.tags-tittles ul.tags-titles-list li span img {
  vertical-align: baseline;
}

.tags-tittles ul.tags-titles-list li {
  display: inline-flex;
  justify-content: space-between;
  padding: 4px 8px;
  gap: 14px;
  align-items: flex-start;
}

@media only screen and (max-width: 400px) {
  .companyCard-cursor {
    margin-right: 13px;
  }

  /*Post a job*/
  .left-form-data,
  .right-form-data {
    max-width: 100%;
    width: 190px;
    height: 242px;
    border-radius: 12px;
    border: 0.5px solid #375B96;
    background: var(--color-white);
  }

  .list-search-dropdown-country-job-post {
    width: 36%;
  }
}

@media only screen and (max-width: 359px) {
  .companyCard-cursor {
    width: 250px;
    margin-right: 13px;
  }
}