.pricing-tabs {
  padding: 130px 0 60px;
}

.pricing-tabs .nav-tabs {
  justify-content: center;
  border: 1px solid var(--dark-black-color-200);
  width: max-content;
  margin: 0 auto;
  background: var(--white-smoke-light-color);
  border-radius: 8px;
  padding: 4px;
}


.pricing-tabs .nav-tabs .nav-item {
  min-width: 160px;
  text-align: center;
  color: var(--dark-black-color);
  border-radius: 8px;
  font-weight: 400;
}

.pricing-tabs .nav-tabs .nav-item .nav-link {
  padding: 12px 13px;
  color: inherit;
  font-size: 16px;
  font-weight: inherit;
}

.pricing-tabs .nav-tabs .nav-item.active {
  background: var(--pacific-blue-color);
  color: var(--color-white);
}

.price-card-sec {
  display: flex;
  flex-wrap: nowrap;
  /* gap: 20px; */
  justify-content: center;
  margin-top: 34px;
}

.price-card {
  /* box-shadow: 0px 4px 12px 0px rgba(43, 43, 43, 0.15); */
  border: 1px solid rgba(43, 43, 43, 0.24);
  box-shadow: 0px 4px 12px 0px rgba(43, 43, 43, 0.15);
  /* border-radius: 28px; */
  padding: 20px;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 265px;
}

.price-card .price-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 22px;
}

.price-card .price-card-header p {
  background: #ECECEC;
  color: var(--dark-black-color);
  font-size: 12px;
  border-radius: 25px;
  padding: 2px 10px;
}

.price-card .price-card-header h4 {
  font-size: 18px;
  margin: 0;
  padding: 0;
  position: relative;
}

.price-card .price-card-header h4:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 100%;
  background: #E15C52;
}

.price-card .price-card-body {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.price-card .price-card-body h3 {
  font-size: 18px;
  font-weight: 800;
}

.price-card .price-card-body .card-para {
  height: 68px;
  overflow: hidden;
}

.price-card .price-card-body .card-para p {
  display: inline;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0.5;
}

.price-card .price-card-body h2 {
  font-size: 28px;
  font-weight: 800;
  margin: 0;
}

.price-card .price-card-body h2 small {
  opacity: 50%;
  font-size: 12px;
  width: 35px;
  display: inline-block;
  font-weight: 400;
}

.price-card .price-card-body .btn-primary {
  border-radius: 25px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  background: var(--pacific-blue-color);
  border-color: var(--pacific-blue-color);
}

.price-card .price-card-body p {
  font-size: inherit;
}

.price-card .price-card-body ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.price-card .price-card-body ul li {
  font-size: inherit;
  position: relative;
  padding-left: 24px;
}

.price-card .price-card-body ul li:after {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  background-size: contain;
  background: url('../../../public/image/check-circle.png') no-repeat;
}

.mk-table .price-card {
  padding: 30px 23px 60px;
  position: relative;
}

.mk-table .price-card .price-card-header {
  position: absolute;
  background: rgba(0, 159, 181, 1);
  left: 0;
  right: 0;
  bottom: 100%;
  border-radius: 0;
  padding: 14px 12px;
  height: auto;
  justify-content: center;
  border: 1px solid rgba(43, 43, 43, 0.24)
}

.mk-table .price-card .price-card-header p {
  font-size: 20px;
  background: transparent;
  line-height: 1;
  color: #fff;
}

.mk-table .price-card .price-card-header.bg-white p {
  color: rgba(43, 43, 43, 1);
}

.mk-table .price-card .price-card-header .premiumRating {
  position: absolute;
  top: -38px;
  left: 0px;
  right: 0px;
  max-width: 62%;
  margin: auto;
}

.mk-table .price-card .price-card-body .card-para {
  height: 52px;
  margin-top: 10px;
}

.mk-table .price-card .price-card-body .card-para p {
  opacity: 1;
}

.mk-table .price-card .price-card-body h2 {
  color: #009FB5;
  font-size: 20px;
}

.mk-table .price-card .price-card-body h3 {
  font-size: 24px;
}

.mk-table .price-card .price-card-body .btn-primary {
  border-radius: 25px;
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
}

.boost-job {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding: 20px 0 80px;
}

.boost-job .boost-job-desc p {
  font-size: 20px;
}

.job-heading {
  position: relative;
}


.need-help{
  position: absolute;
  padding: 14px 12px;
  background: rgba(248, 204, 77, 1);
  max-width: 127px;
  top: 100px;
  right: -27px;
  transform: rotate(-90deg);
  cursor: pointer;
}

.need-help > p{
font-family: Arial;
font-size: 16px;
font-weight: 700;
line-height: 18.4px;
text-align: center;
}

.top-cls{
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.top-cls img {
  max-width: 160px;
}


.container .des h1{
  font-size: 20px;
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}

.container .des {
  max-width: 200px;
  margin-top: 20px;
}

.container .des p {
  font-family: Arial;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}

.left-box{
 padding: 70px 15px;
}

.ques-p{
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
  margin-top: 40px;
}

.email-p{
  margin-top: 14px;
  font-family: Arial;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}

.text-none {
  text-transform: none !important;
}

@media(max-width: 767px) {

  .job-heading {
    position: relative;
  }
  
  .job-heading h1 {
    max-width: 100%;
    font-family: Source Serif Pro;
      font-size: 22px;
      font-weight: 600;
      line-height: 27.57px;
      margin: 0 auto 20px;
}

.job-heading h1+p{

    max-width: 100%;
    margin: 0 auto;
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.4px;

}
  

  .mk-table .price-card-sec {
    gap: 12px;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .mk-table .price-card.card-demo.with-header {
    padding-top: 65px;
  }

  .mk-table .price-card.with-header .price-card-header {
    bottom: unset;
    top: 0;
  }

  .mk-table .price-card .price-card-header .premiumRating {
    display: none;
  }

  .mk-table .price-card .price-card-body h3 {
    font-size: 20px;
  }

  .mk-table .price-card .price-card-body .btn-primary {
    max-width: 70%;
  }

  .mk-table .price-card.card-demo {
    max-width: 100% !important;
  }

  .boost-job {
    gap: 16px;
  }

  .boost-job .boost-job-desc p {
    font-size: 14px;
  }

  .need-help {
    padding: 10px 10px;
    max-width: 110px;
    top: 60px;
  }

  .mk-table .price-card.card-demo {
      max-width: 100%;
  }

  .container .des, .email-p {
      max-width: 100%;
      font-size: 16px;
  }

  .top-cls {
      justify-content: center;
  }

  .pricing-tabs.mk-table {
      padding: 50px 15px;
  }

  .left-box {
      padding: 0 15px;
  }
  .job-heading .need-help > p {
    font-size: 12px !important;
  }

  .container .des p {
    font-size: 16px;
  }
}

.mk-table .price-card.card-demo .price-card-body .card-para {
  height: 64px;
}

.mk-table .price-card.card-demo .price-card-body .pric-tit {
  height: 21px;
}

.mk-table .price-card.card-demo .price-card-body h2 {
  color: #2B2B2B;
  font-size: 22px;
}

.mk-table .price-card.card-demo .price-card-body h2 small {
  display: block;
  width: 100%;
  font-size: 16px;
  padding-left: 8px;
}

.mk-table .price-card.card-demo .price-card-body h2 span {
  font-size: 12px;
  font-weight: 400;
  vertical-align: top;
  margin-right: 2px;
  opacity: 0.5;
}

.mk-table .price-card.card-demo .price-card-body h3 {
  font-size: 20px;
  text-transform: capitalize;

}

.mk-table .price-card.card-demo {
  padding: 30px 25px;
  max-width: 240px;
}

.mk-table .price-card.card-demo .price-card-body .btn-primary {
  font-weight: 400;
  text-transform: capitalize;
  padding: 8.25px 10px;
  max-width: 100%;
}

.mk-table .price-card.card-demo .price-card-body ul li {
  padding-left: 10px;
  font-size: 14px;
}

.mk-table .price-card.card-demo .price-card-body ul li:after {
  /* background: url('../../../public/image/check-demo.png') no-repeat;*/
  background: transparent;
  content: '•';
  font-size: 16px;
  width: auto;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
}

.mk-table .price-card.card-demo .price-card-body {
  gap: 20px;
}

.btn-primary-alternate, 
.btn-primary-alternate:hover,
.btn-primary-alternate:focus {
  background: rgba(90, 172, 153, 1);
  border-color: rgba(90, 172, 153, 1);
  color: #fff;
  box-shadow: none;
  border-radius: 25px;
  padding: 8.25px 10px;
  font-weight: 400;
}