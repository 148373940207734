/****** footer section ***/

.footer-sec {
  background-color: var(--dark-black-color);
  padding: 100px 0px 30px 0px;
}

.footer-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 65px;
}

.footer-address {
  width: 100%;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-address img {
  width: 170px;
}

.footer-services {
  width: 100%;
  max-width: 25%;
  display: flex;
}

.footer-countries {
  width: 100%;
  max-width: 25%;
  display: flex;
}

.footer-privacy {
  width: 100%;
  max-width: 25%;
  display: flex;
}

p.head-office {
  padding: 25px 0;
  font-size: 20px;
  color: var(--color-white);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

p.office-details {
  width: 100%;
  font-size: 16px;
  color: var(--color-white);
  font-family: var(--arial-main);
  font-weight: 400;
  padding: 0px 0px 20px 0px;
}

p.office-details a {
  color: var(--color-white);
}

p.Countries-heading {
  color: var(--color-white) !important;
  padding-bottom: 7px !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

.footer-list-bold ul li {
  list-style: none;
  color: var(--color-white);
  font-size: 20px;
  font-family: var(--arial-main);
  font-weight: 700;
  padding-bottom: 7px;
}

.footer-list-bold ul li a {
  text-decoration: none;
  color: var(--color-white);
}

.footer-list ul li {
  list-style: none;
  color: var(--color-white);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
  padding: 7px 0;
}

.footer-list ul li a {
  text-decoration: none;
  color: var(--color-white);
}

.footer-divider {
  padding: 15px;
  border-top: 1px solid var(--boder-color)
}

.footer-rights {
  display: flex;
  justify-content: space-between;
}

.footer-site-development {
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
  font-size: var(--button-text);
}

ul.site-list {
  display: flex;
  gap: 15px;
  list-style: none;
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
  font-size: var(--button-text);
}

ul.site-list a {
  text-decoration: none;
  color: var(--color-white);
}

.footer-mbl{
  display: none;
}

@media only screen and (max-width:767px) {
  .footer-mbl{
    display: block;
  }
  .footer-address {
    width: 100%;
    max-width: 30%;
  }
  .footer-desktop{
    display: none;
  }
  .dropdown-arrow{
    margin-left: 2px;
    width: 16.5px;
    height: 8.5px;
    margin-top: -2px;
  }

  p.active.Countries-heading .dropdown-arrow {
    transform: rotate(180deg);
  }
  
  li.service-heading,
li.Countries-heading {
  color: var(--accent-color) !important;
  padding-bottom: 15px !important;
  margin-top: 20px;
}
li.service-heading{
  margin-top: 50px;
}


}