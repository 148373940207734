/***************job model popoup **********/

.model-comment-popup {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 55%);
  top: 0%;
  overflow-y: auto;
  z-index: 2;
}

.popup-bg-job {
  width: 50%;
  max-width: 536px;
  margin: 0 auto;
}

.cross-btn {
  width: 24px;
  height: 24px;
}

.popup-job-sec {
  width: 100%;
  /* height: 70vh; */
  margin: 100px auto 0;
  position: relative;
  background: var(--color-white);
  border: 0px solid #bdbdbd;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.model-left-right-job {
  display: flex;
}

.col-right-model {
  max-width: 100%;
  width: 100%;
  padding: 56px 0px;
}

.model-logo-job-sec {
  text-align: center;
  margin-top: -35px;
}

.divider-mg {
  margin-top: 20px;
}

.logo-img {
  width: 180px;
  height: 30px;
}

.content-f {
  padding: 20px 40px;
}

.content-f h5 {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  color: var(--pacific-blue-color);
  font-weight: var(--font-weight-bold);
}

.content-f-black {
  color: black;
  font-weight: 300;
}

p.login-f-model {
  color: var(--dark-black-color);
  text-align: justify;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  padding: 0px 20px;
  line-height: 22px;
}

p.login-f-model a {
  color: var(--dark-black-color);
}

.model-signin {
  background: var(--color-white);
  backdrop-filter: blur(13px);
  border-radius: 8px;
  max-width: 70%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

input#email-j-model {
  max-width: 100%;
  width: 100%;
  display: block;
  padding: 0 10px 0 10px !important;
  border: 0;
}

input#password-field-job {
  max-width: 90%;
  width: 100%;
  display: block;
  padding: 0 10px 0 10px !important;
  border: 0;
}

.error-input>.mail-log-waring-icon {
  background: url(/public/image/warning.png);
  background-repeat: no-repeat;
  background-position: 98% center;
  background-size: 20px;
  padding-right: 40px !important;
}

.error-input>.mail-log-waring-icon {
  border: 1px solid var(--error-msg-color) !important;
}

.error-input>.password-icon-job {
  border: 1px solid var(--error-msg-color) !important;
}

.password-icon-job {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 8px;
  border-radius: 8px;
  border: 1px solid var(--dark-black-color-600);
  background: var(--color-white);
}

.mail-log-waring-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 8px;
  border-radius: 8px;
  border: 1px solid var(--dark-black-color-600);
  background: var(--color-white);
}

button.form-submit-job-model {
  width: 100%;
  background: transparent;
  border: 0.5px solid var(--accent-color);
  border-radius: 12px;
  padding: 18px 0;
  color: var(--accent-color);
  font-family: var(--arial-main);
  font-size: var(--body-text);
  margin-top: 15px;
  line-height: 24px;
}

.or-divider-job {
  display: flex;
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.divider-one-job {
  max-width: 40%;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.remember-login-model {
  color: var(--dark-black-color);
}

.signup-here-job {
  padding: 15px 0px;
}

.signup-here-job p {
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.signup-here-job a {
  text-decoration: none;
}

.signup-here-job p span {
  color: var(--pacific-blue-color);
}

.text-or-job {
  text-align: center;
  color: var(--text-color);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.model-login-google {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 15px 0;
  margin-top: 10px;
}

.model-login-google a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-decoration: none;
}

.model-login-google a p {
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  opacity: 0.3;
}

.model-login-google img {
  max-width: 100%;
  height: 25px;
  width: 25px;
}

.createAccount-head {
  margin-top: 7px;
  margin-bottom: 25px;
}

.pacific-clr {
  color: var(--pacific-blue-color);
  font-weight: var(--font-weight-bold);
  text-decoration-line: underline;
}

.divider-btm {
  margin-top: 5px;
  margin-bottom: 30px;
}

.signIn-submit-btn {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--dark-black-color);

}

.signIn-btn-head {
  font-size: 16px;
  color: white
}

.signup-here-job {
  padding: 15px 0px;
  margin-bottom: -40px;
}

.signIn-modal-btn {
  border: 1px solid #143975;
}

.loader-white {
  color: white;
}

@media only screen and (max-width:767px) {
  .divider-btm {
    margin-top: 5px;
    margin-bottom: 12px;
  }

  .divider-mg {
    margin-top: 5px;
  }

  .createAccount-head {
    margin-top: 2px;
    margin-bottom: 12px;
  }

  .popup-bg-job {
    width: 90%;
    margin: 0 auto;
  }

  .content-f h5 {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    font-weight: var(--font-weight-bold);
  }

  .model-signin {
    max-width: 80%;
    width: 100%;
  }

  .content-f {
    padding: 12px 0px;
  }

  p.forget-password {
    font-size: 12px !important;
  }

  .remember-password {
    font-size: 12px !important;
  }

  .forget-mbl {
    margin-top: 9px !important;
  }

  .signup-here-job p {
    font-size: 12px;
  }


}