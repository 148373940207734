.comments-sec {
  padding: 20px;
}



.notes-box {
  border: 1px solid rgba(43, 43, 43, 0.18);
  border-radius: 16px;
  overflow: hidden;
  height: 750px;
  background: url(../../././../../../public/image/chat-bg.png) no-repeat;
  background-size: cover;
}

.notes-box.notes-scr {
  padding-bottom: 50px;
  height: auto;
  background: #fff;
}

.notes-box .chat-date-b h1 {
  background: #efebe6;
}

.notes-box .header-bar-comment {
  background: #F7F7F7;
  align-items: center;
}

.notes-box .btn-outline-black {
  background: transparent;
  font-weight: 400;
  border-width: 1px;
  line-height: 1.75;
  border-color: var(--dark-black-color-500);
}

.notes-box .chat-comment-box {
  left: 0;
  box-shadow: 0px 4px 32px 6px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  border: 0.5px solid rgba(43, 43, 43, 0.20);
  background: #fff;
}

.notes-box .chat-model-footer {
  background: #F0F2F5;
  position: relative;
}

.notes-box .chat-form,
.notes-box .send-comment button {
  border: 1px solid rgba(43, 43, 43, 0.16);
}

.notes-box .chat-model-comment-box {
  height: 100%;
  padding-top: 82px;
  overflow: unset;
}

.dd-listitem .btn {
  line-height: 1;
}

.dd-listitem .cv-cions {
  display: none;
  position: absolute;
  left: 0;
  box-shadow: 0px 1px 12px 4px rgba(0, 0, 0, 0.06);
  z-index: 9;
}

.dd-listitem .cv-cions ul {
  list-style: none;
  padding: 12px;
  border-radius: 8px;
  background-color: #fff;
  min-width: 125px;
  margin: 0;
}

.dd-listitem .cv-cions ul li {
  padding: 8px;
  transition: 0.25s all ease-in;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.dd-listitem .cv-cions ul li:hover {
  background: #ECECEC;
  font-weight: 700;
}

.dd-listitem .cv-cions.active {
  display: block;
}

.notes-box .chat-model-comment-box .chat-summary {
  height: 100%;
  /* overflow: auto; */
}

.notes-box .header-bar-comment>.size-w-d {
  flex-basis: unset;
}


.chat-slot {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 24px;
}

.chat-slot .single-chat {
  width: 100%;
  max-width: 565px;
}

.single-chat .chit-chat {
  display: flex;
  position: relative;
}

.single-chat .chit-chat form.bg-white {
  border-radius: 8px;
}

.single-chat .chit-chat .pin-chat {
  position: absolute;
  right: 10px;
  top: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.single-chat .chit-chat .pin-chat img {
  max-height: 24px;
}

.single-chat .chit-chat .chit-box {
  border-radius: 12px;
  border: 1px solid rgba(43, 43, 43, 0.40);
  background: var(--Button-Primary-Normal-Text, #FFF);
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.16);
  flex: auto;
  padding: 12px;
  padding-left: 37px;
}

.single-chat .chit-chat .chit-box p {
  text-align: left;
}

.single-chat .chit-chat .chit-box p.reply {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
  text-transform: capitalize;
  cursor: pointer;
  margin-top: 8px;
}

.single-chat .chit-chat .chit-box h5 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 8px;
}

.single-chat .chit-chat {
  display: flex;
  position: relative;
  padding-left: 30px;
  margin-bottom: 2px;
}

.single-chat .chit-chat .chit-box {
  border-radius: 12px;
  border: 1px solid rgba(43, 43, 43, 0.40);
  background: var(--Button-Primary-Normal-Text, #FFF);
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.16);
  flex: auto;
  padding: 12px;
  padding-left: 37px;
  min-height: 80px;
}

.single-chat .chit-chat .chit-box p {
  text-align: left;
}

.single-chat .chit-chat .chit-box h5 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 3px;
}

.single-chat .chit-chat .avatar {
  position: absolute;
  left: 0;
  top: 8px;
  width: 60px;
  height: 60px;
  border: 2px solid #009FB5;
  border-radius: 100%;
  padding: 3px;
  overflow: hidden;
}

.single-chat .chit-chat .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.chat-slot .single-chat p {
  text-align: right;
  font-size: 13px;
  color: #2b2b2b;
}

.single-chat.replied {
  margin-left: auto;
}

.single-chat.replied .chit-chat {
  padding-left: 0;
  padding-right: 30px;
}

.single-chat.replied .chit-chat .pin-chat {
  left: 8px;
  right: unset;
}

.single-chat.replied .chit-chat .avatar {
  right: 0;
  left: unset;
}

.single-chat.replied .chit-chat .chit-box {
  padding-top: 34px;
  padding-right: 37px;
  padding-left: 20px;
  background: #D9FDD2;
}

.chat-slot .single-chat.replied p {
  text-align: left;
}

.chat-extras-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.cand-inque {
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 30px;
  position: relative;
}

.cand-inque+.cand-inque {
  border-top: 1px solid rgba(43, 43, 43, 0.40);
}

.chat-model-comment-box.pinned {
  padding-top: 162px;
}

.replyMode {
  overflow: hidden;
  position: relative;
}

.replyMode:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 7;
  background: url('../../../../../public/image/blur.png') repeat rgba(255, 255, 255, .9);
  background-size: cover;
  width: 100%;
  height: 100%;
}

.reply-msg-sec {
  position: absolute;
  left: 0;
  right: 6px;
  bottom: 100%;
  z-index: 9;
  background: #fff;
  border: 1px solid var(--dark-black-color-500);
  border-left: 12px solid var(--pacific-blue-color);
  border-right: 0;
  padding: 12px 22px;
  padding-left: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.reply-msg-sec h4 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 6px;
}

.single-chat .chit-chat .chit-box .reply-msg-sec {
  position: static;
  border-radius: 8px;
  border-right: 1px solid var(--dark-black-color-500);
  padding: 9px;
  padding-left: 22px;
  border-left-width: 8px;
}

.cand-inque h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.cand-inque h3 small {
  font-size: 12px;
}

.cand-inque ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.cand-inque ul li {
  -webkit-margin-end: -20px;
  margin-inline-end: -18px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid #fff;
}

.cand-inque ul li img {
  width: 100%;
  height: 100%;
}

.pin-toogle {
  position: absolute;
  left: 30px;
  top: 65px;
  background: #fff;
  box-shadow: 0px 3px 4.5px 0px rgba(43, 43, 43, 0.20);
  border-radius: 16px;
  border: 1px solid rgba(43, 43, 43, 0.20);
  width: 100%;
  max-width: 380px;
  padding: 20px 2px;
  z-index: 99;
}

.chat-dd {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 380px;
  overflow: auto;
  padding: 0 28px;
}

.chat-dd::-webkit-scrollbar-track,
.notes-box .chat-model-comment-box .chat-summary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.chat-dd::-webkit-scrollbar,
.notes-box .chat-model-comment-box .chat-summary::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.chat-dd::-webkit-scrollbar-thumb,
.notes-box .chat-model-comment-box .chat-summary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--dark-black-color-500);
}


.s-chat {
  border-radius: 12px;
  border: 1px solid rgba(43, 43, 43, 0.20);
  padding: 12px;
  position: relative;
}

.s-chat .chat-h {
  display: flex;
  align-items: center;
  gap: 10px;
}

.s-chat .chat-h .avatar {
  border: 2px solid #009FB5;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  padding: 2px;
}

.s-chat .chat-h .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.s-chat .chat-h h3 {
  font-size: 16px;
}

.s-chat .pin-chat {
  position: absolute;
  right: 10px;
  top: 8px;
  max-height: 20px;
}

.s-chat .chat-b {
  padding: 8px 0;
}

.s-chat .chat-b p {
  font-size: 13px;
}

.s-chat .chat-f {
  text-align: right;
}

.s-chat .chat-f p {
  font-size: 12px;
  color: var(--dark-black-color-500);
}