/********user admin************/

.col-user-table {
    max-width: 100%;
    width: 100%;
    margin-top: -10px;
}

thead.tb-heading-user th {
    padding: 15px 0px 15px 15px;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    color: rgb(0 0 0 / 100%);
    line-height: 18px;
    text-align: left;
    border: 0;
    background: var(--color-white);
}

tbody.tb-user {
    border-radius: 16px;
    border: 0px solid #BDBDBD;
    background: var(--color-white);
}

tbody.tb-user tr,
thead.tb-heading-deprt tr {
    border: 0;
}

tbody.tb-user td {
    padding: 10px 0px 10px 18px;
    font-size: 14px;
    color: var(--dark-black-color);
    font-weight: var(--font-weight-medium);
    font-family: var(--arial-main);
    text-align: left;
    word-wrap: break-word;
    vertical-align: middle;
}

.tb-bulk-resume {
    text-align: center !important;
}

tbody.tb-user td .icon{
  width: 28px;
}

tbody.tb-user tr:nth-child(even) {
    background-color: white;
}

tbody.tb-user tr:nth-child(odd) {
    background-color: var(--Whisper-light-color);
}

th.size-th-user {
    width: 226px;
}


/********view user css *****/

.size-th-user-role {
    width: 197px;
}

.size-th-user-depart {
    width: 150px;
}

.size-th-user-status {
    width: 100px;
}

.admin-status-filter-form .admin-status-dropdown-profile {
    display: none !important;
  }
  
.admin-status-filter-form .admin-status-dropdown-profile.open {
    display: block !important;
  }