/*********************/

.notify-badge {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  right: -5px;
  top: -5px;
  bottom: calc(100% - 10px);
}

.notify-badge p {
  color: var(--color-white);
  height: 18px;
  width: 18px;
  font-size: 10px;
  z-index: 2;
  background: var(--dark-black-color);
  border-radius: 50%;
  bottom: 0;
  left: 25px;
  text-align: center;
  font-weight: 700;
  padding: 0px 0 0px 0px;
  vertical-align: middle;
  line-height: 18px;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.candidate-td-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

table tr {
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  padding: 0.35em;
}

table td {
  text-align: center;
  color: rgb(0 0 0 / 100%);
  font-size: 16px;
  font-weight: 300;
  font-family: var(--arial-main);
}

table th {
  color: var(--text-color);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  padding: 30px 0px;
  text-align: center;
}

th.technical-tags-row {
  width: 230px;
}

.job-table-employee {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 10px;
}

.job-table-employee button {
  background: #f2f7ff;
  padding: 5px;
  color: var(--primary-color);
  font-size: var(--button-text);
  border-radius: 6px;
  border: 1px solid #e2edff;
}

.cv-cions img {
  object-fit: contain;
  cursor: pointer;
  max-height: 24px;
  max-width: 25px;
}

.cv-cions {
  display: flex;
  gap: 8px;
  position: relative;
  align-items: center;
  justify-content: center;
}

.experience-cell {
  width: 100px;
}

.can-status-cell {
  width: 190px !important;
}

.date-cell {
  width: 110px;
}

.experience-cell {
  width: 100px;
}

.tag-cell {
  padding-left: 20px;
}

input.td-tr-chk {
  margin-right: 8px;
}

/***********tabek tool tiop***********/

.cv-cions-review img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.cv-cions-review {
  display: flex;
  justify-content: center;
  gap: 5px;
  position: relative;
  padding-top: 20px;
}

/**************review cv box tootip *************/

.good-cv-box {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #0ab84d;
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  padding: 8px 12px;
  width: 150px;
  justify-content: space-between;
  top: -30px;
  left: 70px;
}

.good-cv-box p {
  color: #0ab84d;
  font-family: var(--arial-main);
  font-size: 12px;
  font-weight: var(--font-weight-medium);
}

.good-cv-box img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.hide-box {
  opacity: 0;
}

img.tooltip-show:hover~.hide-box {
  opacity: 1;
}

/**************circle progress bar applicant form *************/

.progress-table {
  width: 55px;
  height: 55px;
  position: relative;
  display: inline-block;
  margin-bottom: -15px;
  margin-top: -5px;
}

.progress-bar-container {
  display: inline-block;
  position: relative;
  width: 55px;
  height: 55px;
}

.progress-bar-container-m {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px;
}

.progress-bar-outer {
  width: 100%;
  height: 100%;
  background: none;
  border: 6px solid #0ab84d;
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
}

.progress-bar-inner {
  width: 85%;
  height: 85%;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.progress-value {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #0ab84d;
  line-height: 36px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

td.prog-bar-cir {
  text-align: center;
}

td.cv-icon-m {
  padding-left: 17px !important;
}

tr.bor-top {
  border-top: 1px solid rgb(0 0 0 / 20%);
  border-bottom: 0;
  vertical-align: baseline;
}

.bor-top td {
  padding-top: 20px !important;
  padding-bottom: 18px !important;
}

tr.bor-botm {
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  border-top: 0;
  vertical-align: baseline;
}

.bor-botm td {
  padding-bottom: 20px !important;
}

input.td-tr-chk {
  margin-right: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.img-table-tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip-table {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: -4px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: var(--color-white);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  font-weight: 500;
  white-space: nowrap;
  z-index: 1;
}

img.show-tooltip:hover~.tooltip-table {
  opacity: 1;
}

/**************need think box  tootltip***********/

.thinkneed-cv-box {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--accent-color);
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  padding: 8px 12px;
  width: 150px;
  justify-content: space-between;
  top: -30px;
  left: 45px;
}

.thinkneed-cv-box p {
  color: var(--accent-color);
  font-family: var(--arial-main);
  font-size: 12px;
  font-weight: var(--font-weight-medium);
}

.thinkneed-cv-box img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

/**************bad cv tootltip***********/

.badreview-cv-box {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--error-msg-color);
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  padding: 8px 12px;
  width: 150px;
  justify-content: space-between;
  top: -30px;
  left: 8px;
}

.badreview-cv-box p {
  color: var(--error-msg-color);
  font-family: var(--arial-main);
  font-size: 12px;
  font-weight: var(--font-weight-medium);
}

.badreview-cv-box img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.attention-box-table {
  border-radius: 6px;
  border: 1px solid var(--accent-color);
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  padding: 8px 15px;
  position: absolute;
  bottom: 45px;
  left: -12px;
  width: 225px;
}

.attention-text-table {
  text-align: left;
}

p.text-atten-box-table {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

/*****************/

img.notfill-heart {
  display: none;
}

.cv-resume .tooltiptext {
  visibility: hidden;
  width: 100px;
  border-radius: 4px;
  border: 0.5px solid #bb6bd9;
  background: var(--color-white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: #bb6bd9;
  text-align: center;
  padding: 5px 4px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -38px;
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.cv-resume:hover .tooltiptext {
  visibility: visible;
}

.cv-comment .tooltiptext {
  visibility: hidden;
  width: 100px;
  border-radius: 4px;
  border: 0.5px solid #2b819d;
  background: var(--color-white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: #2b819d;
  text-align: center;
  padding: 5px 10px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 85%;
  margin-left: -60px;
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.cv-comment:hover .tooltiptext {
  visibility: visible;
}

/************job listing filter *********/

.j-fillter-only-dashbord {
  margin: 30px auto 30px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  width: 100%;
  gap: 5px;
  flex-wrap: wrap;
}

.j-fillter-only-dashbord .j-company-fillter {
  padding: 12px;
  border: 0.5px solid var(--boder-color);
  border-radius: 6px;
  width: 215px;
  background: var(--Whisper-color);
}

.j-fillter-only-dashbord .j-company-fillter input::placeholder {
  color: var(--text-color);
  font-family: var(--arial-main);
}

.j-fillter-only-dashbord .j-company-fillter input {
  font-weight: 500;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--arial-main);
  background: transparent;
}

.j-fillter-only-dashbord .j-company-fillter input+img {
  position: absolute;
  right: 5px;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.text-comment-box::after {
  content: "";
  display: block;
  width: 20px;
  height: 1px;
  position: absolute;
  top: -9px;
  right: 30px;
  background: rgba(201, 201, 201, 0.5);
  transform: rotate(54deg);
}

.text-comment-box::before {
  content: "";
  display: block;
  width: 20px;
  height: 1px;
  position: absolute;
  top: -9px;
  background: rgba(201, 201, 201, 0.5);
  transform: rotate(-54deg);
  right: 42px;
}

p.comment-heading-text::after {
  content: "";
  display: block;
  width: 23px;
  height: 2px;
  background: var(--color-white);
  position: absolute;
  top: -1px;
  right: 35px;
  z-index: 99;
}

.name-info-add-box::after {
  content: "";
  display: block;
  width: 23px;
  height: 2px;
  background: var(--color-white);
  position: absolute;
  top: -1px;
  right: 35px;
  z-index: 99;
}

.text-comment-box {
  height: auto;
  width: 570px;
  position: absolute;
  right: 0px;
  border-radius: 12px;
  border: 1px solid rgba(201, 201, 201, 0.5);
  background: var(--color-white);
  box-shadow: 0px 5px 4px 2px rgba(0, 0, 0, 0.07);
  top: 60px;
  padding: 20px 15px;
  z-index: 1;
}

p.comment-heading-text {
  font-size: var(--button-text);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  text-align: left;
}

textarea#comment-area-job {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid rgb(0 0 0 / 10%);
  background: var(--color-white);
}

textarea#comment-area-job::-webkit-input-placeholder {
  color: #bdbdbd;
  font-size: var(--button-text);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

button.comment-submit {
  text-align: end;
  display: block;
  margin: 0 0 0 auto;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid var(--accent-color);
  background: var(--color-white);
  color: var(--accent-color);
  font-size: var(--button-text);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

/**************name hover comment **************/

td.name-box-hvr {
  cursor: pointer;
}

.name-comment-box {
  display: none;
}

td.name-box-hvr:hover .name-comment-box {
  display: block !important;
  z-index: 1;
}

.name-comment-box::before {
  content: "";
  display: block;
  width: 20px;
  height: 1px;
  position: absolute;
  top: -9px;
  background: rgba(201, 201, 201, 0.5);
  transform: rotate(-54deg);
  left: 88px;
}

.name-comment-box::after {
  content: "";
  display: block;
  width: 20px;
  height: 1px;
  position: absolute;
  top: -9px;
  left: 100px;
  background: rgba(201, 201, 201, 0.5);
  transform: rotate(54deg);
}

.name-owner-info::after {
  content: "";
  display: block;
  width: 23px;
  height: 2px;
  background: var(--color-white);
  position: absolute;
  top: -1px;
  left: 92px;
  z-index: 99;
}

.name-comment-box {
  height: auto;
  width: 570px;
  position: absolute;
  left: 0px;
  border-radius: 12px;
  border: 1px solid rgba(201, 201, 201, 0.5);
  background: var(--color-white);
  box-shadow: 0px 5px 4px 2px rgba(0, 0, 0, 0.07);
  padding: 20px 15px;
  top: 135px;
}

td.name-box-hvr {
  position: relative;
  word-break: keep-all;
}

/**************delet list drop down********/

.delet-list-menu {
  position: absolute;
  border-radius: 12px;
  background: var(--color-white);
  width: 240px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
  left: -90px;
  z-index: 1;
  padding: 12px;
}

ul.list-item-delet {
  padding: 0 0px;
  list-style: none;
  margin: 0;
}

ul.list-item-delet li {
  display: flex;
  justify-content: space-between;
  padding: 9px 7px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}

ul.list-item-delet li:hover {
  border-radius: 4px;
  background: var(--Whisper-color);
  font-weight: var(--font-weight-bold);
}