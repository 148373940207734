/*************job detail head *********/
.fw-400{
  font-weight: 400;
}

.job-details-sec {
  padding: 40px 0px;
  background: var(--pacific-blue-color-300);
  margin-top: 30px;
}

.j-compny-btn {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.j-company-logo {
  display: flex;
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #DEDEDE;
}

.j-company-logo img {
  max-width: 100%;
  max-height: 100%;

}

.j-company-dev {
  max-width: 57%;
  width: 100%;
  margin-left: 20px;
}

.job-title-download {
  display: flex;
  align-items: center;
  gap: 20px;
}
.job-title-download img {
  max-width: 100%;
}

h1.company-job-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
}

p.j-sub-title {
  color: #000;
  font-family: "Museo Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.j-sub-title a {
  cursor: pointer;
}

.icon-text-job-requre {
  display: flex;
  gap: 6px;
  margin: 8px 0 0 0;
  padding: 4px 0;
}

.icon-text-job-requre img {
  max-width: 100%;
  width: 18px;
  height: 18px;
  object-fit: contain;
}

p.j-c-salary {
  color: #000;
  font-family: "Museo Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p.j-c-salary span {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: 16px;
  color: var(--text-color);
  opacity: 0.5;
}

.job-find-list {
  display: flex;
  align-items: center;
}

.job-find-list ul {
  margin-bottom: 0;
  padding-left: 31px;
  padding-right: 0 !important;
}

.job-find-list ul li {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: 16px;
  color: var(--text-color);
  opacity: 0.5;
}

.j-company-btn-apply {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 290px;
  width: 100%;
  gap: 10px;
}



.j-company-btn-apply .apply-btn {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  background: var(--dark-black-color);
  padding: 16px;
  height: auto;
  color: var(--color-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.j-company-btn-apply .applied-btn {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  background: transparent;
  padding: 16px;
  height: auto;
  color: var(--dark-black-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 2px solid var(--dark-black-color);
}

button.apply-j-btn {
  width: 100%;
  min-width: 277px;
  padding: 13px 0;
  background: rgba(20, 57, 117, 0.03);
  margin: 0 0 10px auto;
  border: 2px solid var(--dark-black-color);
  color: var(--dark-black-color);
  border-radius: 8px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

button.fav-j-btn {
  min-width: 277px;
  padding: 13px 0;
  background: transparent;
  margin: 0 0 25px auto;
  border: 1px solid var(--form-text-account);
  color: var(--form-text-account);
  border-radius: 4px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

button.edit-j-btn {
  min-width: 277px;
  padding: 13px 0;
  background: rgba(255, 160, 51, 0.05);
  margin: 0 0 25px auto;
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
  border-radius: 4px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

button.ques-list-j-btn {
  min-width: 277px;
  padding: 13px 0;
  background: transparent;
  margin: 0 0 25px auto;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 4px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.j-compny-btn-sub {
  display: flex;
  justify-content: start;
  flex: auto;
  /* margin-left: -100px; */
}

.wish-icon-web {
  display: flex;
}

.wish-icon-mbl {
  display: none;
}

@media only screen and (max-width:1300px) {
  .j-compny-btn-sub {
    margin-left: 0px;
  }
}

@media only screen and (max-width:859px) {
  .j-compny-btn-sub {
    display: flex;
    justify-content: start;
    margin-left: 0px;
  }

  .j-company-logo {
    /* max-width: 100%;
  width: 100%; */

  }

  .j-company-dev-mbl {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 0px !important;
    /* margin-left: -60px !important; */
  }

  .j-c-salary {
    font-size: 12px !important;
  }

  .days-p {
    font-size: 12px !important;
  }

  .time-p {
    font-size: 12px !important;
  }

  .j-company-btn-apply-mbl {
    /* margin-top: 20px !important; */
  }

  .job-details-sec {
    padding: 20px 0px;
    margin-top: 20px;
  }

  .j-company-btn-apply-mbl .wish-cirle {
    position: absolute;
    top: 0px;
    right: 20px;
  }

  /* 
  .company-job-title {
    font-size: 18px !important;
  } */

  .mbl-time-p {
    color: black !important;
    opacity: 0.99 !important;
  }

  .tow-btn-j-com {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .edit-j-btn-mbl {
    margin-left: 0px !important;
  }

  .apply-j-btn-mbl {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width:410px) {
  .j-company-dev-mbl {
    /* margin-left: -35px !important; */
    margin-left: 12px !important;
  }

  .j-c-salary {
    font-size: 14px !important;
  }

  .days-p {
    font-size: 10px !important;
  }

  .time-p {
    font-size: 10px !important;
  }

  .company-job-title {
    font-size: 16px !important;
  }

  .j-sub-title {
    font-size: 14px !important;
  }
}

@media only screen and (max-width:768px) {

  h1.company-job-title {
    width: 100%;
    text-align: left;
  }

  p.j-sub-title {
    color: #000;
    font-family: "Museo Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 6px;
  }

  p.j-c-salary {
    font-weight: 600;
    text-align: left;
  }
  .job-title-download {
    align-items: flex-start;
  }

  .wish-icon-web {
    display: none;
  }

  .wish-icon-mbl {
    display: block;
  }  
}