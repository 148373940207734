.list-job-title-company-local {
    width: 250px !important
}

.job-listing .lr {
    padding: 14px 2px;
    text-align: center;

}

.search-query {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.search-query p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #2b2b2b;
}

.search-query img {
    max-height: 10px;
}

.searchbox-sec {
    padding: 12px 12px 0;
    position: relative;
}

.searchbox-sec input {
    padding: 10px 16px;
    border: 1px solid rgba(43, 43, 43, .4) !important;
    border-radius: 12px;
    padding-left: 44px;
    width: 100% !important;
}

.searchbox-sec input::placeholder {
    color: rgba(43, 43, 43, .4) !important;
}

.searchbox-sec:before {
    content: '';
    position: absolute;
    left: 28px;
    top: calc(50% + 5px);
    transform: translateY(-50%);
    background: url('../../../../public/image/search-gray.png') no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.job-listing .job-listing-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    border-top: 1px solid #BDBDBD;
}

.job-listing .job-listing-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #BDBDBD;

}

.job-listing .job-listing-header .l-desc {
    text-align: center;
}

.j-company-fillter .text-filter-icon p {
    font-weight: 500;
    color: var(--text-color);
    font-size: 16px;
}

.full-name .td-tr-chk {
    display: none !important;
}

.job-listing .checkbox {
    display: none;
}

.edit-icon-emply {
    display: flex;
    align-items: baseline;
    justify-content: end;
    cursor: pointer;
    gap: 15px;
}

.edit-icon-emply-draft {
    margin-bottom: 4rem;
}

.application-dash-hide {
    display: none;
}

.application-dash a {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
    text-decoration: none;
    font-size: 16px;
    color: var(--text-color);
    font-weight: 400;
    font-family: var(--arial-main);
}

.application-dash img {
    height: 20px;
}

@media(min-width: 767px) {
    .job-listing .checkbox {
        display: block;
        flex: 0 0 20px;
        max-width: 20px;
    }

    .job-listing .checkbox input.td-tr-chk {
        margin: 0;
    }

    .job-listing .full-name {
        flex: 0 0 125px;
        max-width: 125px;
        text-align: center;
    }

    .job-listing .j-title {
        flex: 0 0 125px;
        max-width: 125px;
    }

    .applicant-arrow-icon {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }

    .job-listing .experience {
        flex: 0 0 100px;
        max-width: 100px;
        display: flex;
        gap: 5px;
    }

    .job-listing .cand-status {
        flex: 0 0 180px;
        max-width: 180px;
    }

    .job-listing .match {
        flex: 0 0 130px;
        max-width: 130px;
        gap: 5px;
        display: flex;
        justify-content: center;
    }

    .job-listing .impression {
        flex: 0 0 125px;
        max-width: 125px;
    }

    .job-listing .date-app {
        flex: 0 0 120px;
        max-width: 120px;
        display: flex;
        gap: 5px;
    }

    .job-listing .action {
        flex: 0 0 122px;
        max-width: 122px;
    }

    .job-listing .list-name {
        flex: 0 0 196px;
        max-width: 196px;
        text-align: left;
    }

    .job-listing .l-desc {
        flex: 0 0 480px;
        max-width: 480px;
        text-align: left;
    }

    .job-listing .c-date {
        flex: 0 0 124px;
        max-width: 124px;
    }

    .job-listing .no-cand {
        flex: 0 0 140px;
        max-width: 140px;
    }
}

@media only screen and (max-width:767px) {
    .job-title-size {
        font-size: 12px !important;
    }

    ul.list-job-title-company li {
        font-size: 18px !important;
    }

    .list-job-title-company-local {
        width: 220px !important
    }

    .job-listing-mbl {
        width: 92% !important;
    }

    .job-listing .c-date {
        display: none;
    }

    .job-listing .l-desc {
        min-height: 100px;
    }

    .full-name .td-tr-chk {
        display: block !important;
    }

    .job-listing .list-name {
        color: var(--dark-black-color);
        font-weight: 600;
        font-size: 20px;
    }

    .job-listing .no-cand {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .job-listing .job-listing-header {
        display: none;
    }

    .cv-cions-review {
        justify-content: start;
    }

    .cv-cions-review {
        justify-content: start;
    }


    .job-listing .job-listing-body {
        flex-direction: column;
        align-items: flex-start;
        border: 0;
        border-radius: 12px;
        box-shadow: 0px 1px 12px 4px rgba(0, 0, 0, 0.06);
        padding: 12px;
        gap: 12px;
        position: relative;
    }

    .job-listing .job-listing-mb {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .lr.j-title.cursor-pointer {
        display: flex;
        justify-content: space-between;
        flex: 1;
    }

    .lr.experience {
        display: flex;
        justify-content: space-between;
    }

    .job-listing .lr {
        padding: 0;
        text-align: left;
    }

    .lr.list-name {
        width: 80%;
        word-wrap: break-word;
    }

    .notify-badge {
        right: 0px !important;
        top: 0px !important;
    }

    .job-listing .lr span {
        font-weight: 600;
        margin-right: 3px;
    }

    .job-listing .job-fillter-table-data {
        width: 100%;
    }

    .job-listing .job-fillter-table-data .j-company-fillter-table-data {
        width: 100%;
    }

    .job-listing .full-name {
        order: 0;
        font-size: 20px;
        color: var(--dark-black-color);
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
        padding-right: 30px;
    }

    .job-listing .j-title {
        order: 2;
    }

    .job-listing .experience {
        order: 3;
    }

    .job-listing .cand-status {
        order: 4;
        width: 100%;
    }

    .job-listing .match {
        display: none;
    }

    .job-listing .impression {
        order: 1;
    }

    .job-listing .impression .cv-cions-review {
        gap: 16px;
    }

    .job-listing .date-app {
        order: 5;
        font-size: 12px;
        width: 100%;
        text-align: right;
        font-weight: 500;
    }

    .job-listing .action {
        position: absolute;
        right: 12px;
        top: 12px;
    }

    .job-listing .action .btn {
        padding: 0;
    }

    .job-listing .action .cv-cions {
        position: absolute;
        right: 0;
        top: 0%;
        opacity: 0;
        flex-direction: column;
        padding: 12px 8px;
        width: 240px;
        box-shadow: 0px 1px 12px 4px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        gap: 0;
        transition: 0.25s all ease-in;
        z-index: -9;
        background-color: var(--color-white);
    }

    .job-listing .action .cv-cions.active {
        opacity: 1;
        top: 100%;
        z-index: 1;
    }

    .job-listing .action .cv-cions>* {
        display: flex;
        align-items: center;
        padding: 6px 10px;
        gap: 0;
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .job-listing .action .cv-cions>*:hover {
        background: var(--Whisper-color);
        color: var(--dark-black-color);
        border-radius: 8px;
    }

    .job-listing .action .cv-cions .img-table-tooltip img {
        width: 24px;
        height: 22px;
        object-fit: contain;
    }

    .job-listing .action .cv-cions .img-table-tooltip .tooltip-table {
        position: relative;
        opacity: 1;
        border: 0;
        background: transparent;
        top: 0;
        left: 0;
        font-size: 16px;
        padding: 0;
        padding-left: 8px;
    }

    .job-listing .action .cv-cions .text-comment-box {
        width: 320px;
        right: 0px;
    }

    .edit-icon-emply-draft {
        margin-bottom: 0rem;
    }

    .application-dash-hide {
        display: block;
    }
}