/****skills accorddion ****/

.skill-tab-jour-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.skill-upper-conten-sec {
  flex: 1;
}

.accordion.skill-tab-accr {
  padding-top: 20px;
}

button.accordion-button.btn-skills-tabs.collapsed {
  padding: 0 !important;
  margin-bottom: 15px !important;
  color: var(--dark-black-color) !important;
  font-family: var(--arial-main);
  font-size: 20px !important;
  font-weight: 700;
  line-height: 23px;
}

button.accordion-button.btn-skills-tabs {
  padding: 0 !important;
  color: var(--dark-black-color) !important;
  font-family: var(--arial-main);
  font-size: 20px !important;
  font-weight: 700;
  line-height: 23px;
}

.accordion-body.tabs-skills-body {
  padding: 0 !important;
}

.accordion-item.skill-tabs-item {
  padding: 0 !important;
  border: 0 !important;
}

.accordion-item.skill-tabs-item {
  border-bottom: 1px solid #2b2b2b29 !important;
  padding: 15px 0 0 0 !important;
}

.skill-body-table > :not(caption) > * > * {
  background-color: transparent !important;
}

th.th-skill {
  border-left-color: transparent;
  width: 110px;
  text-align: left;
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
}

th.th-pro-level {
  text-align: left;
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
}

th.th-descrip {
  border-right-color: transparent;
  text-align: left;
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  width: 225px;
  color: var(--dark-black-color);
}

td.td-first-row {
  border-left-color: transparent;
  text-align: left;
  font-size: 15px;
  font-family: var(--arial-main);
  font-weight: 400;
  color: var(--dark-black-color);
}

td.td-seacond-row {
  font-size: 15px;
  font-family: var(--arial-main);
  font-weight: 400;
  color: var(--dark-black-color);
}

td.td-third-row {
  border-right-color: transparent;
  text-align: left;
  font-size: 15px;
  font-family: var(--arial-main);
  font-weight: 400;
  color: var(--dark-black-color);
}

.progress.prgress-width {
  width: 100px;
  margin: 0 auto;
}

.progress-bar.bg-ad.progress-bar-color {
  background-color: #0AB84D !important;
}

.progress-bar.bg-inter.progress-bar-color {
  background-color: #FFA033 !important;
}

.progress-bar.bg-lower.progress-bar-color {
  background-color: #E15C52 !important;
}

.color-ad {
  color: #0AB84D !important;
}

.color-inter {
  color: #FFA033 !important;
}

.color-lower {
  color: #E15C52 !important;
}

table.skill-body-table tr:last-of-type {
  border-bottom: 0 !important;
}

p.feed-back-line {
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 700;
  color: var(--dark-black-color);
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  padding: 10px 0;
}

.like-dsilike-img {
  display: flex;
  align-items: center;
  gap: 15px;
}

.like-dsilike-img img {
  max-width: 100%;
}