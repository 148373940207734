/********* juast one heading*****/

.one-heading-sec {
  background: var(--color-white);
  padding: 100px 0 0 0;
}

.ensure-heading {
  width: 50%;
  margin: 0 auto;
}

.ensure-heading h2 {
  text-align: center;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.featured-col:nth-child(3n) {
  padding: 0;
}

.featured-col {
  width: 100%;
  padding: 0 28px 0 0;
  max-width: 33.33%;
}

.featured-col a {
  text-decoration: none;
}

/********* images text start here******/

.sucess-featured-sec-alt {
  background: var(--color-white);
  padding: 100px 0;
}

button.detail-btn {
  text-align: end;
  padding: 12px 32px;
  border-radius: 4px;
  border: 0.5px solid var(--primary-color);
  background: var(--primary-color);
  font-size: 16px;
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  display: block;
  margin: 0 0 0 auto;
  align-self: flex-end;
}

.image-heading-col img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.image-heading-col h3 {
  font-weight: 700;
  font-size: 28px;
  padding-top: 10px;
}

.sucess-featured-sec {
  background: var(--card-home-bg);
  padding: 100px 0;
}

button.detail-btn-lft {
  text-align: end;
  padding: 12px 32px;
  border-radius: 4px;
  border: 0.5px solid var(--primary-color);
  background: var(--primary-color);
  font-size: 16px;
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  display: block;
  margin: 0 0 0 auto;
  align-self: flex-end;
}

.sucess-featured-info a {
  text-decoration: none;
}

.sucess-featured.alt {
  flex-direction: row-reverse;
}

.sucess-featured-img {
  width: 100%;
  max-width: 40%;
  flex: 1;
}

.first-col-content p {
  font-size: 16px;
  color: var(--text-color);
  font-weight: 300;
  line-height: 28px;
  padding: 20px 0px 20px 0;
  opacity: 1;
  font-family: var(--arial-main);
  text-align: justify;
}

.sucess-featured-info {
  width: 100%;
  max-width: 60%;
}

.sucess-featured-sec .sucess-featured-info {
  padding: 0 80px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sucess-featured {
  display: flex;
}

.sucess-featured-sec-alt .sucess-featured-info {
  padding: 0 80px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.first-col-content {
  flex: 1;
}

.sucess-featured-img img {
  max-width: 100%;
  border-radius: 32px;
}
