/*Candidate Listing Page*/

.candidate-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}

.candidate-list-header h2 {
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-size: 24px;
}

a.clr-b-chng {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color) !important;
  padding: 8px !important;
  font-size: 18px !important;
  color: var(--dark-black-color) !important;

}

.hor-line-hide {
  display: none;
}

.section-hide {
  display: none;
}

/*Candidate Listing DropDown*/

.first-menu-dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: var(--color-white);
}

.first-menu-dropdown li .sub-menu-lists {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: none;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  background: transparent;
  text-decoration: none;
}

.first-menu-dropdown .second-menu-dropdown {
  background: var(--color-white);
  border: 0.5px solid #0003;
  border-radius: 12px;
  box-shadow: 0 0 9px 0 #00000040;
  left: 0;
  padding: 0 10px 10px;
  position: relative;
  top: 10px;
  z-index: 1;
}

.view-candidate-img {
  width: 33px;
  height: 25px;
}

.view-candidate-arrow-down {
  width: 20px;
}

.second-menu-dropdown-sroll {
  overflow: auto;
  max-height: 400px;
  height: auto;
}

.second-menu-dropdown-border {
  border: none !important;
  padding: 0 14px 10px 10px !important;
  position: relative !important;
  top: 0px !important;
  border-radius: 8px;
  left: 0px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

/* width */

.second-menu-dropdown-sroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.second-menu-dropdown-sroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.second-menu-dropdown-sroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--dark-black-color);
  background-color: var(--dark-black-color);
}

a.sub-menu-lists.clr-set {
  border-radius: 8px;
  border: 2px solid var(--dark-black-color);
  padding: 8px;
}

.second-menu-dropdown {
  display: none;
}

.first-menu-dropdown .second-menu-dropdown li a {
  color: rgb(0 0 0 / 100%);
  padding: 10px 10px 10px 7px;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 0px;
  text-decoration: none;
  margin-top: 0px;
  font-size: 14px;
  background: transparent;
  font-weight: 300;
  font-family: var(--arial-main);
}

.first-menu-dropdown .second-menu-dropdown li a:hover {
  border-radius: 8px;
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}

.second-menu-dropdown-border li a {
  color: var(--dark-black-color);
  font-size: 12px;
  font-weight: 400;
}

.second-menu-dropdown-border li a:hover {
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}

.second-menu-dropdown-border li img {
  height: 18px;
  width: 18px;
  object-fit: contain;
}

ul.second-menu-dropdown {
  list-style: none;
}

.main-dropdown-heading {
  width: 200px;
  position: relative;
}

li.sub-menu-dropdpwn-list-f a.sub-menu-lists:nth-child(1) {
  margin-top: 10px;
}

.multi-dropdown-box {
  margin-top: 10px;
  border-radius: 8px;
  background: var(--color-white);
  z-index: 1;
  max-height: 42px;
  height: auto;
}

/* .drop-height-sroll {
    max-height: 130px;
    height: auto;
    overflow-y: auto;
  }
  
  .drop-height-sroll::-webkit-scrollbar {
    width: 8px;
  }
  
  .drop-height-sroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
  .drop-height-sroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #143975;
  } */

.dash-bord-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  gap: 8px;
}

.tow-btn-job-dash {
  display: flex;
  flex-direction: column;
}

/**********add list model candidate  main css*********/

.model-list-popup {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 55%);
  top: 0%;
  overflow-y: auto;
  z-index: 999;
}

.popup-bg-listadd {
  width: 370px;
  margin: 0 auto;
  border-radius: 16px;
  border: 1px solid #c6c6c6;
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.14);
  margin: 50px auto 0;
}

.popup-content-list-add {
  width: 100%;
  position: relative;
  padding: 20px 25px;
}

.logo-main {
  text-align: center;
}

.logo-main img {
  max-width: 100%;
  width: 145px;
  margin: 0 auto;
}

.close-btn img {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.list-content-s {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  gap: 15px;
  align-items: center;
}

.list-content-s p {
  color: var(--primary-color);
  font-family: var(--arial-main);
  font-size: 18px;
  font-weight: 600;
}

/*********** form button for moddel of list ********/

.add-rem-list-btn {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  gap: 15px;
}

button.can-list {
  color: #f32131;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  background: transparent;
  border-radius: 6px;
  border: 1px solid #f32131;
  min-width: 100px;
  padding: 7px 5px;
  font-size: 16px;
  font-weight: 400;
}

button.list-sub {
  color: var(--color-white);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  background: var(--primary-color);
  border-radius: 6px;
  border: 0px solid #f32131;
  min-width: 100px;
  padding: 7px 5px;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .hor-line-hide {
    display: flex;
  }

  .section-hide {
    display: flex;
  }
}