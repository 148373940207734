/*********job experince model resume*************/

section.model-popup-3 {
  z-index: 5;
}

.upload-resume-model-jbmdl {
  width: 1040px;
  margin: 0 auto;
}

.model-jd-upld-scroll {
  margin: 50px 0;
}

.popup-resume-jbmdl {
  width: 100%;
  height: 50%;
  border-radius: 16px 16px 0 0;
  background: var(--color-white);
  padding: 20px 20px 0px 20px;
  position: relative;
}

.res-jbmdl-heading {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
  font-size: 20px;
  padding: 10px 0 0px 0;
}

.sub-text-jbmdl-res {
  font-family: var(--arial-main);
  color: #000;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  padding: 0px 0 15px 0;
}

.two-col-jbmdl {
  background: var(--color-white);
  display: flex;
  justify-content: space-between;
}

.col-left-jbmdl {
  max-width: 50%;
  width: 100%;
  margin-right: 12px;
}

.col-left-jbmdl,
.col-right-jbmdl {
  display: flex;
  flex-direction: column;
}

.col-right-jbmdl {
  max-width: 50%;
  width: 100%;
  margin-left: 12px;
}

.img-text-jbmdl {
  border-radius: 12px;
  border: 1px solid #143975;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 10px;
  min-height: 100px;
}

.img-text-jbmdl-right {
  border-radius: 12px;
  border: 1px solid #FFA033;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  padding: 12px 24px;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 10px;
  min-height: 100px;
}

.img-text-jbmdl-right.height-box-r {
  min-height: 100px;
}

p.org-text-right {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
  font-size: 18px;
}

p.org-text-right-r {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: #ffa033;
  font-size: 18px;
}

p.jb-desc-mdl {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  color: rgb(0 0 0 / 50%);
  font-size: 14px;
  line-height: 19px;
}

.textedditor-jbmdl {
  padding: 8px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: rgba(221, 222, 238, 0.25);
  color: var(--text-color);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
}

textarea.txt-editr-jbmdl {
  width: 100%;
  border-radius: 0px;
  border: 0px solid rgba(0, 0, 0, 0.16);
  background: rgb(246 247 251);
  color: var(--text-color);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
}

.hide-box-right {
  display: none;
}

.hide-box-left {
  display: none;
}

.all-text-jbmdl {
  padding: 20px;
  background: var(--color-white);
  border-radius: 0px 0px 16px 16px;
}

.two-btn-jbmdl {
  display: flex;
  justify-content: end;
  padding: 12px 0;
  z-index: 1;
  position: relative;
}

button.regenerate-res {
  border-radius: 8px;
  border: 1px solid #143975;
  background: #143975;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--color-white);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  min-width: 157px;
  cursor: pointer;
}

button.replace-res {
  border-radius: 8px;
  border: 1px solid #143975;
  background: transparent;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--primary-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  margin-right: 15px;
  min-width: 157px;
  cursor: pointer;
}

.text-user-f {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  align-items: stretch;
}

textarea.user-inp-jbmdl {
  width: 100%;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: #f7f7fb;
  color: var(--text-color);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  height: 3.8em;
  overflow-y: hidden;
}

textarea.user-inp-jbmdl:focus-visible {
  outline: 0;
}

button.res-img-btn {
  display: flex;
  border-radius: 8px;
  padding: 12px 26px;
  background: #07944f;
  border: 0;
  align-items: center;
}

.res-img-btn img {
  width: 34px;
  height: 34px;
}

/***tootltip  model form*****/

.toottip-job-mdl img {
  text-align: end;
  display: block;
  margin: 4px 0 4px auto;
}

.toottip-job-mdl {
  position: relative;
  display: none;
}