/******* notification dropdown******/

.hide-show-notification {
  display: none;
}

.notification-dropdown {
  position: absolute;
  width: 440px;
  height: auto;
  max-height: 550px;
  background: #fff;
  right: 180px;
  top: 67px;
  z-index: 10;
  overflow-y: scroll;
  border-radius: 16px;
}


/* width */

.notification-dropdown::-webkit-scrollbar {
  width: 10px;
}


/* Track */

.notification-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


/* Handle */

.notification-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #2B2B2B3D;
}

.start-headind-notif {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  align-items: center;
}

.img-left-notif img{
  border-radius: 50%;
}

.right-side-notif {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: 400;
  color: var(--dark-black-color);
}

.right-side-notif .img-tick {
  max-width: 100% !important;
  height: auto !important;
  width: auto;
  cursor: pointer;
}

.close-notif {
  margin-left: 10px;
}

.right-side-notif .img-tick {
  margin-left: 5px;
}

.left-side-notif {
  font-size: 18px;
  font-family: var(--arial-main);
  font-weight: 400;
  color: var(--dark-black-color);
}

.notifi-msg-row  {
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  background: #fff;
  border-bottom: 1px solid #2B2B2B36;
}

.notifi-msg-row:hover  {
  background-color: #009FB51A;
}

.notifi-active {
  background-color: #009FB51A;
}

.right-side-notifi {
  width: 325px;
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-black-color);
}

.name-of-persone,
.simle-text-notofi,
.question-notifi {
  display: inline !important;
}

.right-side-notifi b,
.right-side-notifi a {
  display: inline !important;
}

.right-side-notifi b {
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: 700;
  color: var(--dark-black-color);
  line-height: 24px;
}

p.simle-text-notofi {
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-black-color);
}

.right-side-notifi a {
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 24px;
  color: #0F66CC;
  text-decoration: underline !important;
}

.time-interview-ques {
  color: #2B2B2B80;
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 24px;
  padding-top: 5px;
}

.notifi-msg-row-buttom {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  gap: 10px;
  background: #fff;
  border-bottom: 1px solid #2B2B2B36;
}

.notifi-msg-row-buttom .see-more-btn {
    background: var(--color-grey);
    border: 1px solid var(--dark-black-color);
    border-radius: 6px;
    color: var(--dark-black-color);
    padding: 8px 18px;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
}

.notify-active {
  background: #009fb51a;
}

@media only screen and (max-width:767px) {

 /*********notification dropdown mbl**********/
 .notification-dropdown {
  width: 96%;
  right: 0;
  margin: 0 auto;
  }
  .right-side-notif {
    display: none;
  }

}