/*********upload resume form*************/

.upload-resume-model-popup-bg {
  width: 680px;
  margin: 0 auto;
}

.popup-resume-content {
  width: 100%;
  height: 50%;
  margin: 50px auto 0;
  border-radius: 10px;
  border: 1px solid #EBEFFA;
  background: var(--color-white);
  box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.10);
  padding: 18px 0 0 0;
  position: relative;
}

.send-correct-resume {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-resmodle-file {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.res-upload-heading {
  font-size: 22px;
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  line-height: 20px;
  text-transform: capitalize;
  padding: 12px 0;
  text-align: center;
  border-bottom: 1px solid #D0D5DD;
}

.sub-text-upload-res {
  font-family: var(--arial-main);
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 0px 0 20px 0;
}


p.sub-text-upload-res {
  color: var(--primary-color);
  text-align: center;
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 20px 0;
  border-top: 1px solid #d0d5dd;
}

p.sub-text-upload-res span {
  font-weight: 600;
}

.user-input-resumefile {
  border-radius: 10px;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  min-height: 284px;
}

.user-input-resumefile-box {
  border-radius: 10px;
  border: 1px dashed #B8B8B8;
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.resmfile-img-data {
  text-align: center;
}

.resmfile-img-data input {
  display: none;
}

.user-input-resumefile-box img {
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

.user-input-resumefile input {
  display: none;
}

label#resumefile-upload-model img {
  cursor: pointer;
}

label#resumefile-upload-model {
  text-align: center;
}

p.candidate-file-res-upload {
  font-family: var(--arial-main);
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 24px 0 0px 0;
}

p.candidate-file-res-subtext-upload {
  font-family: var(--arial-main);
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 12px 0 10px 0;
}

p.candidate-file-res-suberror {
  font-family: var(--arial-main);
  color: #e15c52;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 12px 0 10px 0;
}

.hide-text-res {
  display: none;
}

button.slt-file-res {
  padding: 12px 78px;
  border-radius: 5px;
  border: 1px solid rgba(20, 57, 117, 0.7);
  background: var(--color-white);
  font-family: var(--arial-main);
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}