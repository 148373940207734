.title {
  font-weight: 600;
  font-size: 28px;
  font-family: var(--source-pro);
  line-height: 35px;
}

.bg-azure {
  background-color: rgba(0, 159, 181, 0.03);
}

.mb-6 {
  margin-bottom: 10rem;
}

.link {
  color: rgba(20, 57, 117, 1);
}

.pointer {
  cursor: pointer;
}

.form-check-input {
  --bs-form-check-bg: white;
}

.slider {
  width: 44px;
  height: 22px;
}

.slider:before {
  height: 18px;
  width: 18px;
  background-color: #e15c52;
}

.modal-btn {
  width: 145px !important;
  height: 44px;
}

input:checked + .slider:before {
  background-color: #07944f;
  transform: translateX(20px) translateY(-50%);
}

/****** my resume model css****/

.btn-my-resume-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 0px;
}

.my-resume-btn-create button {
  background: transparent;
  color: var(--dark-black-color);
  border: 2px solid var(--dark-black-color);
}

.btn-my-resume {
  min-width: 170px;
}

.btn-my-resume button {
  border-radius: 8px;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.my-resume-list {
  padding: 100px 0;
}

.rr.post-url a,
.rr.post-url a:hover {
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #143975;
  text-decoration-line: underline;
}

.listing-resume .listing-resume-body,
.listing-resume .listing-resume-header {
  border-top: 1px solid rgb(43 43 43 / 20%);
  display: flex;
  justify-content: space-between;
}

.listing-resume .listing-resume-header {
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
}

.listing-resume .listing-resume-body {
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
}

.listing-resume .rr {
  padding: 14px 2px;
  text-align: center;
}

.listing-resume-header .post-url,
.listing-resume-header .res-status {
  text-align: left;
}

.listing-resume-header .res-msg {
  text-align: center;
}

.cv-cions-res {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.cv-cions-res img {
  cursor: pointer;
  object-fit: contain;
  width: 25px;
}

.cv-cions-res .icon-disable {
  opacity: 0.5;
}

/******new reume cards*****/

.my-resume-cards-btn {
  padding: 25px 0;
  border-bottom: 1px solid #2b2b2b;
}

.my-resume-cards-sec {
  padding: 50px 0 50px 0;
}

.two-crate-upload-res {
  display: flex;
  justify-content: end;
  gap: 0px 20px;
}

button.resume-cv-btn-crt {
  width: 170;
  border-radius: 8px;
  border-width: 1px;
  padding: 11px 24px;
  font-size: 16px;
  font-weight: 700;
  color: var(--dark-black-color);
  background-color: var(--color-white);
  font-family: var(--arial-main);
}

.pdf-word-icon {
  display: flex;
  justify-content: end;
  gap: 0 10px;
}

.icon-disable {
  opacity: 0.5;
}

.pdf-icon-opcity {
  opacity: 0.5;
}

.res-cv-cards-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 20px;
}

.cards-child-res {
  width: 360px;
  background: #fff;
  border: 0.91px solid #c2c2c2;
  padding: 10px;
  border-radius: 14px;
  position: relative;
}

.cv-title-res {
  position: relative;
  z-index: 1;
}

/* Icon Area */

.Status-right-link {
  width: 50%;
}

.cv-icon-res {
  position: absolute;
  top: -40px;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 2;
}

p.resume-name-card {
  font-size: 16px;
  font-weight: 700;
  color: var(--dark-black-color);
  text-align: left;
  line-height: 26px;
  font-family: var(--arial-main);
  margin: 4px 0;
}

p.res-temple-card {
  font-size: 12px;
  font-weight: 400;
  color: var(--dark-black-color);
  text-align: left;
  opacity: 0.6;
  line-height: 18px;
  font-family: var(--arial-main);
  margin: 5px 0;
}

.line-divider-card {
  border-top: 1px solid var(--dark-black-color);
  margin: 7px 0;
}

.defult-res-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.set-res-text {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-black-color);
  text-align: left;
  line-height: 18px;
  font-family: var(--arial-main);
}

.list-res-status {
  display: flex;
  padding-bottom: 8px;
  justify-content: space-between;
}

.Status-left {
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-black-color);
  text-align: left;
  line-height: 18px;
  width: 50%;
  font-family: var(--arial-main);
}

.Status-right-msg,
.Status-right-fee {
  font-size: 12px;
  font-weight: 400;
  color: var(--dark-black-color);
  text-align: left;
  line-height: 13px;
  width: 50%;
  font-family: var(--arial-main);
}

.Status-right-link a {
  font-size: 12px;
  font-weight: 400;
  color: #143975;
  text-align: left;
  line-height: 13px;
  font-family: var(--arial-main);
}

.Status-right {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  line-height: 13px;
  font-family: var(--arial-main);
  width: 50%;
}

.Buy-now-res a {
  color: #009fb5;
}

.Purchased-res {
  color: #0ab84d;
  width: 50%;
}

.draft-res {
  color: #ffa033;
  width: 50%;
}

.Error-res {
  color: #e15c52;
  width: 50%;
}

.eddit-del-btn-res {
  display: flex;
  justify-content: end;
  padding: 10px 0;
  gap: 0 10px;
}

button.resume-cv-btn-del {
  border: 0.91px solid #2b2b2b;
  color: var(--dark-black-color);
  border-width: 0.91px;
  border-radius: 7.31px;
  padding: 3px 18.27px;
  background: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  width: 75px;
  font-family: var(--arial-main);
}

button.resume-cv-btn-edit {
  border: 0.91px solid #2b2b2b;
  color: var(--color-white);
  border-width: 0.91px;
  width: 75px;
  border-radius: 7.31px;
  padding: 3px 18.27px;
  background: var(--dark-black-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  font-family: var(--arial-main);
}

.date-create-res {
  display: flex;
  gap: 0 3px;
  justify-content: end;
  font-size: 10px;
  font-weight: 400;
  color: var(--dark-black-color);
  line-height: 18px;
  font-family: var(--arial-main);
}

.date-create-res p {
  font-size: 10px;
  font-weight: 400;
  color: var(--dark-black-color);
  line-height: 18px;
  font-family: var(--arial-main);
}

@media (min-width: 767px) {
  /******* resume model list****/
  .listing-resume .file-name {
    flex: 0 0 300px;
    max-width: 300px;
    text-align: left;
    word-break: break-word;
  }

  .listing-resume .post-url {
    flex: 0 0 200px;
    max-width: 200px;
  }

  .listing-resume .res-status {
    flex: 0 0 105px;
    max-width: 105px;
  }

  .listing-resume .res-msg {
    flex: 0 0 200px;
    max-width: 200px;
  }

  .listing-resume .res-date {
    flex: 0 0 105px;
    max-width: 105px;
  }

  .listing-resume .pno-defult {
    flex: 0 0 60px;
    max-width: 60px;
  }

  .listing-resume .action-res {
    flex: 0 0 110px;
    max-width: 110px;
  }
}

@media only screen and (max-width: 767px) {
  /***my resume mobile*****/
  .btn-my-resume-container {
    flex-direction: column;
  }

  .btn-my-resume {
    width: 100%;
    min-width: unset;
  }

  .listing-resume .listing-resume-mb {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .listing-resume .listing-resume-header {
    display: none;
  }

  .listing-resume .listing-resume-body {
    border: 0;
    border-radius: 12px;
    box-shadow: 0 1px 12px 4px #0000000f;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    position: relative;
  }

  .listing-resume .rr {
    padding: 0;
    text-align: end;
    width: 100%;
  }

  .rr.file-name {
    word-wrap: break-word;
    width: 80%;
  }

  .rr.res-date {
    display: none;
  }

  .listing-resume .file-name {
    color: var(--dark-black-color);
    font-size: 20px;
    font-weight: 700;
    text-align: left;
  }

  .listing-resume .listing-resume-body {
    align-items: unset;
    padding-bottom: 0;
  }

  .listing-resume .action-res {
    position: absolute;
    right: 12px;
    top: 12px;
    width: auto;
  }

  strong.d-md-none.mr-1.post-w-url {
    min-width: 102px;
  }

  .listing-resume .action-res .btn {
    padding: 0;
  }

  p.date-sapce-res {
    padding-top: 15px;
    font-size: 12px;
  }

  .listing-resume .action-res .cv-cions-res {
    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: 0 1px 12px 4px #0000000f;
    flex-direction: column;
    gap: 0;
    opacity: 0;
    padding: 12px 8px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.25s ease-in;
    width: 240px;
    z-index: -9;
  }

  .listing-resume .action-res .cv-cions-res > * {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    gap: 0;
    justify-content: space-between;
    padding: 6px 10px;
    width: 100%;
  }

  .listing-resume .action-res .cv-cions-res .img-table-tooltip .tooltip-table {
    background: #0000;
    border: 0;
    font-size: 18px;
    left: 0;
    opacity: 1;
    padding: 0 0 0 8px;
    position: relative;
    top: 0;
  }

  .listing-resume .action-res .cv-cions-res.active {
    opacity: 1;
    top: 100%;
    z-index: 1;
  }
  /*********new my reume card**/
  .res-cv-cards-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px 0px;
  }
  .cards-child-res {
    width: 356px;
  }
}
