/***************** candidate login page start here   ***************/

.candidate-profile img {
  max-width: 100%;
  height: auto;
}

.candidate-profile-login-f img {
  max-width: 100%;
  width: 394px;
  height: 328px;
}

.login-f-img img {
  max-width: 100%;
  width: 474px;
  height: 511px;
  object-fit: cover;
}

.candidate-login-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 100px;
  gap: 0 30px;
  justify-content: center;
}

.candidate-login-row-password {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 100px 0 100px 0;
  gap: 0 30px;
  justify-content: center;
}

.candidate-profile {
  max-width: 50%;
  width: 100%;
}

.candiate-login-form {
  max-width: 47%;
  width: 100%;
}

.candidate-signin {
  background: var(--color-white);
  border-radius: 24px;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 40px 60px 40px 60px;
  border: 1px solid var(--dark-black-color-200);
  box-shadow: 0px 1px 23px 1px rgba(0, 0, 0, 0.12);
}

.candidate-signin h2 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: var(--font-weight-bold);
  font-size: 28px;
}

p.heading-below-text {
  color: var(--error-msg-color);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  text-align: center;
  clear: both;
}

.heading-below-p {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}

.candidate-username input[type="text"] {
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.candidate-username {
  margin-bottom: 15px;
}

input#rememberme {
  margin-top: 10px;
}

p.forget-password {
  float: right;
  clear: both;
  margin-top: 6px;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: 14px;
}

p.forget-password a {
  color: var(--primary-color);
  text-decoration: none;
}

.candidate-from-submit input[type="submit"],
button.password-btn {
  width: 100%;
  background: var(--dark-black-color);
  border: 0;
  border-radius: 8px;
  padding: 12px 0;
  color: var(--color-white);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}

.candidate-from-signup input[type="submit"] {
  width: 100%;
  background: var(--dark-black-color);
  border: 0;
  border-radius: 8px;
  padding: 6px 0;
  color: var(--color-white);
  font-family: var(--arial-main);
  font-size: var(--text-size);
  margin-top: 15px;
}

.domain-url-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  font-family: var(--arial-main);
  font-size: 16px;
  color: var(--primary-color);

}

/**** css for form option fields *****/

.signup-here {
  padding: 15px 0px;
}

.signup-here p {
  text-align: center;
  color: var(--dark-black-color);
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.signup-here p span {
  color: var(--pacific-blue-color);
  font-weight: var(--font-weight-bold);
}

.signup-here a {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: var(--pacific-blue-color);
}

.or-divider {
  display: flex;
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.text-or {
  text-align: center;
  color: var(--dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.divider-one {
  max-width: 40%;
  width: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}

.candidate-login-google {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 15px 0;
  margin-top: 15px;
}

.candidate-login-google a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-decoration: none;
}

.candidate-login-google img {
  max-width: 100%;
  height: 25px;
  width: 25px;
}

.candidate-login-google a p {
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  color: rgb(0 0 0 / 30%);
}