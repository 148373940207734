.container-prop{
    margin-top: 16px;
gap: 0px;
border-radius: 16px;
border: 1px solid black;
}

.text-filter-admin {
 position: relative;
}

.selected-ques {
    background: #D9E2DD;
}

.selected-ques .q-head {
    background: transparent;
}

.question-claim {
    max-width: 830px;
    margin: 0 auto;
}

.text-justify {
    text-align: justify;
}
.question-claim img {
    max-width: 40px;
}

.text-filter-admin .s-img {
    position: absolute;
    right: 10px;
    min-width: 20px;
    min-height: 20px;
   }

.container-prop > h1{
    font-family: Arial;
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    padding: 30px 0 15px 26px;

}

.radio-container{
    padding: 5px 20px;
    margin-bottom: 10px;
}

.q-a{
    padding: 5px 20px;
}

.single-qa {
    border-radius: 12px;
    box-shadow: 0px 2px 2.5px 0px #2B2B2B33;
    /* overflow: hidden; */
}

.q-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #009FB51F;
    border-radius: 5px 5px 0 0;
    padding: 0 30px;
    gap: 15px;
}

.q-head .form-check-input:not(:checked) {
    background: transparent;
}
.q-head .form-check-input{
    width: 17px !important;
    height: 17px !important;
    border-color: #B4B4B4;
}
.q-head label{
font-size: 14px;
font-weight: 700;
line-height: 16.1px;
padding: 14px 0;
}

.chk{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
}

.chk > label {
    font-size: 14px;
font-weight: 700;
line-height: 16.1px;
}

.qa-body{
    padding: 12px 30px 10px 30px;
    gap: 10px;
    border: 1px;
    /* background-color: #fff; */
}

.qa-body.with-ans {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.qa-body .form-control {
    background: rgba(221, 222, 238, .25);
}
.qa-body textarea.form-control {
    height: 100px;
}

.qa-body .form-check:not(:last-child) {
    margin-bottom: 10px;
}
.qa-body .form-check-input {
    background: transparent;
    width: 12px !important;
    height: 12px !important;
}

.cat-tile {
    font-size: 18px;
    margin: 0 0 20px;
}

.chk input[type='checkbox'] {
    accent-color: #000;
}

.q-head input[type='checkbox'] {
    background-color: #2b2b2b;
}

.q-head input[type='checkbox']:checked {
    --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e);
}

