/********admin permission model*********/

.popup-admin-portal {
  width: 370px;
  margin: 0 auto;
  padding: 0 10px;
}

.logo-site a img {
  width: 100%;
  max-width: 100%;
}

p.text-admin-two {
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.admin-logo-model {
  padding: 40px 0 0 0;
  text-align: center;
}

.admin-logo-model img {
  max-width: 100%;
  height: 100px;
  width: 100px;
  margin: 0 auto;
}


/********view user css *****/

.size-th-user-role {
  width: 197px;
}

.size-th-user-depart {
  width: 150px;
}

.size-th-user-status {
  width: 100px;
}

.popup-content-admin {
  width: 100%;
  height: 450px;
  margin: 50px auto 0;
  position: relative;
}

.uper-part-admin {
  background: var(--color-white);
  padding: 20px 30px 40px;
  border-radius: 30px 30px 0px 0px;
}

.lower-part-admin {
  padding: 45px 30px;
  border-radius: 0px 0px 30px 30px;
  background: #FBECD6;
}

.admin-logo-site {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0;
}

.admin-logo-site img {
  max-width: 100%;
  width: 140px;
  margin: 0 auto;
}

.close-img-admin {
  position: absolute;
  right: 22px;
  top: 13px;
  cursor: pointer;
}

p.text-con-model {
  text-align: center;
  font-size: 32px;
  color: var(--accent-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

p.text-confrim-modl {
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  color: var(--text-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}