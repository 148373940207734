/******New Job Opportunities - Mobile *******/

.job-card {
  width: 100%;
  height: auto;
  box-shadow: 0px 1px 12px 4px #0000000F;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 12px;
  background: white;
  padding: 22px 12px;
}

.job-card .job-deesc p {
  font-size: 16px;
  font-family: var(--arial-main);
  line-height: 28px;
  color: var(--dark-black-color-500);
}

.card-header-custom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.job-name {
  display: flex;
  color: var(--dark-black-color);
}

.job-name-title {
  font-size: 18px;
  font-weight: 600;
}

.job-name .promoted-job-btn {
  color: #FFA033;
  background: #fdf1e4;
  width: 77px;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid #fdf1e4;
  border-radius: 4px;
  margin-top: -5px;
  margin-left: 11px;

}

.wish-list-mbl {
  width: 32px;
  height: 32px;
  margin-top: -5px;
}

.card-center {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.card-center-left {
  display: flex;
  flex-direction: row;
}

.job-company {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

}

.company-name {
  font-size: 16px;
  color: black;
}

.location-head {
  font-size: 14px;
}

.location-job-tabl {
  display: flex;
  align-items: center;
  gap: 5px;
}

.location-job-tabl img {
  height: 18px;
  width: 18px;
  object-fit: cover;
  max-width: 100%;
}

.location-job-tabl p {
  color: var(--text-color);
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

.card-center-right {
  text-align: end;
  margin-top: 3px;
}

.card-center-right h3 {
  font-size: 12px;
  color: black;
}

.salary-head {
  margin-bottom: 8px;
}

.job-stats-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
}

.view-job-btn {
  width: 100%;
  background: transparent;
  border: 2px solid var(--dark-black-color);
  color: var(--dark-black-color);
  padding: 8px 18px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  border-radius: 8px;
}

.purple-tags {
  /* display: flex;
  flex-direction: row; */
  /* background: ; */
  display: flex;
  flex-direction: row;
  flex: none;
  width: max-content;
  padding: 6px 7px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 14px;
  border: 0;
  background-color: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: 500;
  font-size: 14px;
}

.apply-btn {
  width: 100%;
  height: 42px;
  background: var(--dark-black-color);
  color: white;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.applied-btn {
  width: 100%;
  height: 42px;
  background: white;
  color: var(--dark-black-color);
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid var(--dark-black-color);
}

.apply-head {
  font-size: 14px;
  font-weight: var(--font-weight-medium);
}

.apply-icon {
  margin-right: 5px;
  margin-top: 1px;
}

.tags-section {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.mbl-tag {
  font-size: 12px !important;
}

.date-end {
  margin-left: auto;
  font-size: 12px;
  margin-top: 10px;
}

.company-logo {
  border-radius: 50%;
}

.wish-cirle {
  width: 34px;
  border: 0.3px solid #64A7B6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  border-radius: 50%;
  padding-top: 3px;
  margin-top: -5px;
  top: 20px;
  right: 20px;
}