/***************** verify login OTP page start here   ***************/

.sub-heading-otp {
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  text-align: center;
}

.time-count-otp {
  color: var(--pacific-blue-color);
  font-weight: var(--font-weight-bold);
}

.cantainer-main-form-otp {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .cantainer-main-form-otp {
    flex-wrap: wrap; 
    gap: 5px; 
    padding: 0 10px;
    width: 100%; 
    box-sizing: border-box; 
    gap: 3px; 
  }
}
