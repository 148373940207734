.generating-decs {
    color: var(--dark-black-color);
    font-family: var(--arial-main);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;

}

.modal-content-info .modal-body h4 {
    font-size: 20px;
    margin: 0;
    background: url('../../../../public/image/zoe-bg.png') no-repeat;
    background-position: left -35px center;
    padding: 30px 0px 16px;
}

.modal-content-info .modal-body p.job-decs-sub-heading {
    font-size: 24px;
    font-family: var(--source-pro);
    font-weight: 600;
}

.modal-content-info .modal-body .generating-decs p {
    color: inherit;
}

.zeo-btn {
    display: flex;
    justify-content: center;
    max-width: 60%;
    width: 100%;
    gap: 20px;
}

.zeo-btn .btn {
    width: calc(50% - 10px);
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
}

.zeo-img-size {
    width: 200px;
}

@media(max-width: 767px) {
    .generating-decs {
        font-size: 18px;
    }

    .modal-content-info .modal-body p.job-decs-sub-heading {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 12px !important;
    }
}

.progress-bar-striped,
.progress {
    border-radius: 12px !important;
}

img.animation-img-m {
    margin: 0 auto;
    display: block;
    height: 55px;
    width: 55px;
}

p.progressbar-text {
    color: #0AB84D;
    padding: 5px 0;
    text-align: center;
    font-size: 12px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
}

p.zeo-gen-text {
    color: var(--primary-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
    text-align: center;
}

p.zeo-gen-text span {
    color: var(--accent-color);
    font-size: 20px;
    font-family: var(--arial-main);
}

img.close-m-gen {
    position: absolute;
    right: 20px;
    cursor: pointer;
}

.popup-content-zoe-job-gen {
    width: 100%;
    margin: 50px auto 0;
    background-image: url(/public/image/job-gen-bg.png);
    padding: 20px 40px 100px 40px;
    position: relative;
    border-radius: 30px;
    background-size: cover;
    background-repeat: no-repeat;
}

.col-job-gencard {
    border-radius: 16px;
    border: 1px solid rgba(20, 57, 117, 0.20);
    padding: 20px;
    max-width: 100%;
    width: 365px;
    margin: 55px 0 50px 0;
    position: relative;
}