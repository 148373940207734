/****************candidate profile model ****************/

.model-candidate-resume {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 55%);
  top: 0%;
  overflow-y: auto;
  z-index: 2;
}

.hide-model-popup-profile {
  display: none;
}

.popup-bg {
  width: 90%;
  margin: 0 auto;
}

.popup-candidate-resume {
  width: 100%;
  height: 85vh;
  margin: 100px auto 0;
  padding: 40px 0px;
  position: relative;
  background: var(--color-white);
  border: 2px solid #bdbdbd;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
}

.close-img-resume {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}

.model-logo {
  text-align: center;
  position: relative;
  top: -12px;
}

.candidate-name-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 30px 0;
  border-bottom: 1px solid var(--boder-color);
  position: relative;
}

.resume-data-profile {
  overflow: auto;
  padding: 0 40px;
  height: 100%;
}

/* width */

.resume-data-profile::-webkit-scrollbar {
  width: 15px;
}

/* Track */

.resume-data-profile::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.resume-data-profile::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
}

.resume-heading {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 150px;
  text-align: center;
}

.resume-heading h1 {
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.download-print-btn {
  max-width: 25%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  justify-content: end;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.download-print-btn button {
  background: transparent;
  border: 0;
  color: var(--primary-color);
  font-size: var(--body-text);
  line-height: 22px;
  font-weight: var(--font-weight-medium);
}

.resume-candidate-info {
  text-align: center;
  padding: 30px 0;
}

.resume-candidate-info img {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

.resume-candidate-info h5 {
  text-align: center;
  font-weight: var(--font-weight-bold);
  padding-top: 40px;
}

ul.candidate-social-list li {
  margin: 0px 30px;
}

ul.candidate-social-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 10px;
}

ul.candidate-social-list li {
  margin: 0 17px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: var(--text-color);
  opacity: 0.8;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: var(--promoted-btn);
}

ul.candidate-social-list p {
  font-size: var(--text-color);
  opacity: 0.8;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: var(--promoted-btn);
}

ul.candidate-social-list li:before {
  content: "";
  position: absolute;
  transform: translateY(0%);
  top: 50%;
  border-radius: 50%;
  left: 0;
  margin-top: -4px;
  width: 7px;
  margin-left: -20px;
  height: 7px;
  background: #666666;
}

ul.candidate-social-list li img {
  padding-right: 15px;
}

.resume-profile-accadmic {
  padding: 50px 0;
}

p.candiadte-resume-heading {
  color: var(--primary-color);
  font-size: var(--form-heading);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  line-height: 43px;
  padding-bottom: 15px;
}

.resume-content-candidate,
.resume-education-candidate {
  padding-bottom: 20px;
}

p.resum-content {
  color: var(--or-text-color);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 28px;
}

.university-year {
  display: flex;
  gap: 30px;
  color: var(--primary-color);
  font-size: var(--text-size);
  font-weight: var(--font-weight-medium);
}

p.resume-degree {
  color: var(--or-text-color);
  font-size: var(--text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 28px;
  padding-top: 10px;
}

.resume-skills-candidate ul {
  padding-left: 15px;
}

.resume-skills-candidate ul li {
  color: var(--or-text-color);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 28px;
}

/***********model with iframe**********/

.model-candidate-resume-iframe {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 55%);
  top: 0%;
  overflow-y: auto;
  z-index: 2;
}

.popup-candidate-resume-iframe {
  width: 100%;
  height: 85vh;
  margin: 75px auto 75px;
  padding: 0px 0px;
  position: relative;
  /* background: var(--color-white); */
  /* border: 2px solid #BDBDBD; */
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
}

.resume-data-profile-iframe {
  /* overflow: auto;
padding: 0 40px; */
  height: 100%;
}

.popup-bg-iframe {
  width: 66%;
  margin: 0 auto;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}


/* width */

.resume-data-profile::-webkit-scrollbar {
  width: 15px;
}


/* Track */

.resume-data-profile::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


/* Handle */

.resume-data-profile::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--primary-color);
}

.resume-heading {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 150px;
  text-align: center;
}

.resume-heading h1 {
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.download-print-btn {
  max-width: 25%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  justify-content: end;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.download-print-btn button {
  background: transparent;
  border: 0;
  color: var(--primary-color);
  font-size: var(--body-text);
  line-height: 22px;
  font-weight: var(--font-weight-medium);
}

.resume-candidate-info {
  text-align: center;
  padding: 30px 0;
}

.resume-candidate-info img {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

.resume-candidate-info h5 {
  text-align: center;
  font-weight: var(--font-weight-bold);
  padding-top: 40px;
}

ul.candidate-social-list li {
  margin: 0px 30px;
}

ul.candidate-social-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 10px;
}

ul.candidate-social-list li {
  margin: 0 17px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: var(--text-color);
  opacity: 0.8;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: var(--promoted-btn);
}

ul.candidate-social-list p {
  font-size: var(--text-color);
  opacity: 0.8;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: var(--promoted-btn);
}

ul.candidate-social-list li:before {
  content: "";
  position: absolute;
  transform: translateY(0%);
  top: 50%;
  border-radius: 50%;
  left: 0;
  margin-top: -4px;
  width: 7px;
  margin-left: -20px;
  height: 7px;
  background: #666666;
}

ul.candidate-social-list li img {
  padding-right: 15px;
}

.resume-profile-accadmic {
  padding: 50px 0;
}

p.candiadte-resume-heading {
  color: var(--primary-color);
  font-size: var(--form-heading);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  line-height: 43px;
  padding-bottom: 15px;
}

.resume-content-candidate,
.resume-education-candidate {
  padding-bottom: 20px;
}

p.resum-content {
  color: var(--or-text-color);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 28px;
}

.university-year {
  display: flex;
  gap: 30px;
  color: var(--primary-color);
  font-size: var(--text-size);
  font-weight: var(--font-weight-medium);
}

p.resume-degree {
  color: var(--or-text-color);
  font-size: var(--text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 28px;
  padding-top: 10px;
}

.resume-skills-candidate ul {
  padding-left: 15px;
}

.resume-skills-candidate ul li {
  color: var(--or-text-color);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 28px;
}