
.hiring-btn {
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}
.job-position {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 13px;
}

.job-position h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}

.drag-sec {
  width: calc(100% + 30px);
  padding-top: 16px;
  margin-top: 12px;
  border-top: 2px solid rgba(43, 43, 43, 0.2);
  height: calc(100vh - 174px);
  overflow-x: scroll;   
  overflow-y: hidden;  
  position: relative;
}

.drag-head {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  border-top: 12px solid #000;
  margin: 0 0 12px;
  min-width: 270px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.flex-start-center {
  display: flex;
  justify-content: start;
  align-items: center;
}
.flex between-center {
  display: flex;
  justify-content: between;
  align-items: center;
}

.drag-head h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.drag-head span {
  border: 1px solid #2B2B2B;
  border-radius: 8px;
  display: inline-block;
  padding: 6.25px 5.5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  min-width: 28px;
  text-align: center;
}

.drag-head.allCandidates {
  border-color: #2B2B2B;
}

.drag-head.preScreening {
  border-color: #009FB5;
}

.drag-head.firstInterview {
  border-color: #8CD0FD;
}

.drag-head.jobOffer {
  border-color: #8DE2BE;
}

.jafcQ {
  min-height: calc(100vh - 75px);
  /* overflow-x: hidden;
  overflow-y: hidden;
  padding: 15px 30px; */
}

.drag-body {
  height: 100vh;
  overflow: auto;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  background-color: #F0F0F0;
}

.drag-body::-webkit-scrollbar {
  width: 8px;
  background-color: #EEEEEE;
}

.drag-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(43, 43, 43, .24);
}

.drag-body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #EEEEEE;
}

.no-interview {
  background-color: #EEEEEE;
}

.no-interview>p {
  padding: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;

}