/*********create department *******/

.department-role-space {
  padding: 30px 145px 100px 40px;
}

ul.progressbar-admin {
  padding-left: 0;
  z-index: 1;
  position: relative;
}

.step-progressbar-admin {
  margin: 30px 0;
}

.progressbar-admin {
  counter-reset: step;
}

.progressbar-admin li {
  list-style: none;
  display: inline-block;
  width: 30.33%;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}

.progressbar-admin li:before {
  content: counter(step);
  counter-increment: step;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  font-size: 24px;
  line-height: 44px;
  background: #C7C7C7;
  color: var(--color-white);
  border: 4px solid var(--color-white);
  outline: 2px solid #C7C7C7;
}

.progressbar-admin li.active-circle:before {
  background: #C7C7C7;
  color: var(--color-white);
  border: 4px solid var(--color-white);
  outline: 2px solid #C7C7C7;
}

.progressbar-admin li.active-circle:after {
  background-color: var(--primary-color);
}

ul.progressbar-admin li {
  color: #BDBDBD;
}

.progressbar-admin li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 22px;
  left: -50%;
  z-index: -1;
}

.progressbar-admin li:first-child:after {
  content: none;
}

.progressbar-admin li.active {
  color: var(--dark-black-color);
}

.progressbar-admin li.active:before {
  border-color: var(--dark-black-color);
  background: var(--dark-black-color);
  color: var(--color-white);
  border: 4px solid var(--color-white);
  outline: 2px solid var(--dark-black-color);
}

.department-form-box {
  border-radius: 16px;
  /* border: 1px solid var(--dark-black-color); */
  background: var(--color-white);
  padding: 32px 40px 24px 40px;
  margin-top: 20px;
  box-shadow: 2px 2px 13.4px 1px #00000021;
}

.admin-from-step {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px 25px;
  flex-wrap: wrap;
}

.admin-from-step-pro {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px 25px;
  flex-wrap: wrap;
}

.form-heading h2 {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 24px;
}

input#dept-f {
  padding: 12px;
  border: 0px solid var(--boder-color);
  width: 100%;
  border-radius: 5px;
  background-color: rgba(221, 222, 238, 0.25) !important;
}

.feild-f-admin {
  width: 100%;
  max-width: 31.3%;
  position: relative;
}

.f-admin label {
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  margin-bottom: 10px;
}

.form-font-admin {
  color: rgb(0 0 0 / 100%);
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
}

.admin-form-btn {
  display: flex;
  gap: 16px;
  justify-content: end;
  padding-top: 25px;
}

button.cancel-admin {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  min-width: 140px;
  padding: 12px 10px;
  background: transparent;
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
}

button.next-admin {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  min-width: 140px;
  padding: 12px 10px;
  background: var(--dark-black-color);
  color: var(--color-white);
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
}


/*******create role ********/

.form-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.add-role-admin {
  min-width: 140px;
  padding: 12px 0;
  border-radius: 6px;
  background: #143975;
  color: var(--color-white);
  border: 0;
}

.feild-f-text-are {
  max-width: 100%;
  width: 100%;
}

textarea#text-admin {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  background: rgba(221, 222, 238, 0.25);
  border: 0;
}

.space-b-f {
  margin-bottom: 25px;
}

.admin-status-filter-form {
  /* padding: 5px 8px;
  border: 0.5px solid #BDBDBD; */
  width: 100%;
  /* max-width: 50px; */
  position: relative;
  border-radius: 5px;
  /* background: rgba(189, 189, 189,.25); */

  padding: 12px;
  border: 0px solid var(--dark-black-color-600);
  background: rgba(221, 222, 238, 0.25);
}

.make-dd-100 .admin-status-filter-form {
  max-width: 100%;
}

.admin-status-dropdown-frm {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  overflow: auto;
  z-index: 1;
  margin: 15px 0 0 auto;
  left: 0;
  display: none;
  max-height: 185px;
  height: auto;
}

.admin-status-filter-form.open .admin-status-dropdown-frm {
  display: block;
}

.admin-status-filter-form .admin-status-dropdown-frm {
  display: none;
}


/* width */

.admin-status-dropdown-frm::-webkit-scrollbar {
  width: 8px;
}


/* Track */

.admin-status-dropdown-frm::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


/* Handle */

.admin-status-dropdown-frm::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--dark-black-color);
  background-color: var(--dark-black-color);
}

.tags-list-user-c {
  max-width: 66%;
}

.tag-input-f {
  display: flex;
  gap: 25px 25px;
  width: 100%;
}

.tags-admin ul.tags-titles-list {
  list-style: none;
  display: flex;
  gap: 8px;
  margin: 0px 0 0px 0;
  padding: 0;
  flex-wrap: wrap;
  width: 100%;
}

.list-role-c {
  padding-top: 39px;
}


/* .tags-tittles ul.tags-titles-list li button {
  border: 0;
  background: transparent;
  font-size: 15px;
}

.tags-tittles ul.tags-titles-list li {
  background: var(--secondary-color);
  padding: 4px 8px;
  color: var(--primary-color);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

.tags-tittles ul.tags-titles-list li {
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;
} */

.tags-admin ul.tags-titles-list li img {
  cursor: pointer;
  height: 12px;
  width: 12px;
}

.tags-admin ul.tags-titles-list li {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 0.5px solid var(--dark-black-color);
  background: rgba(43, 43, 43, 0.07);
  padding: 4px 8px;
  color: var(--dark-black-color);
  font-size: 13px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}


/***user persmisson table ****/

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link {
  border: 0;
}

.nav-link:focus,
.nav-link:hover {
  color: rgba(0, 0, 0, 0.3);
}

a.nav-link {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

a.nav-link.active {
  border: 0;
  border-bottom: 2px solid var(--dark-black-color) !important;
  color: var(--dark-black-color) !important;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--dark-black-color);
}

thead.tb-heading-user-admin {
  border: 0;
  background: #F8F8F8;
}

thead.tb-heading-user-admin th {
  color: var(--text-color);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  padding: 20px 0px 20px 18px;
  text-align: left;
}

.user-permission-form-box {
  border-radius: 16px;
  border: 1px solid var(--dark-black-color);
  background: var(--color-white);
  padding: 30px 24px 24px 24px;
  margin-top: 25px;
}

tbody.tb-user-admin tr:nth-child(odd) {
  background-color: var(--Whisper-light-color);
}

tbody.tb-user-admin tr:nth-child(even) {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background: var(--color-white);
}

.name-sr-circle {
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  display: flex;
  align-items: center;
}

tbody.tb-user-admin td {
  padding: 15px 10px;
  text-align: center;
  color: rgb(0 0 0 / 100%);
  font-size: 16px;
  font-weight: 300;
  font-family: var(--arial-main);
  vertical-align: baseline;
}

.tik-crl img {
  width: 28px;
  height: 28px;
}

/******admin portal ********/

.first-part-f,
.light-part-f {
  padding: 0px 0 25px 0;
}

.first-part-f h3,
.light-part-f h3 {
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  color: var(--dark-black-color);
  font-size: 20px;
}

.first-part-f p {
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.light-part-f p {
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  color: rgba(43, 43, 43, 0.7);
  font-size: 16px;
}

.feild-f-admin-addres {
  width: 100%;
  max-width: 65%;
  position: relative;
}

.feild-f-admin-city {
  width: 100%;
  max-width: 31%;
  position: relative;
}

.feild-f-admin-pro {
  width: 100%;
  position: relative;
}

/************** admin contry dropdown css*******/

ul.list-admin-country {
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.list-admin-country li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 0px 10px;
  cursor: pointer;
  color: rgb(0 0 0 / 50%);
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
}

ul.list-admin-country li:hover {
  border-radius: 6px;
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}

ul.list-admin-country li img {
  padding: 0px 10px 0 0;
  position: relative;
  top: -2px;
  height: 14px;
  width: 30px;
  object-fit: contain;
}


/*****************/

ul.list-admin-portal {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

ul.list-admin-portal li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 0px 10px;
  cursor: pointer;
  color: rgb(0 0 0 / 50%);
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
}

ul.list-admin-portal li:hover {
  border-radius: 6px;
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}

ul.list-admin-portal li img {
  height: 18px;
  position: relative;
  top: 10px;
  padding: 0px 10px;
}


/*********svg icon **********/

ul.list-admin-portal-clr {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.list-admin-portal-clr li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 0px 10px;
  cursor: pointer;
  color: rgb(0 0 0 / 50%);
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
}

ul.list-admin-portal-clr li:hover {
  border-radius: 6px;
  background: #F2F7FF;
  color: var(--text-color);
  font-weight: var(--font-weight-medium);
}

ul.list-admin-portal-clr li:hover img.hover-s-list {
  display: block;
}

ul.list-admin-portal-clr li img.normal-s-list {
  height: 18px;
  position: relative;
  padding: 0px 0px 0 10px;
}

img.hover-s-list {
  display: none;
  height: 18px;
  position: relative;
  padding: 0px 0px 0 10px;
}

ul.list-admin-portal-clr li:hover img.normal-s-list {
  display: none;
}

ul.list-admin-portal-clr li:hover img.hover-s-list {
  display: block;
}

ul.list-admin-portal li img.normal-s-list {
  height: 18px;
  position: relative;
  top: 10px;
  padding: 0px 10px;
}

ul.list-admin-portal li:hover img.normal-s-list {
  display: none;
}

ul.list-admin-portal li:hover img.hover-s-list {
  display: block;
}

input#admin-chk {
  accent-color: var(--primary-color)
}


/*************/

img.hide-icon-admin {
  display: none;
}


/*******************/

input#admin-module {
  border: 0;
  background: #ff000000 !important;
  outline: 0;
  width: 100%;
  padding-right: 15px;
}

.custom-tag-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/*************User Credential********/

input#admin-pass-field {
  background: transparent;
  border: 0;
  width: 100%;
}

.password-admin-f {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img.pass-icon-hide {
  display: none;
}


/***********user permission******/

.user-heading-filter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.select-filter {
  display: flex;
  align-items: center;
}

.select-filter {
  display: flex;
  align-items: center;
  max-width: 21%;
  width: 100%;
  gap: 10px;
  position: relative;
}

.select-filter label {
  color: var(--text-color);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.hide-tick {
  display: none;
}



