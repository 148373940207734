/*************all list page tabel*********/

th.list-description {
  width: 400px;
}

.list-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.description-list-content {
  text-align: left; 
  padding-left: 20px;
}

/******** share list table ****/

.save-list-icon {
  display: none;
}