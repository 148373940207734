/*****banner start******/

.banner-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 90px 0px 25px;
  gap: 66px;
  position: relative;
}

.col-1-banner img,
.col-2-banner img,
.col-3-banner img {
  max-width: 100%;
}

.col-1-banner img,
.col-3-banner img {
  height: auto;
  max-height: 430px;
  object-fit: contain;
  position: relative;
  z-index: 1;
}

.ml-auto {
  margin-left: auto;
}

.text-right {
  text-align: right;
}

.col-1-banner,
.col-3-banner {
  max-width: 330px;
  width: 100%;
}

.col-2-banner {
  max-width: 100%;
  flex: auto;
  width: 100%;
}

.col-2-banner p.banner-text {
  color: rgb(0 0 0 / 40%);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  padding: 10px 0px 24px 0px;
  line-height: 24px;
  text-align: justify;
}

.button--postJob {
  width: 100%;
  cursor: pointer;

}

.button-stack button {
  display: block;
  text-align: center;
  margin: 0 auto;
  background: var(--accent-color);
  padding: 14px 30px;
  width: 100%;
  border: 0;
  border-radius: 8px;
  font-size: 18px;
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}


.button-stack-outlined button {
  display: block;
  text-align: center;
  margin: 0 auto;
  background: white;
  padding: 14px 30px;
  width: 100%;
  border: 0;
  border-radius: 8px;
  font-size: 18px;
  color: black;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  border: 1px solid black;
  cursor: pointer;
  border-color: #ff9800;
  color: orange;
}

.align-banner-btn {
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}





.col-2-banner h1 span {
  color: var(--accent-color);
}

.col-2-banner h1 {
  font-size: 44px;
  line-height: 44px;
  text-transform: uppercase;
  color: var(--primary-color);
  font-weight: 700;
  font-family: var(--fira-sans);
}

img.vector {
  width: 100%;
  height: 100%;
}

.button-stack a {
  text-decoration: none;
}

.button-stack-outlined a {
  text-decoration: none;
}

.arrow-picture {
  position: absolute;
  right: 225px;
  bottom: 0px;
  width: 192px;
  transform: rotate(25deg);
  z-index: 0;
}

.post-job-sec {
  display: none;
}

.toggle-mobile-banner {
  display: none;
}

.toggle-desktop-banner {
  display: block;
}

.banner-sub-text {
  display: none;
}

.toggleDesktopText {
  display: block;
}

/*****banner start polycare******/

section.home-benner-sec {
  position: relative;
}

.banner-row-poly {
  padding: 100px 0 50px;
  max-width: 800px;
  margin: 0 auto;
}

.banner-heading-poly h2 {
  color: var(--dark-black-color);
  text-align: center;
  font-size: 70px;
  font-family: var(--source-pro);
  font-weight: 700;
  position: relative;
}

.banner-heading-poly h2 span {
  position: relative;
}

.banner-heading-poly h2 span:before {
  background-image: url(/public/image/underline-img.png);
  content: "";
  position: absolute;
  width: 100%;
  height: 22%;
  bottom: -3px;
  right: 3px;
  background-repeat: no-repeat;
  z-index: -1;
  background-size: contain;
  background-position: bottom;
}

.two-btn-poly {
  text-align: center;
  padding: 50px 0 45px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

button.find-job-poly {
  padding: 20px 60px;
  border: 0;
  color: var(--color-white);
  background-color: var(--pacific-blue-color);
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
  font-family: var(--arial-main);
  border-radius: 35px;
}

button.post-job-poly {
  padding: 20px 60px;
  border: 2.25px solid var(--pacific-blue-color);
  color: var(--pacific-blue-color);
  background-color: transparent;
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
  font-family: var(--arial-main);
  border-radius: 35px;
}

button.post-job-poly:hover {
  background-color: var(--pacific-blue-color);
  color: var(--color-white);
}

.banner-img img {
  max-width: 100%;
}

.feed-back-btn {
  background: rgba(90, 172, 153, 1);
  color: var(--color-white);
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: var(--arial-main);
  width: auto;
  position: absolute;
  right: -52px;
  top: 40%;
  transform: rotate(-90deg);
  border: 0;
  padding: 10px 40px;
}

@media only screen and (max-width: 767px) {
  .button-stack button {
    display: block;
    text-align: center;
    margin: 0 auto;
    background: var(--accent-color);
    padding: 25px 0 0px 0;
    width: 70%;
    border: 0;
    border-radius: 8px;
    font-size: 18px;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-family: var(--arial-main);
  }


  .button-stack-outlined button {
    display: block;
    text-align: center;
    margin: 0 auto;
    background: white;
    padding: 10px 30px;
    width: 80%;
    border: 0;
    border-radius: 8px;
    font-size: 18px;
    color: black;
    font-weight: var(--font-weight-bold);
    font-family: var(--arial-main);
    border: 1px solid black;
    cursor: pointer;
    border-color: #ff9800;
    color: orange;
  }

  .banner-sub-text {
    display: flex;
    width: 100%;
    display: flex;
    margin-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    font-family: Museo Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    opacity: 0.4;
  }



  .button-stack-mbl {
    display: none;
  }

  .button-stack-desktop {
    display: block;
  }

  .banner-text-mbl {
    margin-top: 25px;
    margin-bottom: 20px;
    display: none;
  }

  .col-2-banner h1 {
    font-size: 28px !important;
    line-height: 40px;
  }



  .post-job-sec {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .button--postJob {
    width: 350px;
    cursor: pointer;
  }

  .postIcon {
    width: 10px;
    height: 10px;
    margin-top: -3px;
    margin-right: 10px;
  }

  .toggle-mobile-banner {
    display: block;
  }

  .banner-starting-text-color {
    color: #143975;
  }

  .toggle-desktop-banner {
    display: none;
  }

  .toggleDesktopText {
    display: none;
  }

  .button-stack-desktop {
    display: block;
  }
  
  .feed-back-btn {
    top: 50%;
    right: -36px;
        padding: 10px 22px;
  }
}