/**************** all promotion pages stat here ************/

.form-heading-prom {
  display: flex;
  align-items: center;
  gap: 20px;
}

.form-heading-prom img {
  cursor: pointer;
}

.form-heading-prom h2 {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 22px;
  margin-bottom: 0;
}

.form-labeal-heading-admin h2 {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 22px;
  padding-bottom: 15px;
  margin-bottom: 0;
}
.admin-frm-avtar img {
  max-width: 100%;
}
.img-btn-adminform {
  display: flex;
  justify-content: flex-end;
  align-items: end;
}
.form-text-start {
  font-family: var(--arial-main);
  padding: 10px 10px 0 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000;
}

.form-heading-sub-prom {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-heading-sub-prom1 {
  display: flex;
  gap: 20px;
}

.admin-status-filter.open .promotion-status-dropdown {
  display: block;
}

.admin-status-filter .promotion-status-dropdown {
  display: none;
}

.promotion-status-dropdown {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 240px;
  overflow: auto;
  z-index: 1;
  margin: 5px 0 0 auto;
  left: 0;
  top: 100%;
  max-height: 185px;
  height: auto;
}

/* width */

.promotion-status-dropdown::-webkit-scrollbar {
  width: 8px;
}

/* Track */

.promotion-status-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.promotion-status-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--dark-black-color);
  background-color: var(--dark-black-color);
}

.th-size-one {
  width: 132px;
}

.th-size-two {
  width: 133px;
}

.th-size-three {
  width: 103px;
}

.th-size-four {
  width: 120px;
}

.th-size-five {
  width: 122px;
}

.th-size-seven {
  width: 120px;
}

.th-size-postion {
  width: 150px;
}

.clr-chnage-text {
  color: #e15c52 !important;
}

/*******admin all promotion***********/

.th-size-start {
  width: 153px;
}

.th-size-status {
  width: 77px;
}

.th-size-date {
  width: 100px;
}

input#company-promotion {
  margin-right: 5px;
  accent-color: var(--dark-black-color);
}

.th-size-status-req {
  width: 133px;
}

.th-size-date-req {
  width: 122px;
}

.promotion-table-button {
  display: flex;
  padding: 0px 0px 20px 0;
  justify-content: end;
  gap: 18px;
}

button.reject-prom-btn {
  min-width: 122px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  background: var(--color-white);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}

button.approve-prom-btn {
  min-width: 122px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--color-white);
  background: var(--dark-black-color);
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}