.candidate-search-dropdown-year-job {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  overflow: auto;
  z-index: 2;
  margin: 5px 0 0 auto;
  display: none;
}

.input-withicon {
  position: relative;
}

.input-withicon .icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.input-withicon .icon img {
  width: 25px;
}

.max-w-100 {
  max-width: 100% !important;
}

.cand-form #fsalary-country {
  padding: 15.5px 5px;
  height: 52px;
}

.cand-form input#salary-start-range {
  line-height: 11px;
  padding: 10px 8px;
}

.company-form-dropdownBox-f {
  width: 100%;
  cursor: pointer;
  color: var(--dark-black-color);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 400;
  justify-content: space-between;
}

.Year.open .candidate-search-dropdown-year-job {
  display: block;
}

ul.candidate-job-title-year-job {
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.candidate-job-title-year-job li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 0px 10px 0 2px;
  cursor: pointer;
  color: rgb(0 0 0 / 50%);
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
}

ul.candidate-job-title-year-job li:hover {
  border-radius: 6px;
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);  
}

.candidate-form-dropdownBox-f {
  width: 100%;
  cursor: pointer;
  color: rgb(0 0 0 / 100%);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 400;
  justify-content: space-between;
}