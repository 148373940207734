/**********promotion slot*****/

.pormotion-form-box {
  border-radius: 16px;
  border: 1px solid var(--dark-black-color);
  background: #FFF;
  padding: 32px 24px 24px 24px;
  margin-top: 20px;
}

.admin-form-text {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  padding-top: 25px;
}

.slots-form-boxes-list {
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  margin-left: -24px;
  margin-right: -24px;
  padding: 10px 24px;
  position: relative;
}

.left-right-boxes-admin {
  display: flex;
  justify-content: space-between;
  margin: 25px 0 32px 0;
  padding: 30px;
  background: #FAFDFF;
  border: 1px solid #2B2B2B33;
  border-radius: 16px;
  flex-wrap: wrap;
}

.left-boxes-cards {
  gap: 16px;
  max-width: 50%;
  width: 100%;
  flex: 1;
  align-self: flex-start;
}

.left-boxes-cards,
.right-box-lists {
  display: flex;
}

.right-box-lists {
  max-width: 48%;
  width: 100%;
}

.left-box-card-content {
  border-radius: 12px;
  border: 1px solid #2B2B2B33;
  background: #ffffff;
  /* box-shadow: 0px 0px 16px 0px rgba(55, 91, 150, 0.12); */
  padding: 15px 0px 15px;
  width: 100%;
}

.second-row-l-c {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F2F4F5;
  padding: 10px 15px;
  border: 1px solid #2B2B2B33
}

.vertial-line{
  border-right: 1px solid rgba(43, 43, 43, 0.2);
  height: 500px;
}
.pr-20 {
  padding-right: 20px;
}

/* .logo-postion-promotion {
display: flex;
align-items: center;
gap: 15px;
} */


/* p.logo-card {
border-radius: 70px;
border: 0.5px solid rgba(0, 0, 0, 0.16);
background: #FFF;
color: rgb(0 0 0 / 40%);
font-size: 12px;
font-weight: var(--font-weight-bold);
font-family: var(--arial-main);
height: 50px;
width: 50px;
padding: 0 5px;
display: flex;
align-items: center;
justify-content: center;
} */

p.job-postion-h {
  color: rgb(0 0 0 / 100%);
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  padding: 0 15px 15px;
}

p.slot-left-text {
  color: rgb(43 43 43 / 70%);
  font-size: 14px;
  font-weight: 400;
  font-family: var(--arial-main);
}

p.slot-left-text-right {
  color: rgb(43 43 43 / 70%);
  font-size: 14px;
  font-weight: 400;
  font-family: var(--arial-main);
  min-width: 75px;
}

p.slot-left-text-job {
  color: rgb(43 43 43 / 100%);
  font-size: 14px;
  font-weight: 400;
  font-family: var(--arial-main);
}

p.slot-left-text-salary {
  color: rgb(43 43 43 / 100%);
  font-size: 14px;
  font-weight: 400;
  font-family: var(--arial-main);
  min-width: 75px;
}

.third-row-l-c {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0 15px;
  align-items: center;
}

.three-img-text {
  display: flex;
  align-items: center;
  gap: 10px;
}


/* p.job-no-cir {
border-radius: 70px;
background: #FFF;
color: rgb(0 0 0 / 100%);
font-size: 10px;
font-weight: var(--font-weight-bold);
font-family: var(--arial-main);
height: 50px;
width: 50px;
padding: 0 3px;
display: flex;
align-items: center;
justify-content: center;
}

p.c-name-card {
color: rgb(0 0 0 / 100%);
padding: 7px 0;
font-size: 16px;
font-weight: var(--font-weight-bold);
font-family: var(--arial-main);
}

p.c-content-c {
color: rgb(0 0 0 / 100%);
padding-bottom: 15px;
font-size: 12px;
font-weight: 300;
font-family: var(--arial-main);
}

a.cmpy-card-tags {
border-radius: 44px;
background: var(--Whisper-color);
padding: 5px 20px;
color: var(--dark-black-color);
font-size: 12px;
font-family: var(--arial-main);
font-weight: 400;
margin-top: 12px;
min-width: 88px;
}

.radio-crd-btn {
text-align: end;
}

input#selct-btn-c {
appearance: none;
width: 18px;
border-radius: 50%;
height: 18px;
border: 1px solid #BDBDBD;
background-clip: content-box;
padding: 3px;
}

input#selct-btn-c:checked {
background-color: var(--dark-black-color);
}

input#selct-btn-c-d {
appearance: none;
width: 18px;
border-radius: 50%;
height: 18px;
border: 1px solid #BDBDBD;
background-clip: content-box;
padding: 3px;
}

input#selct-btn-c-d:checked {
background-color: #000000;
} */

.right-box-card {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  /* box-shadow: 0px 0px 7.2px 0px rgba(0, 0, 0, 0.12); */
  padding: 20px;
  width: 100%;
}

p.slot-heading-r {
  color: var(--dark-black-color);
  font-size: 18px;
  font-weight: 400;
  font-family: var(--arial-main);
}

.list-one-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

p.postion-list-c {
  color: #5F6C72;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--arial-main);
}

p.list-date-c {
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: 400;
  font-family: var(--arial-main);
}

.list-one-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #E4E7E9;
  padding: 10px 0 20px 0;
}

p.total-list-c {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: 400;
  font-family: var(--arial-main);
}

p.total-date-c {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: 700;
  font-family: var(--arial-main);
}

button.boooking-req {
  width: 100%;
  padding: 14px 10px;
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  background: var(--dark-black-color);
  color: var(--color-white);
  text-align: center;
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 700;
}

/********cards promotion css******/

.avaliable-date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
}

.date-drop-prom {
  width: 180px;
}

.availabel-slot-sec {
  padding: 32px 0 0 0;
}

.availabel-slot-sec-create {
  padding-top: 30px;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
}

.avaliable-post-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: flex-start;
  padding-top: 35px;
}

.avaliable-post-cards-row {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  padding-top: 30px;
}

.avaliable-job-cards-row {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.card-slot-bg-clr {
  padding: 20px 10px;
  border-radius: 12px;
  border: 1px solid rgba(43, 43, 43, 0.1);
  background: #fff;
  max-width: 100%;
  width: 312px;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.14);
}

.active-color-card {
  padding: 20px 10px 20px;
  width: 25%;
  max-width: 100%;
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  background: linear-gradient(180deg, rgba(242, 242, 242, 1) 34%, rgba(255, 255, 255, 1) 34%, rgba(255, 255, 255, 1) 84%, rgba(43, 43, 43, 1) 84%);
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.14);
  position: relative;
}

.active-color-card p.job-no-cir-avaliable {
  background-color: var(--color-white);
}

.active-color-card a.cmpy-card-tags {
  background-color: var(--Whisper-color);
}

.logo-postion-promotion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

p.logo-card-avaliable {
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.job-postion-avalibe {
  color: var(--dark-black-color);
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.c-next-availabe {
  color: var(--color-white);
  padding: 5px 0 0 0;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  font-family: var(--arial-main);
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
}

p.c-next-availabe-left {
  color: var(--color-white);
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  font-family: var(--arial-main);
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
}

.postion-promotion-l-r {
  align-items: end;
}

.last-row-l-c{
  display: flex;
  justify-content: center;
  align-items: end;
}

p.job-postion-avalibe-l-r {
  color: var(--Whisper-color);
  font-size: 11px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.cross-company {
  text-align: center;
}


/* p.job-no-cir-avaliable {
border-radius: 70px;
background: var(--Whisper-color);
color: var(--dark-black-color);
font-size: 12px;
font-weight: var(--font-weight-bold);
font-family: var(--arial-main);
height: 60px;
width: 60px;
display: flex;
align-items: center;
justify-content: center;
} */

p.c-name-card-avalibe {
  color: var(--dark-black-color);
  padding: 10px 0 10px;
  font-size: 14px;
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.c-content-c {
  color: var(--dark-black-color);
  padding: 0px 0 20px;
  font-size: 10px;
  text-align: center;
  font-weight: 400;
  font-family: var(--arial-main);
}

.radio-crd-btn {
  text-align: end;
}

input#selct-btn-c {
  height: 20px;
  width: 20px;
}

.card-slot-bg-dark-card {
  padding: 20px 10px 5px;
  width: 25%;
  max-width: 100%;
  border-radius: 12px;
  border: 1px solid #2B2B2B1A;
  background: linear-gradient(180deg, rgba(0, 159, 181, 0.2) 34%, rgba(255, 255, 255, 1) 34%);
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.14);
}

.active-boder-clr {
  border: 1px solid #009FB5 !important;
}

p.logo-card-avaliable-dark {
  border-radius: 70px;
  border: 0.5px solid var(--grey48-color);
  opacity: 0.8;
  background: var(--suva-grey-color);
  color: rgb(255 255 255 / 80%);
  font-size: 12px;
  font-family: var(--arial-main);
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

p.job-postion-avalibe-dark {
  color: #797979;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.job-no-cir-avaliable-dark {
  border-radius: 70px;
  border: 0.5px solid var(--grey48-color);
  opacity: 0.8;
  background: var(--suva-grey-color);
  color: rgb(255 255 255 / 80%);
  font-size: 12px;
  font-family: var(--arial-main);
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

p.c-name-card-avalibe-dark {
  color: var(--grey27-color);
  padding: 15px 0 5px;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.c-content-c-dark {
  color: var(--grey27-color);
  padding-bottom: 15px;
  font-size: 12px;
  font-weight: 300;
  font-family: var(--arial-main);
}

a.cmpy-card-tags-dark {
  border-radius: 44px;
  opacity: 0.8;
  background: var(--suva-grey-color);
  padding: 5px 20px;
  color: rgb(255 255 255 / 80%);
  font-size: 12px;
  font-family: var(--arial-main);
  font-weight: 400;
  margin-top: 12px;
  min-width: 88px;
}

img.prom-cross {
  display: none;
}

.dd-container-pro{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 240px;
}
/**********************/

/**prmotion request table*****/

.four-pormotion-img img {
  margin-right: 9px;
  border-radius: 3px;
  padding: 3px;
  width: 26px;
  height: 26px;
}

.four-pormotion-img {
  position: relative;
  display: flex;
}

.dash-td-por {
  width: 13px;
  border-bottom: 4px solid var(--dark-black-color);
  margin: 0 auto;
}

.popup-bg-delet-admin-pormotion {
  width: 370px;
  border-radius: 32px;
  background: #FFF;
  box-shadow: 0px 1px 25px 8px rgba(0, 0, 0, 0.12);
  margin: 50px auto 0;
  position: relative;
  right: 145px;
}


/*******promotion job  slot ******/

.card-slot-bg-clr-job {
  padding: 20px 10px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  max-width: 100%;
  width: 100%;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.14);
  border-top-left-radius: 0;
}

.first-row-full {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.first-row-full img {
  margin-top: -70px;
  margin-left: -11px;
}

p.job-postion-avalibe-full {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  text-align: center;
}

p.job-postion-avalibe-salary {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.jobh-slot-title-logo-company {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.card-slot-bg-dark-card-job {
  padding: 20px 10px 15px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: linear-gradient(180deg, rgba(242, 242, 242, 1) 80%, rgba(43, 43, 43, 1) 80%);
  max-width: 100%;
  width: 100%;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.14);
  border-top-left-radius: 0;
}

p.slot-next-availabe {
  color: var(--color-white);
  padding: 30px 0 0 0;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  font-family: var(--arial-main);
}

.job-slot-salary-company {
  display: flex;
  align-items: center;
  gap: 60px;
  padding-bottom: 15px;
}

.com-logo-jd-prom {
  align-items: center;
  display: flex;
  gap: 20px;
  min-width: 250px;
}

p.job-title-salary,
p.slot-name-card-work {
  color: var(--dark-black-color);
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.job-title-promtion {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.slot-name-card-avalibe {
  color: var(--dark-black-color);
  font-size: 14px;
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.logo-card-avaliable-full {
  border-radius: 70px;
  border: 0.5px solid rgba(0, 0, 0, 0.16);
  background: #FFF;
  color: rgb(0 0 0 / 40%);
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

p.full-content-c {
  color: rgb(0 0 0 / 100%);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: var(--arial-main);
  padding-bottom: 0;
}

p.job-postion-avalibe-dark-full {
  color: #B3B1B1;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  text-align: center;
}

p.job-title-promtion-dark {
  color: #454545;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.logo-card-avaliable-dark {
  border-radius: 70px;
  border: 0.5px solid #868585;
  opacity: 0.8;
  background: #B3B1B1;
  color: rgb(255 255 255 / 80%);
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input#selct-btn-c {
  accent-color: rgba(0, 159, 181, 1);
}


/*****Promotion Search page job promotion ****/

.left-right-card-slots {
  display: flex;
  gap: 20px;
  padding-top: 30px;
}

.left-side-card-slot {
  box-shadow: 0px 1px 16px 0px #00000024;
  border: 1px solid #2B2B2B33;
  background: #F7F7F7;
  border-radius: 12px;
  padding: 20px 10px;
}

.avaliable-post-cards-left {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
}

.left-side-slot-heading {
  color: var(--pacific-blue-color);
  font-family: var(--arial-main);
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 15px;
  text-align: center;
}

.active-color-card-left {
  padding: 10px;
  width: 260px;
  height: 260px;
  max-width: 100%;
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  background: linear-gradient(180deg, rgba(242, 242, 242, 1) 20%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 84%, rgba(43, 43, 43, 1) 84%);
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.14);
  border-bottom: 0;
  position: relative;
}

.card-slot-bg-dark-card-left {
  padding: 10px;
  width: 260px;
  height: 260px;
  max-width: 100%;
  border-radius: 12px;
  border: 1px solid #2B2B2B1A;
  background: linear-gradient(180deg, rgba(0, 159, 181, 1) 20%, rgba(255, 255, 255, 1) 20%);
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.14);
  border-bottom: 0;
}

.logo-postion-promotion-left {
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  top: 40px;
}

.cross-company-left {
  text-align: center;
}

.cross-company-left img {
  margin-top: -15px;
  padding-bottom: 5px;
}

p.c-name-card-left {
  color: var(--dark-black-color);
  padding: 5px 0 0px;
  font-size: 14px;
  text-align: left;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.c-content-c-left {
  color: var(--dark-black-color);
  padding: 5px 0 20px;
  font-size: 10px;
  text-align: left;
  font-weight: 400;
  font-family: var(--arial-main);
}

.avaliable-post-cards-right {
  display: flex;
  flex-wrap: wrap;
  gap: 65px;
  justify-content: flex-start;
  padding-top: 35px;
}

.active-boder-clr {
  border: 1px solid #009FB5;
}

/**************************************/

.job-ad-occupied {
  background-color: #C1C1C1;
}

.job-ad-card-border-radius {
  border-radius: 12px;
}

.job-ad-position-title {
  font-size: 16px;
  font-weight: 700;
}

.job-card-company-logo {
  width:32px;
  height:32px;
}

.job-card-company-name {
  font-size: 14px;
  font-weight: 700;
}

.job-card-salary {
  font-size: 12px;
  font-weight: 700;
}

.job-card-description {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.job-card-content {
  width:80%
}

.job-card-action {
  width:20%
}

.job-card-action-button {
  width:24px;
  height: 24px;
  div {
    width:18px;
    height: 18px;
  }
}

.demo-page-container {
  border: 1px solid #d7d7d7;
  background-color: var(--blue-alice-color);
  border-radius: 16px;
}

.demo-page-home-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
}

.demo-page-home-subtitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.demo-page-home-section-title{
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

.demo-page-home-section-subtitle{
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: var(--dark-black-color);
  font-style: normal;
  font-weight: 400;
  opacity: 0.6;
}

.demo-page-search-container {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  gap:16px
}

.rounded-8 {
  border-radius: 8px;
}

.demo-page-search {
  border: 1px solid #dee2e6;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  div {
    text-align: start;
  }
}

.date-posted-field {
  border: 1px solid #dee2e6;
}

.bg-muted {
  background-color: #ECECEC;
}

.pills-container {
  gap:8px
}

.search-jobs-container {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
}

/* Date Range - Promotion */

.rdrDateRangeWrapper {
  grid-template-columns: 1fr !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}