/******bulk resume upload dashbord ***/

.resume-f-admin {
    width: 100%;
}

.bulk-res-upload {
    padding: 12px;
    border: 0px solid var(--dark-black-color-600);
    width: 100%;
    border-radius: 5px;
    background-color: rgba(221, 222, 238, 0.25) !important;
}

.bulk-res-file-upload.error-input {
    border: 1px dashed var(--error-msg-color) !important;
    outline: 0;
}

.upload-res-part-f {
    padding: 0px 0 0px 0;
}

.upload-res-part-f h3 {
    font-weight: var(--font-weight-bold);
    font-family: var(--arial-main);
    color: var(--dark-black-color);
    font-size: 20px;
}

.upload-res-part-f p {
    font-weight: var(--font-weight-medium);
    font-family: var(--arial-main);
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
}

.bulk-res-file-upload {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: var(--boarder, 8px);
    border: 1px dashed #2B2B2B;
    background: rgba(221, 222, 238, 0.25);
    padding: 15px 0;
    height: 180px;
}

.bulk-res-file-upload input {
    display: none;
}

p.resume-bulk-res {
    margin: 10px 0 10px 0;
    color: var(--dark-black-color);
    text-align: center;
    font-family: var(--arial-main);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.or-divider-res {
    display: flex;
    max-width: 20%;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 5px 0;
}

.divider-one-res {
    width: 100%;
    border-top: 2px solid #B0B0B0;
}

.text-or-res {
    text-align: center;
    color: #6D6D6D;
    font-size: 12px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
}

.bulk-res-f {
    background: var(--color-white);
    border: 1px solid var(--dark-black-color);
    color: var(--dark-black-color);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    line-height: 24px;
    min-width: 141px;
    padding: 8px 0;
    border-radius: 4px;
    text-align: center;
    border-radius: 4px;
}

.progress-bar-resume-bulk {
    width: 100%;
    padding: 16px;
    border: 1px solid #E7E7E7;
    border-radius: 12px;
}

.file-progress-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    align-items: center;
}

.progress-part-f {
    padding: 0px 0 0px 0;
}

.progress-part-h {
   display: flex;
   gap: 5px;
}

.progress-part-img {
    width: 10px;
}

.progress-part-f .load-heading {
    align-items: center;
    font-weight: var(--font-weight-bold);
    font-family: var(--arial-main);
    color: var(--dark-black-color);
    font-size: 12px;
}


.progress-part-f h3 {
    font-weight: var(--font-weight-bold);
    font-family: var(--arial-main);
    color: var(--dark-black-color);
    font-size: 12px;
}

.progress-part-f p {
    font-weight: var(--font-weight-medium);
    font-family: var(--arial-main);
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
}

.two-img-bulk {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.file-uploaded-resume-bulk {
    width: 100%;
    padding: 16px;
    border: 1px solid #E7E7E7;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.file-file-complte-bulk {
    width: 100%;
}
