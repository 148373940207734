.radio-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .radio-button {
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    transition: all 0.3s ease;
    color: #2b2b2b;
  }
  
  .radio-button:hover {
    border-color: #888;
  }
  
  .radio-button input {
    display: none;
  }
  
  .radio-button input:checked + .radio-text {
    border-color: #333;
    background-color: #2b2b2b;
    color: #fff;
  }
  
  .radio-text {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #2b2b2b;
    border-radius: 100px;
  }
  