/*********request detail admin*****/

.res-file-added {
    background: #fff;
    border-radius: 12px;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 4px 0px #2B2B2B1F;
    padding: 15px;
    position: relative;
    margin-top: 20px;
}

.file-res-admin {
    display: flex;
    gap: 10px;
    align-items: center;
}

p.file-name-res {
    font-size: 18px;
    line-height: 20px;
    font-family: var(--arial-main);
    font-weight: 400;
    color: var(--dark-black-color);
}

p.job-title-admin {
    font-size: 14px;
    line-height: 18px;
    font-family: var(--arial-main);
    font-weight: 400;
    color: #2B2B2B99;
}

.name-job-res-admin {
    width: 75%;
    word-wrap: break-word;
}

.date-created-admin {
    font-size: 14px;
    line-height: 18px;
    font-family: var(--arial-main);
    font-weight: 700;
    color: var(--dark-black-color);
    position: absolute;
    right: 19px;
    bottom: 10px;
}

.date-created-admin span {
    font-weight: 400;
}

.two-card-admin-request {
    padding: 20px 0 35px 0px;
    width: 100% !important;
    display: flex;
    gap: 25px;
}

.card-left-request,
.card-right-request {
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 17px 20px;
    flex-wrap: wrap;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 7px 1px #2B2B2B1F;
    width: 100%;
    max-width: 50%;
}

.card-bulk-request-detail{
    font-family: var(--arial-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}

.top-part-img-row {
    display: flex;
    gap: 10px;
    align-items: center;
}

p.name-image-left {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
    color: var(--dark-black-color);
}

.progress-circle-req-detail {
    width: 55px;
    height: 55px;
    position: relative;
    display: inline-block;
}
  
.progress-circle-status {
    display: flex;
    padding: 10px 0px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 175px;
}

.resum-status-admin-list {
    width: 100%;
    max-width: 330px;
}

ul.res-process-resukt-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.res-process-resukt-list li {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 12px;
}

.status-res-list-admin {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

p.left-list-res-admin {
    font-size: 14px;
    line-height: 18px;
    font-family: var(--arial-main);
    font-weight: 400;
    color: var(--dark-black-color);
}

p.res-number-b {
    font-size: 14px;
    line-height: 18px;
    font-family: var(--arial-main);
    font-weight: 700;
    color: var(--dark-black-color);
}

p.res-number-green {
    font-size: 14px;
    line-height: 18px;
    font-family: var(--arial-main);
    font-weight: 700;
    color: #07944F;
}

p.res-number-red {
    font-size: 14px;
    line-height: 18px;
    font-family: var(--arial-main);
    font-weight: 700;
    color: #E15C52;
}

p.res-number-org {
    font-size: 14px;
    line-height: 18px;
    font-family: var(--arial-main);
    font-weight: 700;
    color: #FFA033;
}

.black-status-list {
    width: 6px;
    height: 6px;
    background-color: var(--dark-black-color);
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.blue-status-list {
    width: 6px;
    height: 6px;
    background-color: #009FB5;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.green-status-list {
    width: 6px;
    height: 6px;
    background-color: #07944F;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.oringe-status-list {
    width: 6px;
    height: 6px;
    background-color: #FFA033;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.red-status-list {
    width: 6px;
    height: 6px;
    background-color: #E15C52;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.ble-right-status-list {
    width: 6px;
    height: 6px;
    background-color: #009FB5;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

p.res-right-b {
    font-size: 14px;
    line-height: 18px;
    font-family: var(--arial-main);
    font-weight: 700;
    color: var(--dark-black-color);
}

.progress-bar-res-admin {
    height: 160px;
    width: 160px;
    border-radius: 100%;
    text-align: center;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.chart-container {
    position: relative; 
    top: -20px
}

p.res-progress-socre {
    font-size: 30px;
    line-height: 34px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
    color: var(--dark-black-color);
}

.bulk-resume-disable-icon {
    opacity: 0.5;
}

.btn-analyze {
    border-radius: 8px; 
    font-size: 16px; 
    font-weight: 500;
}
