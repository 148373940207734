.select-filter-resume {
  display: flex;
  align-items: center;
  justify-content: end;
  max-width: 15%;
  width: 100%;
  gap: 10px;
  margin: 0 10px 10px auto;
  position: relative;
}

.select-filter-resume label {
  color: var(--dark-black-color);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.text-filter-admin-bulk {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.text-filter-admin-bulk p {
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: 13px;
}
