/****************add list model ************/

.search-box-list {
  width: 100%;
  margin: 0 auto;
  padding: 8px;
  border-radius: 52px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #fbfbfb;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.search-box-list img {
  height: 18px;
  width: 18px;
  margin: 0px 10px;
}

input[type='radio'],
input[type='checkbox']
 {
  accent-color: var(--dark-black-color);
}

.list-radio-add {
  overflow: auto;
  height: 290px;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-top: 12px;
  width: 100%;
}

/* width */

.list-radio-add::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.list-radio-add::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.list-radio-add::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--dark-black-color);
}

.list-radio-add .radio-list-name:nth-child(1) {
  margin-top: 0;
}

.radio-list-name {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--dark-black-color-600);
  padding: 12px;
  margin-top: 10px;
}

input.list-select-b {
  transform: scale(1.3);
  margin-right: 10px;
}

label.list-lab-clr {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: 600;
}