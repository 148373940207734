/**** header***/

.row-log {
  display: flex;
  justify-content: space-between;
  padding: 25px 0 0 0;
  position: relative;
}

.candidate-profile-header {
  position: relative;
}

.row-login-candidate {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.col-two-button {
  display: inline-flex;
  align-items: center;
}

.row-login-candidate .col-two-button {
  display: inline-flex;
  align-items: center;
  justify-content: end;
}

.menu-items {
  display: flex;
  align-items: center;
  gap: 20px;
}

.toggleBtn {
  display: none;
}

.col-two-button button {
  background: var(--primary-color);
  color: var(--color-white);
  padding: 12px 21px;
  text-align: center;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--arial-main);
  font-size: var(--body-text);
  font-weight: 500;
}

.find-job-btn-candidate-dashbord button {
  background: white;
  color: var(--pacific-blue-color);
  border-radius: 8px;
  border: 2px solid var(--pacific-blue-color);
  padding: 8px 26px;
  text-align: center;
  cursor: pointer;
  font-family: var(--arial-main);
  font-size: 18px;
  font-weight: 500;
}

.job-filter-btn button {
  background: var(--dark-black-color);
  color: var(--color-white);
  padding: 10px 50px;
  text-align: center;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  font-family: var(--arial-main);
  font-size: var(--body-text);
  font-weight: 500;
}

/************/

.all-page-bg-clr {
  background: #fbfbfb;
}

.col-two-button .find-job-btn {
  margin-right: 80px;
}

button.find-j-nav {
  background: var(--primary-color);
  color: var(--color-white);
  padding: 8px 53px;
  text-align: center;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--arial-main);
  font-size: 18px;
  font-weight: 500;
}

button.login-header {
  padding: 8px 32px;
  font-size: 18px;
  background: transparent;
  color: var(--primary-color);
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  font-family: var(--arial-main);
  font-weight: 500;
}

button.signuo-nav {
  background: var(--primary-color);
  color: var(--color-white);
  padding: 8px 32px;
  font-size: 18px;
  text-align: center;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--arial-main);
  font-weight: 500;
}

/********header poly*********/

header {
  background: white;
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
}

.row-log {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  align-items: center;
}

.logo-col a {
  text-decoration: none;
}

.logo-col h1 {
  line-height: 1.46;
  font-size: 0;
}

.logo-col img {
  max-width: 100%;
  width: 150px;
}

p.country-name-logo {
  text-align: end;
  color: var(--dark-black-color);
  font-family: var(--outfit);
  font-size: 15px;
  font-weight: 500;
}

ul.menu-list {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  justify-content: end;
  gap: 50px;
  align-items: center;
}

.menu-items-poly {
  display: flex;
  justify-content: space-between;
  width: 55%;
  padding-left: 25px;
}

ul.menu-list li {
  color: var(--dark-black-color-060);
}

ul.menu-list li a {
  padding: 16px 0px;
  text-decoration: none;
  font-family: var(--arial-main);
  font-weight: var(--font-polycare-medium);
  font-size: 20px;
  color: var(--dark-black-color-060);
}

ul.menu-list li a:hover {
  color: var(--pacific-blue-color);
}

.active-menu {
  color: var(--pacific-blue-color) !important;
}

a.active-menu {
  color: var(--pacific-blue-color) !important;
}

button.login-header-poly {
  font-size: 20px;
  background: var(--dark-black-color);
  border: 2px solid var(--dark-black-color);
  color: var(--color-white);
  border-radius: 8px;
  cursor: pointer;
  line-height: 23px;
  font-family: var(--arial-main);
  font-weight: 400;
  padding: 10px 14px;
}

button.signup-poly {
  background: transparent;
  color: var(--dark-black-color);
  padding: 10px 12px;
  font-size: 20px;
  text-align: center;
  border: 0;
  border-radius: 8px;
  border: 2px solid var(--dark-black-color);
  cursor: pointer;
  line-height: 23px;
  font-family: var(--arial-main);
  font-weight: 400;
  min-width: 98px;
}

/*****************Top Menu Drop Down************************/

.menu-drp-sec {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 9;
}

.list-men-dropdown-head {
  position: absolute;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 225px;
  overflow: auto;
  z-index: 2;
  margin: 0px 0 0 auto;
  top: 100%;
}

.menu-dropdown-head .list-men-dropdown-head {
  display: none;
}

.list-men-dropdown-box {
  position: absolute;
  padding: 20px;
  width: 608px;
  background-color: #fff;
  /* overflow: auto; */
  z-index: 1;
  margin: 0px 0 0 auto;
  top: 100%;
  /* margin: 18px 0 0 0; */
  display: flex;
  gap: 20px;
  /* opacity: 0; */
  border: 1px solid #2b2b2b4d;
  /* box-shadow: 0px 16px 75px -30px #00000066; */
}

.list-men-dropdown-box::before {
  content: "";
  height: 23px;
  width: 135px;
  color: #b4bcc0;
  position: absolute;
  display: block;
  border-top: 5px solid;
  top: -5px;
  left: 0;
}

.left-content-menu {
  width: 100%;
  max-width: 351px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

button.using-free-btn {
  width: fit-content;
  padding: 12px 20px;
  border: 1px solid #2b2b2b;
  border-radius: 8px;
  background: #fff;
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-black-color);
  line-height: 18.4px;
  text-align: left;
}

p.menu-drp-heading {
  font-family: var(--arial-main);
  color: var(--dark-black-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: left;
}

p.menu-drp-heading span {
  color: #009fb5;
}

p.menu-drp-text {
  font-family: var(--arial-main);
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: justified;
}

.right-content-menu {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}

.menu-dropdown-head-img {
  width: 80px;
  margin: 20px 0px 20px 50px;
}

.menu-dropdown-head:hover {
  opacity: 1;
  visibility: visible;
}

.menu-dropdown-head:hover .menu-drp-sec,
.menu-drp-sec:hover {
  opacity: 1;
  visibility: visible;
}

.headig-bg-clr {
  padding: 12px;
}
.headig-bg-clr:hover {
  background: #009fb514;
  border-radius: 16px;
}

.text-menu-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.text-menu-icon p {
  text-decoration: none;
  font-family: var(--arial-main);
  font-weight: var(--font-polycare-medium);
  font-size: 20px;
  color: var(--dark-black-color-060);
}

ul.menu-item-drop-poly {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.menu-item-drop-poly li {
  border-radius: 6px;
  background: #ffffff;
  padding: 0px 10px 0 10px;
  cursor: pointer;
  color: var(--dark-black-color-060);
  font-size: 18px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
}

ul.menu-item-drop-poly li:hover {
  border-radius: 6px;
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: 700;
}

ul.menu-item-drop-poly li a:hover {
  color: var(--dark-black-color);
}

/*********************************************/

/**************candidate profile header *************/

.logo-col-profile-candidate {
  margin-right: 3%;
  flex: 1 1 75%;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}

.logo-col-profile-left {
  margin-right: 3%;
  flex: 1 1 85%;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}

.profile-right-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* gap: 15px; */
}

.hide-wishlist-style {
  display: flex;
  gap: 0px;
}

.candidate-name-img {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.candidate-name-img img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin: 0 10px;
}

.toggle-btn-res {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.candidate-wishlist-block a {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
}

p.candidate-name {
  white-space: nowrap;
}

.toggle-icon-profile.icon-cursor {
  margin-left: 0px;
}

p.candidate-name {
  color: var(--dark-black-color);
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  padding: 0 20px 0 10px;
}

p.candidate-wishlist {
  color: var(--dark-black-color);
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  margin: 0 20px;
}

/***********drop down list data ******/

.dropdown-dashbord {
  max-width: 26%;
  width: 100%;
  margin: 0 0 0 auto;
  position: absolute;
  right: 0;
  z-index: 3;
  padding-right: 10px;
}

.hide-show-click {
  display: none;
}

.dropdown-dashbord ul {
  background: #fcfdfd;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  list-style: none;
  padding: 20px 24px 24px 24px;
  z-index: 1;
}

.dropdown-dashbord ul a {
  font-size: var(--body-text);
  font-family: var(--arial-main);
  font-size: 16px;
  text-decoration: none;
  color: var(--dark-black-color);
  line-height: 20px;
  transition: font-weight 0.3s ease;
}

li.menu-dropdown {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px;
}

li.menu-dropdown {
  font-weight: 400;
  animation: increaseFontWeight 0.3s ease;
}

li.menu-dropdown:hover {
  border-radius: 4px;
  font-family: var(--arial-main);
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: 700;
}

li.menu-dropdown img {
  height: 25px;
  width: 20px;
  object-fit: contain;
}

/********* notifycation css *****/

p.conter-text-notification {
  color: var(--color-white);
  height: 18px;
  width: 18px;
  position: absolute;
  font-size: 10px;
  top: -8px;
  display: inline-block;
  z-index: 2;
  background: var(--dark-black-color);
  border-radius: 50%;
  bottom: 0;
  left: 25px;
  text-align: center;
  font-weight: 700;
  padding: 0px 0 0px 0px;
  vertical-align: middle;
  line-height: 18px;
}

.conter-text-notification-admin{
  left: 15px !important;
}

@keyframes increaseFontWeight {
  0% {
    font-weight: 400;
  }

  100% {
    font-weight: 700;
    /* or any other desired value */
  }
}

.find-job-btn-mbl {
  display: none;
}

.toggle-btn-mbl {
  display: none;
}

.mbl-logo {
  display: none;
}

@media only screen and (max-width: 1120) {
  .list-men-dropdown-box {
    width: auto;
  }
}
@media only screen and (max-width: 767px) {
  .find-job-btn-mbl {
    display: block;
    height: 34px;
    width: 98px;
    padding-left: 60px;
    /* margin-top: -8px; */
  }

  .find-j-nav-mbl {
    background: var(--primary-color);
    color: var(--color-white);
    padding: 1px 3px;
    height: 34px;
    width: 98px;
    text-align: center;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--arial-main);
    font-size: 12px;
    font-weight: 400;
  }

  .toggle-btn-res {
    display: none;
  }

  .toggle-btn-mbl {
    display: flex;
  }
  .list-men-dropdown-box {
    display: none;
  }
  .menu-dropdown-head.open .list-men-dropdown-head {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .log-div {
    display: none;
  }

  .mbl-logo {
    display: flex;
    flex-direction: column;
    /* width: 80px;
    height: 30px; */
  }

  .mbl-logo img {
    width: 140px;
    /* height: 18px; */
  }

  .find-job-btn-mbl {
    padding-left: 55px;
  }

  .toggleBtn {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 400px) {
  .find-job-btn-mbl {
    padding-left: 35px;
  }
}

@media only screen and (max-width: 375px) {
  .find-job-btn-mbl {
    padding-left: 15px;
  }
}

@media (min-width: 767px) {
  .resume-active-menu {
    color: var(--pacific-blue-color) !important;
  }
}
