/*********** candidate SignUp page satrt here ***************/

.candiate-signup-row {
  padding: 100px 0;
}

.candidate-signup-form {
  max-width: 55%;
  width: 100%;
  margin: 0 auto;
}

.goole-login-border {
  max-width: 55%;
  width: 100%;
  margin: 0 auto;
}

.form-center-job {
  margin: 0 30px 0 auto;
}

.candidate-from-signup-create-final-job {
  max-width: 50%;
  width: 100%;
  margin: 0 auto;
}

.login-here {
  padding: 20px 0px;
}

.login-here p {
  text-align: center;
  color: rgb(0 0 0 / 80%);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.login-here a {
  text-decoration: none;
  font-weight: var(--font-weight-bold);
}

.text-pacific-blue {
  color: var(--pacific-blue-color);
}

.candidate-signup h2 {
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-size: 28px;
}

.candidate-employe-btn {
  text-align: center;
  gap: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.candidate-employe-btn a {
  max-width: 50%;
  width: 100%;
}

.candidate-employe-btn {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: var(--color-white);
  padding: 4px;
}

button.employee-btn {
  background: var(--color-white);
  padding: 6.7px;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  color: var(--dark-black-color);
  font-size: 18px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  max-width: 100%;
  width: 100%;
}

button.toggle-btn-active {
  background: var(--dark-black-color);
  color: var(--color-white);
  font-size: 22px;
  font-family: var(--arial-main);
  font-weight: 700;
}

.candidate-signup-google {
  max-width: 70%;
  width: 100%;
  margin: 60px auto 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 15px 0;
}

.or-text-clr .text-or {
  text-align: center;
  color: var(--or-text-color);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.candidate-signup-google a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-decoration: none;
}

.candidate-signup-google img {
  max-width: 100%;
  height: 25px;
  width: 25px;
}

.candidate-signup-google a p {
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  color: var(--form-text-account);
}

.candidate-signup-form-feild {
  max-width: 55%;
  width: 100%;
  margin: 50px auto;
}

.candidate-username-signup-form {
  display: flex;
  flex-wrap: wrap;
  gap: 0 20px;
}

.first-name {
  max-width: 48%;
  width: 100%;
}

.last-name {
  max-width: 48%;
  width: 100%;
}

.candidate-username-signup-form input[type="text"] {
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
}

.f-feild {
  margin-bottom: 30px;
}

.f-feild-forgot {
  margin-bottom: 20px;
}

.f-feild label {
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
}

.city-radio-btn label {
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: var(--font-weight-medium);
}


label.l-f-img {
  opacity: 1;
}

.dropdown-opty {
  opacity: 0.5;
}
.signup-email input[type="email"] {
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
}

.signup-passowrd input[type="password"] {
  width: 100%;
  padding: 18px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
}

.signup-passowrd-confirm input[type="password"] {
  width: 100%;
  padding: 18px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
}

.signup-country #country {
  width: 100%;
  padding: 18px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
}

.merge-feild {
  display: flex;
  gap: 0;
}

#countryCode p {
  text-align: center;
}

.signup-phone #countryCode {
  width: 12%;
  padding: 18px 0px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  appearance: none;
  background: var(--fomr-telcode-bg);
}

input#code-tel-post {
  width: 100%;
  border: 0;
  outline: 0;
  text-align: center;
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  opacity: 0.8;
  background: transparent !important;
}

input#code-tel-post :focus-visible {
  border: 0 !important;
  outline: 0 !important;
}

.signup-phone input[type="tel"] {
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
  /* border-top-left-radius: 0;
border-bottom-left-radius: 0; */
}

.signup-job-titles input[type="text"] {
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
}

.signup-skill-tags input[type="text"] {
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
}

.signup-resume {
  position: relative;
}

.resume-choose-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
  z-index: 0;
  display: block;
  max-width: calc(100% - 80px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.signup-resume input[type="file"] {
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
  text-indent: -999em;
  outline: none;
  /* z-index: 1; */
  position: relative;
}

.signup-resume input[type="text"] {
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
  text-indent: -999em;
  outline: none;
  /* z-index: 1; */
  position: relative;
}

.signup-linkedIn-link input[type="text"] {
  width: 100%;
  padding: 13px 8px;
  border: 1px solid var(--dark-black-color-600);
  border-radius: 8px;
}

.signup-check.f-feild {
  width: 85%;
  margin: 0 auto 0 0;
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  font-weight: 400;
  color: var(--dark-black-color);
  position: relative;
}

.signup-resendmail {
  padding: 25px 0px 0 0;
}

.signup-resendmail p {
  text-align: center;
  color: var(--dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 20px;
}

.signup-resendmail a {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: var(--pacific-blue-color);
  font-weight: 600;
  color: var(--pacific-blue-color);
}


textarea.form-font-f {
  width: 100%;
  padding: 10px;
  border: 0.5px solid var(--dark-black-color-600);
  border-radius: 8px;
}

/*********** attention box*****/

.attention-box {
  border-radius: 15px;
  border: 1px solid var(--dark-black-color-600);
  background: var(--color-white);
  box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
  padding: 10px;
  position: absolute;
  bottom: 30px;
  left: -5px;
}

.attendtion-heading {
  display: flex;
  gap: 10px;
  align-items: center;
}

.icon-atten {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-atten img {
  height: 30px;
  width: 26px;
  object-fit: contain;
}

p.atten-main-heading {
  color: var(--primary-color);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.text-atten-box {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

/***********/

button.form-submit {
  width: 100%;
  background: var(--dark-black-color);
  border: 0;
  border-radius: 8px;
  padding: 18px 0;
  color: var(--color-white);
  font-family: var(--arial-main);
  font-size: 16px;
  margin-top: 15px;
  line-height: 24px;
  font-weight: var(--font-weight-medium);
}

.upload-icon>input {
  background: url(/public/image/upload.png);
  background-repeat: no-repeat;
  display: block;
  background-position: 98% center;
  background-size: 20px;
  padding-right: 40px !important;
}

.tags-tittles {
  position: relative;
}

/********* list for tag in signup form********/

.job-tags-titles-dropdown {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  overflow: auto;
  z-index: 1;
  margin: 5px 0 0 auto;
  right: 0;
  max-height: 185px;
  height: auto;
}

/* width */

.job-tags-titles-dropdown::-webkit-scrollbar {
  width: 8px;
}

/* Track */

.job-tags-titles-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.job-tags-titles-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--dark-black-color);
  background-color: var(--dark-black-color);
}

.tags-tittles ul.tags-titles-list {
  display: flex;
  gap: 20px;
  list-style: none;
  margin: 15px 0 30px;
  padding: 0;
  flex-wrap: wrap;
}

.tags-tittles ul.tags-titles-list li span img {
  vertical-align: baseline;
  width: 12px;
  height: 12px;
}

.tags-tittles ul.tags-titles-list li button {
  border: 0;
  background: transparent;
  font-size: 15px;
}

.tags-tittles ul.tags-titles-list li {
  display: inline-flex;
  font-family: var(--arial-main);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-medium);
  justify-content: space-between;
  padding: 4px 8px;
  gap: 14px;
  align-items: flex-start;
  background-color: rgba(236 236 236 / 70%);
  color: var(--dark-black-color);
  font-size: var(--skill-text-size);
  border-radius: 16px;
  border: 0.5px solid var(--Whisper-color);
}

.disable-field{
  opacity: 0.5;
}

.icon-hide-2 {
  max-width: 5%;
  width: 100%;
}

.icon-hide-2 img {
  width: 20px;
  flex-shrink: 0;
}

@media only screen and (max-width:767px) {
  .attention-box {
    border-radius: 15px;
    border: 1px solid var(--accent-color);
    background: var(--color-white);
    box-shadow: 0px 4px 16px 0px rgba(16, 11, 39, 0.08);
    padding: 10px;
    position: absolute;
    bottom: 63px;
    left: -5px;
  }
}