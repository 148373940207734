/******main tabs of journey***********/

ul.nav.nav-tabs.mb-3.tabs-summary {
    justify-content: left;
    gap: 40px;
}

.table-user-permission {
    padding-top: 50px;
    margin-bottom: 20px;
}

.cand-activities {
    padding: 0 28px;
}

.cand-activities .profile-card {
    background: #f5f5f5;
    box-shadow: none;
}

.cand-meetup h3 {
    font-size: 32px;
    font-weight: 700;
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}



@media(min-width: 767px) {
    .cand-meetup .cand-intro-vid {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .cand-meetup .cand-intro-vid {
        position: relative;
    }

    .cand-meetup .cand-intro-vid:before,
    .cand-meetup .cand-intro-vid:after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        width: 100%;
        height: calc(50% - 23px);
        max-height: 105px;
    }

    .cand-meetup .cand-intro-vid:before {
        background: url('../../../../../public/image/cand-meet-be.png') no-repeat;
        background-size: auto;
        top: 0;
    }

    .cand-meetup .cand-intro-vid:after {
        background: url('../../../../../public/image/cand-meet-af.png') no-repeat;
        background-size: contain;
        bottom: 0;
    }
}

.left-right-content-jour {
    display: flex;
    gap: 30px;
    padding: 50px 20px;
}

.left-col-vertical {
    max-width: 43%;
    width: 100%;
}

.right-content-col-jour {
    max-width: 55%;
    width: 100%;
    border-radius: 16px;
    border: 1px solid #EEE;
    background: rgba(212, 239, 255, 0.07);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tabs-content-jour {
    border-radius: 16px;
    background: var(--color-white);
    padding: 20px 0;
}

p.content-heading-jour {
    color: var(--dark-black-color);
    font-size: 24px;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
    border-bottom: 1px solid rgb(0 0 0 / 20%);
    padding: 0 20px 20px;
    margin-bottom: 15px !important;
}