.job-position{
    margin-top: 0;
}

.que-content .que-title{
    font-size: 20px;
    font-weight: 600;
    border: 1px solid #000;
    display: inline-block;
    padding: 15px;
    margin: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.que-content{
    padding: 15px;
}

.single-ques {
    padding: 15px 0;
}

.single-ques p {
    color: #2B2B2B;
}

.fs-16 {
    font-size: 16px;
}

.text-theme {
    color: #009FB5 !important;
}