/************model image css********/

.send-correct-logo img {
  max-width: 100%;
}

.upload-image-model-popup-bg {
  width: 60%;
  margin: 0 auto;
}

.popup-content-modelimage {
  width: 100%;
  height: 50%;
  margin: 50px auto 0;
  background: var(--color-white);
  padding: 20px 70px 70px 70px;
  position: relative;
}

p.popup-text-model-img {
  text-align: center;
  font-size: 28px;
  color: var(--text-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.company-profile-img {
  text-align: center;
}

p.img-info-model {
  color: rgb(0 0 0 / 80%);
  font-size: var(--button-text);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  text-align: center;
}

.user-input-file {
  padding-top: 15px;
}

.user-input-file input {
  display: none;
}

.user-input-file label {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
}

.img-requrie-info {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 15px;
}

.upload-img-btn {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
}

p.error-msg-model {
  color: var(--error-msg-color);
  font-size: var(--button-text);
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
  text-align: center;
}

.upload-image-model-form {
  padding-top: 50px;
}

button.logo-upload-btn {
  border-radius: 4px;
  border: 1px solid #e15c52;
  background: transparent;
  min-width: 110px;
  padding: 10px 0;
  color: #e15c52;
  font-family: var(--arial-main);
  font-size: var(--body-text);
  font-weight: var(--font-weight-medium);
}

button.save-logo-btn {
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  min-width: 110px;
  padding: 10px 0;
  color: var(--color-white);
  font-family: var(--arial-main);
  font-size: var(--body-text);
  font-weight: var(--font-weight-medium);
}

/*************employer new model*******/

section.model-popup-employee {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 80%);
  top: 0%;
  z-index: 9;
  overflow: auto;
}

.logo-site {
  display: flex;
  justify-content: center;
}

.popup-bg-employee {
  width: 680px;
  margin: 0 auto;
}

.popup-content-employee {
  width: 100%;
  height: 50%;
  margin: 50px auto 0;
  border-radius: 10px;
  border: 1px solid #EBEFFA;
  background: var(--color-white);
  box-shadow: 0px 17px 34px 0px rgba(0, 0, 0, 0.10);
  padding: 18px 0 0 0;
  position: relative;
}

.model-box-poly {
  padding: 24px;
}

p.model-employee-title {
  font-size: 22px;
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  line-height: 24px;
  text-transform: capitalize;
  padding: 0px 0 14px;
  text-align: center;
  border-bottom: 1px solid #D0D5DD;
}


p.employee-model-content {
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  padding-bottom: 8px;
}

p.image-type-size {
  color: rgb(43 43 43 / 60%);
  font-family: var(--arial-main);
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.employee-img-box {
  border-radius: 10px;
  border: 1px dashed #B8B8B8;
  width: 100%;
  height: 280px;
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.employee-img-data {
  text-align: center;
}

.employee-img-data input {
  display: none;
}

.employee-img-box img {
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

p.img-cap {
  color: rgb(43 43 43 / 60%);
  text-align: center;
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  padding: 22px 0;
}

p.brow-btn {
  border-radius: 6px;
  border: 1px dashed #B8B8B8;
  width: 234px;
  color: var(--dark-black-color);
  font-size: 14px;
  font-family: var(--arial-main);
  line-height: 14px;
  padding: 16px 0;
  margin: 0 auto;
}

.employee-img-btns {
  display: flex;
  justify-content: end;
  padding-top: 20px;
  gap: 30px;
}

a.uplod-btn button.img-e-upload {
  background: transparent;
  border: 0;
  font-family: var(--arial-main);
  color: var(--color-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

a.uplod-btn {
  border: 0;
  background: var(--dark-black-color);
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  padding: 15px 16px;
  cursor: pointer;
  min-width: 131px;
  border-radius: 6px;
}

a.uplod-btn input {
  display: none;
}

label#upload-img {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-evenly;
}

button.cancel-img-btn {
  border: 1px solid var(--dark-black-color);
  padding: 15px 0;
  border-radius: 6px;
  min-width: 131px;
  font-family: var(--arial-main);
  color: var(--dark-black-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  background: transparent;
}

.employee-img-btns button.cancel-img-btn {
  height: 54px;
}

.cropper-container {
  height: 280px;
  width: 100%;
}