/****************top match page scss start here***********/

.job-posstion-job-content {
  padding: 70px 0px 50px 0px;
}

.left-right-content {
  display: flex;
}

.col-left-j-space {
  margin-top: 20px;
  max-width: 25%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  border-radius: 16px;
  border: 0.5px solid rgba(20, 57, 117, 0.20);
  background: var(--color-white);
}

.short-content-left.job-col-left {
  /* padding: 30px 20px 25px 0px; */
  /* border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: var(--color-white); */
  padding: 0 15px;
  min-height: 170px;
  width: 100%;
}

.Job-Opportunities-row-j-search {
  display: flex;
  padding: 30px 0px 30px 20px;
  justify-content: space-between;
  width: 100%;
}

.job-col-right {
  min-height: 300px;
}

.job-details-right-col {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 15px;
}

.job-col-right .job-details {
  gap: 10px;
}

.job-col-right .job-requriments {
  width: calc(100% - 245px);
}

.job-col-right .job-announcements {
  width: 220px;
  position: relative;
}

.job-skill-location-right-col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}

.col-right-j-space {
  max-width: 75%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.job-col-right .top-text-btn-align h3 {
  font-size: 16px;
  max-width: 100%;
}

.job-col-right .job-time p {
  font-size: 14px;
}

.job-col-left .job-time p {
  font-size: 14px;
}

.job-col-right .job-title {
  max-width: 40%;
}

.job-col-right .job-seclude {
  max-width: 50%;
}

.job-col-right .job-services-anchor p {
  font-size: 14px;
}

.job-col-right .job-favorite,
.job-col-right .job-apply,
.job-col-right .job-announcements-status p {
  font-size: 14px;
}

.job-col-right p.job-discription {
  font-size: 14px;
  line-height: 23.38px;
  opacity: 1;
  padding: 20px 0px 20px 0px;
  color: var(--text-color);
  font-weight: 300;
  font-family: var(--arial-main);
  text-align: justify;
  min-height: 110px;
  height: auto;
}

.job-title-left {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
}

.job-services-col-left-w {
  max-width: max-content;
}

.job-services-col-left-w-2 {
  max-width: 100%;
}

.col-left-j-space h3 {
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 0;
  padding: 10px 0;
}

.job-discription-left {
  /* padding: 20px 0px 20px 0px; */
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
  font-family: var(--arial-main);
  line-height: 23.38px;
}

.col-left-j-space .short-content-left .view-job-btn {
  display: block;
  margin: 20px auto 0;
  width: 80%;
}

.job-title-left a,
.col-left-j-space a {
  text-decoration: none;
}

.divider-left {
  border-right: 1px solid var(--text-color);
  opacity: 0.2;
}

.job-match-pro-btn h3 {
  max-width: 100%;
}

.job-services-col-left a {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  width: 100%;
}

.job-services-col-left a img {
  height: 35px;
  width: 35px;
  object-fit: contain;
  position: relative;
  top: -5px;
}

.job-services-col-left a p {
  color: var(--text-color);
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: var(--font-weight-bold);
}

.job-col-right .job-skills button {
  background: #F2F7FF;
  color: var(--primary-color);
  font-size: var(--skill-text-size);
  border-radius: 4px;
  border: 0.5px solid #E2EDFF;
  font-weight: var(--font-weight-medium);
  padding: 4px 8px;
}

.job-col-right .job-skill-location {
  gap: 10px;
}

.job-col-right .job-skills {
  max-width: 66%;
}

.job-col-right .job-location {
  max-width: 32%;
}

.job-col-right .job-location p {
  font-size: 14px;
}

.job-col-right .job-announcements-col button {
  background: var(--color-grey);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  color: var(--primary-color);
  padding: 8px 20px;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
}

.job-type-name{
  max-width: max-content
}

@media only screen and (max-width:768px) {


  .col-left-j-space {
    margin-top: 20px;
    max-width: 25%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    border-radius: none;
    border: none;
    background: none;
  }

}