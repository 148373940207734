/*****************employeee profile ***************/

.employr-f-profile {
  position: relative;
}

.email-candidate-profile-f {
  position: relative;
}

.icon-hide .tooltiptext-profile {
  visibility: hidden;
  width: auto;
  border-radius: 4px;
  border: 0.5px solid var(--error-msg-color);
  background: var(--color-white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: var(--error-msg-color);
  text-align: center;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  top: -22px;
  right: 14px;
  padding: 10px 10px;
  margin: -10px;
}

.icon-hide:hover .tooltiptext-profile {
  visibility: visible;
}

input#Company-name-employe-profile {
  border: 0.5px solid #BDBDBD;
  max-width: 95%;
  width: 100%;
  padding: 0 15px 0 0;
  border: 0;
  border-radius: 0;
}

input#profile-url-employee {
  max-width: 100%;
  width: 100%;
  display: block;
  padding: 0 10px 0 10px !important;
  border: 0;
}

.employee-profile-compnay {
  margin-bottom: 40px;
}

.employee-profile-compnay textarea {
  width: 100%;
  border: 0.5px solid var(--gray-4, #BDBDBD);
  border-radius: 4px;
  color: rgb(0 0 0 / 100%);
  font-size: 16px;
  font-weight: 300;
  font-family: var(--arial-main);
  background-color: rgba(221, 222, 238, 0.25) !important;
}

p.company-name-profile {
  font-size: 24px;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

p.employee-profile-company-content {
  padding: 15px 40px 30px 15px;
  border-radius: 6px;
  border: 0.5px solid var(--gray-4, #BDBDBD);
  color: var(--text-color);
  font-size: var(--body-text);
  font-family: var(--arial-main);
  font-weight: 300;
  opacity: 0.8;
  line-height: 42px;
  text-align: justify;
}

.bg-pass-f>input#Company-name-employe-profile,
.bg-pass-f>input#location-f-profile,
.bg-pass-f>input#profile-url-employee,
.bg-pass-f>input#profile-email-candidate,
.bg-pass-f>input#profile-call,
.bg-pass-f>input#social-link {
  color: rgb(0 0 0 / 100%);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  background-color: rgb(221 222 238 / 0%) !important;
}


/******************************/

.country-code-profile {
  display: flex;
}

input#profile-call {
  max-width: 100%;
  width: 100%;
  display: block;
  padding: 0 10px 0 10px !important;
  border: 0;
}

.candidate-call {
  display: inline-flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  padding: 18px 10px;
  border: 0.5px solid #BDBDBD;
  border-radius: 6px;
}

.candidate-call {
  background: url(/public/image/resume-call.png);
  background-repeat: no-repeat;
  display: block;
  background-size: 20px;
  padding-left: 35px !important;
  background-position: left 10px bottom 20px;
}

input#social-link {
  max-width: 100%;
  width: 100%;
  display: block;
  padding: 0 10px 0 10px !important;
  border: 0;
}

.icon-hide img {
  cursor: pointer;
}

input#candidate-name-field::-webkit-input-placeholder,
input#candidate-lname-field::-webkit-input-placeholder,
input#social-link::-webkit-input-placeholder,
input#profile-email-candidate::-webkit-input-placeholder,
input#profile-call::-webkit-input-placeholder,
input#social-link::-webkit-input-placeholder,
select#candidate-location-feild,
input#Company-name-employe-profile::-webkit-input-placeholder,
input#profile-url-employee::-webkit-input-placeholder,
input#location-f-profile::-webkit-input-placeholder,
input#jon-search-box::-webkit-input-placeholder,
input#preferences-prfile::-webkit-input-placeholder {
  color: rgb(0 0 0 / 40%);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 300;
  /* opacity: 0.8; */
}

p.candidate-phone-profile {
  color: var(--text-color);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  opacity: 0.8;
}

.candidate-username-signup-form input[type="text"]::-webkit-input-placeholder,
.signup-email input[type="email"]::-webkit-input-placeholder,
input#password-field::-webkit-input-placeholder,
.signup-phone input[type="tel"]::-webkit-input-placeholder,
.signup-job-titles input[type="text"]::-webkit-input-placeholder,
.signup-skill-tags input[type="text"]::-webkit-input-placeholder,
.signup-linkedIn-link input[type="text"]::-webkit-input-placeholder,
.candidate-username input[type="text"]::-webkit-input-placeholder,
input#forgotpassowrd-mail::-webkit-input-placeholder,
input#jon-search-box::-webkit-input-placeholder,
input#candidate-list-search::-webkit-input-placeholder,
.signup-company-name input[type="text"]::-webkit-input-placeholder,
.signup-website-url input[type="text"]::-webkit-input-placeholder,
input#password-field-job::-webkit-input-placeholder,
input#email-j-model::-webkit-input-placeholder {
  color: rgb(0 0 0 / 40%);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 300;
}

select#candidate-location-feild option {
  color: var(--text-color);
  font-size: var(--promoted-btn);
  font-family: var(--arial-main);
  opacity: 0.8;
}

.form-font-f {
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: 400;
  background-color: rgba(221, 222, 238, 0.25) !important;
}

.form-font-f:placeholder  {
  color: var(--dark-black-color-300);
}

.form-font-job-m {
  color: rgb(0 0 0 / 100%);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  background-color: rgba(221, 222, 238, 0) !important;
}

.bg-pass-f {
  background-color: rgba(221, 222, 238, 0.25) !important;
}

.bg-pass-f>input#password-field {
  color: rgb(0 0 0 / 100%);
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 400;
  background-color: rgb(221 222 238 / 0%) !important;
}

/******* employe form profile****/

.error-input>.location-f-icon {
  border: 1px solid var(--error-msg-color) !important;
}

.location-f-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 13px 8px;
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.20);
  background-color: rgba(221, 222, 238, 0.25) !important;
}

input#location-f-profile {
  max-width: 90%;
  width: 100%;
  display: block;
  padding: 0 10px 0 10px !important;
  border: 0;
  color: rgb(0 0 0 / 80%);
  font-size: var(--body-text);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.location-profile-list {
  position: relative;
}

.error-input>.candidate-call {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input>.candidate-call>p {
  color: var(--error-msg-color);
}

.btn-height-emp-profile {
  max-height: 50px;
}

/*************post a job error feild**********/

.error-input>.salary-start {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.salary-start :focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0 !important;
}

.error-input>.salary-start :focus-visible {
  border: 0px solid var(--error-msg-color) !important;
  outline: 0 !important;
}

.error-input>.Year {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
  border-radius: 6px;
}

#year :focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0 !important;
}

#fsalary-year :focus-visible {
  border: 0px solid var(--error-msg-color) !important;
  outline: 0 !important;
}

.error-input>#fsalary-year :focus-visible {
  outline: 0 !important;
  border: 0px !important;
}


/* .error-input :focus-visible {
border: 1px solid var(--error-msg-color) !important;
outline: 0 !important;
} */

#fsalary-year:focus-visible {
  border: 0px solid var(--error-msg-color) !important;
  outline: 0 !important;
}


/*******filterdropdown hide ***********/

.job-dropdown-hide {
  display: none;
}


/************* model popup start here **************/

section.model-popup {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 80%);
  top: 0%;
}

section.model-popup-2 {
  z-index: 2;
}

.hide-model-popup {
  display: none;
}

.popup-content {
  width: 60%;
  height: 50%;
  margin: 50px auto 0;
  background: var(--color-white);
  /* padding: 20px 100px 100px 100px; */
  position: relative;
}

.close-img {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

p.popup-text {
  text-align: center;
  font-size: var(--sub-heading);
  color: var(--primary-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

p.popup-text span {
  text-align: center;
  font-size: var(--sub-heading);
  color: var(--accent-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

.send-correct {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 30px 0;
}

.send-correct img {
  max-width: 100%;
  height: auto;
  width: 20%;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emp-profile-btns button img {
  width: 19px;
  height: 19px
}


/*****new location feild in profile****/

.profile-location-feild .admin-status-dropdown-profile {
  display: none !important;
}

.profile-location-feild .admin-status-dropdown-profile.open {
  display: block !important;
}

.location-f-new {
  z-index: 2 !important;
}

.profile-location-feild {
  width: 100%;
}

.admin-status-dropdown-profile {
  position: absolute;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  overflow: auto;
  z-index: 1;
  margin: 15px 0 0 auto;
  left: 0px;
  display: none;
  max-height: 185px;
  height: auto;
}

.text-filter-profile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-left: 10px;
}


/* width */

.admin-status-dropdown-profile::-webkit-scrollbar {
  width: 8px;
}


/* Track */

.admin-status-dropdown-profile::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


/* Handle */

.admin-status-dropdown-profile::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--dark-black-color);
  background-color: var(--dark-black-color);
}

.profile-form-dropdownBox1 {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--text-color);
}


.culture-box {
  border: 1px solid rgba(43, 43, 43, 0.2);
  margin-bottom: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px;
}

.culture-box img {
  max-height: 75px;
  margin-bottom: 20px;
}
.culture-box .form-check-input {
  width: 14px !important;
  height: 14px !important;
}
.culture-box .form-check-input:checked[type=radio] {
  accent-color: var(--dark-black-color) !important;
  background-color: var(--dark-black-color) !important;
  border-color: var(--dark-black-color) !important;
}

.culture-box ul {list-style: none;padding: 0;margin: 0;display: flex;flex-direction: column;gap: 6px;font-size: 14px;}