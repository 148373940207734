.burger-root {
    position: fixed;
    width: 355px;
    height: 100vh;
    background: white;
    right: 0px;
    top: 50px;
}

.profile-section {
    width: 355px;
    height: 205px;
    background: rgb(241, 243, 243);
    border-bottom-left-radius: 200px 80px;
    border-bottom-right-radius: 200px 80px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

}

.loggedout-prof {
    width: 100px;
    height: 100px;

}

.signIn-btns {
    font-size: 14px;
    font-weight: 600;
    color: var(--dark-black-color);
    margin-top: 15px;
    cursor: pointer;
    text-decoration: none;
}

.signIn-btns a {
    color: inherit;
    text-decoration: none;
}

.user-email {
    font-size: 12px;
    font-weight: 300;
    color: #00000080;
    font-family: var(--arial-main);
    margin-top: -4px;

}

.hide-menu {
    display: none;
}

.menu-list {
    padding: 10px 10px;
    margin-top: 30px;

}

.menu-option-div {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
}

.menu-option-div:hover {
    background: #F2F7FF;
}

.active-option h3 {
    font-weight: 600;
}

.active-option {
    background: var(--Whisper-color);
}

.dashboard-icon {
    width: 20px;
    height: 20px;
}

.wish-icon {
    height: 18px;
}

.setting-icon {
    width: 20px;
    height: 23px;
}

.option-name {
    font-size: 16px;
    color: var(--dark-black-color);
    margin-left: 10px;
    margin-top: 12px;
}

@media only screen and (max-width:360px) {
    .burger-root {
        width: 320px;
    }

    .profile-section {
        width: 320px;

    }
}