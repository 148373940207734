/*admin table pagination*/

.pagination-admin-tb {
  display: flex;
  justify-content: end;
  margin-right: 0px;
  align-items: center;
}

.pagination-admin-tb a {
  color: #bdbdbd;
  padding: 1px 7px;
  text-decoration: none;
  border: 1px solid var(--boder-color);
  border-radius: 4px;
  margin: 0 5px;
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.pagination-admin-tb a.active {
  background-color: var(--color-white);
  color: var(--dark-black-color);
  border: 2px solid var(--dark-black-color);
  font-size: var(--skill-text-size);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
}

.pagination-admin-tb a {
  line-height: 20px;
}

.pagination-admin-tb p {
  color: var(--text-color);
  font-size: 13px;
  font-weight: var(--font-weight-medium);
  font-family: var(--arial-main);
}