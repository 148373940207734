.steps-body {
  display: flex;
  gap: 12px;

}

.interview-options {
  width: 414px;
  background-color: #F3F8F9;
}

.interview-steps-maker {
  width: 720px;
  min-height: 295px;
  gap: 0px;
  border-radius: 16px;
  border: 1px solid #2B2B2B;
  padding: 16px;
}

.right-container {
  padding-top: 17px;
}

.interview-steps-maker .steper-header p {
  font-family: Arial;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  padding: 0;
}

.bb {
  font-size: 16px !important;
  font-weight: 400 !important;
  background-color: #F3F3F3;
  padding: 15px !important;
  margin-top: 80px !important;
}

.btn-stages {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.btn-stages .btn {
  padding: 10px 50px;
}

.btn-outline {
  border: 1px solid black !important;
}

.option-box {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 17px 24px;
}

.checkbox {
  max-width: calc(50% - 8px);
  flex: 0 0 calc(50% - 8px);
}

.checkbox-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(43, 43, 43, 0.5);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 80px;
  padding: 10px;
  gap: 8px;
  border-radius: 8px;
}

.checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.checkbox-input:checked+.checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #009FB5;
  border-color: #009FB5;
}

.checkbox-input:checked+.checkbox-tile {
  border-color: #009FB5;
  color: #009FB5;
}

.checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}

.checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}

.checkbox-icon svg {
  width: 3rem;
  height: 3rem;
}



.checkbox-tile:hover {
  border-color: #009FB5;
}

.checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.1px;
  text-align: center;
}

.process-flow {
  margin-bottom: 10px;
  border-radius: 12px;
  background: rgb(0 159 181 / 12%);
  padding: 16px;
  border: 1px solid #009FB5;
  display: flex;
  align-items: center;
  gap: 12px;
}

.stroke {
  margin-left: auto;
  cursor: grab;
}

.process-flow p {
  font-size: 14px;
  color: #2B2B2B;
  font-weight: 700;
}

.checkbox-tile .dd-listitem {
  position: absolute;
  right: 4px;
  top: 2px;
}

.checkbox-tile .dd-listitem .btn img {
  width: 16px;
}

.checkbox-tile .dd-listitem li {
  color: #2B2B2B;
  font-size: 14px;
}

.checkbox-tile .dd-listitem .cv-cions {
  display: none;
}
.checkbox-tile .dd-listitem .cv-cions.active {
  display: block;
}

.checkbox-tile .dd-listitem li img {
  max-width: 16px;
  max-height: 16px;
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media(max-width: 767px) {
  .steps-body {
    flex-direction: column-reverse;
  }

  .interview-options {
    width: 100%;
  }

  .interview-steps-maker {
    width: 100%;
  }

  .btn-stages {
    justify-content: center;
  }
}