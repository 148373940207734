/**********admin from common css******/
/*******error table not found **********/

.error-input-admin>input {
  border: 1px solid var(--error-msg-color) !important;
}

.error-input-admin>input:focus-visible {
  border: 1px solid var(--error-msg-color) !important;
  outline: 0;
}

.error-input-admin>input {
  background: url(/public/image/warning.png) !important;
  background-repeat: no-repeat !important;
  display: block;
  background-position: 98% center !important;
  background-size: 20px;
  padding-right: 40px !important;
}

.error-input-admin .admin-status-filter-form {
  border: 1px solid var(--error-msg-color) !important;
}

.filter-bg-clr-f {
  background-color: rgba(221, 222, 238, 0.25) !important;
}

.hide-error {
  display: none;
}

.email-error--admin p {
  text-align: center;
  padding-top: 20px;
  font-size: 18px;
  color: var(--error-msg-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

/* admin table tooltip */

.tooltip-view-role {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: 15px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: var(--color-white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

.tooltip-crete-role {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: 15px;
  border-radius: 4px;
  border: 0.5px solid var(--dark-black-color);
  background: var(--color-white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: var(--dark-black-color);;
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

.tooltip-edit-role {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: 15px;
  border-radius: 4px;
  border: 0.5px solid #FFA033;
  background: var(--color-white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: #FFA033;
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

.tooltip-delete-role {
  position: absolute;
  top: -35px;
  display: inline-block;
  left: 7px;
  border-radius: 4px;
  border: 0.5px solid #F32131;
  background: var(--color-white);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  color: #F32131;
  font-family: var(--arial-main);
  font-size: 12px;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
}

img.show-tooltip:hover~.tooltip-view-role {
  opacity: 1;
}

img.show-tooltip1:hover~.tooltip-crete-role {
  opacity: 1;
}

img.show-tooltip2:hover~.tooltip-edit-role {
  opacity: 1;
}

img.show-tooltip3:hover~.tooltip-delete-role {
  opacity: 1;
}

.img-text-tooltip {
  position: relative;
}

.hide-role {
  display: none;
}


/*admin search & dropdown*/

.filter-search-bar {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px 15px 0;
  align-items: flex-end;
}

.two-drop-role {
  display: flex;
  gap: 20px;
}

.search-admin {
  min-width: 315px;
  width: 100%;
  margin: 0 auto;
  padding: 7px;
  border: 1px solid rgb(0 0 0 / 30%);
  border-radius: 25px;
  display: flex;
  align-items: center;
}


.search-admin img {
  height: 18px;
  width: 18px;
  position: relative;
  left: 5px;
  margin: 0px 10px;
}

.col-left-adminfilter {
  position: relative;
}

.admin-status-filter {
  padding: 12px;
  border: 0px solid var(--boder-color);
  width: 240px;
  border-radius: 5px;
  background: rgba(221, 222, 238, 0.25);
}

.admin-status-filter.open .admin-status-dropdown {
  display: block;
}

.admin-status-filter .admin-status-dropdown {
  display: none;
}

.col-right-searcgadmin {
  padding-left: 20px;
}

.admin-status-dropdown {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 270px;
  overflow: auto;
  z-index: 1;
  margin: 5px 0 0 auto;
  left: 0;
  top: 100%;
  max-height: 185px;
  height: auto;
}

.text-filter-admin,
.text-filter-emp {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

p.filter-label-admin {
  color: var(--dark-black-color);
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  padding-bottom: 5px;
}

p.text-filter-select {
  color: #000 !important;
  opacity: 1;
}

.text-filter-admin p,
.text-filter-emp p {
  color: var(--dark-black-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}


/* width */

.admin-status-dropdown::-webkit-scrollbar {
  width: 8px;
}


/* Track */

.admin-status-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--dark-black-color);
  border-radius: 10px;
}


/* Handle */

.admin-status-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--dark-black-color);
  background-color: var(--dark-black-color);
}