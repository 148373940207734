/*********right side resume form***********/

.main-heading-form-right {
  color: var(--primary-color);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  text-align: center;
  font-size: 28px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  margin-bottom: 20px;
}

.resume-form-info {
  max-width: 700px;
  width: 100%;
  padding: 20px 10px 20px 10px;
  margin: 0 auto;
}

.personal-info-right {
  font-weight: var(--font-weight-bold);
  color: var(--dark-black-color);
  font-size: 24px;
  text-align: left;
}

.resume-personal-form {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  padding-top: 20px;
}

.first-name-res {
  max-width: 49%;
  width: 100%;
}

.last-name-res {
  max-width: 49%;
  width: 100%;
}

.cv-toggler {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.feild-stl-res {
  width: 100%;
  padding: 13px 8px;
  margin-bottom: 0px;
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.16);
  background: rgba(221, 222, 238, 0.25);
}

.f-feild-res label {
  margin-bottom: 8px;
  color: var(--dark-black-color);
  font-size: 16px;
  font-weight: var(--font-weight-bold);
}

.address-res {
  padding-top: 15px;
}

/***button on last rsume ****/

.two-can-sav-btn-last {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-bottom: 15px;
  margin-top: 0px;
  border-top: 1px solid rgb(0 0 0 / 20%);
  padding-top: 30px;
}

button.can-res-btn-last {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  background: var(--color-white);
  color: var(--dark-black-color);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 15px;
  min-width: 290px;
  padding: 19px 10px 20px;
}

button.save-res-btn-last {
  border-radius: 8px;
  border: 1px solid var(--dark-black-color);
  background: var(--dark-black-color);
  color: var(--color-white);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 15px;
  min-width: 290px;
  padding: 19px 10px 20px;
}

/*************/

button.resume-skills-right-tb {
  border-top: 0px solid rgba(0, 0, 0, 0.16) !important;
  background: var(--color-white) !important;
  padding: 0px 20px 20px 15px !important;
  margin: 0 !important;
  border-radius: 12px !important;
  display: block !important;
}