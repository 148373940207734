/*********notofocation page start here*****/

.job-heading {
  background: var(--pacific-blue-color-300);
  padding: 24px 0;
}

input#chk-notifi {
  accent-color: var(--dark-black-color);
  color: var(--dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
}

.notification-row {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 25px;
}

.left-select {
  display: flex;
  align-items: center;
  gap: 10px;
}

.right-side-content {
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 20px;
  color: var(--dark-black-color);
}

.read-msg-noti {
  color: var(--dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
}

#chk-notifi-row {
  accent-color: var(--dark-black-color);
}

.notification-rows {
  padding: 12px 16px;
  /* display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start; */
  background: #ffffff1a;
  border: 1px solid #2b2b2b4d;
  border-radius: 8px;
  margin-bottom: 8px;
}

.img-left-notfication {
  display: flex;
  align-items: center;
  gap: 10px;
}

.name-notfication,
.text-notofication,
.question-notification {
  display: inline !important;
}

p.name-notfication {
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 700;
  color: var(--dark-black-color);
  line-height: 20px;
}

p.text-notofication {
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 20px;
  color: var(--dark-black-color);
}

.right-side-content a {
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 20px;
  color: #0f66cc;
  text-decoration: underline;
}

.interview-time {
  color: #2b2b2b80;
  font-size: 14px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 24px;
  padding-top: 15px;
  text-align: end;
}

.notification-active-row {
  background: #009fb51a;
  border-left: 8px solid #009fb5;
}

.img-left-notfication {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.user-information-noti {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-information-noti-img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 50%;
}

.unread-msg {
  color: var(--dark-black-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: 400;
  line-height: 18px;
}

.pagination-nofity {
  padding-top: 50px;
}

@media only screen and (max-width: 767px) {
  /*********notification mbl **********/
  .img-left-notfication {
    align-items: flex-start;
  }
  p.name-notfication,
  p.text-notofication,
  a.question-notification,
  .right-side-content a {
    font-size: 14px;
  }
}
