/***********job candidate resume ********/

.resume-sec-upload {
    margin: 50px 0;
    padding: 20px 0;
    background: var(--pacific-blue-color-300);
}

.candidate-job-resume-sec {
    width: 600px;
    margin: 0 auto;
}

.text-mainheadingupload {
    color: var(--dark-black-color);
    font-family: var(--source-pro);
    font-size: 36px;
    font-weight: var(--font-weight-bold);
    line-height: 52px;
    text-align: center;
}

.candidate-job-resume {
    width: 600px;
    margin: 0 auto;
    position: relative;
}

p.text-sumtext-upload {
    color: var(--dark-black-color-060);
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
}

.left-res-txt {
    max-width: 50%;
    width: 100%;
    border-radius: 16px 0px 0px 16px;
    background: #F8FBFF;
    padding: 40px 25px 40px 60px;
}

.main-heading-l {
    color: #5E82B8;
    font-family: var(--arial-main);
    font-size: 50px;
    font-weight: var(--font-weight-bold);
    line-height: 57px;
    letter-spacing: 3px;
    text-transform: capitalize;
    padding-right: 75px;
}

p.job-ai-text {
    color: #000;
    font-family: var(--arial-main);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    padding: 20px 60px 40px 0px;
}

.img-stok-job {
    position: absolute;
    left: 15px;
}

.job-form-info {
    max-width: 100%;
    width: 100%;
    padding: 0px 10px 0px 10px;
}

.resume-form-upload {
    padding-bottom: 50px;
}

.job-res-info-right {
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
    color: var(--primary-color);
    font-size: 28px;
    text-align: left;
}

p.job-info-res {
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
    color: rgb(0 0 0 / 40%);
    font-size: 16px;
    line-height: 52px;
}

.candidate-res-file {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: var(--boarder, 8px);
    border: 1px dashed #143975;
    background: rgba(221, 222, 238, 0.25);
    padding: 15px 0;
    height: 130px;
}

.candidate-res-file input {
    display: none;
}

label#candidate-res img {
    cursor: pointer;
    max-width: 100%;
    margin: 0 auto;
    display: block;
}

p.candidate-file-res-drop {
    padding: 10px 0 6px 0;
    color: var(--dark-black-color);
    text-align: center;
    font-weight: var(--font-weight-bold);
    font-size: 16px;
    line-height: 24px;
}

a.brow-res-f {
    color: var(--primary-color);
    font-family: var(--arial-main);
    font-weight: var(--font-weight-bold);
    font-size: 16px;
    line-height: 24px;
}

p.file-suport-text {
    font-weight: var(--font-weight-bold);
    color: rgb(0 0 0 / 50%);
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.job-res-frm-right {
    padding-top: 20px;
}

.two-can-sav-btn-right {
    display: flex;
    gap: 20px;
    padding-bottom: 15px;
}

.can-res-btn-res,
.save-res-btn-res {
    flex-grow: 1;
    max-width: calc(50% - 10px);
    /* Adjusted to accommodate the gap */
}

button.can-res-btn-res {
    border-radius: 8px;
    border: 1px solid var(--dark-black-color);
    background: var(--color-white);
    color: var(--dark-black-color);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    padding: 19px 37.5px 20px;
}

button.save-res-btn-res {
    border-radius: 8px;
    border: 1px solid var(--dark-black-color);
    background: var(--dark-black-color);
    color: var(--color-white);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    padding: 19px 37.5px 20px;
}