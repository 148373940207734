/****************profile detail fill******/

.intro-profile-vedio-chek {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}


.upload-candidate-intro-vedio {
  max-width: 50%;
  width: 100%;
}

.upload-candidate-intro-vedio img {
  max-width: 100%;
}

.check-box-vedio {
  max-width: 50%;
  width: 100%;
}

p.candidate-profile-data {
  color: var(--text-color);
  font-family: var(--arial-main);
  font-size: 16px;
  line-height: 26px;
  text-align: justify;
}

ul.candidate-profile-data-list {
  color: var(--text-color);
  font-family: var(--arial-main);
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}

.candidate-profile-datefill {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  margin-bottom: 15px;
  padding: 0 10px;
  background: transparent;
  box-shadow: none;
}

.profile-section-view {
  flex: 0 0 calc(74% - 33px);
  max-width: calc(74% - 33px);
}

.divider-bottom-tabdata {
  border-bottom: 0.5px solid var(--boder-color);
}

.divider-top-tabdata {
  border-top: 0.5px solid var(--boder-color);
}

.name-head-black {
  color: var(--primary-color);
}

.accordian-profile-res {
  border: 1px solid #CECECE !important;
  border-radius: 8px;
  height: 58px;
}

.upload-profile-img{
  width: 40px;
}

.progress-container-can-profile{
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}