.decision-banner {
  background: #CEDFEF;
  padding-top: 80px;
}

.d-ban-content {
  font-size: 20px;
  color: var(--dark-black-color);
  padding-right: 5.5rem;
  text-align: justify;
  line-height: 1.2;
}

.text-pacific {
  color: var(--pacific-blue-color);
}

.fw-700 {
  font-weight: 700 !important;
}

.d-ban-content h2 {
  font-family: var(--source-pro);
  font-size: 40px;
  font-weight: 600;
}

.d-ban-content .btn {
  min-width: 250px;
  font-weight: 400;
  line-height: 36px;
}