/***********logo slider poly**********/

.logo-dev-poly {
  padding: 30px 0px;
  background-color: var(--pacific-light-blue-color);
}

.two-heading-row-poly {
  text-align: center;
}

.two-heading-row-poly h2 {
  font-family: var(--source-pro);
  font-weight: var(--font-weight-semibold);
  font-size: 28px;
  line-height: 35.5px;
  color: var(--dark-black-color);
}

p.poly-row-test-job {
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  font-size: 20px;
  margin: 0;
  line-height: 23px;
  color: var(--dark-black-color);
}

/**** company card*****/

.featured-sec {
  padding: 60px 0px 60px 0;
  background: transparent;
}

.footer-row ul {
  padding-left: 0;
}

.featured-col-content {
  background: var(--white-smoke-color);
  padding: 20px 20px;
  margin-bottom: 28px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
}

.featured-col-content:hover{
  background-color: var(--light-aqua-color);
}

.image-heading {
  display: flex;
  align-items: center;
  gap: 0px;
  justify-content: space-between;
}

.image-heading img {
  height: 60px;
  width: 60px;
  object-fit: contain;
  border-radius: 100%;
}

.j-home-btn-round,
button.j-home-btn {
  color: var(--dark-black-color);
  font-size: 20px;
  padding: 8px 18px;
  line-height: 21px;
  border-radius: 4px;
  font-family: var(--arial-main);
  border: 0;
  font-weight: var(--font-weight-bold);
}

.j-home-btn-round {
  padding: 1px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.card-headeing h3 {
  font-size: 20px;
  font-weight: 700;
  padding: 20px 0 5px 0;
  color: var(--dark-black-color);
}

.card-text {
  font-size: 14px;
  color: var(--dark-black-color);
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  padding: 0px 0px 0px 0;
  opacity: 0.5;
  font-family: var(--arial-main);
  text-align: justify;
  min-height: 78px;
  height: auto;
}

button.e-btn {
  background: var(--dark-black-color);
  color: var(--color-white);
  font-size: 14px;
  padding: 8px 18px;
  line-height: 21px;
  border-radius: 4px;
  font-family: var(--arial-main);
  border: 0;
  font-weight: var(--font-weight-medium);
}

.featured-row {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}

button.e-btn-rounded {
  border-radius: 44px;
  cursor: auto;
}

.companyCard-cursor {
  cursor: pointer;
}

@media only screen and (max-width:767px) {
  .featured-row {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .companyCard-cursor {
    width: 328px;
    margin-right: 13px
  }
}

@media only screen and (max-width: 400px) {
  .companyCard-cursor {
    width: 290px;
    margin-right: 13px;
  }

}

@media only screen and (max-width: 359px) {
  .companyCard-cursor {
    width: 250px;
    margin-right: 13px;
    height: 310px
  }

}