.apply-job-modal-title {
    font-weight: 600;
    font-size: 20px;
    font-family: var(--source-pro);
    line-height: 25px;
}

.apply-modal {
    border-radius: 30px;
    max-width: 640px;
}

.btn-close {
    border: 1px solid #2B2B2B;
    border-radius: 50%;
    align-self: flex-start;
    margin: unset;
}

.apply-row-border {
    border: 1px solid rgba(43, 43, 43, 40%);
    border-radius: 8px;
}

.apply-modal-header {
    padding: 16px 16px 10px 20px;
    position: relative;
}
.apply-now-radio-btn {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.modal-lg {
    display: flex;
    justify-content: center;
}

.apply-modal-body {
    max-width: 550px;
}

.section-title {
    font-weight: 600;
    font-size: 18px;
    font-family: var(--source-pro);
    line-height: 25.55px;
}

.ms-data {
    margin-left: 1.5rem;
}

.bg-success-teal {
    background-color: #009FB5;
    border-radius: 50%;
}

.radio-container-teal {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: rgba(43, 43, 43, 1) !important;
}

.form-check-input:checked {
    background-color: #009FB5;
    border-color: #009FB5;
}

.form-check-input {
    --bs-form-check-bg: #737373 !important;
    width: 36px !important;
    height: 20px !important;
}

.form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.form-check-input:focus {
    border-color: #86b7fe;
    box-shadow: none !important;
    outline: 0;
}

.label-teal {
    color: #009FB5;
}

.label-switch {
    font-size: 18px;
    line-height: 24px;
}

.resume-container {
    max-height: 315px;
    overflow-y: auto;
}

.resume-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}

.resume-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 13.8px;
}

.close-icon-button {
    position: absolute;
    right: 16px;
    top: 16px;
}

.apply-on-job-modal-step-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
}

.apply-modal-footer {
    max-width: 550px;
}