.popup-resume-content .btn-black {
  padding: 12px;
  font-size: 18px;
  font-weight: 600;
}

.fd-content h3 {
  color: #000;
  font-size: 28px;
  margin: 0;
  font-weight: 600;
}

.fd-content .tt {
  color: #170F49;
  font-weight: 700;
}

.fd-options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 16px;
}

.fd-options .form-check {
  padding: 0;
  margin: 0;
}

.fd-options .form-check .form-check-input {
  display: none;
}

.fd-options .form-check .form-check-label {
  border: 1px solid #6D6E71;
  border-radius: 8px;
  padding: 6px 10px;
  transition: 0.25s all ease-in-out;
  cursor: pointer;
}

.fd-options .form-check .form-check-input:checked+.form-check-label,
.fd-options .form-check .form-check-label:hover {
  color: var(--color-white);
  background: #000;
  border-color: #000;
}

.fd-options .form-check .form-check-input:not(:checked)+.form-check-label,
.fd-options .form-check .form-check-label:hover {
  color: #000;
  background: var(--color-white);
  border-color: #000;
}

.fd-options p.btn {
  border-width: 1px;
}

.fd-content textarea {
  border-radius: 8px;
  border: 1px solid #E3E3E3;
  background: #F6F6F6;
}