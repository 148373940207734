/*****************employee dasbord ***********/

.employee-job-dasboard {
  gap: 20px;
  padding: 45px 0 50px 0;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: [col1-start] 240px [col2-start] 250px [col3-start] 250px [col4-start] 250px [col5-end];
  grid-template-rows: [row1-start] 152px [row2-start] 152px [row2-end];
  justify-content: center;
}

.employee-job-dasboard .btn-black {
  grid-column: col4-start;
  font-size: 20px;
  font-weight: 400;
  padding: 12px;
}

.modal-content-info .modal-body.job-purchase p {
  color: var(--dark-black-color);
  font-size: 18px;
  margin-top: 12px;
}

.job-buy-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.job-buy-option .form-select {
  width: 120px;
  padding: 11.75px 10px;
  border-color: #000;
}

.job-buy-option .form-select:focus {
  box-shadow: none;
  outline: none;
}

.job-buy-option .btn {
  padding: 10.25px 15px;
}

.job-employee-cards img {
  display: block;
  margin: 0px 0 0 auto;
  padding-bottom: 0;
}

.employee-job-dasboard .link-box {
  display: grid;
  position: relative;
}

.anchor-cards-job-1 {
  background-image: url(/public/image/employee-card-1.jpg);
  /* box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 20px 15px;
  max-width: 100%;
  background-size: cover;
  border: 0;
  background-position: center;
  width: 100%;
  text-decoration: none; */
}

.anchor-cards-job-4 {
  background-image: url(/public/image/employee-card-4.jpg);
  /* box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: 12px;
  padding: 20px 15px;
  max-width: 100%;
  background-size: cover;
  border: 0;
  background-position: center;
  width: 100%;
  text-decoration: none; */
}

.anchor-cards-job-5 {
  background-image: url(/public/image/employee-card-5.png);
  max-width: 520px;
  /* box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: 12px;
  padding: 20px 15px;
  max-width: 520px;
  background-size: cover;
  border: 0;
  background-position: center;
  width: 100%;
  text-decoration: none; */
}

.anchor-cards-job-6 {
  background-image: url(/public/image/employee-card-6.png);
  /* box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: 12px;
  padding: 20px 15px;
  max-width: 100%;
  background-size: cover;
  border: 0;
  background-position: center;
  width: 100%;
  text-decoration: none; */
}


.anchor-cards-job-2 {
  background-image: url(/public/image/employee-card-2.jpg);
  /* box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: 12px;
  padding: 20px 15px;
  max-width: 100%;
  background-size: cover;
  border: 0;
  background-position: center;
  width: 100%;
  text-decoration: none; */
}

.anchor-cards-job-3 {
  background-image: url(/public/image/employee-card-3.jpg);
  /* box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: 12px;
  padding: 20px 15px;
  max-width: 100%;
  background-size: cover;
  border: 0;
  background-position: center;
  width: 100%;
  text-decoration: none; */
}

.job-applied-col.job-employee-cards {
  display: flex;
  align-items: end;
  justify-content: center;
}

.card-1 {
  grid-column: col1-start;
  grid-row: row1-start / row2-end;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card-2 {
  grid-column: col2-start;
  grid-row: row1-start;
}

.card-3 {
  grid-column: col3-start;
  grid-row: row1-start;
}

.card-4 {
  grid-column: col4-start;
  grid-row: row1-start;
}

.card-5 {
  grid-column: col2-start / col4-end;
  grid-row: row2-start;
}

.card-6 {
  grid-column: col4-start;
  grid-row: row2-start;
}

.employee-job-dasboard .link-box img {
  position: absolute;
    top: 20px;
    right: 10px;
    width: 35px;
    height: 30px;
    object-fit: contain;
}

/* .card-2 img {
  width: 26px;
}

.card-3 img {
  width: 22px;
}

.card-4 img,
.card-5 img {
  width: 33px;
}

.card-6 img {
  width: 24px;
} */

.j-fillter-only-table {
  margin: 30px auto 30px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  width: 100%;
  gap: 20px 26px;
  flex-wrap: wrap;
  padding-right: 30px;
}

.job-fillter-company-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
}

.company-form-dropdownBox1-table {
  box-sizing: border-box;
  width: 100%;
  max-width: 484px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--text-color);
  font-size: var(--promoted-btn);
  font-weight: var(--font-weight-medium);
}

.text-filter-icon-table {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

/* .text-filter-icon-table img {
width: 18px;
height: 14px;
} */

.text-filter-icon-table p {
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
}

.j-company-fillter-table {
  border: 0.5px solid var(--boder-color);
  padding: 12px;
  border-radius: 6px;
  width: 200px;
  background: var(--Whisper-color);
  position: relative;

}

.j-company-fillter-table.open {
  display: flex;
  align-items: center;
  position: relative;
}

.j-company-fillter-table .list-search-dropdown-company {
  display: none;
}

.j-company-fillter-table.open .list-search-dropdown-company {
  display: block;
}

.j-company-fillter-table-data {
  padding: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 230px;
  background: var(--Whisper-color);
  position: relative;
}

.j-company-fillter-table-data.open {
  display: flex;
  align-items: center;
  position: relative;
}

.j-company-fillter-table-data .list-search-dropdown-company {
  display: none;
}

.j-company-fillter-table-data.open .list-search-dropdown-company {
  display: block;
}

.company-form-dropdownBox1-tabeldate {
  box-sizing: border-box;
  width: 100%;
  max-width: 484px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--dark-black-color);
  font-size: 13px;
  font-weight: var(--font-weight-medium);
  gap: 8px;
}

.company-form-dropdownBox1-tabeldate span {
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 40px);
  text-align: left;
}

.company-form-dropdownBox1-tabeldate:after {
  content: " ";
  background-image: url(/public/image/table-applicant-arrow.png);
  width: 30px;
  height: 10px;
  background-repeat: no-repeat;
  margin-left: 10px;
  position: absolute;
  background-size: contain;
  right: 0;
  background-position: right 10px bottom 0px;
}

ul.list-table-title-cand {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.list-table-title-cand li {
  border-radius: 8px;
  background: var(--color-white);
  cursor: pointer;
  color: var(--dark-black-color);
  font-size: 12px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 33px;
  text-align: left;
  padding-left: 8px;
}

ul.list-table-title-cand li:hover {
  border-radius: 6px;
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
  background: var(--Whisper-color);
}

.job-fillter-table-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 190px;
  margin-left: 10px;
}

.post-job-table button {
  background: var(--color-grey);
  border: 2px solid var(--dark-black-color);
  border-radius: 8px;
  color: var(--dark-black-color);
  padding: 8px 18px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  width: 100%;
}

.edit-post-job-table button {
  background: transparent;
  border: 1px solid var(--accent-color);
  border-radius: 6px;
  color: var(--accent-color);
  padding: 8px 20px;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  width: 100%;
  margin-top: 15px;
}

/*******filter drop diown *********/

.list-search-dropdown-company {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  overflow: auto;
  z-index: 1;
  margin: 5px 0 0 auto;
  right: 0;
  top: 100%;
  max-height: 185px;
  height: auto;
}

/* width */

.list-search-dropdown-company::-webkit-scrollbar {
  width: 8px;
}

/* Track */

.list-search-dropdown-company::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.list-search-dropdown-company::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--dark-black-color);
  background-color: var(--dark-black-color);
}

ul.list-job-title-company {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.list-job-title-company li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 5px 10px;
  cursor: pointer;
  color: rgb(0 0 0 / 50%);
  font-size: 16px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 1.35;
}

ul.list-job-title-company li:hover {
  border-radius: 4px;
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}

.list-search-dropdown-status {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  overflow: auto;
  z-index: 1;
  margin: 5px 0 0 auto;
  right: 0;
  top: 100%;
  display: none;
}

ul.dorpdown-list-date-company li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 0px 10px;
  cursor: pointer;
  color: rgb(0 0 0 / 50%);
  font-size: 16px !important;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
  display: flex;
  align-items: flex-start;
  word-break: break-word !important;
}

.j-company-fillter.open .list-search-dropdown-company {
  display: block;
}

.j-company-fillter .list-search-dropdown-company {
  display: none;
}

.j-company-fillter.open .list-search-dropdown-status {
  display: block;
}

ul.dorpdown-list-date-company {
  margin: 0;
  list-style: none;
  padding: 15px 10px;
}

ul.dorpdown-list-date-company li:hover {
  border-radius: 6px;
  background: var(--Whisper-color);
  color: var(--text-color);
  font-weight: var(--font-weight-bold);
}

ul.dorpdown-list-date-company li img {
  padding-right: 10px;
  height: 14px;
  position: relative;
  top: 11px;
}

.search-box-location {
  display: flex;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 8px;
  margin: 0 15px;
}

#jon-search-box-location {
  border: 0;
  width: 85%;
  margin-left: 5px;
  border-radius: 4px;
  background: #f9f9f9;
  color: #000;
  font-family: var(--arial-main);
  font-size: 13px;
  font-weight: 300;
}

/************** contry dropdown css*******/

ul.list-job-title-country {
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.list-job-title-country li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 0px 10px;
  cursor: pointer;
  color: rgb(0 0 0 / 50%);
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
}

ul.list-job-title-country li:hover {
  border-radius: 8px;
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}

.industry-feild-frm.open .list-search-dropdown-company {
  display: block;
}

ul.list-job-title-country li img {
  padding: 0px 10px 0 0;
  position: relative;
  top: -2px;
  height: 14px;
  width: 30px;
  object-fit: contain;
}

/**********job a post dropdown **********/

.salary-range-start-end {
  position: relative;
}

.list-search-dropdown-country-job {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 42%;
  overflow: auto;
  z-index: 2;
  margin: 5px 0 0 auto;
  left: 0;
  top: 93%;
  max-height: 185px;
  height: auto;
  display: none;
}

.salary-start.open .list-search-dropdown-country-job {
  display: block;
}

/* width */

.list-search-dropdown-country-job::-webkit-scrollbar {
  width: 8px;
}

/* Track */

.list-search-dropdown-country-job::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.list-search-dropdown-country-job::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
}

.list-search-dropdown-year-job {
  position: absolute;
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.16);
  width: 100%;
  overflow: auto;
  z-index: 2;
  margin: 5px 0 0 auto;
  right: 0;
  top: 90%;
  display: none;
}

.Year.open .list-search-dropdown-year-job {
  display: block;
}

ul.list-job-title-year-job {
  list-style: none;
  margin-bottom: 0;
  padding: 15px 10px;
}

ul.list-job-title-year-job li {
  border-radius: 8px;
  background: var(--color-white);
  padding: 0px 10px 0 2px;
  cursor: pointer;
  color: rgb(0 0 0 / 50%);
  font-size: var(--skill-text-size);
  font-family: var(--arial-main);
  font-weight: var(--font-weight-medium);
  line-height: 40px;
}

ul.list-job-title-year-job li:hover {
  border-radius: 6px;
  background: var(--Whisper-color);
  color: var(--dark-black-color);
  font-weight: var(--font-weight-bold);
}


@media only screen and (max-width:767px) {

  ul.dorpdown-list-date-company li {
    border-radius: 8px;
    background: var(--color-white);
    padding: 0px 10px;
    cursor: pointer;
    color: rgb(0 0 0 / 50%);
    font-size: 18px !important;
    font-family: var(--arial-main);
    font-weight: var(--font-weight-medium);
    line-height: 40px;
    display: flex;
    align-items: flex-start;
    word-break: break-word !important;
  }

}