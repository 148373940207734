/******bulk View Job Description ***/

.feild-f-job-title-bulk {
    width: 100%;
    max-width: 48%;
    position: relative;
}

.bulk-edit-resume-form {
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;
}


/******bulk View Job Description data dill ***/

.f-job-title-bulk-datafill {
    width: 100%;
    max-width: 100%;
    position: relative;
}

ul.bulk-datafill-list {
    color: var(--dark-black-color);
    font-family: var(--arial-main);
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.form-fill-user-bulk {
    color: var(--dark-black-color);
    font-family: var(--arial-main);
    font-size: 16px;
    font-weight: 400;
    word-break: break-word;
    line-height: 20px;
}

.form-fill-user-list {
    color: var(--dark-black-color);
    font-family: var(--arial-main);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    word-break: break-word;
    padding: 20px 0 10px 0;
}

.f-admin-fill label {
    color: var(--dark-black-color);
    font-size: 20px;
    font-weight: var(--font-weight-bold);
    font-family: var(--arial-main);
    margin-bottom: 15px;
}

