/*****canidate dashbord page card******/

.job-recommend-applied {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 50px 0 50px 0;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}

.job-inbox-journey {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 0px 0 100px 0;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}

.recommende-new-job {
  padding: 100px 0 100px 0;
}

a.card-link-box {
  background: var(--color-white);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0 20px 15px;
  max-width: 50%;
  width: 100%;
  text-decoration: none;
}

p.job-title-candidate-dashboard {
  text-align: center;
  color: var(--dark-black-color);
  font-size: 20px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
}

p.job-desc-candidate-dashboard {
  text-align: center;
  color: var(--dark-black-color);
  font-size: 20px;
  font-family: var(--arial-main);
}

.job-applied-col.job-candidate-cards img {
  display: block;
  margin: 0 0 0 auto;
  padding-bottom: 0px;
  width: 35px;
  height: 30px;
  object-fit: contain;
}

p.job-applied-candidate-dashboard {
  color: var(--dark-black-color);
  font-size: 48px;
  font-family: var(--arial-main);
  font-weight: var(--font-weight-bold);
  ;
  text-align: center;
  line-height: normal;
}

.link-box {
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 20px 15px;
  max-width: 285px;
  border: 0;
  width: 100%;
  text-decoration: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

}

a.card-link-box-1 {
  background-image: url('/public/image/candiate-card-1.jpg');
}

a.card-link-box-2 {
  background-image: url('/public/image/candiate-card-2.jpg');
}

a.card-link-box-3 {
  background-image: url('/public/image/candiate-card-3.png');
}

a.card-link-box-4 {
  background-image: url('/public/image/candiate-card-4.png');
}

/*****************candidate dashbor right column***********/

.job-opstion-status {
  display: flex;
  gap: 20px;
  padding: 15px 0px 0 0;
  font-size: 16px;
  color: var(--text-color);
  font-weight: var(--font-weight-medium);
}

.job-date-candidate {
  margin-top: 10px;
}

.job-date-candidate p {
  padding: 0px 0px 0px;
  font-size: 16px;
  color: var(--text-color);
  font-weight: var(--font-weight-regular);
}

.job-applied-text-candidate {
  display: flex;
  gap: 20px;
  padding: 15px 0px 0 0;
  font-size: 16px;
  color: var(--text-color);
  font-weight: var(--font-weight-medium);
}

.job-applied-text-candidate img,
.job-opstion-status img {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.job-application-status p {
  padding: 15px 0px 0px;
  font-size: 14px;
  color: var(--text-color);
  font-weight: var(--font-weight-medium);
}