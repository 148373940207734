/**********rename resume file model***********/

section.model-popup-resmdl {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 80%);
  top: 0%;
  display: grid;
  place-items: center;
}

.modal-body-resume-save {
  align-items: stretch !important;
}

.modal-body-resume-save img {
  margin: 0 10px;
}
section.model-popup-resfile {
  z-index: 4;
}

.send-correct-resume img {
  width: 141px;
  height: 29px;
  object-fit: contain;
}

.save-resume-model-popup-bg {
  width: 370px;
  margin: 0 auto;
}

.popup-resumesave-content {
  width: 100%;
  height: 50%;
  margin: 0px auto 0;
  border-radius: 10px;
  border: 1px solid #ebeffa;
  background: var(--White, #fff);
  box-shadow: 0px 2px 40px 0px rgb(0 0 0 / 30%);
  padding: 24px 27px;
  position: relative;
}

.res-save-heading {
  font-size: 18px;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-family: var(--arial-main);
  line-height: 20px;
  text-transform: capitalize;
  padding: 12px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.res-save-heading img {
  max-width: 100%;
}

.f-feild-renameres label {
  margin-bottom: 8px;
  color: var(--text-color);
  font-family: var(--arial-main);
  font-size: 14px;
  font-weight: 400;
}

button.can-resfile-btn {
  border-radius: 6px;
  border: 1px solid #E15C52;
  background: var(--color-white);
  color: #E15C52;
  text-align: center;
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  min-width: 99px;
  padding: 10px 10px 11px;
}

button.save-resfile-btn {
  border-radius: 6px;
  border: 1px solid #143975;
  background: #143975;
  color: var(--color-white);
  text-align: center;
  font-family: var(--arial-main);
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  min-width: 99px;
  padding: 10px 10px 11px;
}