.mbl-mice-sec{
    display: none;
}
.recruiter-details{
    word-wrap: break-word; /* This property ensures that long words break and wrap onto the next line */
  white-space: pre-line;
  display: flex;
  flex-wrap: wrap;
  
}

ul.ints-steps {
    list-style: none;
    padding: 0 !important;
    margin: 18px 0 0 !important;
}

ul.ints-steps li {
    padding: 0 0 10px 30px;
    position: relative;
    font-size: 16px;
    color: #2B2B2B;
}

ul.ints-steps li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 39%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid #BFBFBF;
}

ul.ints-steps li:not(:last-child):after {
    content: '';
    position: absolute;
    left: 9px;
    top: 26px;
    bottom: 0;
    height: calc(100% - 21px);
    width: 1px;
    border-radius: 10px;
    background: #BFBFBF;
}

.social-media-share {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
}

.pre-line-text {
    white-space: pre-line;
}

@media only screen and (max-width:859px) {
.nice-desktop-sec{
    display: none;
}
.mbl-mice-sec{
    display: flex;
}
.mbl-tags-mg{
    margin-top: -100px;
}
}